// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import ApplePayIntegration from "../../blocks/applepayintegration/src/ApplePayIntegration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import HotelNavigationMenu from "../../blocks/navigationmenu/src/HotelNavigationMenu";
import CarNavigationMenu from "../../blocks/navigationmenu/src/CarNavigationMenu";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import AdvancedSearch from "../../blocks/advancedsearch/src/EndUserHotelAdvanced";
import EndUserHotelAdvanced from "../../blocks/advancedsearch/src/EndUserHotelAdvanced.web";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Maps from "../../blocks/maps/src/Maps";
import Geofence from "../../blocks/geofence/src/Geofence";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Reservations from "../../blocks/reservations/src/Reservations";
import GeneralBusinessInfo from "../../blocks/customisableuserprofiles/src/GeneralBusinessInfo.web"
import CarCatalogue from "../../blocks/catalogue/src/CarCatalogue.web"
import CustomForm from "../../blocks/customform/src/CustomForm.web"
import HotelSettings from "../../blocks/settings2/src/HotelSettings.web"
import HotelCreateRoom from "../../blocks/catalogue/src/HotelCreateRoom.web"
import CarSettings from "../../blocks/settings2/src/CarSettings.web"


const routeMap = {
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
customisableuserprofiles:{
  component:GeneralBusinessInfo,
 path:"/GeneralBusinessInfo"},
customform:{
  component:CustomForm,
 path:"/customform"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Filteritems:{
 component:CarSettings,
path:"/CarSettings"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
CarCatalogue:{
 component:CarCatalogue,
path:"/CarCatalogue"},
HotelCatalogue:{
  component:HotelCreateRoom,
 path:"/HotelCreateRoom"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
MultipleCurrencySupport:{
 component:MultipleCurrencySupport,
path:"/MultipleCurrencySupport"},
CountryCodeSelector:{
 component:HotelSettings,
path:"/HotelSettings"},
// Splashscreen:{
//  component:Splashscreen,
// path:"/Splashscreen"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Location:{
 component:Location,
path:"/Location"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
ApplePayIntegration:{
 component:ApplePayIntegration,
path:"/ApplePayIntegration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EndUserHotelAdvanced:{
  component:EndUserHotelAdvanced,
 path:"/EndUserHotelAdvanced"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Maps:{
 component:Maps,
path:"/Maps"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Reservations:{
 component:Reservations,
path:"/Reservations"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: "*/AlertWeb",
  //   modal: true
  // }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;