import React from "react";
// ...

// Customizable Area Start
import OTPInputAuthController, { Props } from "./OTPInputAuthController";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { checkIcon, crossIcon, } from "./assets";
import { Box, Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import OtpInput from 'react-otp-input';
import { LeftBox } from "../../../components/src/LeftBox";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
        },
    },
});

// Customizable Area End

export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box maxWidth="xl" sx={webStyle.viewContainer}>
                    <LeftBox />
                    {/* Right Block */}
                    <Box style={webStyle.formBlock}>
                        <Box sx={webStyle.form}>
                            <Typography variant="h5" style={webStyle.formHeading}>Email Verification</Typography>
                            <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography style={webStyle.instructions}>Enter Verification Code</Typography>
                                <OtpInput
                                    containerStyle={{ justifyContent: 'space-between' }}
                                    value={this.state.otp}
                                    onChange={this.setOtp}
                                    data-test-id='input1'
                                    numInputs={4}
                                    inputType="tel"
                                    renderInput={(props) => <input {...props} />}
                                    inputStyle={webStyle.otpInput}
                                />
                                <Button onClick={this.handleVerify} data-test-id='verifyBtn' fullWidth style={webStyle.verifyButton}>VERIFY</Button>
                            </Box>
                            <Typography align="center" variant="body2" style={webStyle.resendText}>
                                Don’t receive the email? <span style={webStyle.resendLink} onClick={this.reVerify}>Click to resend</span>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Dialog open={this.state.openDialog} onClose={this.handleCloseDialog} >

                    <Box style={{ display: "flex", flexDirection: 'column', alignItems: 'center', padding: '5px', width: '440px', height: '350px' }}>
                        <DialogContent >
                            <Box sx={webStyle.dialog}>

                                <img height='100px' width='100px' src={this.state.verificationSuccess ? checkIcon : crossIcon} />
                                <Typography style={{ fontWeight: 700, letterSpacing: '-2px', fontSize: '24px', marginTop: '5px', color: '#0F172A', lineHeight: '32px' }}>{this.state.verificationSuccess ? "Email verified" : "Verification failed"}</Typography>
                                <Box textAlign={"center"}>

                                    <Typography style={{ color: '#64748B', fontWeight: 600, fontSize: '10px' }}>
                                        {this.state.verificationSuccess ? "Email verified successfully." : "Something went wrong!"}
                                    </Typography>
                                </Box>
                                <Button onClick={this.state.verificationSuccess ? this.navigateToForm : this.reVerify} variant="outlined" data-test-id="dialogBtn" fullWidth style={{ backgroundColor: '#FAA81D', borderRadius: '8px', padding: '16px' }}>
                                    <Typography style={{
                                        color: "#fff",
                                        lineHeight: "24px",
                                        fontWeight: 700,
                                        fontSize: "16px"
                                    }}>
                                        {this.state.verificationSuccess ? "Continue" : "Resend"}
                                    </Typography>
                                </Button>
                            </Box>
                        </DialogContent>
                    </Box>
                </Dialog>
            </ThemeProvider >
            // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    // custom style definitions
    formBlock: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40%'
    },
    textLinks: {
        color: '#FAA81D',
        cursor: "pointer",
        fontWeight: 700,
        fontSize: '18px'
    },
    dialog: {
        width: "360px",
        height: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center"
    },
    viewContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        width: '100%',
        fontFamily: 'League Spartan, Arial, sans-serif'
    },
    form: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: '400px',
        maxHeight: '350px',
        backgroundColor: '#FEF3C7',
        borderRadius: '24px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    formHeading: {
        fontWeight: 700,
        fontSize: '30px',
        color: '#0F172A',
        letterSpacing: '-2',
        marginBottom: '10px'
    },
    instructions: {
        fontSize: '16px',
        color: '#334155',
        width: '100%'
    },
    otpInput: {
        height: '56px',
        width: '56px',
        fontSize: '24px',
        margin: '6px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        color: '#4B4C4B',
        gap: '20px'
    },
    verifyButton: {
        backgroundColor: '#FAA81D',
        color: 'white',
        padding: '16px',
        fontSize: '16px',
        fontWeight: 700,
        marginTop: '20px',
    },
    resendText: {
        color: '#0F172A',
        fontSize: '14px',
        fontWeight: 400,
        marginTop: '20px',
    },
    resendLink: {
        color: '#FAA81D',
        cursor: "pointer",
        fontWeight: 700,
        marginLeft: '5px'
    }

    // custom style definitions
};
// Customizable Area End
