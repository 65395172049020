import React from "react"
import { Box, Typography } from "@material-ui/core"
import { imgLogo, imgLeft } from "../../blocks/email-account-registration/src/assets"


export const LeftBox = () => {
    return (
        <Box sx={webStyle.leftBlock}>
            {/* logo */}
            <Box sx={webStyle.imageBox}>
                <img style={webStyle.logoImg} src={imgLogo} />
            </Box>
            {/* Content Lines */}
            <Box>
                <Typography variant="h5" gutterBottom align="center" className="LeagueSpartan" style={webStyle.leftHeading}>
                    Let's Explore the Wonders of the Unknown!
                </Typography>
                <Typography variant="subtitle1" align="center" className="LeagueSpartan" style={webStyle.subHeading}>
                    Get all your trip essentials at one place.
                </Typography>
            </Box>
            {/* Hotel illustration */}
            <Box sx={webStyle.imageBox}>
                <img style={webStyle.hotelImage} src={imgLeft} />
            </Box>
        </Box>
    )
}


const webStyle = {
    leftBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '60%',
        height: '100%'
    },
    logoImg: {
        height: '131px',
        width: '118px'
    },
    hotelImage: {
        height: '315px',
        width: '550px'
    },
    imageBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    subHeading: {
        fontWeight: 400,
        fontSize: '22px',
        lineHeight:'40px',
        letterSpacing: '-0.005em'
    },
    leftHeading: {
        fontWeight: 500,
        fontSize: '30px',
        letterSpacing: '-2'
    }
    // custom style definitions
};