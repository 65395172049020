import React from "react";
// ...

// Customizable Area Start
import { ThemeProvider, styled } from "@material-ui/styles";
import { Box, Button, Dialog, DialogContent, Divider, FormControlLabel, Grid, IconButton, InputAdornment, List, ListItem, Menu, MenuItem, Paper, Radio, RadioGroup, Tab, Tabs, TextField, TextareaAutosize, Typography, createTheme } from "@material-ui/core";
import { ic_background, ic_business, ic_logout, ic_notifications, ic_profile, ic_userManagement, } from "./assets";
import * as Yup from 'yup'
import CarNavigationMenu from "../../../../packages/blocks/navigationmenu/src/CarNavigationMenu.web";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { AddCircleOutline, ArrowForwardIos, Clear, DeleteOutline, Edit, MoreVert, Save, Visibility, VisibilityOff } from "@material-ui/icons";
import { BpCheckedIcon, BpIcon } from "./HotelSettings.web";

const theme = createTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#FAA81D",
        },
    },
});

type ProfileField =
    "Language" |
    "Account settings" |
    "Notification" | null

const BusinessSettingsfields: BusinessField[] = [
    "Business profile",
    "Legal",
    "User management",
    "Car categories"
]
type BusinessField =
    "Business profile"
    | "User management"
    | "Legal" |
    "Car categories"
    | null;

const ProfileSettingsfields: ProfileField[] = [
    "Account settings", "Notification",
    "Language",
]


const GeneralBusinessInformationValidationSchema = Yup.object({
    businessName: Yup.string().required('Business name is required'),
    doorNumber: Yup.string().required('Door number/Building name is required'),
    vatNumber: Yup.string().required('VAT Number is required'),
    additionalInfo: Yup.string().max(500, 'Maximum 500 characters allowed'),
    street: Yup.string().required('Street is required'),
    supportEmail: Yup.string().email('Invalid email format').required('Support email is required'),
    city: Yup.string().required('City is required'),
    country: Yup.string().required('Country/Region is required'),
    postCode: Yup.string().required('Post code is required'),
    contactNumber: Yup.string().required('Contact number is required'),
});


const passwordValidationSchema = Yup.object().shape({
    old_password: Yup.string()
        .required('Old password is required'),
    new_password: Yup.string()
        .min(8, 'New password must be at least 8 characters long')
        .required('New password is required'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .required('Please confirm your new password'),
})

const BankingValidationSchema = Yup.object().shape({
    bank: Yup.string()
        .required('Bank name is required'),

    accountHolderName: Yup.string()
        .required('Account holder\'s name is required'),

    iban: Yup.string()
        .matches(/^[A-Z0-9]+$/, 'IBAN must be alphanumeric')
        .required('IBAN is required')
        .length(22, 'IBAN must be exactly 22 characters'),

    accountNumber: Yup.string()
        .required('Account number is required')
        .matches(/^\d+$/, 'Account number must be numeric')
        .max(10, 'Account number must be less than 10 digits')
        .min(7, 'Account number must be at least 7 digits'),


    sortCode: Yup.string()
        .required('Sort code is required')
        .matches(/^\d{2}-\d{2}-\d{2}$/, 'Sort code must be in the format XX-XX-XX'),

    swift: Yup.string()
        .required('SWIFT code is required')
        .matches(/^[A-Z0-9]{8,11}$/, 'SWIFT code must be 8 or 11 characters long'),

    routingNumber: Yup.string()
        .required('Routing number is required')
        .matches(/^\d{9}$/, 'Routing number must be exactly 9 digits'),
});

const addUserSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required'),
    userRole: Yup.string().required('User Role is required'),
    username: Yup.string().required('Username is required'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    jobTitle: Yup.string().required('Job Title is required'),
    password: Yup.string().required('Password is required'),
    email: Yup.string().email('Invalid email address').required('Email Address is required'),
    accessPermission: Yup.string().required('Access Permission is required'),
})


const CustomTabs = styled(Tabs)({
    borderRadius: 5,
    justifyContent: 'start',
    backgroundColor: '#f6f8fa',
    display: 'flex',
    '& .MuiTabs-indicator': {
        display: 'none',
    },
});

const CustomTab = styled(Tab)({
    textTransform: 'none',
    borderRadius: '10px',
    fontSize: theme.typography.pxToRem(15),
    color: '#64748B',
    '&.Mui-selected': {
        backgroundColor: '#fff',
        color: '#FAA81D',
        fontSize: '16px',
        fontWeight: 700,
        fontFamily: 'LeagueSpartan , sans-serif',
        border: '1px solid #CBD5E1'
    },
});

// Customizable Area End

import CarSettingsController, {
    Props,
} from "./CarSettingsController";
import { SwitchBtn } from "./Settings2.web";
import OTPInput from "react-otp-input";
export default class CarSettings extends CarSettingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    BusinessProfile = () => {
        const { BusinessSettingsFields } = this.state;
        switch (BusinessSettingsFields) {
            case 'About Us Information':
                return this.renderAboutUsInformation();
            case 'General Business Information':
                return this.renderGeneralBusinessInformation();
            case 'Payment and Banking Information':
                return this.renderPaymentAndBankingInformation();
        }
    }

    LegalSettings = () => {
        const { LegalSettingsField } = this.state;
        switch (LegalSettingsField) {
            case 'Cancellation Policy':
                return this.renderCancellationPolicy();
            case 'Location Instructions':
                return this.renderLocationInstruction();
            case 'Terms and Conditions':
                return this.renderTermsAndConditions();
            case 'Payment Policy':
                return this.renderPaymentPolicy();
        }
    }

    renderGeneralBusinessInformation = () => {
        return (
            <Formik
                initialValues={{
                    additionalInfo: '',
                    businessName: '',
                    vatNumber: '',
                    postCode: '',
                    doorNumber: '',
                    street: '',
                    country: '',
                    supportEmail: '',
                    city: '',
                    contactNumber: '',
                }}
                validationSchema={GeneralBusinessInformationValidationSchema}
                onSubmit={this.handleGeneralBusinessFormikValues}
            >
                {({ handleSubmit, values }) => (
                    <Form onSubmit={handleSubmit}>
                        <Paper
                            elevation={2}
                        >
                            <Box
                                paddingTop={"40px"}
                                style={{
                                    gap: "24px",
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                <Box style={{
                                    flexDirection: "column",
                                    gap: "16px",
                                    display: "flex",
                                }}>
                                    <Typography
                                        style={{ ...webStyle.leftHeadings, padding: "0px 40px" }}
                                        className="LeagueSpartan"
                                    >General Business Information</Typography>

                                    <Box
                                        display="flex" style={{
                                            padding: "0px 40px",
                                            justifyContent: " space-between",
                                            gap: "16px",
                                        }}>
                                        <Box width={"60%"}>
                                            <Typography style={webStyle.BusinessSettingsSubHeadingTypography}
                                                className="LeagueSpartan">Business name</Typography>
                                            <Field
                                                as={TextField}
                                                name="businessName"
                                                variant="outlined"
                                                fullWidth
                                                type="text"
                                            />
                                            <ErrorMessage name="businessName" component="div" style={{ color: 'red' }} />
                                        </Box>
                                        <Box width={"40%"}>
                                            <Typography style={webStyle.BusinessSettingsSubHeadingTypography}
                                                className="LeagueSpartan"
                                            >VAT Number
                                            </Typography>
                                            <Field
                                                name="vatNumber"
                                                type="text"
                                                as={TextField}
                                                fullWidth
                                                variant="outlined"
                                            />
                                            <ErrorMessage name="vatNumber" component="div" style={{ color: 'red' }} />
                                        </Box>
                                    </Box>

                                    <Box
                                        style={{
                                            flexDirection: 'column',
                                            display: 'flex',
                                            padding: '0px 40px'
                                        }}
                                    >
                                        <Typography
                                            style={webStyle.BusinessSettingsSubHeadingTypography}
                                            className="LeagueSpartan">Description of service</Typography>
                                        <Field
                                            as={TextareaAutosize}
                                            className="textarea"
                                            name="additionalInfo"
                                            minRows={4}
                                        />
                                        <ErrorMessage name="additionalInfo"
                                            component="div" style={{ color: 'red' }} />
                                        <Typography
                                            style={{
                                                alignSelf: "end",
                                                fontSize: '12px',
                                                fontWeight: 400, lineHeight: '18px'
                                            }} className="LeagueSpartan">
                                            {values.additionalInfo?.length || 0}/500</Typography>
                                    </Box>
                                </Box>

                                <Box style={{
                                    gap: "16px", display: "flex",
                                    flexDirection: "column"
                                }}>
                                    <Typography className="LeagueSpartan" style={{
                                        ...webStyle.leftHeadings,
                                        padding: "0px 40px"
                                    }}>Business Address</Typography>
                                    <Box display="flex"
                                        style={{
                                            padding: "0px 40px",
                                            justifyContent: " space-between",
                                            gap: "16px",
                                        }}
                                    >
                                        <Box width={"25%"}>
                                            <Typography className="LeagueSpartan" style={webStyle.BusinessSettingsSubHeadingTypography}>Door number/ Building name</Typography>
                                            <Field
                                                as={TextField}
                                                name="doorNumber"
                                                variant="outlined"
                                                type="text"
                                                fullWidth
                                            />
                                            <ErrorMessage name="doorNumber" component="div" style={{ color: 'red' }} />

                                        </Box>
                                        <Box width={"50%"}>
                                            <Typography style={webStyle.BusinessSettingsSubHeadingTypography}
                                                className="LeagueSpartan"
                                            >Street*</Typography>
                                            <Field
                                                as={TextField}
                                                name="street"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                            />
                                            <ErrorMessage name="street" component="div" style={{ color: 'red' }} />
                                        </Box>
                                        <Box width={"25%"}>
                                            <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Post code*</Typography>
                                            <Field
                                                type="text"
                                                variant="outlined"
                                                as={TextField}
                                                name="postCode"
                                                fullWidth
                                            />
                                            <ErrorMessage name="postCode" component="div" style={{ color: 'red' }} />
                                        </Box>
                                    </Box>
                                    <Box display="flex"
                                        style={{
                                            justifyContent: " space-between",
                                            padding: "0px 40px",
                                            gap: "16px",
                                        }}
                                    >
                                        <Box width={"100%"}>
                                            <Typography
                                                style={webStyle.BusinessSettingsSubHeadingTypography}
                                                className="LeagueSpartan">City*</Typography>
                                            <Field
                                                as={TextField}
                                                type="text"
                                                name="city"
                                                variant="outlined"
                                                fullWidth
                                            />
                                            <ErrorMessage name="city"
                                                component="div" style={{ color: 'red' }} />
                                        </Box>
                                        <Box width={"100%"}>
                                            <Typography
                                                className="LeagueSpartan" style={webStyle.BusinessSettingsSubHeadingTypography}
                                            >
                                                Country/Region*</Typography>
                                            <Field
                                                as={TextField}
                                                name="country"
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                            />
                                            <ErrorMessage name="country" component="div"
                                                style={{ color: 'red' }} />
                                        </Box>
                                    </Box>
                                </Box>

                                <Box style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
                                    <Typography className="LeagueSpartan"
                                        style={{
                                            ...webStyle.leftHeadings,
                                            padding: "0px 40px"
                                        }}>Contact Information
                                    </Typography>
                                    <Box display="flex"
                                        style={{
                                            justifyContent: " space-between",
                                            gap: "16px",
                                            padding: "0px 40px"
                                        }}
                                    >
                                        <Box width={"100%"}>
                                            <Typography style={webStyle.BusinessSettingsSubHeadingTypography}
                                                className="LeagueSpartan">Contact Number</Typography>
                                            <Field
                                                as={TextField}
                                                variant="outlined"
                                                name="contactNumber"
                                                type="tel"
                                                fullWidth
                                            />
                                            <ErrorMessage name="contactNumber" component="div" style={{ color: 'red' }} />
                                        </Box>
                                        <Box width={"100%"}>
                                            <Typography style={webStyle.BusinessSettingsSubHeadingTypography}
                                                className="LeagueSpartan">Support email</Typography>
                                            <Field
                                                name="supportEmail"
                                                as={TextField}
                                                fullWidth
                                                variant="outlined"
                                                type="email"
                                            />
                                            <ErrorMessage
                                                name="suppportEmail"
                                                component="div" style={{ color: 'red' }} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                paddingTop={"20px"}
                            >
                                <Divider />
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} padding={"24px 40px"}>
                                <Button
                                    variant="contained"
                                    style={{
                                        color: '#fff', padding: '16px 24px',
                                        backgroundColor: '#FAA81D',
                                        alignSelf: 'end',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        borderRadius: '8px', float: 'right',

                                    }}
                                    type="submit"
                                    data-test-id="saveChanges"
                                >
                                    Save
                                </Button>
                            </Box>
                        </Paper>
                    </Form>
                )
                }
            </Formik>
        );
    };

    renderPaymentAndBankingInformation = () => {
        const { bankingFormikValues } = this.state
        return (
            <Formik
                initialValues={{
                    accountHolderName: bankingFormikValues.account_holder_name,
                    bank: bankingFormikValues.bank_name,
                    swift: bankingFormikValues.swift,
                    accountNumber: bankingFormikValues.account_number,
                    sortCode: bankingFormikValues.sort_code,
                    iban: bankingFormikValues.IBAN,
                    routingNumber: bankingFormikValues.routing_number,
                }}
                onSubmit={this.handleBankingFormikValues}
                validationSchema={BankingValidationSchema}
                enableReinitialize={true}
            >
                {({ handleSubmit, errors, touched, values }) => (
                    <Form onSubmit={handleSubmit}>
                        <Paper elevation={2}>
                            <Box paddingTop={"40px"}
                                style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
                                <Box
                                    style={{
                                        gap: "16px", display: "flex", flexDirection: "column"
                                    }}>
                                    <Typography className="LeagueSpartan"
                                        style={{ ...webStyle.leftHeadings, padding: "0px 40px" }}>
                                        Payment and Banking Information
                                    </Typography>

                                    <Grid container spacing={2} style={{ padding: "0px 40px" }}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Bank</Typography>
                                            <Field
                                                as={TextField}
                                                name="bank"
                                                value={values.bank}
                                                variant="outlined"
                                                type="text"
                                                error={touched.bank && Boolean(errors.bank)}
                                                fullWidth
                                                helperText={touched.bank && errors.bank}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Account holder's name</Typography>
                                            <Field
                                                as={TextField}
                                                name="accountHolderName"
                                                type="text"
                                                variant="outlined"
                                                helperText={touched.accountHolderName && errors.accountHolderName}
                                                fullWidth
                                                error={touched.accountHolderName && Boolean(errors.accountHolderName)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Account Number</Typography>
                                            <Field
                                                fullWidth
                                                as={TextField}
                                                name="accountNumber"
                                                variant="outlined"
                                                error={touched.accountNumber && Boolean(errors.accountNumber)}
                                                helperText={touched.accountNumber && errors.accountNumber}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">IBAN</Typography>
                                            <Field
                                                as={TextField}
                                                name="iban"
                                                error={touched.iban && Boolean(errors.iban)}
                                                variant="outlined"
                                                // type="text"
                                                fullWidth
                                                helperText={touched.iban && errors.iban}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">SWIFT</Typography>
                                            <Field
                                                as={TextField}
                                                fullWidth
                                                error={touched.swift && Boolean(errors.swift)}
                                                name="swift"
                                                variant="outlined"
                                                helperText={touched.swift && errors.swift}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography
                                                style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Sort Code
                                            </Typography>
                                            <Field
                                                name="sortCode"
                                                variant="outlined"
                                                as={TextField}
                                                // type="tel"
                                                error={touched.sortCode && Boolean(errors.sortCode)}
                                                fullWidth
                                                helperText={touched.sortCode && errors.sortCode}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography style={webStyle.BusinessSettingsSubHeadingTypography}
                                                className="LeagueSpartan">Routing Number</Typography>
                                            <Field
                                                fullWidth
                                                as={TextField}
                                                name="routingNumber"
                                                helperText={touched.routingNumber && errors.routingNumber}
                                                variant="outlined"
                                                error={touched.routingNumber && Boolean(errors.routingNumber)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                            <Box
                                paddingTop={"20px"}
                            >
                                <Divider />
                            </Box>
                            <Box
                                display={"flex"}
                                flexDirection={"column"} padding={"24px 40px"}>
                                <Button
                                    variant="contained"
                                    style={{
                                        alignSelf: 'end',
                                        borderRadius: '8px', color: '#fff',
                                        fontWeight: 700, padding: '16px 24px',
                                        fontSize: '16px', backgroundColor: '#FAA81D',
                                        float: 'right',
                                    }}
                                    data-test-id="saveChanges"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </Box>
                        </Paper>
                    </Form>
                )}
            </Formik>
        );
    };

    renderAboutUsInformation = () => {
        return (
            <Paper elevation={2}>
                <Box paddingTop={"40px"}
                    style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
                    <Box style={{
                        gap: "16px",
                        display: "flex", flexDirection: "column"
                    }}>
                        <Typography style={{ ...webStyle.leftHeadings, padding: "0px 40px" }}
                            className="LeagueSpartan">About Us Information</Typography>
                    </Box>

                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '0px 40px',
                            maxWidth: '100%'
                        }}
                    >
                        <Typography style={webStyle.BusinessSettingsSubHeadingTypography} className="LeagueSpartan">Description of service</Typography>
                        <TextareaAutosize
                            className="textarea LeagueSpartan"
                            data-test-id="handleInputChange"
                            value={this.state.aboutUsInfo}
                            minRows={8}
                            onChange={this.handleInputChange}
                        />
                        <Typography style={{ alignSelf: "end", fontSize: '12px', fontWeight: 400, lineHeight: '18px' }}
                            className="LeagueSpartan">{this.state.aboutUsInfo?.length || 0}/500</Typography>
                    </Box>
                </Box>
                <Box paddingTop={"20px"}>
                    <Divider />
                </Box>
                <Box display={"flex"} flexDirection={"column"} padding={"24px 40px"}>
                    <Button
                        style={webStyle.textAreaBtn}
                        data-test-id="handleAboutUsInfo" onClick={this.handleAboutUsInfo}
                        variant="contained"
                    >Save
                    </Button>
                </Box>
            </Paper>
        );
    };

    renderTermsAndConditions = () => {
        return (
            <Paper elevation={2}>
                <Box
                    paddingTop={"40px"}
                    style={{
                        gap: "24px", display: "flex",
                        flexDirection: "column"
                    }}>
                    <Box style={{
                        gap: "16px",
                        display: "flex", flexDirection: "column"
                    }}>
                        <Typography style={{
                            ...webStyle.leftHeadings,
                            padding: "0px 40px"
                        }} className="LeagueSpartan">Terms and Conditions</Typography>
                    </Box>

                    <Box
                        style={{
                            display: 'flex',
                            maxWidth: '100%',
                            flexDirection: 'column',
                            minHeight: '300px',
                            padding: '0px 40px',
                        }}
                    >

                        <TextareaAutosize
                            className="textarea LeagueSpartan"
                            data-test-id="handleTermsAndConditionsInputChange"
                            minRows={8}
                            value={this.state.termsAndConditions}
                            onChange={this.handleTermsAndConditionsInputChange}
                        />

                        <Typography
                            style={{
                                alignSelf: "end", fontSize: '12px', fontWeight: 400, lineHeight: '18px'
                            }}
                            className="LeagueSpartan"
                        >{this.state.termsAndConditions?.length || 0}/500
                        </Typography>
                    </Box>
                </Box>
                <Box paddingTop={"20px"}>
                    <Divider />
                </Box>
                <Box display={"flex"} flexDirection={"column"} padding={"24px 40px"}>
                    <Button
                        variant="contained"
                        style={webStyle.textAreaBtn}
                        data-test-id="handleTermsAndConditions"
                        onClick={this.handleTermsAndConditions}
                    >Save</Button>
                </Box>
            </Paper>
        );
    };

    renderPaymentPolicy = () => {
        return (
            <Paper elevation={2}>
                <Box
                    paddingTop={"40px"} style={{
                        gap: "24px", display: "flex", flexDirection: "column"
                    }}>
                    <Box
                        style={{
                            gap: "16px", display: "flex", flexDirection: "column"
                        }}>
                        <Typography style={{ ...webStyle.leftHeadings, padding: "0px 40px" }}
                            className="LeagueSpartan">
                            Payment Policy
                        </Typography>
                    </Box>

                    <Box
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                            padding: '0px 40px',
                            minHeight: '300px',
                            maxWidth: '100%'
                        }}
                    >
                        <TextareaAutosize
                            className="textarea LeagueSpartan"
                            minRows={8}
                            data-test-id="handlePaymentPolicyInputChange"
                            onChange={this.handlePaymentPolicyInputChange}
                            value={this.state.paymentPolicy}
                        />
                        <Typography
                            style={{
                                alignSelf: "end",
                                fontSize: '12px',
                                fontWeight: 400, lineHeight: '18px'
                            }}
                            className="LeagueSpartan"
                        >{this.state.paymentPolicy?.length || 0}/500</Typography>
                    </Box>
                </Box>
                <Box
                    paddingTop={"20px"}
                >
                    <Divider />
                </Box>
                <Box display={"flex"}
                    flexDirection={"column"} padding={"24px 40px"}>
                    <Button
                        variant="contained"
                        style={webStyle.textAreaBtn}
                        data-test-id="handlePaymentPolicy"
                        onClick={this.handlePaymentPolicy}
                    >
                        Save
                    </Button>
                </Box>
            </Paper>
        );
    };

    renderCancellationPolicy = () => {
        return (
            <Paper elevation={2}>
                <Box paddingTop={"40px"} style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
                    <Box
                        style={{
                            gap: "16px", display: "flex", flexDirection: "column"
                        }}
                    >
                        <Typography style={{ ...webStyle.leftHeadings, padding: "0px 40px" }}
                            className="LeagueSpartan">Cancellation Policy</Typography>
                    </Box>

                    <Box
                        style={{
                            minHeight: '300px',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '0px 40px',
                            maxWidth: '100%'
                        }}
                    >
                        <TextareaAutosize
                            className="textarea LeagueSpartan"
                            onChange={this.handleCancellationPolicyInputChange}
                            minRows={8}
                            data-test-id="handleCancellationPolicyInputChange"
                            value={this.state.cancellationPolicy}
                        />
                        <Typography style={{
                            alignSelf: "end",
                            fontSize: '12px', fontWeight: 400,
                            lineHeight: '18px'
                        }}
                            className="LeagueSpartan">{this.state.cancellationPolicy?.length || 0}/500</Typography>
                    </Box>
                </Box>
                <Box paddingTop={"20px"}>
                    <Divider />
                </Box>
                <Box display={"flex"} flexDirection={"column"} padding={"24px 40px"}>
                    <Button
                        variant="contained"
                        style={webStyle.textAreaBtn}
                        data-test-id="handleCancellationPolicy"
                        onClick={this.handleCancellationPolicy}
                    >
                        Save</Button>
                </Box>
            </Paper>
        );
    };

    renderLocationInstruction = () => {
        return (
            <Paper elevation={2}>
                <Box
                    paddingTop={"40px"}
                    style={{ gap: "24px", display: "flex", flexDirection: "column" }}>
                    <Box
                        style={{ gap: "16px", display: "flex", flexDirection: "column" }}>
                        <Typography style={{ ...webStyle.leftHeadings, padding: "0px 40px" }}
                            className="LeagueSpartan">
                            Location Instructions
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            flexDirection: 'column',
                            minHeight: '300px',
                            display: 'flex',
                            padding: '0px 40px',
                            maxWidth: '100%'
                        }}
                    >
                        <TextareaAutosize
                            data-test-id="handleLocationInstructionInputChange"
                            onChange={this.handleLocationInstructionInputChange}
                            minRows={8}
                            value={this.state.locationInstruction}
                            className="textarea LeagueSpartan"
                        />

                        <Typography
                            style={{ alignSelf: "end", fontSize: '12px', fontWeight: 400, lineHeight: '18px' }}
                            className="LeagueSpartan">
                            {this.state.locationInstruction?.length || 0}/500</Typography>
                    </Box>
                </Box>
                <Box
                    paddingTop={"20px"}>
                    <Divider />
                </Box>
                <Box display={"flex"}
                    flexDirection={"column"}
                    padding={"24px 40px"}>
                    <Button
                        data-test-id="handleLocationInstruction"
                        style={webStyle.textAreaBtn}
                        onClick={this.handleLocationInstruction}
                        variant="contained"
                    >
                        Save</Button>
                </Box>
            </Paper>
        );
    };

    UserManagement = () => {
        return (
            <Paper elevation={2}>
                <Box
                    paddingY={"40px"}
                    style={{
                        gap: "24px",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                    <Box style={{
                        gap: "16px",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <Typography style={{
                            ...webStyle.leftHeadings,
                            padding: "0px 40px"
                        }}
                            className="LeagueSpartan"
                        >User management</Typography>
                    </Box>
                    {this.state.userListArray.length !== 0 ? (
                        <Box paddingX={"40px"}
                            overflow={"hidden"}
                            display={"flex"}
                            flexDirection={"column"}
                            style={{ gap: "16px" }}>
                            {this.state.userListArray.map((user, key) => (
                                <Box
                                    padding={"16px"} border={"1px solid #CBD5E1"}
                                    borderRadius={"8px"} flexDirection={"row"}
                                    display={"flex"}
                                    key={key}
                                    style={{
                                        gap: "16px",
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        width={"90%"} style={{ gap: '16px' }}>
                                        <Typography className="LeagueSpartan"
                                            style={{
                                                color: '#1E293B',
                                                fontSize: '18px',
                                                fontWeight: 700,
                                                lineHeight: "26px"
                                            }}>
                                            {user.fullName}
                                        </Typography>
                                        <Typography
                                            className="LeagueSpartan"
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                lineHeight: "22px"
                                            }}
                                        >
                                            Username: {" "}
                                            <span
                                                className="LeagueSpartan"
                                                style={{
                                                    color: '#1E293B', fontSize: '14px',
                                                    fontWeight: 700, lineHeight: "22px"
                                                }}
                                            >{user.username}</span>
                                        </Typography>

                                        <Typography
                                            className="LeagueSpartan"
                                            style={{ fontSize: '14px', fontWeight: 400, lineHeight: "22px" }}
                                        >
                                            Job Title: {" "}
                                            <span
                                                style={{ color: '#1E293B', fontSize: '14px', fontWeight: 700, lineHeight: "22px" }}
                                                className="LeagueSpartan"
                                            >{user.jobTitle}</span>
                                        </Typography>

                                        <Typography
                                            className="LeagueSpartan"
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                lineHeight: "22px"
                                            }}
                                        >
                                            Phone Number: {" "}
                                            <span
                                                className="LeagueSpartan"
                                                style={{ color: '#1E293B', fontSize: '14px', fontWeight: 700, lineHeight: "22px" }}
                                            >{user.phoneNumber}</span>
                                        </Typography>
                                    </Box>
                                    <Button style={{
                                        alignSelf: "start",
                                        paddingLeft: '30px'
                                    }}
                                        data-test-id={`menuBtn${key}`}
                                        onClick={(event) => this.handleMenuOpen(event, key)}>
                                        <MoreVert />
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Box
                            style={{
                                justifyContent: 'center',
                                padding: '40px',
                                gap: '24px',
                                display: 'flex',
                                maxWidth: '100%',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img src={ic_userManagement} height={"240px"} width={"240px"} />
                            <Box textAlign={"center"}>
                                <Typography className="LeagueSpartan" style={{ fontWeight: 400, fontSize: '20px', lineHeight: '28px' }}>
                                    There are no users currently added.
                                    <br />Click 'Add User' to get started.
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                style={{ ...webStyle.textAreaBtn, alignSelf: 'center' }}
                                data-test-id="handleAddUserDialog2"
                                onClick={this.handleAddUserDialog}
                            >
                                Add User
                            </Button>
                        </Box>
                    )}

                    <Menu
                        onClose={this.handleMenuClose}
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                    >
                        <MenuItem data-test-id="editBtn"
                            style={{
                                display: 'flex',
                                padding: "12px 16px",
                                gap: '8px'
                            }}
                            onClick={this.handleEditUser}>
                            <Edit style={{ color: "#64748B" }} />
                            <Box>
                                <Typography
                                    className="LeagueSpartan">
                                    Edit
                                </Typography>
                            </Box>
                        </MenuItem>
                        <MenuItem data-test-id="deleteBtn"
                            onClick={this.handleDeleteUser}
                            style={{ display: 'flex', color: '#DC2626', justifyContent: 'space-between', padding: "12px 16px", gap: '8px' }}
                        >
                            <DeleteOutline />
                            <Box>
                                <Typography className="LeagueSpartan">
                                    Delete user
                                </Typography>
                            </Box>
                        </MenuItem>
                    </Menu>
                </Box>
            </Paper>
        )
    }

    CarCategories = () => {
        const { selectedCarCategory, carCategories, openSubCategory, showNewField } = this.state
        const newFieldIndex = selectedCarCategory ? carCategories[selectedCarCategory].length - 1 : -1;
        const newFieldValue = selectedCarCategory ? carCategories[selectedCarCategory][newFieldIndex] : '';

        return (
            <Paper style={{
                display: 'flex', flexDirection: 'column', minHeight: '700px',
                width: '100%', gap: '30px', padding: '40px 16px 40px 16px'
            }}
                elevation={2}
            >
                <style>
                    {
                        `
                & .MuiInput-underline:before {
                    border-bottom: none;
                  }
                `
                    }
                </style>
                {Object.keys(carCategories).map((category) => (
                    <Box key={category} style={{
                        boxShadow: "0px 2px 8px 0px #0000001A",
                        display: 'flex', justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0px 16px 0px 16px'
                    }}
                    >
                        <Typography className="LeagueSpartan" style={webStyle.formControlLanguageHeading}>
                            {category}
                        </Typography>
                        <IconButton
                            onClick={() => this.handleClickOpen(category)}
                            style={{ color: "#FAA81D" }}
                            data-test-id="categoryBtn"
                        >
                            <ArrowForwardIos />
                        </IconButton>

                    </Box>
                ))}

                <Dialog open={openSubCategory}>
                    <style>
                        {`
                        .MuiPaper-rounded {
                          border-radius:8px 8px 32px 8px;
                        }
                        .MuiDialog-paperWidthSm {
                          max-width: 800px;
                        }
                        .MuiDialog-paperScrollPaper {
                          max-height: 800px;
                        }
                        .MuiListItem-root{
                            padding:4px 0px;
                        }
                    `}
                    </style>
                    <DialogContent
                        style={{
                            padding: "10px 0px 0px 0px",
                            overflowX: 'hidden',
                            borderRadius: '8 8 32 8',
                            width: '600px'
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"} >
                            <Box display={"flex"}
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                padding={"16px 0px 16px 40px"}>
                                <Typography
                                    className="LeagueSpartan"
                                    style={{
                                        color: '#0F172A',
                                        fontSize: '24px',
                                        fontWeight: 700,
                                        letterSpacing: '-0.5',
                                        lineHeight: '32px',
                                    }}>
                                    {selectedCarCategory}
                                </Typography>
                                <Box alignSelf={"end"}>
                                    <Button
                                        onClick={this.handleCloseCarCategories}
                                        data-test-id="handleCloseCarCategories"
                                    >
                                        <Clear />
                                    </Button>
                                </Box>
                            </Box>

                            <Divider />


                            <Box display="flex" flexDirection="column" padding="8px 40px" style={{ gap: '5px' }}>
                                <List disablePadding>
                                    {selectedCarCategory && carCategories[selectedCarCategory].map((item, index) => (
                                        <ListItem key={index}>
                                            <TextField
                                                value={item}
                                                data-test-id={`selectedCarCategory${index}`}
                                                onChange={(event) => this.handleItemChange(index, event)}
                                                fullWidth
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: '16px',
                                                    lineHeight: '24px',
                                                    color: '#0F172A'
                                                }}
                                                className="LeagueSpartan"
                                                autoFocus={showNewField && index === carCategories[selectedCarCategory].length - 1} // Auto focus only the new field
                                            />
                                            <IconButton
                                                data-test-id={`deleteSelectedCarCategory${index}`}
                                                onClick={() => this.handleDeleteItem(index)}
                                                style={{ color: 'red' }}>
                                                <DeleteOutline />
                                            </IconButton>
                                            {showNewField && index === newFieldIndex && (
                                                <IconButton
                                                    data-test-id={`handleSaveItem`}
                                                    onClick={this.handleSaveItem}
                                                    style={{ color: newFieldValue.length > 0 ? '#FAA81D' : '#64748B' }}>
                                                    <Save />
                                                </IconButton>
                                            )}
                                        </ListItem>
                                    ))}
                                    {selectedCarCategory && (
                                        <ListItem style={{ display: 'flex', justifyContent: "space-between" }}>
                                            <Typography
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: '16px',
                                                    lineHeight: '24px',
                                                    color: '#0F172A'
                                                }}
                                                className="LeagueSpartan"
                                            >
                                                Add one more
                                            </Typography>
                                            <IconButton
                                                data-test-id="addnewitem"
                                                onClick={this.handleAddItem}
                                                style={{ color: '#FAA81D' }}>
                                                <AddCircleOutline />
                                            </IconButton>
                                        </ListItem>
                                    )}
                                </List>
                            </Box>

                            <Box
                                paddingTop={"20px"}>
                                <Divider />
                            </Box>

                            <Box
                                style={{ gap: '16px' }}
                                display={'flex'}
                                padding={'24px'} justifyContent={'end'} >
                                <Button
                                    data-test-id="handleCloseCarCategories"
                                    onClick={this.handleCloseCarCategories}
                                    style={{
                                        borderRadius: '8px',
                                        border: '#FAA81D 1px solid',
                                        color: '#FAA81D',
                                        padding: '16px 26px',
                                        fontWeight: 700, fontSize: '16px',
                                        lineHeight: '24px'
                                    }}
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    onClick={this.handleSave}
                                    data-test-id="handleSave"
                                    style={{
                                        color: '#fff',
                                        fontWeight: 700,
                                        backgroundColor: '#FAA81D',
                                        padding: '16px 24px',
                                        borderRadius: '8px',
                                        fontSize: '16px', lineHeight: '24px',
                                    }}
                                >
                                    SAVE</Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            </Paper>
        )
    }

    AddUserForm = () => {
        const { selectedUser } = this.state
        return (
            <Formik
                initialValues={
                    selectedUser ? {
                        userRole: selectedUser.userRole,
                        fullName: selectedUser.fullName,
                        email: selectedUser.email,
                        jobTitle: selectedUser.jobTitle,
                        password: selectedUser.password,
                        phoneNumber: selectedUser.phoneNumber,
                        accessPermission: selectedUser.accessPermission,
                        username: selectedUser.username,
                    } : {
                        jobTitle: "",
                        userRole: "",
                        fullName: "",
                        phoneNumber: "",
                        email: "",
                        password: "",
                        username: "",
                        accessPermission: "",
                    }
                }
                onSubmit={this.handleSaveUser}
                enableReinitialize={true}
                validationSchema={addUserSchema}
            >
                {({ handleSubmit, errors, values }) => (
                    <Form
                        onSubmit={handleSubmit}
                        style={{ margin: "0px" }}>
                        <Box paddingTop={"10px"}
                            style={{
                                gap: "24px",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                            <Box style={{
                                gap: "16px",
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <Box
                                    padding={"0px 40px"}
                                    display={"flex"}
                                    flexDirection={"column"}>
                                    <Typography
                                        style={webStyle.BusinessSettingsSubHeadingTypography}
                                        className="LeagueSpartan"
                                    >Full Name</Typography>
                                    <Field
                                        variant="outlined"
                                        as={TextField}
                                        value={values.fullName}
                                        type="text"
                                        name="fullName"
                                    />
                                    {errors.fullName &&
                                        <Typography color="error" style={webStyle.errorText}>{errors.fullName}</Typography>}
                                </Box>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{ padding: "0px 40px" }}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography style={webStyle.BusinessSettingsSubHeadingTypography}
                                            className="LeagueSpartan">User Role</Typography>
                                        <Field
                                            as={TextField}
                                            name="userRole"
                                            variant="outlined"
                                            type="text"
                                            fullWidth
                                        />
                                        {errors.userRole &&
                                            <Typography color="error" style={webStyle.errorText}>{errors.userRole}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            style={webStyle.BusinessSettingsSubHeadingTypography}
                                            className="LeagueSpartan">Job Title</Typography>
                                        <Field
                                            name="jobTitle"
                                            as={TextField}
                                            fullWidth
                                            variant="outlined"
                                        />
                                        {errors.jobTitle &&
                                            <Typography color="error" style={webStyle.errorText}>{errors.jobTitle}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            style={webStyle.BusinessSettingsSubHeadingTypography}
                                            className="LeagueSpartan">Email Address</Typography>
                                        <Field
                                            name="email"
                                            as={TextField}
                                            fullWidth
                                            variant="outlined"
                                        />
                                        {errors.email &&
                                            <Typography color="error" style={webStyle.errorText}>{errors.email}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            style={webStyle.BusinessSettingsSubHeadingTypography}
                                            className="LeagueSpartan">Phone Number</Typography>
                                        <Field
                                            as={TextField}
                                            name="phoneNumber"
                                            fullWidth
                                            variant="outlined"
                                        />
                                        {errors.phoneNumber && <Typography color="error"
                                            style={webStyle.errorText}>{errors.phoneNumber}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography style={webStyle.BusinessSettingsSubHeadingTypography}
                                            className="LeagueSpartan">Username</Typography>
                                        <Field
                                            as={TextField}
                                            name="username"
                                            fullWidth
                                            variant="outlined"
                                        />
                                        {errors.username && <Typography
                                            color="error" style={webStyle.errorText}>{errors.username}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography style={webStyle.BusinessSettingsSubHeadingTypography}
                                            className="LeagueSpartan">Password</Typography>
                                        <Field
                                            name="password"
                                            as={TextField}
                                            variant="outlined"
                                            type="password"
                                            fullWidth
                                        />
                                        {errors.password && <Typography color="error" style={webStyle.errorText} >
                                            {errors.password}</Typography>}
                                    </Grid>
                                </Grid>
                                <Box display={"flex"} flexDirection={"column"} paddingX={"40px"}>
                                    <Typography style={webStyle.BusinessSettingsSubHeadingTypography}
                                        className="LeagueSpartan">Access Permissions</Typography>
                                    <Field name="accessPermission">
                                        {({ field }: any) => (
                                            <RadioGroup {...field}
                                                data-test-id="accessPermission" style={{
                                                    display: 'flex',
                                                    width: "100%",
                                                    gap: '16px',
                                                    flexDirection: "column", borderRadius: '10px'
                                                }}>
                                                <FormControlLabel
                                                    labelPlacement="end"
                                                    style={webStyle.FormControlCheck}
                                                    label={
                                                        <Typography style={webStyle.accessPermissionTypography} className="LeagueSpartan">
                                                            Can view</Typography>
                                                    }
                                                    value="view"
                                                    name="accessPermission"
                                                    control={
                                                        <Radio
                                                            style={webStyle.radioBtn}
                                                            disableRipple
                                                            checkedIcon={<BpCheckedIcon />}
                                                            icon={<BpIcon />}
                                                        />
                                                    }
                                                />
                                                <FormControlLabel
                                                    style={webStyle.FormControlCheck}
                                                    labelPlacement="end"
                                                    label={
                                                        <Typography style={webStyle.accessPermissionTypography}>
                                                            Can edit</Typography>
                                                    }
                                                    value="edit"
                                                    name="accessPermission"
                                                    control={
                                                        <Radio
                                                            style={webStyle.radioBtn}
                                                            disableRipple
                                                            checkedIcon={<BpCheckedIcon />}
                                                            icon={<BpIcon />}
                                                        />
                                                    }
                                                />
                                                <FormControlLabel
                                                    labelPlacement="end"
                                                    style={webStyle.FormControlCheck}
                                                    label={
                                                        <Typography style={webStyle.accessPermissionTypography}>
                                                            Can manage within the system</Typography>
                                                    }
                                                    value="system"
                                                    name="accessPermission"
                                                    control={
                                                        <Radio
                                                            style={webStyle.radioBtn}
                                                            checkedIcon={<BpCheckedIcon />}
                                                            disableRipple
                                                            icon={<BpIcon />}
                                                        />
                                                    }
                                                />
                                            </RadioGroup>)}
                                    </Field>
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            style={{ paddingTop: '20px' }}
                        >
                            <Divider />
                        </Box>

                        <Box
                            style={{ gap: '16px' }}
                            display={'flex'}
                            padding={'24px'}
                            justifyContent={'end'} >
                            <Button
                                data-test-id="closeAddUserDialog"
                                onClick={this.closeAddUserDialog}
                                style={{
                                    borderRadius: '8px',
                                    color: '#FAA81D',
                                    fontWeight: 700, fontSize: '16px',
                                    border: '#FAA81D 1px solid',
                                    lineHeight: '24px',
                                    padding: '16px 26px',
                                }}
                            >
                                CANCEL
                            </Button>
                            <Button
                                type="submit"
                                data-test-id="handleLogout"
                                style={{
                                    backgroundColor: '#FAA81D',
                                    color: '#fff',
                                    padding: '16px 24px',
                                    fontWeight: 700,
                                    fontSize: '16px', lineHeight: '24px',
                                    borderRadius: '8px',
                                }}
                            >
                                SAVE</Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        )
    }

    AddUserDialogBox = () => {
        return (
            <Dialog open={this.state.addUserDialogBox}>
                <style>
                    {`
                     .MuiDialog-paperWidthSm {
                         max-width: 800px;
                       }
                       .MuiDialog-paperScrollPaper {
                         max-height: 800px;
                       }
                     .MuiPaper-rounded {
                       border-radius:8px 8px 32px 8px
                     }
        `}
                </style>
                <DialogContent
                    style={{
                        overflow: 'hidden',
                        padding: "10px 0px 0px 0px",
                    }}
                >
                    <Box display={"flex"}
                        flexDirection={"column"} >
                        <Box display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            padding={"16px 0px 16px 40px"}>
                            <Typography
                                className="LeagueSpartan"
                                style={{
                                    fontWeight: 700,
                                    color: '#0F172A',
                                    lineHeight: '32px',
                                    letterSpacing: '-0.5',
                                    fontSize: '24px',
                                }}>
                                Add new user
                            </Typography>
                            <Box alignSelf={"end"}>
                                <Button
                                    onClick={this.closeAddUserDialog}
                                    data-test-id="closeAddUserDialog"
                                >
                                    <Clear />
                                </Button>
                            </Box>
                        </Box>
                        <Divider />

                        {this.AddUserForm()}
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    renderBusiness = () => {
        const { businessActiveField } = this.state;

        switch (businessActiveField) {
            case 'Business profile':
                return (
                    this.BusinessProfile()
                );
            case 'Legal':
                return (
                    this.LegalSettings()
                );
            case 'User management':
                return (
                    this.UserManagement()
                );
            case 'Car categories':
                return (
                    this.CarCategories()
                )
        }
    }

    renderProfile = () => {
        const { profileActiveField } = this.state
        switch (profileActiveField) {
            case 'Account settings':
                return (
                    <>
                        {this.renderAccountSettings()}
                    </>
                )
            case 'Language':
                return (
                    <>
                        {this.renderLanguage()}
                    </>
                )
            case 'Notification':
                return (
                    <>
                        {this.renderNotifications()}
                    </>
                )
        }
    }

    renderAccountSettings = () => {
        return (
            <Paper>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    style={{
                        gap: "16px",
                        minHeight: '540px'
                    }}
                    padding={"0px 40px 24px 40px"}>
                    <Typography
                        className="LeagueSpartan"
                        style={{
                            fontWeight: 700,
                            fontSize: '20px',
                            color: '#334155',
                            lineHeight: '28px',
                            paddingTop: '40px'
                        }}
                    >
                        Account Settings
                    </Typography>

                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={6}>
                            <Typography
                                className="LeagueSpartan"
                                style={webStyle.BusinessSettingsSubHeadingTypography}
                            >
                                First name
                            </Typography>
                            <TextField
                                type="text"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                className="LeagueSpartan"
                                style={webStyle.BusinessSettingsSubHeadingTypography}
                            >
                                Second name
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                type="text"
                            />

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                className="LeagueSpartan"
                                style={webStyle.BusinessSettingsSubHeadingTypography}
                            >
                                Email address
                            </Typography>
                            <TextField
                                fullWidth
                                value={this.state.email}
                                disabled
                                variant="outlined"
                                className="emailDialog"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className="emailDialogBtn"
                                                onClick={this.openEmailDialogBox}>
                                                <Edit style={{ color: "#FAA81D" }} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Typography
                                className="LeagueSpartan"
                                style={webStyle.BusinessSettingsSubHeadingTypography}
                            >
                                Password
                            </Typography>
                            <TextField
                                variant="outlined"
                                type="password"
                                className="passDialog"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end">
                                            <IconButton
                                                onClick={this.openPasswordDialogBox}
                                                className="passDialogBtn"
                                            >
                                                <Edit style={{ color: "#FAA81D" }} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        data-test-id="deleteBtn"
                        onClick={this.handleDeleteAccount}
                        style={{
                            display: 'flex',
                            color: '#DC2626',
                            gap: '8px'
                        }}
                    >
                        <DeleteOutline />
                        <Box>
                            <Typography
                                className="LeagueSpartan"
                                style={{ fontSize: '18px', lineHeight: '26px', fontWeight: 700, }}>
                                Delete account
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box
                    display={'flex'}
                    padding={'20px'}
                    justifyContent={'end'}>
                    <Button
                        data-test-id="handleAccountSettings"
                        onClick={this.handleAccountSettings}
                        style={webStyle.saveBtn}
                    >
                        SAVE
                    </Button>
                </Box>
            </Paper>
        )
    }

    renderLanguage = () => {
        return (
            <Paper>
                <Box display={"flex"} flexDirection={"column"}
                    style={{
                        gap: "16px",
                        minHeight: '540px'
                    }}
                    padding={"0px 40px 24px 40px"}>
                    <Typography
                        className="LeagueSpartan"
                        style={{
                            fontWeight: 700,
                            fontSize: '20px',
                            color: '#334155',
                            lineHeight: '28px',
                            paddingTop: '40px'
                        }}
                    >
                        Language
                    </Typography>
                    <RadioGroup
                        onChange={this.handleLanguageChange}
                        value={this.state.language}
                        data-test-id="handleLanguageChange"
                        style={{
                            display: 'flex',
                            width: "100%",
                            gap: '16px',
                            flexDirection: "column",
                            borderRadius: '10px',
                        }}
                    >
                        <FormControlLabel
                            labelPlacement="start"
                            style={webStyle.FormControlCheckLanguage}
                            label={
                                <Typography style={webStyle.languageTypography} className="LeagueSpartan">
                                    English</Typography>
                            }
                            value="english"
                            control={
                                <Radio
                                    style={webStyle.radioBtn}
                                    checkedIcon={<BpCheckedIcon />}
                                    disableRipple
                                    icon={<BpIcon />}
                                />
                            }
                        />
                        <FormControlLabel
                            style={webStyle.FormControlCheckLanguage}
                            labelPlacement="start"
                            label={
                                <Typography style={webStyle.languageTypography}>
                                    Spanish</Typography>
                            }
                            value="spanish"
                            control={
                                <Radio
                                    disableRipple
                                    style={webStyle.radioBtn}
                                    icon={<BpIcon />}
                                    checkedIcon={<BpCheckedIcon />}
                                />
                            }
                        />
                        <FormControlLabel
                            style={webStyle.FormControlCheckLanguage}
                            labelPlacement="start"
                            label={
                                <Typography
                                    style={webStyle.languageTypography}>
                                    Icelandic</Typography>
                            }
                            value="icelandic"
                            control={
                                <Radio
                                    disableRipple
                                    checkedIcon={<BpCheckedIcon />}
                                    style={webStyle.radioBtn}
                                    icon={<BpIcon />}
                                />
                            }
                        />
                    </RadioGroup>
                </Box>
                <Divider />
                <Box
                    display={'flex'}
                    padding={'20px'} justifyContent={'end'}>
                    <Button
                        onClick={this.handleLanguageSettings}
                        style={webStyle.saveBtn}
                        data-test-id="handleLanguageSettings"
                    >
                        SAVE
                    </Button>
                </Box>
            </Paper>

        )
    }

    renderNotifications = () => {
        return (
            <Paper>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    style={{ gap: "16px", minHeight: '540px' }} padding={"0px 40px 24px 40px"}>
                    <Typography
                        style={{
                            fontWeight: 700,
                            fontSize: '20px',
                            color: '#334155',
                            lineHeight: '28px',
                            paddingTop: '40px'
                        }}
                        className="LeagueSpartan"
                    >
                        Notification
                    </Typography>
                    <Box
                        style={{
                            display: 'flex',
                            width: "100%",
                            gap: '16px',
                            padding: '12px 16px',
                            flexDirection: "column",
                            borderRadius: '10px',
                        }}
                    >
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}>
                            <Typography
                                style={webStyle.languageTypography}
                            >Allow push notifications to this device</Typography>
                            <SwitchBtn
                                data-test-id="handleAllowPushNotificationDevice"
                                checked={this.state.allowPushNotificationDevice}
                                onChange={this.handleAllowPushNotificationDevice}
                            />
                        </Box>
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography style={webStyle.languageTypography}>Push notifications</Typography>
                            <SwitchBtn
                                checked={this.state.allowPushNotification}
                                data-test-id="handleAllowPushNotification"
                                onChange={this.handleAllowPushNotification}
                            />
                        </Box>
                        <Box display={"flex"}
                            justifyContent={"space-between"}>
                            <Typography style={webStyle.languageTypography}>Email</Typography>
                            <SwitchBtn
                                onChange={this.handleAllowEmail}
                                data-test-id="handleAllowEmail"
                                checked={this.state.allowEmail}
                            />
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box
                    display={'flex'} padding={'20px'} justifyContent={'end'}>
                    <Button
                        style={webStyle.saveBtn}
                        data-test-id="handleNotificationSettings"
                        onClick={this.handleNotificationSettings}
                    >
                        SAVE
                    </Button>
                </Box>
            </Paper>
        )
    }

    EmailDialogBox = () => {
        return (
            <Dialog open={this.state.emailDialogBox}>
                <style>
                    {`
                        .MuiPaper-rounded {
                          border-radius:8px 8px 32px 8px
                        }
                        .MuiDialog-paperWidthSm {
                          max-width: 800px;
                        }
                        .MuiDialog-paperScrollPaper {
                          max-height: 800px;
                        }
                    `}
                </style>
                <DialogContent
                    style={{
                        padding: "10px 0px 0px 0px",
                        overflow: 'hidden',
                        width: '600px'
                    }}
                >
                    <Box
                        display={"flex"}
                        flexDirection={"column"} >
                        <Box display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            padding={"16px 0px 16px 40px"}>
                            <Typography
                                className="LeagueSpartan"
                                style={{
                                    color: '#0F172A',
                                    fontSize: '24px',
                                    fontWeight: 700,
                                    letterSpacing: '-0.5',
                                    lineHeight: '32px',
                                }}>
                                Change email
                            </Typography>
                            <Box alignSelf={"end"}>
                                <Button
                                    onClick={this.closeEmailDialogBox}
                                    data-test-id="closeEmailDialogBox"
                                >
                                    <Clear />
                                </Button>
                            </Box>
                        </Box>

                        <Divider />

                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            paddingY={"20px"}
                            style={{ gap: '10px' }}>
                            <Box padding={"0px 40px"}
                                display={"flex"}
                                flexDirection={"column"}>
                                <Typography style={webStyle.BusinessSettingsSubHeadingTypography}
                                    className="LeagueSpartan"
                                >
                                    New email address
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                />
                            </Box>
                            <Box padding={"0px 40px"}
                                display={"flex"} flexDirection={"column"}>
                                <Typography
                                    style={webStyle.BusinessSettingsSubHeadingTypography}
                                    className="LeagueSpartan">
                                    Confirm email address
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                />
                            </Box>
                            <Box padding={"0px 40px"} display={"flex"} flexDirection={"column"}>
                                <Typography style={webStyle.BusinessSettingsSubHeadingTypography}
                                    className="LeagueSpartan">
                                    Confirm password
                                </Typography>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                />
                            </Box>
                        </Box>

                        <Box
                            paddingTop={"20px"}>
                            <Divider />
                        </Box>

                        <Box
                            style={{ gap: '16px' }}
                            display={'flex'}
                            padding={'24px'} justifyContent={'end'} >
                            <Button
                                data-test-id="closeEmailDialogBox"
                                onClick={this.closeEmailDialogBox}
                                style={{
                                    borderRadius: '8px',
                                    border: '#FAA81D 1px solid',
                                    color: '#FAA81D',
                                    padding: '16px 26px',
                                    fontWeight: 700, fontSize: '16px',
                                    lineHeight: '24px'
                                }}
                            >
                                CANCEL
                            </Button>
                            <Button
                                onClick={this.handleEmailChange}
                                data-test-id="handleEmailChange"
                                style={{
                                    color: '#fff',
                                    fontWeight: 700,
                                    backgroundColor: '#FAA81D',
                                    padding: '16px 24px',
                                    borderRadius: '8px',
                                    fontSize: '16px', lineHeight: '24px',
                                }}
                            >
                                SAVE</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    UpdateEmailDialogBox = () => {
        return (

            <Dialog open={this.state.showUpdateEmailDialogBox}>
                <style>
                    {`
                       .MuiPaper-rounded{
                         border-radius:8px 8px 32px 8px
                       }
                    `}
                </style>
                <DialogContent
                    style={{
                        height: "280px",
                        padding: "10px 0px",
                        width: "600px",
                    }}>
                    <Box
                        flexDirection={"column"}
                        display={"flex"}
                    >
                        <Box alignSelf={"end"}>
                            <Button
                                data-test-id="closeUpdateEmailDialogBox"
                                onClick={this.closeUpdateEmailDialogBox}
                            >
                                <Clear />
                            </Button>
                        </Box>
                        <Box padding={'25'} style={{
                            gap: "10px",
                            display: "flex",
                            flexDirection: "column"
                        }}>
                            <Typography
                                className="LeagueSpartan"
                                style={{
                                    color: '#0F172A',
                                    lineHeight: '32px',
                                    fontSize: '20px',
                                    fontWeight: 700,
                                }}
                            >Your email address has been updated!</Typography>
                            <Typography
                                className="LeagueSpartan"
                                style={{
                                    color: '#0F172A',
                                    fontWeight: 400,
                                    fontSize: '15px',
                                    lineHeight: '26px'
                                }}>We sent you a verification code to confirm your new email. Please <br /> open this link to verify the change</Typography>
                        </Box>
                        <Box style={{ gap: '10px' }}
                            display={'flex'}
                            padding={'24px 24px 0px 0px'}
                            justifyContent={'end'} borderTop={"1px #CBD5E1 solid"} >
                            <Button
                                data-test-id="closeUpdateEmailDialogBox"
                                onClick={this.closeUpdateEmailDialogBox}
                                style={{
                                    color: '#FAA81D',
                                    border: '#FAA81D 1px solid',
                                    fontWeight: 700, fontSize: '16px',
                                    borderRadius: '8px',
                                    padding: '16px 26px',
                                    lineHeight: '24px'
                                }}
                            >
                                CANCEL
                            </Button>
                            <Button
                                data-test-id="handleEmailDialogContinue"
                                onClick={this.handleEmailDialogContinue}
                                style={{
                                    color: '#fff',
                                    borderRadius: '8px',
                                    backgroundColor: '#FAA81D',
                                    fontWeight: 700,
                                    padding: '16px',
                                    fontSize: '16px', lineHeight: '24px'
                                }}
                            >
                                OPEN EMAIL</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    PasswordDialogBox = () => {
        return (
            <Dialog open={this.state.passwordDialogBox}>
                <style>
                    {`
                        .MuiDialog-paperScrollPaper {
                            max-height: 800px;
                        }
                        .MuiPaper-rounded {
                          border-radius:8px 8px 32px 8px
                        }
                        .MuiDialog-paperWidthSm {
                          max-width: 800px;
                        }
                    `}
                </style>

                <Formik
                    validationSchema={passwordValidationSchema}
                    initialValues={{
                        old_password: '',
                        new_password: '',
                        confirm_password: ''
                    }}
                    validateOnMount={true}
                    onSubmit={this.handlePasswordChange}
                >
                    {({ values, handleChange, isValid, }) => (
                        <Form
                            style={{ margin: '0px', padding: '0px' }}
                        >
                            <DialogContent
                                style={{
                                    padding: "10px 0px 0px 0px",
                                    overflow: 'hidden',
                                    width: '600px'
                                }}
                            >
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"} >
                                    <Box
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                        padding={"16px 0px 16px 40px"}>
                                        <Typography
                                            className="LeagueSpartan"
                                            style={{
                                                fontWeight: 700,
                                                lineHeight: '32px',
                                                fontSize: '24px',
                                                color: '#0F172A',
                                                letterSpacing: '-0.5'
                                            }}>
                                            Change password
                                        </Typography>
                                        <Box alignSelf={"end"}>
                                            <Button
                                                data-test-id="closePasswordDialogBox"
                                                className="closePasswordDialogBox"
                                                onClick={this.closePasswordDialogBox}
                                            >
                                                <Clear />
                                            </Button>
                                        </Box>
                                    </Box>

                                    <Divider />
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        width={'100%'}
                                        style={{
                                            padding: '20px 0px',
                                            justifyContent: 'space-between',
                                            gap: '15px'
                                        }}
                                    >
                                        <Box padding={"10px 20px"} style={{
                                            gap: "16px",
                                            flexDirection: "column",
                                            display: 'flex',
                                        }}>
                                            <Box>
                                                <Typography
                                                    className="LeagueSpartan"
                                                    style={webStyle.BusinessSettingsSubHeadingTypography}>Old password</Typography>
                                                <Field
                                                    as={TextField}
                                                    name="old_password"
                                                    variant="outlined"
                                                    type={this.state.showOldPassword ? 'text' : 'password'}
                                                    fullWidth
                                                    value={values.old_password}
                                                    className="login_test_password"
                                                    inputProps={{ style: { backgroundColor: "white" } }}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        style: {
                                                            backgroundColor: 'white',
                                                            padding: '2'
                                                        },
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    className="password_button_type"
                                                                    onClick={() => this.handleToggle(1)}
                                                                >
                                                                    {
                                                                        !this.state.showOldPassword ? <VisibilityOff /> : <Visibility />
                                                                    }
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    placeholder="********"
                                                />
                                                <ErrorMessage
                                                    name="old_password"
                                                    component="div"
                                                    style={{ color: 'red', fontSize: '10px' }} />
                                            </Box>
                                            <Box>
                                                <Typography className="LeagueSpartan"
                                                    style={webStyle.BusinessSettingsSubHeadingTypography}>New password</Typography>
                                                <Field
                                                    as={TextField}
                                                    fullWidth
                                                    name="new_password"
                                                    variant="outlined"
                                                    value={values.new_password}
                                                    type={this.state.showNewPassword ? 'text' : 'password'}
                                                    onChange={handleChange}
                                                    className="login_test_password2"
                                                    inputProps={{
                                                        style: {
                                                            backgroundColor: "white"
                                                        }
                                                    }}
                                                    InputProps={{
                                                        style: {
                                                            backgroundColor: 'white',
                                                            padding: '2'
                                                        },
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => this.handleToggle(2)}
                                                                    className="password_button_type2"
                                                                    aria-label="toggle password visibility"
                                                                >
                                                                    {
                                                                        !this.state.showNewPassword ? <VisibilityOff /> : <Visibility />
                                                                    }
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    placeholder="********"
                                                />
                                                <ErrorMessage
                                                    name="new_password"
                                                    component="div" style={{
                                                        color: 'red',
                                                        fontSize: '10px'
                                                    }} />
                                            </Box>
                                            <Box>
                                                <Typography className="LeagueSpartan" style={webStyle.BusinessSettingsSubHeadingTypography}>Confirm password</Typography>
                                                <Field
                                                    as={TextField}
                                                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                                                    variant="outlined"
                                                    name="confirm_password"
                                                    fullWidth
                                                    className="login_test_password3"
                                                    value={values.confirm_password}
                                                    onChange={handleChange}
                                                    inputProps={{ style: { backgroundColor: "white" } }}
                                                    InputProps={{
                                                        style: {
                                                            backgroundColor: 'white',
                                                            padding: '2'
                                                        },
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    className="password_button_type3"
                                                                    onClick={
                                                                        () => this.handleToggle(3)
                                                                    }
                                                                >
                                                                    {
                                                                        !this.state.showConfirmPassword ?
                                                                            <VisibilityOff />
                                                                            :
                                                                            <Visibility />
                                                                    }
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    placeholder="********"
                                                />
                                                <ErrorMessage name="confirm_password" component="div"
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '10px'
                                                    }} />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box
                                        paddingTop={"20px"}>
                                        <Divider />
                                    </Box>

                                    <Box style={{
                                        gap: '16px'
                                    }}
                                        display={'flex'}
                                        padding={'24px'}
                                        justifyContent={'end'} >
                                        <Button
                                            data-test-id="closePasswordDialogBox"
                                            onClick={this.closePasswordDialogBox}
                                            style={{
                                                borderRadius: '8px',
                                                border: '#FAA81D 1px solid',
                                                color: '#FAA81D',
                                                padding: '16px 26px',
                                                fontWeight: 700, fontSize: '16px',
                                                lineHeight: '24px'
                                            }}
                                        >
                                            CANCEL
                                        </Button>
                                        <Button
                                            style={{
                                                color: isValid ? '#fff' : '#64748B',
                                                borderRadius: '8px', fontWeight: 700,
                                                backgroundColor: isValid ? '#FAA81D' : '#F1F5F9',
                                                padding: '16px 24px',
                                                fontSize: '16px', lineHeight: '24px',
                                            }}
                                            disabled={!isValid}
                                            type="submit"
                                        >
                                            CHANGE PASSWORD  </Button>
                                    </Box>
                                </Box>
                            </DialogContent>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        )
    };

    HotelSettingsMain = () => {
        return (
            <Box display={'flex'} flexDirection={'row'} height={'100%'}
                style={{
                    fontFamily: 'League Spartan , sans-serif'
                }}>
                {/* left */}
                <Box width={'120px'}>
                    <CarNavigationMenu navigation={undefined} id={""} />
                </Box>

                {/* right */}
                <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'} style={{
                    backgroundImage: `url(${ic_background})`,
                    backgroundRepeat: "no-repeat", backgroundSize: 'cover',
                }}>

                    {/* header for setting */}
                    <Box display={'flex'} justifyContent={"space-between"} padding={'20px 40px'} style={{ borderLeft: "1px #CBD5E1 solid" }}>
                        <Typography variant="h5" className="LeagueSpartan"
                            style={{
                                fontWeight: 700,
                                fontSize: '24px', color: "#0F172A"
                            }}>Settings</Typography>
                        <Box>
                            {this.state.businessActiveField === 'User management' &&
                                this.state.userListArray.length !== 0 &&
                                <Button
                                    style={{
                                        fontWeight: 700, fontStyle: 'bold', alignSelf: 'start', fontSize: '18px', lineHeight: '26px', backgroundColor: "#FAA81D", color: '#fff',
                                        textTransform: 'none', padding: '10px 16px 10px 12px'
                                    }}
                                    data-test-id="handleAddUserDialog2"
                                    startIcon={<AddCircleOutline style={{ height: '24px', width: '24px', backgroundColor: "#FAA81D", color: '#fff' }} />}
                                    onClick={this.handleAddUserDialog}
                                    className="LeagueSpartan"
                                >
                                    ADD USER
                                </Button>
                            }<Button><img src={ic_notifications} alt="" /></Button>
                        </Box>
                    </Box>

                    {/* settings */}
                    <Box display={'flex'} flexDirection={'row'} >

                        {/* left */}
                        <Box width={"360px"} style={{
                            backgroundColor: '#fff',
                            borderLeft: "1px #CBD5E1 solid",
                            minHeight: '100vh'
                        }} padding={"10px 15px 0px 10px"}  >
                            {/* innerBox */}
                            <Box padding={'10px'}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "30px",
                                }}>
                                {/* Business box */}
                                <Box
                                    width={'310px'}
                                    padding={"16px 20px"}
                                    display={"flex"}
                                    borderRadius={'8px'}
                                    style={{
                                        backgroundColor: this.state.BusinessSettings ? '#FEF3C7' : '#fff',
                                        gap: "10px"
                                    }}
                                >
                                    <img
                                        height={"24px"}
                                        width={"24px"}
                                        src={ic_business} alt="" />
                                    <Box display={"flex"} flexDirection={"column"}>
                                        <Typography variant="h6" style={webStyle.leftHeadings}
                                            className="LeagueSpartan">Business Settings</Typography>
                                        <Box style={{ padding: "10px 0px 0px 4px" }}>
                                            {BusinessSettingsfields.map((field, index) => (
                                                <Typography
                                                    key={index}
                                                    onClick={() => this.handleBusinessFieldClick(field)}
                                                    className="LeagueSpartan"
                                                    data-test-id={`Businesscase${index}`}
                                                    style={
                                                        this.state.businessActiveField === field ? webStyle.FieldActive : webStyle.Fields}
                                                >
                                                    {field}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                                {/* Profile box */}
                                <Box
                                    display={"flex"} width={'310px'}
                                    borderRadius={'8px'} padding={"16px 20px"}
                                    style={{
                                        backgroundColor: this.state.ProfileSettings ? '#FEF3C7' : '#fff',
                                        gap: "10px"
                                    }}
                                >
                                    <img height={"24px"}
                                        width={"24px"}
                                        src={ic_profile} alt="" />
                                    <Box display={"flex"} flexDirection={"column"}>
                                        <Typography variant="h6"
                                            style={webStyle.leftHeadings}
                                            className="LeagueSpartan">Personal Settings</Typography>
                                        <Box
                                            style={{
                                                padding: "10px 0px 0px 4px"
                                            }}>
                                            {ProfileSettingsfields.map((field, index) => (
                                                <Typography
                                                    key={index}
                                                    onClick={() => this.handleProfileFieldClick(field)}
                                                    className="LeagueSpartan"
                                                    data-test-id={`Profilecase${index}`}
                                                    style={
                                                        this.state.profileActiveField === field ? webStyle.FieldActive : webStyle.Fields}
                                                >
                                                    {field}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>

                                {/*Log out  */}
                                <Box style={webStyle.list}
                                    data-test-id="logoutBox" >
                                    <img height={"24px"} width={"24px"} src={ic_logout} alt="" />
                                    <Typography variant="h6" style={{
                                        color: '#DC2626',
                                        fontWeight: 700, fontSize: '20px',
                                        lineHeight: '28px'
                                    }}
                                        className="LeagueSpartan"
                                    >Log out</Typography>
                                </Box>

                            </Box>
                        </Box>

                        {/* Right */}
                        <Box width={"100"} style={{
                            padding: "20px 40px", width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px"
                        }}>
                            {this.state.BusinessSettings &&
                                <>
                                    {this.state.businessActiveField === "Business profile" &&
                                        <>
                                            <style>{`
                                                 .MuiTabs-flexContainer{
                                                   justify-content:space-between;
                                                 }
                                                 .MuiTab-root{
                                                   min-width:33%
                         }
                         `}
                                            </style>
                                            <CustomTabs
                                                onChange={this.handleBusinessTabChange}
                                                value={this.state.BusinessSettingsFields}
                                                data-test-id="tabs1"
                                            >
                                                <CustomTab data-test-id="GBI"
                                                    label={
                                                        <Typography className="LeagueSpartan"
                                                            style={webStyle.customTabTypography}>
                                                            General Business Information
                                                        </Typography>
                                                    }
                                                    value="General Business Information" />
                                                <CustomTab data-test-id="PBI"
                                                    label={
                                                        <Typography className="LeagueSpartan"
                                                            style={webStyle.customTabTypography}>
                                                            Payment and Banking Information
                                                        </Typography>
                                                    }
                                                    value="Payment and Banking Information" />
                                                <CustomTab data-test-id="AUI"
                                                    label={
                                                        <Typography className="LeagueSpartan"
                                                            style={webStyle.customTabTypography}>
                                                            About Us Information
                                                        </Typography>
                                                    }
                                                    value="About Us Information" />
                                            </CustomTabs>
                                        </>
                                    }
                                    {this.state.businessActiveField === "Legal" &&
                                        <>
                                            <style>
                                                {`
                                                    .MuiTabs-flexContainer{
                                                      justify-content:space-between;
                                                    }
                                                    .MuiTab-root{
                                                      min-width:25%
                                                    }
                                                `}
                                            </style>
                                            <CustomTabs
                                                value={this.state.LegalSettingsField}
                                                onChange={this.handleLegalTabChange}
                                                data-test-id="tabs2"
                                            >
                                                <CustomTab data-test-id="GBI"
                                                    label={
                                                        <Typography
                                                            className="LeagueSpartan"
                                                            style={webStyle.customTabTypography}>
                                                            Terms and Conditions
                                                        </Typography>
                                                    }
                                                    value="Terms and Conditions" />
                                                <CustomTab data-test-id="PBI"
                                                    label={
                                                        <Typography
                                                            className="LeagueSpartan"
                                                            style={webStyle.customTabTypography}>
                                                            Payment Policy
                                                        </Typography>
                                                    }
                                                    value="Payment Policy" />
                                                <CustomTab data-test-id="AUI"
                                                    label={
                                                        <Typography
                                                            className="LeagueSpartan"
                                                            style={webStyle.customTabTypography}>
                                                            Cancellation Policy
                                                        </Typography>
                                                    }
                                                    value="Cancellation Policy" />

                                                <CustomTab data-test-id="AUI"
                                                    label={
                                                        <Typography className="LeagueSpartan"
                                                            style={webStyle.customTabTypography}>
                                                            Location Instructions
                                                        </Typography>
                                                    }
                                                    value="Location Instructions" />
                                            </CustomTabs>
                                        </>

                                    }
                                    {this.renderBusiness()}
                                </>
                            }
                            {this.state.ProfileSettings && this.renderProfile()}
                        </Box>
                    </Box>

                </Box>
            </Box>
        )
    }

    Otp = () => {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
                height={'100%'}
                style={{
                    backgroundRepeat: "no-repeat", backgroundSize: 'cover',
                    backgroundImage: `url(${ic_background})`,
                }}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Paper style={{
                    height: '350px',
                    display: 'flex',
                    padding: '20px',
                    justifyContent: "center",
                    alignItems: "center"
                }} elevation={2}>

                    <Box sx={webStyle.form}>
                        <Box display={"flex"}>
                            <Typography
                                variant="h5"
                                style={{
                                    ...webStyle.formHeading,
                                    paddingBottom: '20px'
                                }}>Verification Code</Typography>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'left',

                            }}>
                            <Typography
                                className="LeagueSpartan"
                                style={webStyle.instructions}
                            >
                                Enter Verification Code</Typography>
                            <Box>
                                <OTPInput
                                    value={this.state.otp}
                                    containerStyle={{ justifyContent: 'space-between' }}
                                    onChange={this.setOtp}
                                    data-test-id='input1'
                                    inputType="tel"
                                    numInputs={4}
                                    inputStyle={{ ...webStyle.otpInput }}
                                    renderInput={(props) => <input {...props} />}
                                />
                            </Box>

                            <Box display={"flex"} paddingY={"20px"} flexDirection={"column"}>
                                <Button
                                    data-test-id='verifyBtn'
                                    onClick={this.handleEmailOtpVerification}
                                    style={{ ...webStyle.verifyButton, alignSelf: 'end' }}>SEND</Button>
                                fullWidth
                            </Box>
                            <Typography align="center"
                                variant="body2"
                                style={{
                                    ...webStyle.resendText,
                                }}
                                className="LeagueSpartan"
                            >
                                Don’t receive the email?
                                <span
                                    className="LeagueSpartan"
                                    style={webStyle.resendLink}
                                    data-test-id="reverifyBtn"
                                    onClick={this.reVerify}>Click to resend</span>
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        )
    }


    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <style>
                    {`
          
                    .textarea {
                      max-width: 100%;
                      border-radius: 8px;
                      min-height: 60px;
                      border: 1px solid #CBD5E1;
                      padding: 14px;
                      font-size: 16px;
                      min-width: 100%;
                      font-weight: 400;
                      color: #0F172A;
                    }
                    .textarea:focus {
                      border: 1px solid #625d58;
                      outline: none;
                    }
          `}
                </style>
                {this.state.showOtpSection ?
                    this.Otp()
                    :
                    this.HotelSettingsMain()
                }

                {this.AddUserDialogBox()}
                {/* email dialog box for account settings */}

                {this.state.showUpdateEmailDialogBox && this.UpdateEmailDialogBox()}
                {this.EmailDialogBox()}


                {/* password dialog box for account settings */}
                {this.PasswordDialogBox()}
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    // custom style definitions

    Fields: {
        fontWeight: 400,
        padding: '4px 16px',
        fontSize: '16px',
        color: '#0F172A',
        borderLeft: '#CBD5E1 1px solid',
        lineHeight: '24px',
        cursor: 'pointer',
    },
    otpInput: {
        height: '56px',
        borderRadius: '8px',
        fontSize: '24px',
        border: '1px solid #ccc',
        width: '56px',
        margin: '6px',
        color: '#4B4C4B',
        gap: '20px'
    },

    dialog: {
        width: "360px",
        flexDirection: "column",
        height: "300px",
        justifyContent: "space-around",
        display: "flex",
        alignItems: "center"
    },
    list: {
        gap: '10px',
        display: 'flex',
        borderRadius: '8px',
        alignItems: 'center',
        padding: '16px 20px',
        cursor: 'pointer'
    },
    FieldActive: {
        padding: '4px 16px',
        borderLeft: '#FBBF24 1px solid',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
        color: '#0F172A',
        cursor: 'pointer',
    },
    verifyButton: {
        backgroundColor: '#FAA81D',
        color: 'white',
        padding: '16 24 16 24',
        fontSize: '16px',
    },
    fontWeight: 700,
    resendText: {
        color: '#0F172A',
        marginTop: '20px',
        fontSize: '14px',
        fontWeight: 400,
    },
    leftHeadings: {
        color: '#0F172A',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '28px'
    },
    resendLink: {
        color: '#FAA81D',
        fontWeight: 700,
        cursor: "pointer",
        marginLeft: '5px'
    },
    accessPermissionTypography: {
        gap: '10px',
        fontSize: '16px',
        fontWeight: 400,
        padding: '0',
        fontFamily: 'League Spartan , sans-serif',
        marginLeft: '10px',
        lineHeight: '24px',
        color: '#0F172A',
    },
    FormControlCheck: {
        display: 'flex',
        padding: '0px',
        width: '100%',
        margin: '0',
    },
    generalFormHeading: {
        fontSize: '20px',
        fontWeight: 700,
        color: '#0F172A',
        lineHeight: '28px',
        fontFamily: 'League Spartan , sans-serif'
    },
    generalFormSubHeading: {
        fontWeight: 400,
        lineHeight: '24px',
        fontSize: '18px',
        color: '#0F172A',
        fontFamily: 'League Spartan , sans-serif'
    },
    generalFormBoxes: {
        justifyContent: 'space-between',
        borderRadius: "8px",
        padding: ' 12px 16px',
        display: 'flex',
        alignItems: "center",
    },
    FormControlCheckLanguage: {
        display: 'flex',
        margin: '0',
        width: '100%',
        justifyContent: 'space-between',
        padding: '12px 16px',
    },
    radioBtn: {
        padding: 0,
        margin: 0,
    },
    formControlLanguageHeading: {
        fontWeight: 400,
        color: '#0F172A',
        fontSize: '18px',
        fontFamily: 'League Spartan , sans-serif',
        lineHeight: '24px',
        margin: 0,
        padding: 0
    },
    BusinessSettingsSubHeadingTypography: {
        fontSize: "14px",
        color: '#475569',
        fontWeight: 700,
        lineHeight: "22px",
        paddingBottom: '4px'
    },
    textAreaBtn: {
        color: '#fff',
        fontWeight: 700,
        backgroundColor: '#FAA81D',
        padding: '16px 24px',
        alignSelf: 'end',
        borderRadius: '8px',
        fontSize: '16px',
    },
    errorText: {
        fontSize: '0.75rem',
        color: '#f44336',
    },
    saveBtn: {
        backgroundColor: '#FAA81D',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        lineHeight: '24px',
        fontWeight: 700,
        padding: '16px 24px'
    },
    languageTypography: {
        color: '#0F172A',
        fontWeight: 400,
        lineHeight: '24px',
        fontSize: '16px',
        fontFamily: 'League Spartan , sans-serif'
    },
    form: {
        flexDirection: 'column',
        display: 'flex',
        borderRadius: '24px',
        width: '440px',
    },
    formHeading: {
        letterSpacing: '-2',
        fontWeight: 700,
        fontSize: '30px',
        color: '#0F172A',
        marginBottom: '10px'
    },
    instructions: {
        lineHeight: '24px',
        fontSize: '16px',
        fontWeight: 700,
        color: '#334155',
    },
    customTabTypography: { fontWeight: 700, fontSize: '16px', lineHeight: '24px', alignSelf: 'center' }
};
// Customizable Area End
