import React from "react";
// ...

// Customizable Area Start

import EmailAccountRegistrationController, {
    Props
} from "./EmailAccountRegistrationController";
import { Box, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, ThemeProvider, Typography, createTheme } from "@material-ui/core";
import { checkIcon, crossIcon, imgGoogle, } from "./assets";
import { CalendarToday, Visibility, VisibilityOff } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import { LeftBox } from "../../../components/src/LeftBox";
import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik } from "formik";
import { GoogleLogin } from "react-google-login";

const theme = createTheme({
    palette: {
        primary: {
            main: "#FAA81D"
        },
    },
    typography: {
        h5: {
            fontWeight: 700
        },
        button: {
            fontWeight: 700,
            textTransform: 'none'
        },
    },

})

const validationSchema = Yup.object().shape({
    fullName: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, "Only alphabets are allowed for this field")
        .required("Full Name is required"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/\d/, "Password must contain a number")
        .matches(/[A-Z]/, "Password must contain an uppercase letter")
        .required("Password is required"),
    agree: Yup.boolean()
        .oneOf([true], "You must agree to terms and conditions").required("You must agree to terms and conditions")
});
// Customizable Area End


export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box maxWidth="xl" sx={webStyle.pageContainer}>
                    <LeftBox />
                    {/* Right Block */}
                    <Box style={webStyle.rightBlock}>
                        <Box>
                            <Box sx={webStyle.form}>
                                <Typography variant="h5" style={webStyle.formHeading} className="LeagueSpartan">Create an Account</Typography>
                                <Formik
                                    initialValues={{ fullName: "", email: '', password: '', agree: false, isValid: false }}
                                    validationSchema={validationSchema}
                                    onSubmit={this.handleFormikValues}
                                    validateOnMount={true}
                                >
                                    {({ values, isValid, handleChange, handleBlur, handleSubmit }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Box sx={webStyle.formFields}>
                                                <Typography style={webStyle.formFieldsTexts} className="LeagueSpartan">Full Name</Typography>
                                                <Field
                                                    as={TextField}
                                                    name="fullName"
                                                    placeholder="Your Full Name"
                                                    variant="outlined"
                                                    value={values.fullName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    data-test-id="fullName"
                                                    inputProps={{ style: { backgroundColor: "white" } }}
                                                    fullWidth
                                                />
                                                <ErrorMessage name="fullName" component="div" style={{ color: 'red' }} />
                                                <Typography style={webStyle.formFieldsTexts} className="LeagueSpartan">Email</Typography>
                                                <Field
                                                    as={TextField}
                                                    name="email"
                                                    placeholder="Your Email"
                                                    variant="outlined"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    data-test-id="userEmail"
                                                    inputProps={{ style: { backgroundColor: "white" } }}
                                                    fullWidth
                                                />

                                                <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                                                <Typography style={webStyle.formFieldsTexts} className="LeagueSpartan">Date of Birth</Typography>
                                                <DatePicker
                                                    onChange={this.handleDobChange}
                                                    placeholderText="Select Date"
                                                    customInput={<TextField variant="outlined" fullWidth
                                                        InputProps={{
                                                            style: { backgroundColor: 'white', padding: '2' },
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                    >
                                                                        <CalendarToday />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }} />}
                                                    disabled
                                                    dateFormat="MM/dd/yyyy"
                                                />

                                                <Typography style={webStyle.formFieldsTexts} className="LeagueSpartan">Password</Typography>
                                                <Field
                                                    as={TextField}
                                                    placeholder="Enter Password"
                                                    type={this.state.showPassword ? 'text' : 'password'}
                                                    variant="outlined"
                                                    value={values.password}
                                                    data-test-id="userPassword"
                                                    name='password'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    inputProps={{ style: { backgroundColor: "white" } }}
                                                    InputProps={{
                                                        style: { backgroundColor: 'white', padding: '2' },
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={this.handleToggle}
                                                                    className="showPassword"
                                                                >
                                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                                                <Box style={{ visibility: values.password ? 'visible' : 'hidden', height: values.password ? "80px" : "0" }}>
                                                    <ul style={webStyle.list} >
                                                        <li style={webStyle.listItems}>
                                                            {values.password.length >= 8 ? (<img src={checkIcon} />) : (<img src={crossIcon} />)}Min 8 characters
                                                        </li>

                                                        <li style={webStyle.listItems}>
                                                            {/\d/.test(values.password) ? (<img src={checkIcon} />) : (<img src={crossIcon} />)}One number
                                                        </li>

                                                        <li style={webStyle.listItems}>
                                                            {/[A-Z]/.test(values.password) ? (<img src={checkIcon} />) : (<img src={crossIcon} />)}One uppercase letter
                                                        </li>
                                                    </ul>
                                                </Box>


                                                <FormControlLabel
                                                    style={webStyle.checkBox}
                                                    data-test-id='termsCheck'
                                                    className="termsCheckBtn"
                                                    control={
                                                        <Field
                                                            as={Checkbox}
                                                            name="agree"
                                                            color="primary"
                                                            checked={values.agree}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={
                                                        <Typography variant="body2" className="LeagueSpartan">
                                                            I agree with
                                                            <Button onClick={this.handleTerms} variant="text" style={webStyle.textLinks}>
                                                                Privacy Policy and Terms and Conditions
                                                            </Button>
                                                        </Typography>
                                                    }
                                                />

                                                <ErrorMessage name="agree" component="div" style={{ color: 'red', fontSize: '0.8rem' }} />
                                                <Button
                                                    variant="contained"
                                                    data-test-id="signUpBtn"
                                                    style={{ padding: '10px', color: isValid  ? '#fff' : '#64748B', backgroundColor: isValid ? '#FAA81D' : '#F1F5F9' }}
                                                    className="LeagueSpartan"
                                                    type="submit"
                                                    disabled={!isValid}
                                                    fullWidth
                                                >
                                                    SIGN UP
                                                </Button>
                                                <Box sx={{ ...webStyle.orBox, paddingTop: this.state.password ? '20px' : '0px' }} className="LeagueSpartan">
                                                    <Typography align="center" variant="body2" style={webStyle.orText}>
                                                        OR
                                                    </Typography>
                                                    <GoogleLogin
                                                        data-test-id="Gloginbutton"
                                                        clientId="649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com"
                                                        className="googleBtn"
                                                        render={renderProps => (
                                                            <Button
                                                                variant="contained"
                                                                fullWidth startIcon={<img src={imgGoogle} alt="Google" />}
                                                                style={webStyle.googleButton} onClick={renderProps.onClick} disabled={renderProps.disabled}>

                                                                Continue with Google
                                                            </Button>
                                                        )}
                                                        onSuccess={this.responseGoogle}
                                                        onFailure={this.responseGoogle}
                                                        cookiePolicy={'single_host_origin'}
                                                    />,

                                                    <Typography align="center" variant="body2" style={webStyle.loginText}>
                                                        Already have an account? <Button onClick={this.handleLogin} className="logInBtn" style={webStyle.textLinks}>Log in</Button>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    pageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        width: '100%',
        fontFamily: 'League Spartan, Arial, sans-serif'
    },
    rightBlock: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40%'
    },
    form: {
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#FEF3C7',
        borderRadius: '24px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    formFields: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        width: '100%',
        fontSize: '14px'
    },
    textLinks: {
        color: '#FAA81D',
        cursor: "pointer",
        fontWeight: 700,
        fontStyle: 'bold'
    },
    orBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    checkBox: {
        display: 'block ruby',
        fontWeight: 400,
        fontSize: '16px',
    },
    orText: {
        fontWeight: 700,
        fontSize: '16px',
        color: '#64748B',
        padding: '10px'
    },
    googleButton: {
        backgroundColor: 'white',
        padding: '10px',
        borderColor: '#4285f4',
    },
    formHeading: {
        fontWeight: 700,
        fontSize: '30px',
        letterSpacing: '-2',
        marginBottom: '25px',
        color: '#0F172A'
    },
    loginText: {
        color: '#0F172A',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '26px',
        marginTop: '5px'
    },
    formFieldsTexts: {
        fontWeight: 600,
        fontSize: '14px',
    },
    list: {
        listStyle: 'none',
        padding: '0px'
    },
    listItems: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
    }

    // custom style definitions
};
// Customizable Area End
