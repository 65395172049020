import React from "react";
// ...

// Customizable Area Start
import { Box, Button,Dialog,DialogContent,MobileStepper,TextField, TextFieldProps, TextareaAutosize, Typography, withStyles } from "@material-ui/core";

import { InfoOutlined, AddCircleOutlineOutlined, Clear, DeleteOutlined , WifiOutlined, KitchenOutlined, PoolOutlined, TvOutlined, LocationOnOutlined} from "@material-ui/icons";
const CssTextField = withStyles(() => ({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: (props: TextFieldProps & { error?: boolean }) =>
          props.error ? '#DC2626' : '#625d58',
      },
    },
  },
}))(TextField);

const CssTextField2 = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FAA81D',
      },
    },
  },
})(TextField);

const styles: {
  toggleButtonGroup: React.CSSProperties;
  toggleButtonGroup2: React.CSSProperties;
  toggleButtonGroup3: React.CSSProperties;
  toggleButton: React.CSSProperties;
  toggleButton2: React.CSSProperties;
  toggleButton3: React.CSSProperties;
} = {
  toggleButtonGroup: {
    display: 'flex',
    flexWrap: 'wrap', 
    gap: '10px',
    maxWidth: '100%', 
  },
  toggleButtonGroup2: {
    display: 'flex',
    flexWrap: 'wrap', 
    gap: '10px',
    maxWidth: '100%', 
  },
  toggleButtonGroup3: {
    display: 'flex',
    flexWrap: 'wrap', 
    gap: '10px',
    maxWidth: '100%',
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
    padding: "14px 16px 14px 16px",
  },
  toggleButton: {
    borderRadius: '58px',
    width: '128px', 
  },
  toggleButton2: {
    borderRadius: '58px',
    textTransform: "none",
  },
  toggleButton3: {
    borderRadius: '58px',
    textTransform: "none",
    border: "1px solid rgba(100, 116, 139, 1) !important"
  },
};

// Customizable Area End

import HotelCreateRoomController, {
  Errors,
  Props,
} from "./HotelCreateRoomController";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";


export default class HotelCreateRoom extends HotelCreateRoomController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  logoSvg = require("../assets/logoSvg.svg");
  addCarSvg = require("../assets/addCarSvg.svg");
  hotelbg = require("../assets/hotelbg.svg");
  addCarBtn = require("../assets/addCarBtn.svg");
  infoSvg = require("../assets/infoSvg.svg");
  bgImage = require("../assets/bgImage.svg");
  euroSvg = require("../assets/euroSvg.svg");
  addIconSvg = require("../assets/addIconSvg.svg");
  imageadd = require("../assets/imageadd.svg");
  divider = require("../assets/divider.svg");
  add = require("../assets/add.svg");
  editBtn = require("../assets/editBtn.svg");
  makeCover = require("../assets/makeCover.svg");
  rotateImg = require("../assets/rotate.svg");
  deleteImg = require("../assets/deleteImg.svg");
  
  verification = require("../assets/verification.svg");
  washer = require("../assets/washer.svg");
  microwave = require("../assets/microwave.svg");
  map = require("../assets/map.png");
  
  renderStepper() {
    if (this.state.activeStep !== 4) {
      return (
        <MobileStepper
        variant="progress"
        steps={4}
        position="static"
        activeStep={1}
        nextButton={null}
        backButton={null}
        />
      );
    }
    }

  renderErrorMessage = (fieldName: keyof Errors) => {
    const errorMessage = this.state.errors[fieldName];
    if (errorMessage) {
      return (
        <Typography style={{ color: '#DC2626', fontSize: '12px' }}>
          {errorMessage}
        </Typography>
      );
    }
    return null;
  };
  
  renderHeader = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', height: '92px', position: 'relative', padding: "15px" }}>
          <img src={this.logoSvg} style={{marginLeft: "10px"}}/>
            <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            {this.state.activeStep == 4 ?
            <Typography style={{ fontWeight: '700', fontSize: '24px' }}>
              Preview of your room
            </Typography> 
            :
            <Typography style={{ fontWeight: '700', fontSize: '24px' }}>
              Add room
            </Typography> 
            }
            </span>
            {this.state.activeStep == 1 &&(
            <span style={{ position: 'absolute', left: '100%', transform: 'translateX(-100%)', paddingRight: "48px",display: "flex", flexDirection: "row", gap: "16px"}}>
              <Button 
              data-test-id="next"
              style={webStyle.buttonStyleModal9}
              // onClick={this.handleConfirm} 
              size="small" >
              <Box style={{display: "flex",gap:"8px"}}>
               <AddCircleOutlineOutlined/>
               <Typography style={{fontSize: "16px", fontWeight: 700}} className="LeagueSpartan">Add Room Type</Typography>
              </Box>
              </Button>
            </span>
             )}
            {this.state.activeStep == 4 &&(
            <span style={{ position: 'absolute', left: '100%', transform: 'translateX(-100%)', paddingRight: "48px",display: "flex", flexDirection: "row", gap: "16px"}}>
            <Button data-test-id="change" onClick={() => { this.setState({ activeStep: 3 })}}
              style={webStyle.buttonStyleModal8} 
              size="small" 
              variant="outlined" >
                  Change
              </Button>
              <Button 
              data-test-id="confirm"
              style={webStyle.buttonStyleModal6}
              onClick={this.handleConfirm} 
              size="small" >
                  Confirm
              </Button>
            </span>
             )}
          </div>
    )
  }

  renderStep0 = () => {
    return (
      <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content",margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  

      <span></span>
      <Box>
        <img src={this.hotelbg}/>
      </Box>
      
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}} style={{ gap: "40px"}}>
        <Box style={{display: "flex", gap: "24px", flexDirection: "column", alignItems: "center"}}>
        <Box style={{display: "flex", gap: "16px", flexDirection: "column", alignItems: "center"}}>
        <Typography style={{color: "#0F172A", fontSize: "24px", fontWeight: 700}}>Room type has been successfully created!</Typography>
        <Typography style={{color: "#64748B", fontSize: "18px", fontWeight: 400}}>It's time to add your rooms to the inventory</Typography>
        </Box>
        <Button data-test-id="next" style={{display: "flex",alignItems: "center", flexDirection: "row", padding: "16px 24px 16px 20px",borderRadius: "8px", height: "56px", background: "#FAA81D", color: "white", fontSize: "16px"}}  
        onClick={this.handleGetNext} size="small">
         <Box style={{display: "flex",gap:"8px"}}>
         <AddCircleOutlineOutlined/>
         <Typography style={{fontWeight: 700}} className="LeagueSpartan">Add Room</Typography>
         </Box>
        </Button>
        </Box>
      </Box>
      <span></span>

      </Box>
    )
  }

  renderStep1 = () => {
    return (
      <Box style={{ backgroundColor: "white",gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
      
      <Box sx={webStyle.content}>
      
      <Box style={{width: "100%"}}>
      <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px", display: "flex", alignItems: "center"}}>Please choose a room type <InfoOutlined style={{paddingLeft: "8px"}}/></Typography>
      </Box> 
      <Box style={{display:"flex", width:"590px", flexDirection: "column", gap:"24px", paddingLeft:"40px"}}>
      {this.state.roomTypes.map((room) => {
        const isSelected = this.state.selectedRoomTypeId === room.id;
        return (
          <Box
            data-test-id="roomType"
            key={room.id}
            onClick={() => this.handleRoomTypeClick(room.id, room)}
            style={{
              flexDirection: "column",
              display: "flex",
              padding: "16px",
              border: isSelected ? "1px solid #FAA81D" : "1px solid #CBD5E1",
              background: isSelected ? "#FEFCF9" : "#FFFFFF", 
              borderRadius: "8px",
              cursor: "pointer", 
              gap: "16px"
            }}
          >
            <Box style={{ display: "flex", gap: "16px" }}>
              <img src={room.attributes.cover_image.url} style={{ borderRadius: "8px", width: "80px", height: "56px" }} />
              <Typography className="LeagueSpartan" style={{ fontSize: "20px", fontWeight: 700 }}>
                {room.attributes.room_view} - {room.attributes.room_category}
              </Typography>
            </Box>
            <Box style={{display: "flex", flexDirection: "row", paddingBottom:"16px", borderBottom: "1px solid #E2E8F0"}}>
              <Box style={{width: "111.5px", display: "flex", flexDirection: "column", gap:"4px", paddingRight: "16px"}}>
              <Typography className="LeagueSpartan" style={{fontSize: "14px", fontWeight: 400, color: "#1E293B"}}>Rack Rate</Typography>
              <Typography className="LeagueSpartan" style={{fontSize: "16px", fontWeight: 700, color: "#0F172A"}}>€{room.attributes.rack_rate}</Typography>
              </Box>
              <Box style={{width: "111.5px", display: "flex", flexDirection: "column", gap:"4px", paddingRight: "16px"}}>
              <Typography className="LeagueSpartan" style={{fontSize: "14px", fontWeight: 400, color: "#1E293B"}}>+Adult Rate</Typography>
              <Typography className="LeagueSpartan" style={{fontSize: "16px", fontWeight: 700, color: "#0F172A"}}>€{room.attributes.extra_adult_rate}</Typography>
              </Box>
              <Box style={{width: "111.5px", display: "flex", flexDirection: "column", gap:"4px", paddingRight: "16px"}}>
              <Typography className="LeagueSpartan" style={{fontSize: "14px", fontWeight: 400, color: "#1E293B"}}>+Child Rate</Typography>
              <Typography className="LeagueSpartan" style={{fontSize: "16px", fontWeight: 700, color: "#0F172A"}}>€{room.attributes.extra_child_rate}</Typography>
              </Box>
              <Box style={{width: "111.5px", display: "flex", flexDirection: "column", gap:"4px"}}>
              <Typography className="LeagueSpartan" style={{fontSize: "14px", fontWeight: 400, color: "#1E293B"}}>+Infant Rate</Typography>
              <Typography className="LeagueSpartan" style={{fontSize: "16px", fontWeight: 700, color: "#0F172A"}}>€{room.attributes.extra_infant_rate}</Typography>
              </Box>
            </Box>

            <Box style={{display: "flex", flexDirection: "row", paddingBottom:"16px"}}>
              <Box style={{width: "111.5px", display: "flex", flexDirection: "column", gap:"4px", paddingRight: "16px"}}>
              <Typography className="LeagueSpartan" style={{fontSize: "14px", fontWeight: 400, color: "#1E293B"}}>Included Guests</Typography>
              <Typography className="LeagueSpartan" style={{fontSize: "16px", fontWeight: 700, color: "#0F172A"}}>{room.attributes.inc_guests}</Typography>
              </Box>
              <Box style={{width: "111.5px", display: "flex", flexDirection: "column", gap:"4px", paddingRight: "16px"}}>
              <Typography className="LeagueSpartan" style={{fontSize: "14px", fontWeight: 400, color: "#1E293B"}}>Max Adults</Typography>
              <Typography className="LeagueSpartan" style={{fontSize: "16px", fontWeight: 700, color: "#0F172A"}}>{room.attributes.max_adults}</Typography>
              </Box>
              <Box style={{width: "111.5px", display: "flex", flexDirection: "column", gap:"4px", paddingRight: "16px"}}>
              <Typography className="LeagueSpartan" style={{fontSize: "14px", fontWeight: 400, color: "#1E293B"}}>Max Children</Typography>
              <Typography className="LeagueSpartan" style={{fontSize: "16px", fontWeight: 700, color: "#0F172A"}}>{room.attributes.max_children}</Typography>
              </Box>
              <Box style={{width: "111.5px", display: "flex", flexDirection: "column", gap:"4px"}}>
              <Typography className="LeagueSpartan" style={{fontSize: "14px", fontWeight: 400, color: "#1E293B"}}>Max Infants</Typography>
              <Typography className="LeagueSpartan" style={{fontSize: "16px", fontWeight: 700, color: "#0F172A"}}>{room.attributes.max_infants}</Typography>
              </Box>
              <Box style={{width: "111.5px", display: "flex", flexDirection: "column", gap:"4px"}}>
              <Typography className="LeagueSpartan" style={{fontSize: "14px", fontWeight: 400, color: "#1E293B"}}>Max Guests</Typography>
              <Typography className="LeagueSpartan" style={{fontSize: "16px", fontWeight: 700, color: "#0F172A"}}>{room.attributes.max_guests}</Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
      </Box>   
      </Box> 
                
      <Box style={{gap: "16px", width: "100%"}} sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", paddingBottom: "16px",borderTop: "1px solid rgb(226, 232, 240)"}}>       
        <Box style={{paddingRight: "40px", 
        display: "flex", 
        gap: "16px"}}>
        <Button 
        style={webStyle.buttonStyleModal2} 
        onClick={this.handleBackClick} 
        size="small" 
        data-test-id="back"
         variant="outlined" >
            Back
        </Button>
        <Button data-test-id="next"
        style={
          this.state.selectedRoomTypeId == null
            ? webStyle.disabledButtonStyle 
            : webStyle.buttonStyleModal 
        }
        disabled={this.state.selectedRoomTypeId == null} 
        onClick={this.handleNextClick} 
        size="small" >
            Next
        </Button>
        </Box>
      </Box>
      </Box>
    )
  }

  renderStep2 = () => {
    return (
      <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
      
      <Box sx={webStyle.content}>
      
      <Box style={{width: "100%"}}>
      <Typography style={{fontWeight: 700, fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Let’s give your room a title and description</Typography>
      </Box> 

      <Box style={{display:"flex", 
      paddingLeft: "40px",
      paddingRight: "40px",
      flexDirection: "column", 
      gap: "4px"}}>
        <Typography style={{color: "#475569", fontSize: "14px", fontWeight: 700}}>Room number</Typography>
        <CssTextField  
          error={Boolean(this.state.errors.room_number)}
          data-test-id="room_number"            
          variant="outlined"                 
          fullWidth
          id="room_number"             
          name="room_number"
          value={this.state.data.room_number}
          onChange={(e) => this.onValueChange("room_number", e.target.value)}
          InputProps={{
            style: webStyle.inputStyle,
          }}
        />
        {this.renderErrorMessage('room_number')}
      </Box>   

      <Box style={{display:"flex", 
      paddingLeft: "40px",
      paddingRight: "40px",
      flexDirection: "column", 
      gap: "4px"}}>
        <Typography style={{color: "#475569", fontSize: "14px", fontWeight: 700}}>Description</Typography>
        <TextareaAutosize
          data-test-id="room_description"
          id="room_description"
          name="room_description"
          value={this.state.data.room_description}
          onChange={(e) => this.onValueChange("room_description", e.target.value)}
          rows={4}
          maxLength={500}
          className="textarea LeagueSpartan"
        />
        <Box style={{width:"100%", display: "flex", justifyContent: "end"}}>
          <Typography style={webStyle.textStyle2} variant="caption" id="descriptionOfService-helper-text">
            {`${this.state.data.room_description.length}/500`}
          </Typography>             
        </Box>
      </Box>   

      </Box>                  
      <Box style={{gap: "16px", width: "100%"}} sx={{
        paddingTop: "10px", 
        flexDirection: "row",justifyContent: "flex-end", 
        paddingBottom: "16px",
        display: "flex", 
      borderTop: "1px solid rgb(226, 232, 240)"}}>       
        <Box style={{
          display: "flex", 
          paddingRight: "40px", 
          gap: "16px"}}>
        <Button data-test-id="back" onClick={this.handleBackClick} style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
            Back
        </Button>
        <Button data-test-id="next" 
        disabled={Object.values(this.state.errors).some(error => error !== '')|| 
        this.state.data.room_number == ""} 
          onClick={this.handleNextClick} 
          style={
            Object.values(this.state.errors).some(error => error !== '')|| 
            this.state.data.room_number == ""
              ? webStyle.disabledButtonStyle 
              : webStyle.buttonStyleModal 
          } size="small" >
            Next
        </Button>
        </Box>
      </Box>
      </Box>
    )
  }

  renderStep3Image = () => {
    const files  = this.state.selectedRoomAttributes.attributes.images;
      
    return (
      <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Box
          style={{   
            position: "relative",
            height: "auto",
          }}
        >
          {files.length > 0 && (
            <Box style={{ 
              position: "relative", 
              width: "100%", height: "300px", overflow: "hidden", borderRadius: "8px" }}>
              <Box
                style={{
                  height: "100%",
                  overflow: "hidden",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <img
                  src={files[0].url}
                  style={{
                    width: "100%",
                    transformOrigin: "center",
                  }}
                  alt="Preview"
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gap: "10px",
            height: "108px"
          }}
        >
          {files.slice(1).map((image, index) => (
            <Box key={index + 1} style={{ position: "relative",height: "100%", width: "100%", overflow: "hidden", alignSelf: "center" }}>
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
              >
                <img
                  src={image.url}
                  alt={`Preview ${index + 1}`}
                  style={{
                    height: "100%",
                    transformOrigin: "center",
                  }}
                />
              </Box>
            </Box>
          ))}
          <Button data-test-id="editBtn" style={{width: "108px", height: "108px" ,boxShadow: "0px 2px 8px 0px #0000001A"}} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>
      </Box>
    );
  }

  renderStep3 = () => {
    return (
      <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
      
      <Box sx={webStyle.content}>
      
      <Box style={{width: "100%"}}>
      <Typography className="LeagueSpartan" style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Room Details</Typography>
      </Box> 

      <Box style={{width: "100%", display: "flex", justifyContent: "center", gap: "16px", flexDirection: "column"}}>

      <Box style={{display:"flex", 
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  flexDirection: "column", 
                  gap: "24px"}}>

        {this.renderStep3Image()}

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Room Type</Typography>
          <Button data-test-id="editBtn1" onClick={() => this.handleEditClick(1)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>
        <CssTextField2    
          data-test-id="roomType"            
          variant="outlined"                 
          fullWidth
          id="roomType"             
          name="roomType"
          value={`${this.state.selectedRoomAttributes.attributes.room_view} - ${this.state.selectedRoomAttributes.attributes.room_category}`}
          disabled
          InputProps={{
            style: {
              ...webStyle.inputStyle,
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
              color: '#0F172A',
            },
            inputProps: {
              style: {
                color: '#0F172A', 
            }}
          }}
        />
        </Box>

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Room number</Typography>
          <Button data-test-id="editBtn2" onClick={() => this.handleEditClick(2)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>
        <CssTextField2   
          data-test-id="room_number"            
          variant="outlined"                 
          fullWidth
          id="room_number"             
          name="room_number"
          value={this.state.data.room_number}
          onChange={(e) => this.onValueChange("room_number", e.target.value)}
          InputProps={{
            style: {
              ...webStyle.inputStyle,
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
            }
          }}
          disabled={!this.state.isEditing[2]}
        />
          {this.renderErrorMessage('room_number')}
        </Box>

        

        <Box style={{display: "flex", flexDirection: "column", }}>
          <Box style={{display: "flex", justifyContent: "space-between" }}>
            <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Amenities</Typography>
          </Box>

          <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            style={{ padding: '20px 0px', justifyContent: 'space-between' }}
          >
            <ToggleButtonGroup
              data-test-id="ToggleButtonGroupFeatures"
              onChange={this.handleToggleDestination}
              style={styles.toggleButtonGroup3}
            >
              {this.state.data.destinationArray
              .filter((ele) => ele.selected)
              .map((ele, index) => (
                <ToggleButton
                disabled
                  data-test-id={`toggleBtn${index}`}
                  key={ele.id}
                  style={{
                    ...styles.toggleButton3,
                    color: 'inherit', 
                    opacity: 1, 
                  }}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  value={ele.id}
                >
                  {ele.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

          </Box>

          <Box style={{display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography className="LeagueSpartan" style={{color: "#334155", fontSize: "16px", fontWeight: "400"}}>Any new amenities?</Typography>
            <Button
              style={{ width: "fit-content", textTransform: "none"}}
              data-test-id="addNewDestination"
              onClick={this.addNewDestination}
            >
              <div style={{display: "flex", gap: "8px"}}>
              <img src={this.add}/>
              <Typography className="LeagueSpartan" style={{color: "#FAA81D", fontSize: "18px", fontWeight: "700"}}>Add amenities</Typography>
              </div>
            </Button>
          </Box>

          <Dialog open={this.state.showAddDestinationBox}>
          <style>
            {`
              .MuiPaper-rounded{
                border-radius:8px 8px 32px 8px
              }
              .MuiDialog-paperWidthSm {
                max-width: 800px;
              }
            `}
          </style>
          <DialogContent
            style={{ padding: "0px", 
            width: "800px", }}
          >
            <Box 
            display={"flex"} 
            flexDirection={"column"} 
            style={{ gap: '40px'  }} >
              
              <Box style={{
                padding:"24px 16px 24px 40px", 
                borderBottom: "1px solid #E2E8F0" }} 
                justifyContent={"space-between"} 
                display={"flex"} 
                alignItems={"center"}>
                <Typography
                  className="LeagueSpartan"
                  style={{ color: '#0F172A', 
                  fontSize: '24px', 
                  fontWeight: 700, 
                  lineHeight: '32px' }}>
                  Add amenities
                </Typography>
                <Box
                  alignSelf={"end"}
                >
                  <Button
                    onClick={this.closeAddDestinationBox}
                    data-test-id="closeAddDestinationBox"
                  >
                    <Clear />
                  </Button>
                </Box>
              </Box>

              <Box 
              style={{padding:"0px 40px 0px 40px", display: "flex", gap:"16px", flexDirection:"column"}}>
              
              <ToggleButtonGroup
                data-test-id="ToggleButtonGroupAmenities"
                onChange={this.handleToggleDestination}
                style={styles.toggleButtonGroup2}
              >
                {this.state.data.destinationArray.map((ele, index) => (
                  <ToggleButton
                    className="LeagueSpartan"
                    data-test-id={`toggleBtn${index}`}
                    key={ele.id}
                    style={styles.toggleButton2}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    value={ele.id}
                    selected={ele.selected}
                  >
                    {ele.name}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>

              <Button data-test-id="addAmenity" size="small" style={{height: "32px", width: "fit-content"}} onClick={this.addNewInputField}>
                <img src={this.add}/><Typography style={{fontSize: "16px", fontWeight: 700, color: "#FAA81D", paddingLeft: "8px"}} className="LeagueSpartan">Add Custom Amenity</Typography>
              </Button>

              {this.state.newDestination.slice(1).map((destination, index) => (
                <Box key={destination.id} style={{ display: 'flex', gap: '4px', flexDirection:"column" }}>
                  <Typography className="LeagueSpartan" 
                  style={{color: "#475569", 
                  fontSize: "14px", fontWeight: "700"}}>Custom amenity
                  </Typography>
                  <Box key={destination.id} style={{ display: 'flex', alignItems: 'center',}}>
                  <CssTextField 
                    className="LeagueSpartan"            
                    variant="outlined"                 
                    fullWidth
                    value={destination.name}
                    name={`handleNewDestination-${index+1}`}
                    data-test-id={`handleNewDestination-${index+1}`}
                    onChange={(e) => this.handleNewDestination(e, index+1)}
                    InputProps={{
                      style: webStyle.inputStyle
                    }}
                  />
                  <Button 
                    style={{ height: "56px", width: "56px" }} 
                    onClick={() => this.deleteDestinationField(index+1)}
                    data-test-id={`deleteDestination-${index+1}`}
                  >
                    <DeleteOutlined style={{ color: '#DC2626' }}/>
                  </Button>
                  </Box>
                </Box>
              ))}

              
            </Box>

            <Box style=
            {{borderTop: "1px solid #E2E8F0", 
            padding:"24px 24px 24px 40px", gap: "16px" }}
            paddingRight={'24px'} 
            display={'flex'} 
              justifyContent={'end'} >
              <Button
                data-test-id="cancelDialogBtn"
                onClick={this.closeAddDestinationBox}
                style={{
                  borderRadius: '8px',
                  padding: '16px 30px',
                  color: '#FAA81D',
                  fontWeight: 700, fontSize: '16px',
                  lineHeight: '24px',
                  border: '#FAA81D 1px solid',
                  height: "56px",
                  width: "120px",
                }}
              >
                CANCEL
              </Button>
              <Button
                onClick={this.addNewDestinationToArray}
                data-test-id="addNewDestinationToArray"
                style={{
                  color: '#fff',
                  backgroundColor: '#FAA81D',
                  fontWeight: 700,
                  lineHeight: '24px',
                  padding: '16px 30px',
                  height: "56px",
                  borderRadius: '8px',
                  width: "120px",
                  fontSize: '16px', 
                }}
              >
                SAVE
              </Button>
            </Box>

            </Box>
          </DialogContent>
          </Dialog>
        </Box> 

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Description</Typography>
          <Button data-test-id="editBtn3" onClick={() => this.handleEditClick(3)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>
        <CssTextField2             
          variant="outlined"                 
          fullWidth
          data-test-id="room_description"
          id="room_description"
          name="room_description"
          value={this.state.data.room_description}
          onChange={(e) => this.onValueChange("room_description", e.target.value)}
          InputProps={{
            style: {
              ...webStyle.inputStyle,
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
            }
          }}
          disabled={!this.state.isEditing[3]}
        />
        </Box>

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Rack rate*</Typography>
          <Button data-test-id="editBtn4" onClick={() => this.handleEditClick(4)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>
        <CssTextField2             
          variant="outlined"                 
          fullWidth
          data-test-id="rack_rate"
          id="rack_rate"
          name="rack_rate"
          value={this.state.selectedRoomAttributes.attributes.rack_rate}
          onChange={(e) => this.onValueChangeAPIData("rack_rate", e.target.value)}
          InputProps={{
            style: {
              ...webStyle.inputStyle,
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
            }
          }}
          disabled={!this.state.isEditing[4]}
        />
        </Box>

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Default extra adult rate*</Typography>
          <Button data-test-id="editBtn5" onClick={() => this.handleEditClick(5)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>
        <CssTextField2             
          variant="outlined"                 
          fullWidth
          data-test-id="extra_adult_rate"
          id="extra_adult_rate"
          name="extra_adult_rate"
          value={this.state.selectedRoomAttributes.attributes.extra_adult_rate}
          onChange={(e) => this.onValueChangeAPIData("extra_adult_rate", e.target.value)}
          InputProps={{
            style: {
              ...webStyle.inputStyle,
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
            }
          }}
          disabled={!this.state.isEditing[5]}
        />
        </Box>

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Default extra child*</Typography>
          <Button data-test-id="editBtn6" onClick={() => this.handleEditClick(6)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>
        <CssTextField2             
          variant="outlined"                 
          fullWidth
          data-test-id="extra_child_rate"
          id="extra_child_rate"
          name="extra_child_rate"
          value={this.state.selectedRoomAttributes.attributes.extra_child_rate}
          onChange={(e) => this.onValueChangeAPIData("extra_child_rate", e.target.value)}
          InputProps={{
            style: {
              ...webStyle.inputStyle,
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
            }
          }}
          disabled={!this.state.isEditing[6]}
        />
        </Box>

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Default extra infant rate*</Typography>
          <Button data-test-id="editBtn7" onClick={() => this.handleEditClick(7)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>
        <CssTextField2             
          variant="outlined"                 
          fullWidth
          data-test-id="extra_infant_rate"
          id="extra_infant_rate"
          name="extra_infant_rate"
          value={this.state.selectedRoomAttributes.attributes.extra_infant_rate}
          onChange={(e) => this.onValueChangeAPIData("extra_infant_rate", e.target.value)}
          InputProps={{
            style: {
              ...webStyle.inputStyle,
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
            }
          }}
          disabled={!this.state.isEditing[7]}
        />
        </Box>
      </Box> 
      </Box>
      </Box>                   
      <Box 
      style={{gap: "16px", width: "100%"}} 
      sx={{
        flexDirection: "row",
        paddingTop: "10px", 
        paddingBottom: "16px",
        justifyContent: "flex-end", 
        display: "flex", 
        borderTop: "1px solid rgb(226, 232, 240)"}}>       
      <Box 
        style={{
          display: "flex", 
          paddingRight: "40px", gap: "16px"}}>
        <Button data-test-id="preview" onClick={this.handleNextClick} 
        style={webStyle.buttonStyleModal7} 
        size="small" 
        variant="outlined" >
            Check Preview
        </Button>
        <Button 
        data-test-id="confirm"
        style={webStyle.buttonStyleModal6}
        onClick={this.handleConfirm} 
        size="small" >
            Confirm
        </Button>
        </Box>
      </Box>
      </Box>
    )
  }

  renderStep4Image = () => {
    const files = this.state.selectedRoomAttributes.attributes.images;
  
    return (
      <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
        <Box style={{ position: "relative", height: "auto" }}>
          {files.length > 0 && (
            <Box
              style={{
                position: "relative",
                width: "690px",
                overflow: "hidden",
                borderRadius: "8px",
              }}
            >
              <Box
                style={{
                  height: "100%",
                  overflow: "hidden",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <img
                  src={files[0].url}
                  style={{
                    width: "100%",
                    height: "100%",
                    transformOrigin: "center",
                    objectFit: "cover",
                  }}
                  alt="Preview"
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)", 
            gap: "8px",
            width: "432px", // keep the width fixed for the grid
          }}
        >
          {files.slice(1, 7).map((image, index) => (
            <Box
              key={index + 1}
              style={{
                position: "relative",
                width: "100%",
                paddingBottom: "75%", // maintain aspect ratio
                overflow: "hidden",
                borderRadius: "8px",
              }}
            >
              <img
                src={image.url}
                alt={`Preview ${index + 1}`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  transformOrigin: "center",
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  renderStep4 = () => {
    return(
      <Box style={{display: "flex", flexDirection: "column", gap: "32px"}}>
        <Box style={{width: "1130px", height: "540px"}}>
          {this.renderStep4Image()}
        </Box>
        <Box style={{width: "1130px", height: "540px", display: "flex", flexDirection: "row", gap: "20px"}}>
          <Box style={{width: "785px", height: "fit-content", display: "flex", borderRadius: "8px",
          flexDirection: "column", gap: "32px", padding: "32px 40px 32px 40px", boxShadow: "0px 2px 8px 0px #0000001A", backgroundColor: "white"}}> 
            <Box style={{display: "flex", flexDirection: "column"}}>
              <Typography className="LeagueSpartan" style={{fontSize: "30px", fontWeight: "700", color: "#0F172A"}}>
                {this.state.selectedRoomAttributes.attributes.room_name}
              </Typography>
              <Typography className="LeagueSpartan" style={{fontSize: "18px", fontWeight: "400", color: "#0F172A"}}>
                {this.state.selectedRoomAttributes.attributes.guest_count} guests•{this.state.selectedRoomAttributes.attributes.bedroom_count} bedrooms
                •{this.state.selectedRoomAttributes.attributes.bed_count} beds•{this.state.selectedRoomAttributes.attributes.bathroom_count} baths
              </Typography>
            </Box>
            <Typography className="LeagueSpartan">{this.state.data.room_description}</Typography>
            <Box style={{display: "flex", flexDirection: "column", gap: "8px"}}>
            <Typography className="LeagueSpartan" style={{fontSize: "18px", fontWeight: "700", color: "#0F172A"}}>
              Amenities
            </Typography>
            <Box
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "16px", 
              }}
            >
              {this.state.data.destinationArray
              .filter(item =>
                item.selected &&
                ["WiFi", "Stove", "Microwave", "TV", "Washer", "Pool"].includes(item.name)
              )
              .map(filteredItem => {
                let IconComponent;

                switch (filteredItem.name) {
                  case "WiFi":
                    IconComponent = WifiOutlined;
                    break;
                  case "Stove":
                    IconComponent = KitchenOutlined;
                    break;
                  case "Microwave":
                    IconComponent = this.microwave;
                    break;
                  case "TV":
                    IconComponent = TvOutlined;
                    break;
                  case "Washer":
                    IconComponent = this.washer;
                    break;
                  case "Pool":
                    IconComponent = PoolOutlined;
                    break;
                  default:
                    IconComponent = null;
                }

                return (
                  <Box
                    key={filteredItem.id}
                    style={{
                      flex: "1 0 30%",
                      boxSizing: "border-box",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px", 
                    }}
                  >
                    {typeof IconComponent === 'string' ? (
                      <img src={IconComponent} alt={filteredItem.name} style={{ width: "24px", height: "24px" }} />
                    ) : (
                      <IconComponent style={{ color: '#64748B' }} />
                    )}
                    <Typography className="LeagueSpartan" style={{fontSize: "16px", fontWeight: "400", color: "#0F172A"}}>{filteredItem.name == "Stove" ? "Kitchen" : filteredItem.name}</Typography>
                  </Box>
                );
              })}
            </Box>
            </Box>
            <Box style={{display: "flex", flexDirection: "column", gap: "16px"}}>
            <Typography className="LeagueSpartan" style={{fontSize: "18px", fontWeight: "700", color: "#0F172A"}}>
              Where you’ll be
            </Typography>
            <Typography className="LeagueSpartan" style={{fontSize: "18px", fontWeight: "400", color: "#0F172A"}}>
              <LocationOnOutlined style={{color: "#FAA81D"}}/>Kensington Palace
            </Typography>
            <img src={this.map}/>
            </Box>
          </Box>
          <Box style={{width: "325px", height: "fit-content", padding: "32px 40px 32px 40px", borderRadius: "8px", boxShadow: "0px 2px 8px 0px #0000001A", backgroundColor: "white", display: "flex", flexDirection: "row",gap: "8px"}}>
          <Typography className="LeagueSpartan" style={{fontSize: "30px", fontWeight: "700", color: "#0F172A", display: "flex"}}>
              {this.state.selectedRoomAttributes.attributes.rack_rate}
          </Typography>
          <Typography className="LeagueSpartan" style={{alignSelf: "end", fontSize: "18px", fontWeight: "400", color: "#64748B"}}>
              per night
          </Typography>
          </Box>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <Box sx={webStyle.main}>

        <style>
          {`
            .MuiButton-root{
              min-width: 0px;
            }
            .MuiInputBase-input::placeholder {
              opacity: 1;            
            }
            .MuiList-padding {
              padding-top: 0px;
              padding-bottom: 0px;
            }
            .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
              border-color: transparent;
            }
            .MuiMobileStepper-progress {
              background: #CBD5E1;
              width: 100%;
              border-radius: 6px 0px 0px 6px;
            }
            .MuiMobileStepper-root{
              background: 0;
              padding: 0px;
            }
            .MuiLinearProgress-barColorPrimary {
              background: #CBD5E1;
            }       
            .MuiInput-underline::before .MuiInput-underline::after{
              border: 0;
            }
            .MuiInput-underline{
              border-bottom: 0px;
            }
            .MuiButton-outlined {
              border: 1px solid rgb(250, 168, 29);
            }     
            .MuiTypography-body1 {
              font-family: inherit;
            }
            .MuiOutlinedInput-input {
              padding: 0;
            }
            .MuiRadio-colorSecondary.Mui-checked {
              color: rgba(250, 168, 29, 1);
            }
            .custom-select {
              margin: 0;
              padding: 10px;
              width: 200px; 
            }
            .MuiSelect-select {
              padding: 0px; 
            }
            .MuiMenuItem-root:hover {
              background-color: #FEF3C7; 
            }
            .MuiFormControlLabel-labelPlacementStart {
              flex-direction: row-reverse;
              justify-content: space-between;
              margin-left: 0;
              margin-right: 40px;
            }
            .MuiFormControlLabel-root {
              margin-left: 0;
            }
            .styled-select .react-dropdown-select-dropdown {
              border-radius: 8px;
              border: 1px solid #cbd5e1;
              box-shadow: 0px 2px 4px 0px #00000026;
            }

            .styled-select .react-dropdown-select-item {
              height: 48px;
              display: flex;
              align-items: center;
            }

            .styled-select .react-dropdown-select-item:hover {
              background-color: #fef3c7;
            }

            .styled-select .react-dropdown-select-item.react-dropdown-select-item-selected {
              background-color: #fef3c7;
              color: #000000;
            }

            .styled-select .react-dropdown-select {
              border-radius: 8px;
              border: 1px solid #cbd5e1;
            }


            .MuiTouchRipple-root {
              display: none;
            }

            .MuiToggleButton-root{
              padding: 6px 12px 6px 12px; 
              border: 1px solid #64748B;
              font-family: inherit;
              color: #1E293B;
            }

            .MuiToggleButton-root.Mui-selected {
              background-color: #FEFCF9;
              color: #1E293B !important;
              border: 1px solid #FAA81D !important; 
            }
            .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
              border: 1px solid #64748B; 
              margin-left: 0;
            }
            .MuiFormControlLabel-labelPlacementStart {
              margin-right: -11px !important;
            }

            .ReactDropdownSelect:focus-within {
              outline: 0px;
              box-shadow: none;
            }

            .css-1o5fdv0-ReactDropdownSelect {
              color: #64748B;
            }

            .css-1ncinaz-DropDown{
              top: 55px;
            }

            input[type="file"] {
              display: none;
          }
            .custom-file-upload {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0px 16px 0px 16px;
              height: 44px;
              background: rgba(254, 252, 249, 1);
              border-radius: 8px;
              border: 1px solid rgba(250, 168, 29, 1);
              cursor: pointer;
          }

          .textarea {
            max-width: 100%;
            min-width: 100%;
            min-height: 140px;
            height: 140px;
            max-height: 220px;
            border-radius: 8px;
            border: 1px solid #CBD5E1;
            padding: 14px;
            font-size: 16px;
            font-weight: 400;
            color: #0F172A;
          }
          .textarea:focus {
            border: 1px solid #625d58;
            outline: none;
          }
          `}
        </style>

          <Box sx={{width: "100%",height: "92px",display: "flex", flexDirection: "column"}}>
          {this.renderHeader()}
          <Box sx={{width: "100%", alignSelf: "center"}}>
          {this.renderStepper()}
          </Box>
          </Box>
          
          <Box style={{
            width: "100%",
            height: "100%",
            backgroundImage: this.state.activeStep !== 4 ? `url(${this.bgImage})` : 'none',
            backgroundColor: this.state.activeStep !== 4 ? `none` : '#fefcf9',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            alignSelf: "center",
            display: "flex",
            justifyContent: "center"
          }}>


          {this.state.activeStep == 0 && this.renderStep0()}

          {this.state.activeStep == 1 && this.renderStep1()}
          
          {this.state.activeStep == 2 && this.renderStep2()}

          {this.state.activeStep == 3 && this.renderStep3()}

          {this.state.activeStep == 4 && this.renderStep4()}
          
          </Box>
        </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const webStyle = {
  // custom style definitions
  main: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    fontFamily: "League Spartan, Arial, sans-serif",
    flexDirection: "column"
  },
  paperStyleS: {
    display: 'flex', 
    alignItems: 'center', 
    fontSize: "16px",
    padding: "16px",
    borderRadius: "8px", 
    boxShadow: "0px 2px 8px 0px #0000001A"
  },

  paperStyle: {
    padding: "10px",
    borderRadius: "8px", 
    boxShadow: "0px 2px 8px 0px #0000001A"
  },

  helperText: {
    textAlign: "right",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "24px",
    padding: "40px 40px 0px 40px",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  disabledButtonStyle: {
    color: "#64748B",
    backgroundColor: "#F1F5F9",
    border: 0,
    borderRadius: "8px",
    fontSize: "16px",
    width: "88px",
    height: "56px",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "88px",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal6: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "117px",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal9: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "200px",
    height: "44px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal7: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "167px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal2: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "88px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal3: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "100%",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal4: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "117px",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal8: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "117px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal5: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "108px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  inputStyle: {
    width: "100%",
    height: "56px",
    padding: "14px",
    gap: "8px",
    borderRadius: "8px",
    color: "#0f172A",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  textStyle : {
    color: "#475569",
    fontSize: "14px",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  textStyle2 : {
    color: "#475569",
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "League Spartan, Arial, sans-serif",
  }
};
// Customizable Area End