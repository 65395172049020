import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {logo, dashboard, selected_dashboard, car, selected_car, reservations, selected_reservations, maintanance, selected_maintanance, pricing, selected_pricing, storelocator, selected_storelocator, analytics, selected_analytics, settings, selected_settings} from "./assets";
// Customizable Area End

import CarNavigationMenuController, {
  Props,
} from "./CarNavigationMenuController";

export default class CarNavigationMenu extends CarNavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const nav_items = [
      { label: "Dashboard", img: dashboard, active: selected_dashboard, index: 1 },
      { label: "Reservations", img: reservations, active: selected_reservations, index: 2},
      { label: "Cars", img: car, active: selected_car, index: 3 },
      { label: "Pricing and promotions", img: pricing, active: selected_pricing, index: 4 },
      { label: "Maintenance", img: maintanance, active: selected_maintanance, index: 5},
      { label: "Store Locator", img: storelocator, active: selected_storelocator, index: 6 },
      { label: "Analytics", img: analytics, active: selected_analytics, index: 7 },
      { label: "Settings", img: settings, active: selected_settings, index: 8 }
    ];
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{width: "120px", 
        height: "100%",
        alignItems: 'center', display: 'flex', flexDirection: 'column', borderRight: '1px solid #CBD5E1'}}>
        <Box sx={{width: "50px", 
        height: "56px", 
        marginTop: "24px"}}>
          <img src={logo}></img>
        </Box>
        <Box sx={{width: "80px", 
        alignItems: 'center', 
        display: 'flex', 
        flexDirection: 'column', marginTop:"40px"}} style={{gap:'32px'}}>
          {nav_items.map((navItem) => (
            <Box data-test-id="navBtn" 
            onClick={() => this.handleClick(navItem.index)} sx={{width: "80px", display: 'flex', flexDirection: 'column', alignItems: 'center'}} style={{gap: '8px'}}>
              
              {this.state.active != navItem.index ? 
              <Box 
              style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
              <img 
              src = {navItem.img}></img> 
              <Typography 
              className = "LeagueSpartan" 
              style={{fontSize: '14px', color: '#64748B', 
              fontWeight: 400, 
              textAlign: "center"}}>{navItem.label}</Typography>
              </Box>
              :
              <Box style={{display: "flex", flexDirection: "column", 
              alignItems: "center", justifyContent: "center"}}>
              <img src = {navItem.active}></img> 
              <Typography className = "LeagueSpartan" 
              style={{fontSize: '14px', color: "#FAA81D", fontWeight: 400, 
              textAlign: "center"}}>{navItem.label}</Typography>
              </Box>  }
            </Box> ))}
        </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
