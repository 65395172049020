import React from "react";
// ...

// Customizable Area Start
import { Box, Button, createTheme, Dialog, DialogContent, IconButton, InputAdornment, TextField, ThemeProvider, Typography } from "@material-ui/core";
import { LeftBox } from "../../../components/src/LeftBox";
import { ErrorMessage, Field, Form, Formik } from "formik";
import OTPInput from "react-otp-input";
import * as Yup from 'yup'
import { checkIcon, crossIcon } from "./assets";
import { Visibility, VisibilityOff } from "@material-ui/icons";


const theme = createTheme({
  palette: {
    primary: {
      main: "#FAA81D"
    },
  },
  typography: {
    h5: {
      fontWeight: 700
    },
    button: {
      fontWeight: 700,
      textTransform: 'none'
    },
  },
})

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email("please enter a valid email").required('please enter a valid email')
})
const passwordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password is too short - should be 8 characters minimum.')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase character.')
    .matches(/\d/, 'Password must contain at least one number.')
    .matches(/[@$!%*?&#]/, 'Password must contain at least one special character.')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password is not matching with the new password')
    .required('Confirm password is required')
})

// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <ThemeProvider theme={theme}>
        <Box maxWidth="xl" sx={webStyle.blockContainer}>
          <LeftBox />
          {/* Right Block */}
          <Box style={webStyle.rightBlock}>

            {/* Step 1 Email Input*/}

            {this.state.steps === 1 &&
              <Box sx={webStyle.formBox}>
                < Typography style={webStyle.formBoxHeading}>Forgot password</Typography>
                <Box style={{ width: '100%' }}>
                  <Formik initialValues={{ email: '' }} validateOnMount={true} validationSchema={emailValidationSchema} onSubmit={this.handleEmailValue}>
                    {({ handleChange, handleSubmit,isValid }) => (
                      <Form onSubmit={handleSubmit}>
                        <Box sx={webStyle.fieldBoxes}>
                          <Typography className="LeagueSpartan" style={webStyle.fieldLabel}>Email</Typography>
                          <Field
                            as={TextField}
                            variant="outlined"
                            name="email"
                            fullWidth
                            placeholder="Your Email"
                            type="email"
                            style={{ backgroundColor: 'white' }}
                            onChange={handleChange}
                          />
                          <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                          <Button
                            style={{...webStyle.Btn, color: isValid ? '#fff' : '#64748B', backgroundColor: isValid ? '#FAA81D' : '#F1F5F9'}}
                            disabled={!isValid}
                            type="submit"
                            className="LeagueSpartan"
                            >
                            SEND
                          </Button>
                          <Typography className="LeagueSpartan" style={{ alignSelf: 'center', }}>
                            Back to
                            <Button
                              variant="text"
                              className="forgotBtn"
                              onClick={this.handleLogin}
                              style={{
                                ...webStyle.forgotBtn, textTransform: 'none',
                              }}>
                              Log in
                            </Button>
                          </Typography>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
                <Box sx={webStyle.fieldBoxes}>
                  <Typography className="LeagueSpartan" style={{ alignSelf: 'center' ,paddingTop:'60px'}}>Don't have an account?
                    <Button style={{ ...webStyle.forgotBtn, textTransform: 'none' }} data-test-id="signUpBtn" onClick={this.handleSignUp} variant="text">
                      Sign up
                    </Button>
                  </Typography>
                </Box>
              </Box>}

            {/* Step 2 - Verification */}

            {this.state.steps === 2 &&
              <Box sx={webStyle.formBox}>
                <Typography variant="h5" style={webStyle.formBoxHeading}>Verification</Typography>
                <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                  <Typography style={webStyle.instructions}>Enter Verification Code</Typography>
                  <OTPInput
                    containerStyle={{ justifyContent: 'space-between' }}
                    value={this.state.otp}
                    onChange={this.setOtp}
                    data-test-id='input1'
                    numInputs={4}
                    inputType="tel"
                    renderInput={(props) => <input {...props} />}
                    inputStyle={webStyle.otpInput}
                  />
                  <Button onClick={this.handleVerify} data-test-id='verifyBtn' fullWidth style={webStyle.Btn}>VERIFY</Button>
                  <Typography align="center" variant="body2" className="LeagueSpartan" style={{ padding: '25px' }}>
                    If you didn't receive a code, <span data-test-id="reverifyBtn" style={webStyle.forgotBtn} onClick={this.reVerify}>Resend</span>
                  </Typography>
                </Box>
                <Box sx={webStyle.fieldBoxes}>
                  <Typography className="LeagueSpartan" style={{ alignSelf: 'center', fontSize: '0.9rem',paddingTop:'60px' }}>Don't have an account?
                    <Button style={{ ...webStyle.forgotBtn, textTransform: 'none' }} data-test-id="signUpBtn" onClick={this.handleSignUp} variant="text">
                      Sign up
                    </Button>
                  </Typography>
                </Box>
              </Box>
            }


            {/* Step 3 Set new password */}
            {this.state.steps === 3 &&
              <Box sx={webStyle.formBox}>
                <Typography style={webStyle.formBoxHeading}>New password</Typography>
                <Box style={{ width: '100%' }}>
                  <Formik initialValues={{ password: '', confirm_password: '' }} validationSchema={passwordValidationSchema} onSubmit={this.handlePasswordValue}>
                    {({ handleChange, handleSubmit, values }) => (
                      <Form onSubmit={handleSubmit}>
                        <Box sx={webStyle.fieldBoxes}>
                          <Typography style={webStyle.fieldLabel} className="LeagueSpartan">Enter new password</Typography>
                          <Field
                            as={TextField}
                            type="password"
                            placeholder="New password"
                            name="password"
                            onChange={handleChange}
                            variant="outlined"
                            style={{ backgroundColor: 'white' }}
                          />
                          <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                          <Typography style={webStyle.fieldLabel} className="LeagueSpartan">Confirm password</Typography>
                          <Field
                            as={TextField}
                            placeholder="Confirm password"
                            type={this.state.enablePasswordField ? 'password' : 'text'}
                            variant="outlined"
                            name="confirm_password"
                            data-test-id="userPassword"
                            onChange={handleChange}
                            inputProps={{ style: { backgroundColor: "white" } }}
                            InputProps={{
                              style: { backgroundColor: 'white', padding: '2' },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                  >
                                    {this.state.enablePasswordField ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Typography style={{ fontSize: '14px' }}>

                            <ErrorMessage name="confirm_password" component="div" style={{ color: 'red' }} />
                          </Typography>
                          <Box style={{ visibility: values.password ? 'visible' : 'hidden', height: values.password ? "80px" : "0" }}>
                            <ul style={webStyle.list} >
                              <li style={webStyle.listItems}>
                                {values.password.length >= 8 ? (<img src={checkIcon} />) : (<img src={crossIcon} />)}Min 8 characters
                              </li>

                              <li style={webStyle.listItems}>
                                {/\d/.test(values.password) ? (<img src={checkIcon} />) : (<img src={crossIcon} />)}One number
                              </li>

                              <li style={webStyle.listItems}>
                                {/[A-Z]/.test(values.password) ? (<img src={checkIcon} />) : (<img src={crossIcon} />)}One uppercase letter
                              </li>
                            </ul>
                          </Box>
                          <Button
                            style={webStyle.Btn}
                            type="submit"
                            className="LeagueSpartan">
                            SAVE
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            }
          </Box>
          <Dialog open={this.state.openDialog} onClose={this.handleCloseDialog} >
            <Box style={{ display: "flex", flexDirection: 'column', alignItems: 'center', padding: '5px', width: '440px', height: '350px' }}>
              <DialogContent >
                <Box sx={webStyle.dialog}>

                  <img height='100px' width='100px' src={checkIcon} />
                  <Typography style={{ fontWeight: 700, letterSpacing: '-2px', fontSize: '24px', marginTop: '5px', color: '#0F172A', lineHeight: '32px' }}>Password saved</Typography>
                  <Box textAlign={"center"}>
                    <Typography style={{ color: '##334155', fontWeight: 400, fontSize: '18px' }}>
                      Your password succesfully saved.
                    </Typography>
                    <Typography style={{ color: '##334155', fontWeight: 400, fontSize: '18px' }}>
                      Click below to continue.
                    </Typography>
                  </Box>
                  <Button onClick={this.handleLogin} variant="outlined" fullWidth style={{ backgroundColor: '#FAA81D', borderRadius: '8px', padding: '16px' }}>
                    <Typography style={{
                      color: "#fff",
                      lineHeight: "24px",
                      fontWeight: 700,
                      fontSize: "16px"
                    }}>
                      CONTINUE
                    </Typography>
                  </Button>
                </Box>

              </DialogContent>
            </Box>
          </Dialog>
        </Box>
      </ThemeProvider >
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const webStyle = {
  dialog: {
    width: "360px",
    height: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center"
  },
  rightBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%'
  },
  blockContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100%',
    fontFamily: 'League Spartan, sans-serif'
  },
  formBoxHeading: {
    fontWeight: 700,
    fontSize: '30px',
    letterSpacing: '-2',
    marginBottom: '20px'
  },
  formBox: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#FEF3C7',
    borderRadius: '24px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  subHeading: {
    fontWeight: 400,
    fontSize: '22px',
    letterSpacing: '-2'
  },
  forgotBtn: {
    color: '#FAA81D',
    fontWeight: 700,
    fontSize: '18px',
    cursor: 'pointer'
  },
  fieldBoxes: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '10px'
  },
  Btn: {
    width: '100%',
    backgroundColor: '#FAA81D',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 700,
    marginTop: '20px',
    padding:'12px'
  },
  fieldLabel: {
    fontWeight: 700,
    fontSize: '14px',
    color: '#334155'
  },
  list: {
    listStyle: 'none',
    padding: '0px'
  },
  listItems: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  instructions: {
    color: '#334155',
    fontWeight: 700,
    fontSize: '16px'
  },
  otpInput: {
    height: '56px',
    width: '56px',
    fontSize: '24px',
    margin: '6px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    color: '#4B4C4B',
    gap: '20px'
  },
}
// Customizable Area End
