import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { LeftBox } from "../../../components/src/LeftBox";
import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik } from "formik";

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is Required'),
  password: Yup.string().required('Password is Required')
})
const theme = createTheme({
  palette: {
    primary: {
      main: "#FAA81D",
    },
  },
});

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";


export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box maxWidth="xl" sx={webStyle.blockContainer}>
          <LeftBox />
          {/* Right Block */}
          <Box style={webStyle.rightBlock}>
            <Box sx={webStyle.form}>
              {/* heading */}
              <Typography style={webStyle.formHeading}>Welcome back</Typography>
              {/* fields */}
              <Box style={{ width: '100%' }}>
                <Formik initialValues={{ email: '', password: '' }} validationSchema={validationSchema} validateOnMount={true} onSubmit={this.handleFormikValues}>
                  {({ handleChange, handleSubmit, isValid }) => (
                    <Form onSubmit={handleSubmit}>
                      <Box sx={webStyle.fieldBox}>
                        <Typography className="LeagueSpartan" style={webStyle.fieldLabel}>Email</Typography>
                        <Field
                          as={TextField}
                          variant="outlined"
                          name="email"
                          fullWidth
                          placeholder="Your Email"
                          type="email"
                          style={{ backgroundColor: 'white' }}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                        <Typography style={webStyle.fieldLabel} className="LeagueSpartan">Password</Typography>
                        <Field
                          as={TextField}
                          inputProps={{ style: { backgroundColor: "white" } }}
                          data-test-id="userPassword"
                          type={this.state.enablePasswordField ? 'text' : 'password'}
                          name="password"
                          variant="outlined"
                          onChange={handleChange}
                          placeholder="Your Password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={this.handleClickShowPassword}
                                  aria-label="toggle password visibility"
                                >
                                  {this.state.enablePasswordField ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            style: { backgroundColor: 'white', padding: '2' },
                          }}
                        />
                        <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                        <Typography className="LeagueSpartan" style={{ alignSelf: 'end' }}>
                          <Button
                            variant="text"
                            className="forgotBtn"
                            onClick={this.handleForgotPassword}
                            style={{
                              ...webStyle.forgotBtn, textTransform: 'none',
                            }}>
                            Forgot Password?
                          </Button>
                        </Typography>
                        <Button
                          style={{ ...webStyle.loginBtn, color: isValid ? '#fff' : '#64748B', backgroundColor: isValid ? '#FAA81D' : '#F1F5F9' }}
                          type="submit"
                          disabled={!isValid}
                          className="LeagueSpartan">
                          LOG IN
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
              <Box sx={webStyle.fieldBox}>
                <Typography className="LeagueSpartan" style={{ alignSelf: 'center' }}>Don't have an account?
                  <Button style={{ ...webStyle.forgotBtn, textTransform: 'none' }} data-test-id="signUpBtn" onClick={this.handleSignUp} variant="text">
                    Sign up
                  </Button>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  formHeading: {
    fontWeight: 700,
    fontSize: '30px',
    letterSpacing: '-2',
    marginBottom: '20px'
  },
  blockContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100%',
    fontFamily: 'League Spartan, sans-serif'
  },
  rightBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%'
  },
  form: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '400px',
    backgroundColor: '#FEF3C7',
    borderRadius: '24px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  fieldBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '5px'
  },
  subHeading: {
    fontWeight: 400,
    fontSize: '22px',
    letterSpacing: '-2'
  },
  forgotBtn: {
    color: '#FAA81D',
    fontWeight: 700,
    fontSize: '18px',
  },
  loginBtn: {
    width: '100%',
    backgroundColor: '#F1F5F9',
    color: '#64748B',
    fontSize: '16px',
    fontWeight: 700
  },
  fieldLabel: {
    fontWeight: 700,
    fontSize: '14px',
    color: '#334155'
  }
}
// Customizable Area End
