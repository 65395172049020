import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  MobileStepper,
  Input,
  FormControlLabel,
  Checkbox,
  withStyles,
  CheckboxProps,
  InputLabel,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { logo, background, building } from "./assets";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  OrangeCheckbox = withStyles({
    root: {
      color: "#FAA81D",
      "&$checked": {
        color: "#FAA81D",
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
  getWebList(item: any) {
    let value = item.attributes;
    return (
      <Box sx={webStyle.productBox} key={`product-item-${item.id}`}>
        <Box sx={webStyle.ImgContainer}>
          <img style={webStyle.productImg} src={value.images?.[0].url} />
        </Box>
        <Box sx={webStyle.detailContent}>
          <Typography variant="h6">{value.name}</Typography>
          <Typography variant="h6">{value.price}</Typography>
          <Typography variant="h6">{value.average_rating}</Typography>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box width="100vw" height="auto" sx={webStyle.mainWrapper}>
          <style>
            {`
            .MuiMobileStepper-progress {
              background: rgba(0, 0, 0, 0.1);
              width: 100%;
              border-radius: 6px 0px 0px 6px;
            }
            .MuiLinearProgress-barColorPrimary {
              background: linear-gradient(99.09deg, #D97706 2.64%, #FCD34D 100%);
            }       
            .MuiInput-underline::before .MuiInput-underline::after{
              border: 0;
            }
            .MuiRadio-colorSecondary.Mui-checked {
              color: rgba(250, 168, 29, 1);
            }
            .MuiFormGroup-root{
              flex-direction: row;
            }
            .MuiInput-underline{
              border-bottom: 0px;
            }
            .MuiToggleButton-root{
              text-transform: none;
              text-wrap: nowrap;
              padding: 6px 18px 6px 18px; 
              border: 1px solid rgb(113, 113, 113);
            }
            .MuiButton-outlined {
              border: 1px solid rgb(250, 168, 29);
            }
            .MuiToggleButton-root.Mui-selected {
              color: #FAA81D;
              background-color: #FFFFFF;
              border-color: #FAA81D;
            }
            .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
              border-left: 1px solid rgb(113, 113, 113);
            }
            .MuiToggleButton-root.Mui-selected:not(:first-child) {
              color: #FAA81D;
              background-color: #FFFFFF;
              border-color: #FAA81D;
            }
            .MuiListItem-gutters {
              padding-left: 0;
              padding-right: 0;
              display: flex;
              flex-direction: row;
            }          
            .MuiListItemText-root {
              flex: 0;
              min-width: 300px;
              margin-top: 0;
              margin-bottom: 0;
            }
            .MuiList-padding {
              padding-top: 0;
              padding-bottom: 0;
            }
            .MuiFormControlLabel-root {
              min-width: 180px;
            }
          `}
          </style>
          <Box sx={webStyle.customBox4}>
            <Box sx={webStyle.header}>
              <img src={logo} alt="" />
              <Box sx={webStyle.customtypo}>Hotel info</Box>
            </Box>
          </Box>

          {this.state.activeStep == 0 && (
            <Box sx={webStyle.customBox3}>
              <MobileStepper
                variant="progress"
                steps={8}
                position="static"
                activeStep={this.state.activeStep}
                nextButton={null}
                backButton={null}
              />
              <StyledBox>
                <Box className="cardSection">
                  <img src={building} alt="" />
                  <Box sx={webStyle.customtypo2}>
                    You currently have no property.
                    <br /> Begin your hotel business by creating your first
                    hotel now!
                  </Box>
                  <button
                    style={webStyle.customBtn}
                    onClick={() => {
                      this.handleNext();
                    }}
                  >
                    {" "}
                    <ControlPointIcon />
                    ADD HOTEL
                  </button>
                </Box>
              </StyledBox>
            </Box>
          )}

          {this.state.activeStep == 1 && (
            <Box sx={webStyle.customBox3}>
              <Box>
                <Box sx={webStyle.customBox2}>
                  <MobileStepper
                    variant="progress"
                    steps={8}
                    position="static"
                    activeStep={this.state.activeStep}
                    nextButton={null}
                    backButton={null}
                  />
                  <StyledBox>
                    <Box className="cardSection2">
                      <Typography style={webStyle.mainLabel}>
                        Basic Info
                      </Typography>
                      <Box sx={webStyle.inputBoxStyle}>
                        <InputLabel style={webStyle.textStyle} required>
                          Hotel Name
                        </InputLabel>
                        <Input
                          style={webStyle.inputStyle}
                          disableUnderline={true}
                          placeholder="Hotel name"
                        />
                      </Box>
                      <Box sx={webStyle.inputBoxStyle}>
                        <InputLabel
                          style={{ ...webStyle.textStyle, fontWeight: 700 }}
                          required
                        >
                          Star Rating
                        </InputLabel>
                        <Rating
                          name="simple-controlled"
                          value={this.state.stars}
                          onChange={(event, newValue) => {
                            this.setState({ stars: newValue });
                          }}
                        />
                      </Box>
                      <FormControlLabel
                        control={<this.OrangeCheckbox name="star" />}
                        label="Star rated"
                      />
                      <Box sx={webStyle.customBox} style={{}}>
                        <Button
                          variant="outlined"
                          data-test-id="back2"
                          style={webStyle.buttonStyleModal2}
                          size="small"
                          onClick={this.handleBack}
                        >
                          Back
                        </Button>
                        <Button
                          size="small"
                          style={webStyle.buttonStyleModal}
                          onClick={this.handleNext}
                          data-test-id="next"
                        >
                          Next
                        </Button>
                      </Box>
                    </Box>
                  </StyledBox>
                </Box>
              </Box>
            </Box>
          )}
          {this.state.activeStep == 2 && (
            <Box sx={webStyle.customBox3}>
              <Box>
                <Box sx={webStyle.customBox2}>
                  <MobileStepper
                    activeStep={this.state.activeStep}
                    position="static"
                    nextButton={null}
                    variant="progress"
                    steps={8}
                    backButton={null}
                  />
                  <StyledBox>
                    <Box className="cardSection2">
                      <Typography style={webStyle.mainLabel}>
                        Basic Info
                      </Typography>
                      <Box sx={webStyle.inputBoxStyle}>
                        <InputLabel style={webStyle.textStyle} required>
                          Email Address
                        </InputLabel>
                        <Input
                          style={webStyle.inputStyle}
                          disableUnderline={true}
                        />
                      </Box>
                      <Box sx={webStyle.inputBoxStyle2}>
                        <Box sx={webStyle.inputBoxStyle}>
                          <InputLabel style={webStyle.textStyle} required>
                            Phone Number
                          </InputLabel>
                          <Input
                            style={webStyle.inputStyle}
                            disableUnderline={true}
                          />
                        </Box>
                        <Box sx={webStyle.inputBoxStyle}>
                          <InputLabel style={webStyle.textStyle} required>
                            Alternative Phone Number
                          </InputLabel>
                          <Input
                            style={webStyle.inputStyle2}
                            disableUnderline={true}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ ...webStyle.inputBoxStyle }}>
                        <InputLabel style={webStyle.textStyle} required>
                          Fax Number
                        </InputLabel>
                        <Input
                          style={{ ...webStyle.inputStyle2, maxWidth: "327px" }}
                          disableUnderline={true}
                        />
                      </Box>
                      <Box sx={webStyle.inputBoxStyle} />
                      <Box sx={webStyle.customBox}>
                        <Button
                          style={webStyle.buttonStyleModal2}
                          data-test-id="back2"
                          onClick={this.handleBack}
                          variant="outlined"
                          size="small"
                        >
                          Back
                        </Button>
                        <Button
                          onClick={this.handleNext}
                          data-test-id="next"
                          style={webStyle.buttonStyleModal}
                          size="small"
                        >
                          Next
                        </Button>
                      </Box>
                    </Box>
                  </StyledBox>
                </Box>
              </Box>
            </Box>
          )}

          {this.state.activeStep == 3 && (
            <Box sx={webStyle.customBox3}>
              <Box>
                <Box sx={webStyle.customBox2}>
                  <MobileStepper
                    variant="progress"
                    steps={8}
                    position="static"
                    activeStep={this.state.activeStep}
                    nextButton={null}
                    backButton={null}
                  />
                  <StyledBox>
                    <Box className="cardSection2">
                      <Typography style={webStyle.mainLabel}>
                        Hotel location
                      </Typography>
                      <Box sx={webStyle.inputBoxStyle}>
                        <InputLabel style={webStyle.textStyle}>
                          Door number/Building name
                        </InputLabel>
                        <Input
                          style={webStyle.inputStyle}
                          disableUnderline={true}
                        />
                      </Box>
                      <Box sx={webStyle.inputBoxStyle}>
                        <InputLabel style={webStyle.textStyle} required>
                          Street
                        </InputLabel>
                        <Input
                          style={webStyle.inputStyle}
                          disableUnderline={true}
                        />
                      </Box>
                      <Box sx={webStyle.inputBoxStyle}>
                        <InputLabel style={webStyle.textStyle} required>
                          Post code
                        </InputLabel>
                        <Input
                          style={webStyle.inputStyle}
                          disableUnderline={true}
                        />
                      </Box>
                      <Box sx={webStyle.inputBoxStyle2}>
                        <Box sx={webStyle.inputBoxStyle}>
                          <InputLabel style={webStyle.textStyle} required>
                            City
                          </InputLabel>
                          <Input
                            style={webStyle.inputStyle}
                            disableUnderline={true}
                          />
                        </Box>
                        <Box sx={webStyle.inputBoxStyle}>
                          <InputLabel style={webStyle.textStyle} required>
                            Country/Region
                          </InputLabel>
                          <Input
                            style={webStyle.inputStyle2}
                            disableUnderline={true}
                          />
                        </Box>
                      </Box>
                      <Box sx={webStyle.inputBoxStyle} />
                      <Box sx={webStyle.customBox}>
                        <Button
                          size="small"
                          style={webStyle.buttonStyleModal2}
                          variant="outlined"
                          onClick={this.handleBack}
                        >
                          Back
                        </Button>
                        <Button
                          style={webStyle.buttonStyleModal}
                          data-test-id="next"
                          onClick={this.handleNext}
                          size="small"
                        >
                          Next
                        </Button>
                      </Box>
                    </Box>
                  </StyledBox>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </ThemeProvider>

      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  customBox: {
    justifyContent: "flex-end",
    gap: "13px",
    display: "flex",
    flexDirection: "row",
  },

  customBox2: {
    width: "100%",
    alignSelf: "center",
  },

  customBox3: {
    width: "100%",
  },

  customBox4: {
    display: "flex",
    width: "100%",
  },
  customBtn: {
    display: "flex",
    alignItems: "center",
    padding: "16px 24px 16px 20px",
    borderRadius: "8px",
    background: "#FAA81D",
    fontFamily: "League Spartan",
    fontSize: "16px",
    fontWeight: "700",
    justifyContent: "center",
    gap: "8px",
    color: "#FFFFFF",
    cursor: "pointer",
  },

  customtypo: {
    fontFamily: " League Spartan",
    fontSize: "24px",
    fontWeight: "700",
  },

  customtypo2: {
    fontFamily: "League Spartan",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    margin: "25px auto",
    color: "#64748B",
  },
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  buttonStyle: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "360px",
    height: "56px",
    color: "white",
    fontWeight: "700",
  },
  buttonStyleModal: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "120px",
    height: "56px",
    color: "white",
    fontWeight: "700",
  },
  buttonStyleModal2: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "120px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
  },
  inputStyle: {
    width: "100%",
    height: "56px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid rgba(203, 213, 225, 1)",
  },
  inputStyle2: {
    width: "100%",
    height: "56px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid rgba(203, 213, 225, 1)",
  },
  inputBoxStyle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  inputBoxStyle2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "12px",
  },
  textStyle: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#475569",
    lineHeight: "22px",
  },
  mainLabel: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    height: 150,
  },

  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "League Spartan",
    fontSize: "24px",
    fontWeight: "700",
    padding: "20px",
    width: "50%",
  },
  mains: {
    BackgroundImage: background,
  },
  divide: {
    height: "2px",
    "&::before, &::after": {
      width: "30%",
    },
    "&::before": {
      borderColor: "transparent",
    },
    "&::after": {
      borderColor: "red",
    },
  },
};

const StyledBox = styled("div")({
  width: "100%",
  height: "100vh",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundPosition: "center",

  "& .cardSection": {
    marginTop: "56px",
    maxWidth: "670px",
    maxHeight: "492px",
    padding: "40px",
    borderRadius: "8px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFFFF",
    boxShadow: "0px 2px 8px 0px #0000001A",
  },
  "& .cardSection2": {
    maxWidth: "670px",
    padding: "40px",
    borderRadius: "8px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    justifyContent: "center",
    background: "#FFFFFF",
    boxShadow: "0px 2px 8px 0px #0000001A",
    marginTop: "53px",
  },
});

// Customizable Area End
