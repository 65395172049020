export const userProfile = require("../assets/user-profile.png");
export const logo = require("../assets/logo.svg")
export const mytrips = require("../assets/mytrips.svg")
export const car = require("../assets/car.svg")
export const hotel = require("../assets/hotel.svg")
export const activity = require("../assets/activity.svg")
export const settings = require("../assets/settings.svg")
export const selected_mytrips = require("../assets/selected_mytrips.svg")
export const selected_car = require("../assets/selected_car.svg")
export const selected_hotel = require("../assets/selected_hotel.svg")
export const selected_activity = require("../assets/selected_activity.svg")
export const selected_settings = require("../assets/selected_settings.svg")

export const dashboard = require("../assets/dashboard.svg")
export const calender = require("../assets/calender.svg")
export const reservations = require("../assets/reservations.svg")
export const room = require("../assets/room.svg")
export const pricing = require("../assets/pricing.svg")
export const hotels = require("../assets/hotels.svg")
export const analytics = require("../assets/analytics.svg")
export const maintanance = require("../assets/maintanance.svg")
export const storelocator = require("../assets/storelocator.svg")

export const selected_dashboard = require("../assets/selected_dashboard.svg")
export const selected_calender = require("../assets/selected_calender.svg")
export const selected_reservations = require("../assets/selected_reservations.svg")
export const selected_room = require("../assets/selected_room.svg")
export const selected_pricing = require("../assets/selected_pricing.svg")
export const selected_hotels = require("../assets/selected_hotels.svg")
export const selected_analytics = require("../assets/selected_analytics.svg")
export const selected_maintanance = require("../assets/selected_maintanance.svg")
export const selected_storelocator = require("../assets/selected_storelocator.svg")

