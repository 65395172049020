import React from "react";
// ...

// Customizable Area Start
import { Box, Button,MobileStepper, Paper, TextareaAutosize, TextField, Typography, withStyles } from "@material-ui/core";

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#625d58',
      },
    },
  },
})(TextField);
// Customizable Area End

import GeneralBusinessInfoController, {
  Props,
} from "./GeneralBusinessInfoController";


export default class GeneralBusinessInfo extends GeneralBusinessInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  logoSvg = require("../assets/logoSvg.svg");
  editBtn = require("../assets/editBtn.svg");
  verification = require("../assets/verification.svg");
  bgImage = require("../assets/bgImage.svg");
  renderStepper() {
    if (this.state.editClicked || this.state.activeStep === 4) {
      return null; 
    }
    return (
      <MobileStepper
        variant="progress"
        steps={4}
        position="static"
        activeStep={1}
        nextButton={null}
        backButton={null}
      />
    );
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <Box sx={webStyle.main}>

        <style>
          {`
            .MuiMobileStepper-progress {
              background: rgba(0, 0, 0, 0.1);
              width: 100%;
              border-radius: 6px 0px 0px 6px;
            }
            .MuiMobileStepper-root{
              background: 0;
            }
            .MuiLinearProgress-barColorPrimary {
              background: linear-gradient(99.09deg, #D97706 2.64%, #FCD34D 100%);
            }       
            .MuiInput-underline::before .MuiInput-underline::after{
              border: 0;
            }
            .MuiInput-underline{
              border-bottom: 0px;
            }
            .MuiButton-outlined {
              border: 1px solid rgb(250, 168, 29);
            }     
            .MuiTypography-body1 {
              font-family: inherit;
            }
            .MuiOutlinedInput-input {
              padding: 0;
            }
            .textarea {
              max-width: 100%;
              min-width: 100%;
              min-height: 140px;
              height: 140px;
              max-height: 220px;
              border-radius: 8px;
              border: 1px solid #CBD5E1;
              padding: 14px;
              font-size: 16px;
              font-weight: 400;
            }
            .textarea:focus {
              border: 1px solid #625d58;
              outline: none;
            }
          `}
        </style>

          <Box sx={{width: "100%",height: "92px",display: "flex", flexDirection: "column"}}>
          <div style={{ display: 'flex', alignItems: 'center', height: '92px', position: 'relative', padding: "15px" }}>
            <img src={this.logoSvg} style={{marginLeft: "10px"}}/>
            <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            <Typography style={{ fontWeight: '700', fontSize: '24px' }}>
              {(() => {
                switch (this.state.activeStep) {
                  case 0:
                    return 'Business Information';
                  case 1:
                    return 'Business Information';
                  case 2:
                    return 'Business Information';
                  case 3:
                    return 'Review Information';
                  case 4:
                    return '';
                  default:
                    return 'Business Information';
                }
              })()}
            </Typography> 
            </span>
          </div>
          <Box sx={{width: "100%", alignSelf: "center"}}>
          {this.renderStepper()}
          </Box>
          </Box>
          
          <Box style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${this.bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            alignSelf: "center",
            display: "flex",
            justifyContent: "center"
            }}>
          {this.state.activeStep == 0 && (
            <Box style={{ alignSelf: "center", backgroundColor: "white"}} sx={{width: "440px", margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column", justifyContent: "space-between", }}>                  
           
            <Box sx={webStyle.content}>
            
            <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A"}}>General</Typography>

            <form style={{display: "flex", flexDirection: "column", gap:"24px"}} noValidate autoComplete="off">
              <Box>
              <Typography style={webStyle.textStyle}>Business name</Typography>
              <CssTextField   
                data-test-id="bName"             
                variant="outlined"                               
                fullWidth
                id="businessName"             
                name="businessName"
                value={this.state.businessName}
                onChange={this.handleInputChange} 
                InputProps={{ style : webStyle.inputStyle }}
              />
              </Box>

              <Box>
              <Typography style={webStyle.textStyle}>VAT Number</Typography>
              <CssTextField
                data-test-id="vatNum"   
                variant="outlined"                         
                fullWidth
                id="vatNumber"                  
                name="vatNumber"
                value={this.state.vatNumber}
                onChange={this.handleInputChange}
                InputProps={{ style : webStyle.inputStyle }}
              />
              </Box>
              <Box>
              <Typography style={webStyle.textStyle}>Description of service</Typography>
              <TextareaAutosize
                id="descriptionOfService"
                name="descriptionOfService"
                value={this.state.descriptionOfService}
                onChange={this.handleInputChange}
                rows={4}
                maxLength={500}
                className="textarea LeagueSpartan"
              />
              <Box style={{width:"100%", display: "flex", justifyContent: "end"}}>
                <Typography className="LeagueSpartan" style={webStyle.textStyle2} variant="caption" id="descriptionOfService-helper-text">
                  {`${this.state.descriptionOfService.length}/500`}
                </Typography>             
              </Box>
              </Box>

            </form>                       
            </Box>  

            <Box style={{gap: "16px"}} sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", paddingRight: "36px",paddingBottom: "16px",borderTop: "1px solid rgb(226, 232, 240)"}}>
              {this.state.editClicked ? 
              (<>
              <Button data-test-id="cancel" onClick={()=>{this.handleCancel(1)}} style={webStyle.buttonStyleModal5} size="small" variant="outlined" >
                  Cancel
              </Button>
              <Button onClick={()=>{this.handleSave(1)}} data-test-id="save" style={webStyle.buttonStyleModal} size="small" >
                  Save
              </Button>
              </>
               )  
              :
              (<>
              <Button style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
                  Back
              </Button>
              <Button data-test-id="next1" onClick={()=>{this.handleNextClick(1)}} style={webStyle.buttonStyleModal} size="small" >
                  Next
              </Button>
              </>
              )
              }
              
            </Box>

            </Box>
          )}

          {this.state.activeStep == 1 && (
            <Box style={{ alignSelf: "center", backgroundColor: "white"}} sx={{width: "440px", margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column", justifyContent: "space-between", }}>                  
            
            <Box sx={webStyle.content}>
            <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A"}}>Business Address</Typography>

            <form style={{display: "flex", flexDirection: "column", gap:"24px"}} noValidate autoComplete="off">
              
              <Box sx={{display: "flex", flexDirection: "column"}}>
              <Typography style={webStyle.textStyle}>Door number/Building name</Typography>
              <CssTextField    
                data-test-id="bNum"            
                variant="outlined"                 
                fullWidth
                id="buildingNumber"             
                name="buildingNumber"
                value={this.state.buildingNumber}
                onChange={this.handleInputChange}
                InputProps={{ style : webStyle.inputStyle }}
              />
              </Box>
            
              <Box>
              <Typography style={webStyle.textStyle}>Street*</Typography>            
              <CssTextField
               data-test-id="street" 
                variant="outlined"               
                
                fullWidth
                id="street"                  
                name="street"
                value={this.state.street}
                onChange={this.handleInputChange}
                error={this.state.errors.street}
                helperText={
                  this.state.errors.street
                    ? "Street is required"
                    : ""
                }
                
                InputProps={{ style : webStyle.inputStyle }}
              />         
              </Box>

              <Box>
              <Typography style={webStyle.textStyle}>Post code*</Typography>            
              <CssTextField
               data-test-id="post" 
                variant="outlined"               
                
                fullWidth
                id="postcode"                  
                name="postcode"
                value={this.state.postcode}
                onChange={this.handleInputChange}
                error={this.state.errors.postcode}
                helperText={
                  this.state.errors.postcode
                    ? "PostCode is required"
                    : ""
                }
                
                InputProps={{ style : webStyle.inputStyle }}
              />         
              </Box>

              <Box>
              <Typography style={webStyle.textStyle}>City*</Typography>            
              <CssTextField
               data-test-id="city" 
                variant="outlined"               
                
                fullWidth
                id="city"                  
                name="city"
                value={this.state.city}
                onChange={this.handleInputChange}
                error={this.state.errors.city}
                helperText={
                  this.state.errors.city
                    ? "City is required"
                    : ""
                }
                
                InputProps={{ style : webStyle.inputStyle }}
              />         
              </Box>

              <Box>
              <Typography style={webStyle.textStyle}>Country/Region*</Typography>            
              <CssTextField
               data-test-id="country" 
                variant="outlined"               
                
                fullWidth
                id="country"                  
                name="country"
                value={this.state.country}
                onChange={this.handleInputChange}
                error={this.state.errors.country}
                helperText={
                  this.state.errors.country
                    ? "Country/Region is required"
                    : ""
                }
                
                InputProps={{ style : webStyle.inputStyle }}
              />         
              </Box>

            </form>        
                 
            </Box> 
                      
            <Box style={{gap: "16px"}} sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", paddingRight: "36px",paddingBottom: "16px",borderTop: "1px solid rgb(226, 232, 240)"}}>
              {this.state.editClicked ? 
              (<>
              <Button data-test-id="cancel" onClick={()=>{this.handleCancel(2)}} style={webStyle.buttonStyleModal5} size="small" variant="outlined" >
                  Cancel
              </Button>
              <Button onClick={()=>{this.handleSave(2)}} data-test-id="save" style={webStyle.buttonStyleModal} size="small" >
                  Save
              </Button>
              </>
               )  
              :
              (<>
              <Button data-test-id="back" onClick={this.handleBackClick} style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
                  Back
              </Button>
              <Button data-test-id="next" onClick={()=>{this.handleNextClick(2)}} style={webStyle.buttonStyleModal} size="small" >
                  Next
              </Button>
              </>
              )
              }
              
            </Box>

            </Box>
          )}
          
          {this.state.activeStep == 2 && (
            <Box style={{ alignSelf: "center", backgroundColor: "white"}} sx={{width: "440px", margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column", justifyContent: "space-between", }}>                  
            
            <Box sx={webStyle.content}>
            <Typography style={{fontWeight: "700", fontSize: "18px", color: "#0F172A"}}>Payment and Banking Information</Typography>

            <form style={{display: "flex", flexDirection: "column", gap:"24px"}} noValidate autoComplete="off">
              
              <Box sx={{display: "flex", flexDirection: "column"}}>
              <Typography style={webStyle.textStyle}>Bank</Typography>
              <CssTextField    
                data-test-id="bank"            
                variant="outlined"                 
                fullWidth
                id="bank"             
                name="bank"
                value={this.state.bank}
                onChange={this.handleInputChange}
                InputProps={{ style : webStyle.inputStyle }}
              />
              </Box>
            
              <Box>
              <Typography style={webStyle.textStyle}>Account holder's name</Typography>            
              <CssTextField
               data-test-id="accholdername" 
                variant="outlined"               
                required
                fullWidth
                id="accholdername"                  
                name="accholdername"
                value={this.state.accholdername}
                onChange={this.handleInputChange}
                InputProps={{ style : webStyle.inputStyle }}
              />         
              </Box>

              <Box style={{gap: "20px"}} sx={{display: "flex", flexDirection: "row"}}>
              <Box sx={{width: "50%"}}>
              <Typography style={webStyle.textStyle}>Account number</Typography>            
              <CssTextField
               data-test-id="accnum" 
                variant="outlined"               
                required
                fullWidth
                id="accnum"                  
                name="accnum"
                value={this.state.accnum}
                onChange={this.handleAccNumChange}
                InputProps={{ style : webStyle.inputStyle }}
              />         
              </Box>
              <Box sx={{width: "50%"}}>
              <Typography style={webStyle.textStyle}>Sort code</Typography>            
              <CssTextField
               data-test-id="sortcode" 
                variant="outlined"               
                required
                fullWidth
                id="sortcode"                  
                name="sortcode"
                value={this.state.sortcode}
                onChange={this.handleInputChange}
                InputProps={{ style : webStyle.inputStyle }}
              />         
              </Box>
              </Box>

              <Box>
              <Typography style={webStyle.textStyle}>IBAN</Typography>            
              <CssTextField
               data-test-id="iban" 
                variant="outlined"               
                required
                fullWidth
                id="iban"                  
                name="iban"
                value={this.state.iban}
                onChange={this.handleInputChange}
                InputProps={{ style : webStyle.inputStyle }}
              />         
              </Box>

              <Box>
              <Typography style={webStyle.textStyle}>SWIFT</Typography>            
              <CssTextField
               data-test-id="swift" 
                variant="outlined"               
                required
                fullWidth
                id="swift"                  
                name="swift"
                value={this.state.swift}
                onChange={this.handleInputChange}
                InputProps={{ style : webStyle.inputStyle }}
              />         
              </Box>

              <Box>
              <Typography style={webStyle.textStyle}>Routing number</Typography>            
              <CssTextField
               data-test-id="routingnum" 
                variant="outlined"               
                required
                fullWidth
                id="routingnum"                  
                name="routingnum"
                value={this.state.routingnum}
                onChange={this.handleRoutingNumChange}
                InputProps={{ style : webStyle.inputStyle }}
              />         
              </Box>

            </form>                        
            </Box> 
                      
            <Box style={{gap: "16px"}} sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", paddingRight: "36px",paddingBottom: "16px",borderTop: "1px solid rgb(226, 232, 240)"}}>
              {this.state.editClicked ? 
              (<>
              <Button data-test-id="cancel" onClick={()=>{this.handleCancel(3)}} style={webStyle.buttonStyleModal5} size="small" variant="outlined" >
                  Cancel
              </Button>
              <Button onClick={()=>{this.handleSave(3)}} data-test-id="save" style={webStyle.buttonStyleModal} size="small" >
                  Save
              </Button>
              </>
               )  
              :
              (<>
              <Button data-test-id="back" onClick={this.handleBackClick} style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
                  Back
              </Button>
              <Button data-test-id="next" onClick={()=>{this.handleNextClick(3)}} style={webStyle.buttonStyleModal} size="small" >
                  Next
              </Button>
              </>
              )
              }
              
            </Box>

            </Box>
          )}

          {this.state.activeStep == 3 && (
            <Box style={{ alignSelf: "center", backgroundColor: "white"}} sx={{width: "660px", margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column", justifyContent: "space-between", }}>                  
            
            <Box sx={webStyle.content}>

            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
            <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A"}}>General</Typography>
            <Button data-test-id="edit1" onClick={()=>{this.handleEdit(1)}} size="small"><img src={this.editBtn}/></Button>
            </Box>

            <form style={{display: "flex", flexDirection: "column", gap:"24px"}} noValidate autoComplete="off">
              <Box style={{gap: "20px"}} sx={{display: "flex", flexDirection: "row"}}>            
              <Box sx={{width: "50%"}}>
              <Typography style={webStyle.textStyle}>Business name</Typography>
              <Paper style={{...webStyle.paperStyleS, overflowWrap: "anywhere"}}>{this.state.businessName}</Paper>      
              </Box>

              <Box sx={{width: "50%"}}>
              <Typography style={webStyle.textStyle}>VAT Number</Typography>
              <Paper style={{...webStyle.paperStyleS, overflowWrap: "anywhere"}}>{this.state.vatNumber}</Paper>
              </Box>
              </Box>

              <Box>
              <Typography style={webStyle.textStyle}>Description of service</Typography>
              <Paper  style={{...webStyle.paperStyleS, overflowWrap: "anywhere"}}>{this.state.descriptionOfService}</Paper>
              </Box>

            </form>                       
            </Box>  

            <Box sx={webStyle.content}>
            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}>
            <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A"}}>Business Address</Typography>
            <Button 
            data-test-id="edit2" 
            onClick={()=>{this.handleEdit(2)}} size="small">
              <img src={this.editBtn}/>
              </Button>
            </Box>

            <form style={{display: "flex", flexDirection: "column", gap:"24px"}} noValidate autoComplete="off">
              <Box>      
              <Paper 
              style={{...webStyle.paperStyle, overflowWrap: "anywhere"}}>{this.state.buildingNumber} {this.state.street}, {this.state.city} {this.state.postcode}, {this.state.country}</Paper>
              </Box>
            </form>                       
            </Box> 

            <Box sx={webStyle.content}>
            <Box sx={{display: "flex", 
            flexDirection: "row", 
            justifyContent: "space-between", width: "100%"}}>
            <Typography style={{fontWeight: "700", 
            fontSize: "24px", color: "#0F172A"}}>Payment and Banking Information</Typography>
            <Button data-test-id="edit3" onClick={()=>{this.handleEdit(3)}} size="small"><img src={this.editBtn}/></Button>
            </Box>

            <form style={{display: "flex", 
            flexDirection: "column", gap:"24px"}} noValidate autoComplete="off">
             
              <Box style={{gap: "20px"}} sx={{display: "flex", flexDirection: "row"}}> 
              <Box sx={{width: "50%"}}>
              <Typography style={webStyle.textStyle}>Bank</Typography>
              <Paper style={{...webStyle.paperStyleS, overflowWrap: "anywhere"}}>{this.state.bank}</Paper>      
              </Box>
              <Box sx={{width: "50%"}}>
              <Typography style={webStyle.textStyle}>Account holder's name</Typography>            
              <Paper 
              style={{...webStyle.paperStyleS, overflowWrap: "anywhere"}}>{this.state.accholdername}</Paper>      
              </Box>
              </Box>

              <Box style={{gap: "20px"}} sx={{display: "flex", flexDirection: "row"}}>
              <Box sx={{width: "50%"}}>
              <Typography 
              style={webStyle.textStyle}>Account number</Typography>            
              <Paper style={{...webStyle.paperStyleS, overflowWrap: "anywhere"}}>{this.state.accnum}</Paper>      
              </Box>
              <Box sx={{width: "50%"}}>
              <Typography style={webStyle.textStyle}>Sort code</Typography>            
              <Paper style={{...webStyle.paperStyleS, overflowWrap: "anywhere"}}>{this.state.sortcode}</Paper>             
              </Box>
              </Box>

              <Box style={{gap: "20px"}} sx={{display: "flex", flexDirection: "row"}}>
              <Box sx={{width: "50%"}}>
              <Typography style={webStyle.textStyle}>IBAN</Typography>            
              <Paper style={{...webStyle.paperStyleS, overflowWrap: "anywhere"}}>{this.state.iban}</Paper>          
              </Box>
              <Box sx={{width: "50%"}}>
              <Typography 
              style={webStyle.textStyle}>SWIFT</Typography>            
              <Paper style={{...webStyle.paperStyleS, overflowWrap: "anywhere"}}>{this.state.swift}</Paper>       
              </Box>
              </Box>

              <Box sx={{width: "50%"}}>
              <Typography style={webStyle.textStyle}>Routing number</Typography>            
              <Paper  style={{...webStyle.paperStyleS, wordWrap: "break-word"}}>{this.state.routingnum}</Paper>            
              </Box>

            </form>                        
            </Box> 
                      
            <Box style={{gap: "16px"}} sx={{paddingRight: "36px",paddingBottom: "16px", display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", borderTop: "1px solid rgb(226, 232, 240)"}}>
              <Button 
              data-test-id="back" 
              onClick={()=>{this.setState((prevState) => ({ activeStep: prevState.activeStep - 1}))}} style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
                  Back
              </Button>
              <Button 
              onClick={this.handleConfirm} data-test-id="confirm" style={webStyle.buttonStyleModal4} size="small" >
                  Confirm
              </Button>
            </Box> 

            </Box>
          )}  

          {this.state.activeStep == 4 && (
            <Box style={{ alignSelf: "center", backgroundColor: "white"}} 
            sx={{width: "440px",margin: "3%" , 
            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", 
            borderRadius: "8px", padding: "40px", 
            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>                  
            <img style={{paddingBottom: "24px"}} src={this.verification}/>
            
            <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A"}}>Verification Pending</Typography>             
            
            <Box sx={{paddingTop: "16px", 
            paddingBottom: "18px"}}>
              <Typography style={{textAlign:"center" ,
              fontWeight: "400", fontSize: "18px", 
              color: "#334155"}}>Profile submitted for verification. You'll receive confirmation once it's complete and active. Thanks!</Typography>           
            </Box>
            
            <Button onClick={this.handleNavigate} data-test-id="continue" fullWidth style={webStyle.buttonStyleModal3}>Continue</Button>
            </Box>
          )}
          </Box>
        </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const webStyle = {
  // custom style definitions
  main: {
    width: "100vw",
    display: "flex",
    fontFamily: "League Spartan, Arial, sans-serif",
    flexDirection: "column"
  },
  paperStyleS: {
    display: 'flex', 
    alignItems: 'center', 
    fontSize: "16px",
    padding: "16px",
    borderRadius: "8px", 
    boxShadow: "0px 2px 8px 0px #0000001A",
  },

  paperStyle: {
    display: 'flex', 
    alignItems: 'center', 
    fontSize: "16px",
    padding: "20px",
    borderRadius: "8px", 
    boxShadow: "0px 2px 8px 0px #0000001A"
  },

  helperText: {
    textAlign: "right",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "40px 40px 0px 40px",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  buttonStyleModal: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "88px",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal2: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "88px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal3: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "100%",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal4: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "117px",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal5: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "108px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  inputStyle: {
    width: "100%",
    height: "56px",
    padding: "14px",
    gap: "8px",
    borderRadius: "8px",
    // border: "1px solid rgba(203, 213, 225, 1)",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  textStyle : {
    color: "#475569",
    fontSize: "14px",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  textStyle2 : {
    color: "#475569",
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "League Spartan, Arial, sans-serif",
  }
};
// Customizable Area End