Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";

exports.postBankInfo = "account_block/banks"
exports.postHotelBusinessInfo = "account_block/hotel_provider_accounts"

exports.postCarBusinessInfo = "bx_block_settings/car_settings/create_car_provider"
exports.postCarBankInfo = "bx_block_settings/car_settings/create_bank_details"

// Customizable Area End
