import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";

export type ProfileField =
  'Change name'
  | 'Change email'
  | 'Change password'
  | 'Preferred destination'
  | 'Preferred activities'
  | 'Preferred accommodation'
  | null;

interface UserDataArrayType {
  id: number,
  name: string,
  selected: boolean
}
interface GetDestinationResponse {
  message: string,
  destinations: Array<UserDataArrayType>
}

interface GetAccommodationResponse {
  message: string,
  accomdations: Array<UserDataArrayType>
}

interface GetActivitiesResponse {
  message: string,
  activities: Array<UserDataArrayType>
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeField: ProfileField
  firstName: string,
  lastName: string,
  email: string,
  confirm_email: string,
  otp: string,
  showEmailDialogContent: boolean,
  old_password: string,
  new_password: string,
  confirm_password: string,
  showOldPassword: boolean,
  showNewPassword: boolean,
  showConfirmPassword: boolean,
  showOtpVerification: boolean,
  showEmailDialogBox: boolean,
  showSuccessDialog: boolean,
  profileSettings: boolean,
  generalSettings: boolean,
  emailNotification: boolean,
  smsNotification: boolean,
  pushNotification: boolean,
  tripUpdates: boolean
  promotions: boolean
  gpsLocation: boolean
  bluetooth: boolean,
  language: string,
  currency: string,
  destinationArray: Array<UserDataArrayType>,
  otpToken: string,
  activityArray: Array<UserDataArrayType>
  accommodationArray: Array<UserDataArrayType>
  showAddDestinationBox: boolean
  newDestination: string
  termsAndConditions: boolean
  termsAndConditionsContent: string[]
  privacyPolicyContent: string[]
  privacyPolicy: boolean
  showLogOutBox: boolean
  showDeleteAccount: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDestinationsId: string = ""
  getActivitiesId: string = ""
  getAccommodationsId: string = ""
  nameChangeId: string = ""
  emailChangeId: string = ""
  emailOtpId: string = ""
  emailOtpVerifcationId: string = ""
  passwordChangeId: string = ""
  updateDestinationId: string = ""
  updateActivityId: string = ""
  updateAccommodationsId: string = ""
  getTermsId: string = ""
  getPrivacyId: string = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeField: 'Change name',
      firstName: "",
      lastName: "",
      email: "",
      confirm_email: "",
      otp: "",
      showEmailDialogContent: true,
      old_password: "",
      newDestination: "",
      new_password: '',
      confirm_password: '',
      showOldPassword: false,
      showConfirmPassword: false,
      showNewPassword: false,
      showOtpVerification: false,
      showEmailDialogBox: false,
      showSuccessDialog: false,
      profileSettings: true,
      generalSettings: false,
      emailNotification: true,
      smsNotification: true,
      pushNotification: true,
      tripUpdates: true,
      promotions: false,
      gpsLocation: true,
      bluetooth: true,
      language: 'English',
      currency: 'Euro',
      destinationArray: [],
      otpToken: "",
      activityArray: [],
      accommodationArray: [],
      showAddDestinationBox: false,
      termsAndConditions: false,
      termsAndConditionsContent: [],
      privacyPolicy: false,
      privacyPolicyContent: [],
      showLogOutBox: false,
      showDeleteAccount: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleResponse(message)
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    this.getDestinations()
    this.getActivities()
    this.getAccommodation()
    this.getPrivacyPolicy()
    this.getTermsAndConditions()
  }
  handleResponse = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getDestinationsId) {
      this.handleGetDestinationResponse(responseJson);
    }
    if (apiRequestCallId === this.getActivitiesId) {
      this.handleGetActivityResponse(responseJson)
    }
    if (apiRequestCallId === this.getAccommodationsId) {
      this.handleGetAccomodationResponse(responseJson)
    }
    if (apiRequestCallId === this.nameChangeId) {
      this.handleNameChangeResponse(responseJson)
    }
    if (apiRequestCallId === this.emailChangeId) {
      this.handleEmailChangeResponse(responseJson)
    }
    if (apiRequestCallId === this.emailOtpId) {
      this.handleEmailOtpRequestResponse(responseJson)
    }
    if (apiRequestCallId === this.emailOtpVerifcationId) {
      this.handleEmailOtpVerificationResponse(responseJson)
    }
    if (apiRequestCallId === this.passwordChangeId) {
      this.handlePasswordChangeRequestResponse(responseJson)
    }
    if (apiRequestCallId === this.getTermsId) {
      this.handleTermsAndConditionsResponse(responseJson)
    }
    if (apiRequestCallId === this.getPrivacyId) {
      this.handlePrivacyPolicyResponse(responseJson)
    }
  }

  handleFieldClick = (field: ProfileField) => {
    this.setState({
      activeField: field,
      profileSettings: true,
      generalSettings: false,
      privacyPolicy: false,
      termsAndConditions: false
    })
  }

  closeEmailDialogBox = () => {
    this.setState({ showEmailDialogBox: false })
  }

  closeShowDeleteAccount = () => {
    this.setState({ showDeleteAccount: false })
  }

  ShowDeleteAccount = () => {
    this.setState({ showDeleteAccount: true })
    this.DeleteAccount()
  }

  DeleteAccount = () => { }

  saveChanges = () => { }

  handleToggle = (value: number) => {
    if (value === 1) {
      this.setState((prev) => ({ showOldPassword: !prev.showOldPassword, }))
    } else if (value === 2) {
      this.setState((prev) => ({ showNewPassword: !prev.showNewPassword }))
    }
    else if (value === 3) {
      this.setState((prev) => ({ showConfirmPassword: !prev.showConfirmPassword }))
    }
  }


  handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstName: event.target.value })
  }

  handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastName: event.target.value })
  }

  handleNameChange = async () => {
    const userData = {
      "data": {
        "first_name": this.state.firstName,
        "last_name": this.state.lastName,
      }
    }
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.applicationApiContentType,
      "token": token
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.nameChangeId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.nameChangeEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(userData)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethodType
    );
    runEngine.sendMessage(ApiRes.id, ApiRes);
  }

  handleNameChangeResponse = (responseJson: { message: string }) => {
    if (responseJson.message === "Name successfully updated") {
      this.setState({ firstName: '', lastName: '' })
    }
  }

  goBackToEmail = () => {
    this.setState({ showOtpVerification: false })
  }

  closeSuccessDialogBox = () => {
    this.setState({
      showSuccessDialog: false,
      showOtpVerification: false
    })
  }

  setOtp = (otp: string) => {
    this.setState({ otp })
  }

  reVerify = () => {
    this.setState({ otp: "" })
    this.handleEmailOtpRequest();
  }

  handleEmailChange = async (values: { email: string, confirm_email: string }) => {
    this.setState({
      email: values.email,
      confirm_email: values.confirm_email,
      showEmailDialogBox: true
    })
  }

  handleEmailDialogContinue = async () => {
    const userData = {
      "data": {
        "new_email": this.state.email,
        "confirm_new_email": this.state.confirm_email
      }
    }
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.applicationApiContentType,
      "token": token
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.emailChangeId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailChangeEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(userData)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethodType
    );
    runEngine.sendMessage(ApiRes.id, ApiRes);
  }
  handleGeneralSettings = () => {
    this.setState({
      profileSettings: false,
      generalSettings: true,
      activeField: null,
      termsAndConditions: false,
      privacyPolicy: false
    })
  }
  handleToggleGeneral = (name: keyof S, event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [name]: event.target.checked } as unknown as Pick<S, keyof S>);
  };

  handleChangeGeneral = (name: keyof S) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [name]: event.target.value } as unknown as Pick<S, keyof S>);
  };
  handleEmailChangeResponse = (responseJson: { message: string, user: { email: string } }) => {
    if (responseJson.message === "Email successfully updated") {
      this.setState({ showOtpVerification: true, showEmailDialogBox: false, email: responseJson.user.email }, () =>
        this.handleEmailOtpRequest()
      )
    }
  }

  handleEmailOtpRequest = async () => {
    const data = {
      data: {
        type: "update_email",
        attributes: {
          email: this.state.email
        }
      }
    }

    const header = {
      "Content-Type": configJSON.applicationApiContentType,
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.emailOtpId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );
    runEngine.sendMessage(ApiRes.id, ApiRes);
  }

  handleEmailOtpRequestResponse = (responseJson: { meta: { token: string } }) => {
    if (responseJson.meta.token) {
      this.setState({ otpToken: responseJson.meta.token })
    }
  }

  handleEmailOtpVerification = async () => {
    const data = {
      data: {
        otp_code: this.state.otp,
        token: this.state.otpToken,
      }
    }
    const header = {
      "Content-Type": configJSON.applicationApiContentType,
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.emailOtpVerifcationId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpConfirmationEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );
    runEngine.sendMessage(ApiRes.id, ApiRes);
  }

  handleEmailOtpVerificationResponse = (responseJson: { messages: [{ otp: string, token: string }] }) => {
    if (responseJson.messages[0].otp === "Email verified") {
      setStorageData('token', responseJson.messages[0].token)
      this.setState({ showEmailDialogContent: true, showSuccessDialog: true })
    }
  }

  handlePasswordChange = (values: { old_password: string, new_password: string, confirm_password: string }, { resetForm }: any) => {
    this.setState({
      old_password: values.old_password,
      new_password: values.new_password,
      confirm_password: values.confirm_password
    },
      () => this.handlePasswordChangeRequest()
    )
    resetForm()
  }

  handlePasswordChangeRequest = async () => {
    const userData = {
      "data": {
        "old_password": this.state.old_password,
        "new_password": this.state.new_password,
        "confirm_password": this.state.confirm_password
      }
    }
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.applicationApiContentType,
      "token": token
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.passwordChangeId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passChangeEndPoint + "password"
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(userData)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethodType
    );
    runEngine.sendMessage(ApiRes.id, ApiRes);
  }

  handlePasswordChangeRequestResponse = (responseJson: { message: string }) => {
    if (responseJson.message === "Password successfully updated") {
      this.setState({ showSuccessDialog: true, showEmailDialogContent: false })
    }
  }

  getDestinations = async () => {
    const token = await getStorageData("token")
    const header = {
      "token": token
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDestinationsId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDestinationEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(ApiRes.messageId, ApiRes);
  }

  handleGetDestinationResponse = (response: GetDestinationResponse) => {
    const destinations = response.destinations.map(destination => ({
      id: destination.id,
      name: destination.name,
      selected: destination.selected
    }))
    this.setState({ destinationArray: destinations })
  }

  handlePreferredDestination = async () => {
    const data = {
      destinations:
        this.state.destinationArray
          .filter(destination => destination.selected)
          .map(destination => destination.name)
    }
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.applicationApiContentType,
      "token": token
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateDestinationId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateDestinationEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethodType
    );
    runEngine.sendMessage(ApiRes.id, ApiRes);
  }

  handlePreferredActivity = async () => {
    const data = {
      activities:
        this.state.activityArray
          .filter(activities => activities.selected)
          .map(activities => activities.name)
    }
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.applicationApiContentType,
      "token": token
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateActivityId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateActivityEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethodType
    );
    runEngine.sendMessage(ApiRes.id, ApiRes);
  }

  handlePreferredAccommodation = async () => {
    const data = {
      accomdations:
        this.state.accommodationArray
          .filter(accomdations => accomdations.selected)
          .map(accomdations => accomdations.name)
    }
    const token = await getStorageData("token")
    const header = {
      "Content-Type": configJSON.applicationApiContentType,
      "token": token
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateAccommodationsId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateAccommodationEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethodType
    );
    runEngine.sendMessage(ApiRes.id, ApiRes);
  }


  handleToggleDestination = (event: React.MouseEvent<HTMLElement>, id: Array<number>) => {
    if (id !== null) {
      this.setState(prevState => ({
        destinationArray: prevState.destinationArray.map(item =>
          item.id === id[0] ? { ...item, selected: !item.selected } : item
        )
      }));
    }
  };

  handleToggleActivity = (event: React.MouseEvent<HTMLElement>, id: Array<number>) => {
    if (id !== null) {
      this.setState(prevState => ({
        activityArray: prevState.activityArray.map(item =>
          item.id === id[0] ? { ...item, selected: !item.selected } : item
        )
      }));
    }
  };

  handleToggleAccommodation = (event: React.MouseEvent<HTMLElement>, id: Array<number>) => {
    if (id !== null) {
      this.setState(prevState => ({
        accommodationArray: prevState.accommodationArray.map(item =>
          item.id === id[0] ? { ...item, selected: !item.selected } : item
        )
      }));
    }
  };

  getActivities = async () => {
    const token = await getStorageData("token")
    const header = {
      "token": token
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getActivitiesId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getActivitiesEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(ApiRes.messageId, ApiRes);
  }

  handleGetActivityResponse = (response: GetActivitiesResponse) => {
    const activities = response.activities.map(activity => ({
      id: activity.id,
      name: activity.name,
      selected: activity.selected
    }))
    this.setState({ activityArray: activities })
  }

  getAccommodation = async () => {
    const token = await getStorageData("token")
    const header = {
      "token": token
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAccommodationsId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccommodationEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(ApiRes.messageId, ApiRes);
  }

  handleGetAccomodationResponse = (response: GetAccommodationResponse) => {
    const acc = response.accomdations.map(acc => ({
      id: acc.id,
      name: acc.name,
      selected: acc.selected
    }))
    this.setState({ accommodationArray: acc })
  }

  closeAddDestinationBox = () => {
    this.setState({ showAddDestinationBox: false, newDestination: "" })
  }

  addNewDestination = () => {
    this.setState({ showAddDestinationBox: true })
  }

  handleNewDestination = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newDestination: event.target.value });
  };

  addNewDestinationToArray = () => {
    const newDestinationObject: UserDataArrayType = {
      id: this.state.destinationArray.length + 1,
      name: this.state.newDestination,
      selected: true
    };
    this.setState({
      newDestination: "",
      showAddDestinationBox: false,
      destinationArray: [...this.state.destinationArray, newDestinationObject],
    })
  }

  handleTermsAndConditions = () => {
    this.setState({
      termsAndConditions: true,
      privacyPolicy: false,
      showLogOutBox: false,
      generalSettings: false,
      profileSettings: false
    })
  }

  handlePrivacyPolicy = () => {
    this.setState({
      privacyPolicy: true,
      termsAndConditions: false,
      showLogOutBox: false,
      generalSettings: false,
      profileSettings: false
    })
  }

  handleLogoutBox = () => {
    this.setState({
      showLogOutBox: true,
    })
  }

  closeLogoutBox = () => {
    this.setState({ showLogOutBox: false })
  }

  handleLogout = () => {
    this.setState({ showLogOutBox: false })
  }

  getTermsAndConditions = async () => {
    const token = await getStorageData("token")
    const header = {
      "token": token
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getTermsId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(ApiRes.messageId, ApiRes);
  }

  handleTermsAndConditionsResponse = (responseJson: { terms_and_conditions: string[] }) => {
    const paragraphs = responseJson.terms_and_conditions[0].split('\r\n');
    this.setState({ termsAndConditionsContent: paragraphs })
  }

  getPrivacyPolicy = async () => {
    const token = await getStorageData("token")
    const header = {
      "token": token
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getPrivacyId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(ApiRes.messageId, ApiRes);
  }

  handlePrivacyPolicyResponse = (responseJson: { privacy_policies: string[] }) => {
    const paragraphs = responseJson.privacy_policies[0].split('\r\n');
    this.setState({ privacyPolicyContent: paragraphs })
  }


  // Customizable Area End
}
