Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";

exports.postCarCatalogue = "bx_block_catalogue/catalogues"
exports.apiMethodTypePost = "POST";

exports.postHotelCreateRoom = "bx_block_profile/rooms"
exports.getRoomTypes = "bx_block_profile/get_hotelwise_room?hotel_profile_id="
exports.validationApiContentType = "application/json";
// Customizable Area End