import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start


export interface Option {
  label: string;
  value: string;
}


export interface Data {
  startLoc: string;
  finalLoc: string;
  dates: [Date | null, Date | null];
  routePref: string;
  dietPref: string;
  dietPrefInfo: string;
  landmarks: { id: number, value: string }[];
  scenicStops: {}[];
  prefCurr: string;
  ovrBudget: string;
  dailyBudget: string;
  accom: {}[];
  restStop: string;
  adults: number;
  child: number;
  travelCompanion: string[];
  timeStart: string;
  timeEnd: string;
  prefVehicle: string;
  carFeatures: {}[];
  flexibility: string;
  leisureDaysorStops: {}[];
  occassion: string;
  activity: {}[];
  cultural: {}[];
  cuisine: string;
  entertainment: string;
  shopping: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  activeStep: number;
  data: Data;
  open: boolean;
  landmarks: { id: number, value: string }[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: {
        startLoc: "",
        finalLoc: "",       
        dates: [null, null],
        routePref: "",
        dietPref: "",
        dietPrefInfo: "",
        landmarks: [{id: 1, value: ""}],
        scenicStops: [],
        prefCurr: "",
        ovrBudget: "",
        dailyBudget: "",
        accom: [],
        restStop: "",
        adults: 0,
        child: 0,
        travelCompanion: [],
        timeStart: "",
        timeEnd: "",
        prefVehicle: "",
        carFeatures: [],
        flexibility: "",
        leisureDaysorStops: [],
        occassion: "",
        activity: [],
        cultural: [],
        cuisine: "",
        entertainment: "",
        shopping: "",
      },
      activeStep: 0,
      open: false,
      landmarks: [{id: 1, value: ""}],

      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
 
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
  }

  setValue = (field: string, values: Option[]) => {
    const selectedValue = values.length > 0 ? values[0].value : "";
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [field]: selectedValue,
      },
    }));
  };

  getDefaultPage5State = () => ({
    adults: 0,
    child: 0,
    travelCompanion: [],
    dietPref: "",
    dietPrefInfo: "",
  });

  getDefaultPage6State = () => ({
    timeStart: "",
    timeEnd: "",
    prefVehicle: "",
    carFeatures: [],
  });

  getDefaultPage7State = () => ({
    flexibility: "",
    landmarks: [{id: 1, value: ""}],
    leisureDaysorStops: [],
    occassion: "",
  });

  getDefaultPage8State = () => ({
    landmarks: [{id: 1, value: ""}],    
  });

  handleSkip = (page: number) => {
    let newData = { ...this.state.data };
    
    if (page === 1) {
      newData = { ...newData, ...this.getDefaultPage5State() };
      this.setState({     
        data: newData,
        activeStep: this.state.activeStep + 1,
      });
    } else if (page === 2) {
      newData = { ...newData, ...this.getDefaultPage6State() };
      this.setState({     
        data: newData,
        activeStep: this.state.activeStep + 1,
      });
    } else if (page === 3) {
      newData = { ...newData, ...this.getDefaultPage7State() };
      this.setState({     
        data: newData,
        landmarks: [{id: 1, value: ""}],
        activeStep: this.state.activeStep + 1,
      });
  }};

  handleOvrBudgetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {
      this.setState({ data: { ...this.state.data, ovrBudget: e.target.value }});
    }
  };

  handleNextDisable = () => {
  const nextDisabled = (this.state.data.startLoc === "" || this.state.data.finalLoc === "" || !this.state.data.dates[0])
    if (nextDisabled){return true;}
    return false;
  }

  handleDailyBudgetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {
      this.setState({ data: { ...this.state.data, dailyBudget: e.target.value }});
    }
  };

  handleNext = () => {
    this.setState({activeStep: (this.state.activeStep + 1)});
  
  };
  handleBack = () => {
    this.setState({activeStep: (this.state.activeStep - 1)});
  };

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    this.setState((prevState) => {
      const { travelCompanion } = prevState.data;
      if (checked) {
        return {
          data: {
            ...prevState.data,
            travelCompanion: [...travelCompanion, name],
          },
        };
      } else {
        return {
          data: {
            ...prevState.data,
            travelCompanion: travelCompanion.filter((item) => item !== name),
          },
        };
      }
    });
  };

  onDragEnd = (fromIndex: number, toIndex: number) => {
    const { landmarks } = this.state.data;
    const updatedLandmarks = [...landmarks];
    const [movedItem] = updatedLandmarks.splice(fromIndex, 1);
    updatedLandmarks.splice(toIndex, 0, movedItem);

    this.setState({
      data: {
        ...this.state.data,
        landmarks: updatedLandmarks,
      },
    });
  };
  
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ landmarks: [{ id: 1, value: "" }]  });
    this.setState({ open: false });
  };

  handleReset = () => {
    let newData = { ...this.state.data };
    newData = { ...newData, ...this.getDefaultPage8State() };
    this.setState({     
      data: newData,
    });
   };

  handleIncAdult = () => {
    this.setState({ data: { ...this.state.data, adults: this.state.data.adults + 1 }});
  }

  handleDecAdult = () => {
    this.setState({ data: { ...this.state.data, adults: this.state.data.adults - 1 }});
  }

  handleIncChild = () => {
    this.setState({ data: { ...this.state.data, child: this.state.data.child + 1 }});
  }

  handleDecChild = () => {
    this.setState({ data: { ...this.state.data, child: this.state.data.child - 1 }});
  }

  addNewLandmark = () => {
    const newId = this.state.data.landmarks.length + 1 ;
    this.setState({ data: { ...this.state.data, landmarks: [...this.state.data.landmarks, { id: newId, value: "" }] } });
  };



  handleDeleteLandmark = (id: number) => {
    let updatedLandmarks;
    
    if (id === 1) {
  
      updatedLandmarks = this.state.data.landmarks.map(landmark => {
        if (landmark.id === 1) {
          return { ...landmark, value: "" };
        }
        return landmark;
      });
    } else {
     
      updatedLandmarks = this.state.data.landmarks.filter(landmark => landmark.id !== id);
    }

    this.setState({
      data: {
        ...this.state.data,
        landmarks: updatedLandmarks,
      }
    });
  };
  
  addModalLandmark = () => {
    const newId = this.state.landmarks.length + 1 ;
    this.setState({ landmarks: [...this.state.landmarks, { id: newId, value: "" }]  });
  };

 
  
  updateLandmarks = () => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        landmarks: [...prevState.landmarks],
      },
    }));
  }

  handleToggleScenicStop = (event: React.MouseEvent<HTMLElement>, newScenicStops: string[]) => {
    if (newScenicStops.includes("All")) {
      this.setState({ data: { ...this.state.data, scenicStops: ["All"] } });
    } else {
      this.setState({ data: { ...this.state.data, scenicStops: newScenicStops } });
    }
  };

  handleToggleAccomodation = (event: React.MouseEvent<HTMLElement>, newAccom: string[]) => {
    if (newAccom.includes("All")) {
      this.setState({ data: { ...this.state.data, accom: ["All"] } });
    } else {
      this.setState({ data: { ...this.state.data, accom: newAccom } });
    }
  };

  handleToggleActivity = (event: React.MouseEvent<HTMLElement>, newActivity: string[]) => {
    if (newActivity.includes("All")) {
      this.setState({ data: { ...this.state.data, activity: ["All"] } });
    } else {
      this.setState({ data: { ...this.state.data, activity: newActivity } });
    }
  };

  handleToggleLeisure = (event: React.MouseEvent<HTMLElement>, newLeisure: string[]) => {
      this.setState({ data: { ...this.state.data, leisureDaysorStops: newLeisure } });
    };
  

  handleToggleCulture = (event: React.MouseEvent<HTMLElement>, newCultural: string[]) => {
    if (newCultural.includes("All")) {
      this.setState({ data: { ...this.state.data, cultural: ["All"] } });
    } else {
      this.setState({ data: { ...this.state.data, cultural: newCultural } });
    }
  };


  handleToggleCarFeatures = (event: React.MouseEvent<HTMLElement>, feature: string[]) => {  
      this.setState({ data: { ...this.state.data, carFeatures: feature } });
    };
  

  handleDateChange = (dates: [Date | null, Date | null]) => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        dates: dates,
      },
    }));
  };

  onValueChange = (name: keyof Data, value: any, id?: number) => {
    if (name === "landmarks" && id !== undefined) {
      const newLandmarks = this.state.data.landmarks.map(landmark =>
        landmark.id === id ? { ...landmark, value } : landmark
      );
      this.setState({ data: { ...this.state.data, landmarks: newLandmarks } });
    } else {
      this.setState({ data: { ...this.state.data, [name]: value } });
    }
  };

  

handleModalDeleteLandmark = (id: number) => {
  let updatedLandmarks;

  if (id === 1) {
   
    updatedLandmarks = this.state.landmarks.map(landmark => {
      if (landmark.id === 1) {
        return { ...landmark, value: "" };
      }
      return landmark;
    });
  } else {
   
    updatedLandmarks = this.state.landmarks.filter(landmark => landmark.id !== id);
  }

  
  this.setState({ landmarks: updatedLandmarks });
};



  onModalValueChange = (value: any , id?: number) => {
    const newLandmarks = this.state.landmarks.map(landmark =>
      landmark.id === id ? { ...landmark, value } : landmark
    );
    this.setState({ landmarks:  newLandmarks } );
  }

  // Customizable Area End
}
