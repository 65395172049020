import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start

export interface Data{
  room_number: string;
  room_description: string;
  destinationArray: Array<UserDataArrayType>;
}

export interface UserDataArrayType {
  id: number,
  name: string,
  selected: boolean
}
export interface Errors {
  room_number: string;
}
export interface Option {
  label: string;
  value: string;
}

const initialDestinationArray: Array<UserDataArrayType> = [
  { id: 1, name: "Parking", selected: false },
  { id: 2, name: "Pet-friendly", selected: false },
  { id: 3, name: "Spa", selected: false },
  { id: 4, name: "WiFi", selected: true },
  { id: 5, name: "Café", selected: false },
  { id: 6, name: "Pool", selected: true },
  { id: 7, name: "Restaurant", selected: false },
  { id: 8, name: "Conference Rooms", selected: false },
  { id: 9, name: "Cooking basics (pots, oil, salt, pepper)", selected: true },
  { id: 10, name: "Breakfast", selected: false },
  { id: 11, name: "Lobby", selected: false },
  { id: 12, name: "Toilet paper", selected: true },
  { id: 13, name: "Shampoo", selected: true },
  { id: 14, name: "Hair dryer", selected: true },
  { id: 15, name: "Bed linens", selected: true },
  { id: 16, name: "Soap", selected: true },
  { id: 17, name: "Towels", selected: true },
  { id: 18, name: "Microwave", selected: true },
  { id: 19, name: "TV", selected: true },
  { id: 20, name: "Stove", selected: true },
  { id: 21, name: "Room Service", selected: false },
  { id: 22, name: "Washer", selected: true },
  { id: 23, name: "Fitness Center", selected: false },
];



interface IsEditing {
  [id: number]: boolean;
}

interface Amenities {
  id: number;
  name: string;
}
interface RoomAttributes {
  room_number: string;
  room_name: string;
  room_category: string;
  room_size: string;
  size_unit: string;
  description: string;
  room_view: string;
  smoking_policy: string;
  guest_count: string;
  bedroom_count: string;
  bed_count: string;
  bathroom_count: string;
  bed_type: string;
  rack_rate: string;
  extra_adult_rate: string;
  extra_child_rate: string;
  extra_infant_rate: string;
  max_adults: string;
  max_children: string;
  max_infants: string;
  inc_guests: string;
  max_guests: string;
  images: { id: number; url: string }[];
  cover_image: { id: number; url: string };
  hotel_profile_id: string;
  is_activated: boolean;
}
interface RoomType {
  id: string;
  attributes: RoomAttributes;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
navigation: any;
id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeStep: number;
    data : Data;
    errors: Errors;
    roomTypes: RoomType[];
    selectedRoomTypeId: string | null;
    selectedRoomAttributes: RoomType;
    isEditing: IsEditing;
    showAddDestinationBox: boolean;
    newDestination: Amenities[] ;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class HotelCreateRoomController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    postHotelCreateRoomApiId = ""
    getRoomTypesApiId = ""
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        isEditing: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
        },
        selectedRoomTypeId: null, 
        selectedRoomAttributes: {
          id: "",
          attributes: { 
          room_number: "",
          room_name: "",
          room_category: "",
          room_size: "",
          size_unit: "",
          description: "",
          room_view: "",
          smoking_policy: "",
          guest_count: "",
          bedroom_count: "",
          bed_count: "",
          bathroom_count: "",
          bed_type: "",
          rack_rate: "",
          extra_adult_rate: "",
          extra_child_rate: "",
          extra_infant_rate: "",
          max_adults: "",
          max_children: "",
          max_infants: "",
          inc_guests: "",
          max_guests: "",
          images: [],
          cover_image: { id: 0, url: "" },
          hotel_profile_id: "",
          is_activated: true,
          }
        },
        roomTypes: [],
        activeStep: 0,
        data: {
          room_number: "",
          room_description: "",
          destinationArray: initialDestinationArray,
        },
        showAddDestinationBox: false,
        newDestination: [{ id: 0, name: "" }],
        errors: {
          room_number: "",
        }
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
      runEngine.debugLog("Message Recived", message);
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        this.handleRestAPIResponseMessage(message);
      } 
      // Customizable Area End
    }

  
    // Customizable Area Start

    private handleRestAPIResponseMessage(message: Message) {
      const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
      if (this.getRoomTypesApiId === dataMessage) {
        this.handleRequestCallResponse(message);
      }
    }

    private handleRequestCallResponse(message: Message) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const roomTypes = responseJson.data.map((room: any) => ({
        id: room.id,
        attributes: {
          room_number: room.attributes.room_number,
          room_name: room.attributes.room_name,
          room_category: room.attributes.room_category,
          room_size: room.attributes.room_size,
          size_unit: room.attributes.size_unit,
          description: room.attributes.description,
          room_view: room.attributes.room_view,
          smoking_policy: room.attributes.smoking_policy,
          guest_count: room.attributes.guest_count,
          bedroom_count: room.attributes.bedroom_count,
          bed_count: room.attributes.bed_count,
          bathroom_count: room.attributes.bathroom_count,
          bed_type: room.attributes.bed_type,
          rack_rate: room.attributes.rack_rate,
          extra_adult_rate: room.attributes.extra_adult_rate,
          extra_child_rate: room.attributes.extra_child_rate,
          extra_infant_rate: room.attributes.extra_infant_rate,
          max_adults: room.attributes.max_adults.toString(),
          max_children: room.attributes.max_children.toString(),
          max_infants: room.attributes.max_infants.toString(),
          inc_guests: room.attributes.inc_guests.toString(),
          max_guests: room.attributes.max_guests.toString(),
          images: room.attributes.images.map((image: any) => ({
            id: image.id,
            url: image.url,
          })),
          cover_image: {
            id: room.attributes.cover_image.id,
            url: room.attributes.cover_image.url,
          },
          hotel_profile_id: room.attributes.hotel_profile_id,
          is_activated: room.attributes.is_activated,
        },
      }));
  
      this.setState({ roomTypes });
    }

    handleImageBlob = (image: string | Blob) => {
      let imageSrc;
      if (typeof image !== "string") {
        imageSrc = URL.createObjectURL(image);
      }
      return imageSrc;
    };

    handleConfirm = async() => {
      const header = {
        token : await getStorageData("token"),
      };

      let formData = new FormData();
      
      formData.append("room_number", this.state.data.room_number);
      formData.append("room_name", this.state.selectedRoomAttributes.attributes.room_name);
      formData.append("room_category", this.state.selectedRoomAttributes.attributes.room_category);
      formData.append("room_description", this.state.data.room_description);
      formData.append("room_view", this.state.selectedRoomAttributes.attributes.room_view);
      formData.append("guest_count", this.state.selectedRoomAttributes.attributes.guest_count);
      formData.append("bedroom_count", this.state.selectedRoomAttributes.attributes.bedroom_count);
      formData.append("bed_count", this.state.selectedRoomAttributes.attributes.bed_count);
      formData.append("bathroom_count", this.state.selectedRoomAttributes.attributes.bathroom_count);
      formData.append("rack_rate", this.state.selectedRoomAttributes.attributes.rack_rate);
      formData.append("extra_adult_rate", this.state.selectedRoomAttributes.attributes.extra_adult_rate);
      formData.append("extra_child_rate", this.state.selectedRoomAttributes.attributes.extra_child_rate);
      formData.append("extra_infant_rate", this.state.selectedRoomAttributes.attributes.extra_infant_rate);
      formData.append("hotel_profiles_id", 574 as unknown as string);
      formData.append("is_activated", "true");
      formData.append("bed_type", this.state.selectedRoomAttributes.attributes.bed_type);
      formData.append("max_adults", this.state.selectedRoomAttributes.attributes.max_adults);
      formData.append("max_children", this.state.selectedRoomAttributes.attributes.max_children);
      formData.append("max_guests", this.state.selectedRoomAttributes.attributes.max_guests);
      formData.append("inc_guests", this.state.selectedRoomAttributes.attributes.inc_guests);
      formData.append("max_infants", this.state.selectedRoomAttributes.attributes.max_infants);

      if (this.state.selectedRoomTypeId !== null) {
        formData.append("room_type_id", this.state.selectedRoomTypeId);
      }

      const selectedAmenities = this.state.data.destinationArray
      .filter(destination => destination.selected)
      .map(destination => destination.name);

      selectedAmenities.forEach((amenities, index) => {
        formData.append(`amenities[${index}]`, amenities);
      });

  
      const files: File[] = this.state.selectedRoomAttributes.attributes.images.map((image) => {
        const imageBlob = this.handleImageBlob(image.url);
        return new File([imageBlob || image.url], `image_${image.id}.jpg`, { type: "image/jpeg" });
      });
      
      files.forEach((file) => {
        formData.append("images[]", file);
      });


      const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postHotelCreateRoom
      );
      message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);
      message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
      message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
      this.postHotelCreateRoomApiId = message.messageId;
  
      runEngine.sendMessage(message.messageId, message);
 
    }

    getRoomTypes = async()=>{
      const header = {
        token : await getStorageData("token"),
      };

      const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getRoomTypes}${574}`
      );
      message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
      message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
      this.getRoomTypesApiId = message.messageId;
  
      runEngine.sendMessage(message.messageId, message);
    }

    handleEditClick = (id: number) => {
      
      if (id == 1){
        this.setState(() => ({
          activeStep: 1,
        }));
      }
      this.setState(prevState => ({
        isEditing: {
          ...prevState.isEditing,
          [id]: !prevState.isEditing[id]
        }
      }));
    };

    handleGetNext = () => {
      this.getRoomTypes()
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }
    
    handleRoomTypeClick = (roomId: string, room : any) => {
      this.setState({
        selectedRoomTypeId: roomId,
        selectedRoomAttributes: room,
      });
    };

  
    onValueChange = (name: keyof S['data'], value: any) => {
      const errors = { ...this.state.errors };
    
      const validationRules: { [key in keyof S['data']]?: (value: any) => string } = {
        room_number: (value) => (!/^\d{1,5}$/.test(value) ? 'Input must be at least 1 digit with a maximum of 5 digits long' : ''),
      };
    
      const validateField = (name: keyof S['data'], value: any) => {
        const validate = validationRules[name];
        return validate ? validate(value) : '';
      };
    
      if (name in errors) {
        errors[name as keyof Errors] = validateField(name, value);
      }
    
      this.setState({
        data: { ...this.state.data, [name]: value },
        errors,
      });
    };

    onValueChangeAPIData = (name: keyof S['selectedRoomAttributes']['attributes'], value: any) => {
      this.setState({
          selectedRoomAttributes: {
              ...this.state.selectedRoomAttributes, 
              attributes: {
                  ...this.state.selectedRoomAttributes.attributes, 
                  [name]: value, 
              },
          },
      });
    };
  

    handleBackClick = () => {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep - 1,
      }));
    }
  
    handleNextClick = () => {
        this.setState((prevState) => ({
          activeStep: prevState.activeStep + 1,
        }));
    };
    
    addNewInputField = () => {
      this.setState(prevState => ({
        newDestination: [
          ...prevState.newDestination, 
          { id: prevState.newDestination.length + 1, name: "" }
        ]
      }));
    };
    
    closeAddDestinationBox = () => {
      this.setState({ showAddDestinationBox: false, newDestination: [{ id: 1, name: "" }] });
    };
  
    addNewDestination = () => {
      this.setState({ showAddDestinationBox: true });
    };
  
    handleNewDestination = (event: any, index: number) => {
      const newDestination = [...this.state.newDestination];
      newDestination[index].name = event.target.value;
      this.setState({ newDestination });
    };
  
    deleteDestinationField = (index: number) => {
      const newDestination = [...this.state.newDestination];
      newDestination.splice(index, 1);
      this.setState({ newDestination });
    };

    addNewDestinationToArray = () => {
      const newAmenities = this.state.newDestination.filter(dest => dest.name.trim() !== "");
      if (newAmenities.length > 0) {
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            destinationArray: [
              ...prevState.data.destinationArray,
              ...newAmenities.map(dest => ({ id: prevState.data.destinationArray.length + dest.id, name: dest.name, selected: true }))
            ]
          },
          newDestination: [{ id: 1, name: "" }] ,
          showAddDestinationBox: false
        }));
      }
      this.setState(() => ({showAddDestinationBox: false}))
    };

    handleToggleDestination = (event: React.MouseEvent<HTMLElement>, id: Array<number>) => {
      if (id !== null) {
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            destinationArray: prevState.data.destinationArray.map(item =>
              item.id === id[0] ? { ...item, selected: !item.selected } : item
            )
          }
        }));
      }
    };

    
  
  
    // Customizable Area End
}
