import React, { CSSProperties } from "react";
// ...

// Customizable Area Start
import { Box, Button,Dialog,DialogContent,FormControl,FormControlLabel,IconButton,InputAdornment,Menu,MenuItem,MobileStepper, Paper, Radio, RadioGroup, TextField, TextFieldProps, Typography, withStyles } from "@material-ui/core";
import Select from "react-dropdown-select";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

const CssTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: (props: TextFieldProps & { error?: boolean }) =>
          props.error ? '#DC2626' : '#625d58',
      },
    },
  },
}))(TextField);

const CssTextField2 = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FAA81D',
      },
    },
  },
})(TextField);

const styles: {
  toggleButtonGroup: CSSProperties;
  toggleButtonGroup2: CSSProperties;
  toggleButtonGroup3: CSSProperties;
  toggleButton: CSSProperties;
  toggleButton2: CSSProperties;
  toggleButton3: CSSProperties;
} = {
  toggleButtonGroup: {
    display: 'flex',
    flexWrap: 'wrap', 
    gap: '10px',
    maxWidth: '100%', 
  },
  toggleButtonGroup2: {
    display: 'flex',
    flexWrap: 'wrap', 
    gap: '10px',
    maxWidth: '100%', 
    paddingLeft: "40px",
  },
  toggleButtonGroup3: {
    display: 'flex',
    flexWrap: 'wrap', 
    gap: '10px',
    maxWidth: '100%',
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
    padding: "14px 16px 14px 16px",
  },
  toggleButton: {
    borderRadius: '58px',
    width: '128px', 
  },
  toggleButton2: {
    borderRadius: '58px',
    textTransform: "none",
  },
  toggleButton3: {
    borderRadius: '58px',
    textTransform: "none",
    border: "1px solid rgba(100, 116, 139, 1) !important"
  },
};


const make = [
  { label: "Volkswagen", value: "Volkswagen" },
  { label: "Volvo", value: "Volvo" },
  { label: "BMW", value: "BMW" },
  { label: "Mercedes-Benz", value: "Mercedes-Benz" },
  { label: "Opel", value: "Opel" },
];

const model = [
  { label: "Golf", value: "Golf" },
  { label: "GTI", value: "GTI" },
];

const year = [
  { label: "2018", value: "2018" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
];

const country = [
  { label: "United Kingdom", value: "United Kingdom" },
];

const fuel = [
  { label: "Diesel", value: "Diesel" },
  { label: "Petrol", value: "Petrol" },
  { label: "Electric", value: "Electric" },
  { label: "Hybrid", value: "Hybrid" },
];
// Customizable Area End

import CarCatalogueController, {
  Errors,
  Props,
} from "./CarCatalogueController";
import { Clear, MoreHoriz } from "@material-ui/icons";


export default class CarCatalogue extends CarCatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  logoSvg = require("../assets/logoSvg.svg");
  addCarSvg = require("../assets/addCarSvg.svg");
  addCarBtn = require("../assets/addCarBtn.svg");
  infoSvg = require("../assets/infoSvg.svg");
  bgImage = require("../assets/bgImage.svg");
  euroSvg = require("../assets/euroSvg.svg");
  addIconSvg = require("../assets/addIconSvg.svg");
  imageadd = require("../assets/imageadd.svg");
  divider = require("../assets/divider.svg");
  add = require("../assets/add.svg");
  editBtn = require("../assets/editBtn.svg");
  makeCover = require("../assets/makeCover.svg");
  rotateImg = require("../assets/rotate.svg");
  deleteImg = require("../assets/deleteImg.svg");
  verification = require("../assets/verification.svg");
  
  renderStepper() {
    return (
      <MobileStepper
        variant="progress"
        steps={4}
        position="static"
        activeStep={1}
        nextButton={null}
        backButton={null}
      />
    );
  }

  renderErrorMessage = (fieldName: keyof Errors) => {
    const errorMessage = this.state.errors[fieldName];
    if (errorMessage) {
      return (
        <Typography style={{ color: '#DC2626', fontSize: '12px' }}>
          {errorMessage}
        </Typography>
      );
    }
    return null;
  };
  
  renderStep4 = () => {
    return (
      <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
      
      <Box sx={webStyle.content}>
      
      <Box style={{width: "100%"}}>
      <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Car registration details</Typography>
      </Box> 

      <Box style={{width: "100%", display: "flex", justifyContent: "center", gap: "16px", flexDirection: "column"}}>

      <Box style={{display:"flex", 
      paddingLeft: "40px",
      paddingRight: "40px",
      flexDirection: "column", 
      gap: "4px"}}>
        <Typography style={{color: "#475569", fontSize: "14px", fontWeight: "700"}}>Car Registration number*</Typography>
      
        <CssTextField    
          data-test-id="carRegNo"            
          variant="outlined"                 
          fullWidth
          id="carRegNo"             
          name="carRegNo"
          value={this.state.data.carRegNo}
          onChange={(e) => this.onValueChange("carRegNo", e.target.value)}
          InputProps={{
            style: webStyle.inputStyle}}
        />
        {this.renderErrorMessage('carRegNo')}

      </Box> 

      <Box style={{paddingLeft:"40px", paddingRight:"40px", display: "flex", flexDirection:"row", gap:"16px"}}>
        
        <Box style={{display:"flex", 
            flexDirection: "column", 
            width: "100%",
            gap: "4px"}}>
          <Typography style={{color: "#475569", fontSize: "14px", fontWeight: "700"}}>VIN (Vehicle Identification Number)*</Typography>
          <CssTextField    
            data-test-id="vin"            
            variant="outlined"                 
            fullWidth              
            id="vin"             
            name="vin"
            value={this.state.data.vin}
            onChange={(e) => this.onValueChange("vin", e.target.value)}
            inputProps={{ maxLength: 12 }}
            InputProps={{
              style: webStyle.inputStyle}}
          />
        {this.renderErrorMessage('vin')}
        </Box> 

        <Box style={{display:"flex", 
             flexDirection: "column", 
             width: "100%",
             gap: "4px"}}>
          <Typography style={{color: "#475569", fontSize: "14px", fontWeight: "700"}}>Country of registration</Typography>
          <Select
            data-test-id="select-component"
            style={{ borderRadius: "8px", border: "1px solid #cbd5e1" ,paddingLeft: "16px", height: "56px"}}
            className="styled-select"
            placeholder=""
            options={country}
            values={country.filter(
              (country) => country.value === this.state.data.countryOfReg
            )} 
            onChange={(values) => this.setValue('countryOfReg', values)}
          />
        </Box>

      </Box>
      
      <Box style={{paddingLeft:"40px", paddingRight:"40px", display: "flex", flexDirection:"row", gap:"8px"}}>
        <img src={this.infoSvg}/>
        <Typography style={{color: "#64748B", fontSize: "12px", fontWeight: "400px"}}>You can find this information on the car registration certificate.</Typography>
      </Box>
      </Box>

      </Box>                  
     
      <Box style={{gap: "16px", 
      width: "100%"}} 
      sx={{
        paddingTop: "10px", 
        flexDirection: "row",justifyContent: "flex-end", 
        paddingBottom: "16px",
        display: "flex", 
      borderTop: "1px solid rgb(226, 232, 240)"}}>       
        <Box 
        style={{
          display: "flex", 
          paddingRight: "40px", 
          gap: "16px"}}>
        <Button data-test-id="back" onClick={this.handleBackClick} 
        style={webStyle.buttonStyleModal2} size="small" 
        variant="outlined" >
            Back
        </Button>
        <Button 
        data-test-id="next" 
        onClick={this.handleNextClick} 
        disabled={!this.canProceedToNextStep()}
        style={
          this.canProceedToNextStep()
            ? webStyle.buttonStyleModal
            : webStyle.disabledButtonStyle 
        } size="small" >
            Next
        </Button>
        </Box>
      </Box>
      </Box>
    )
  }
  renderStep5 = () => {
    return (
      <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
      
      <Box sx={webStyle.content}>
      
      <Box style={{width: "100%"}}>
      <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Add more details</Typography>
      </Box> 

      <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '16px', flexDirection: 'column' }}>
  <Box style={{ paddingLeft: '40px', paddingRight: '40px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
    <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '16px' }}>
      <Typography style={{ color: '#475569', fontSize: '14px', fontWeight: '700' }}>Numbers of doors*</Typography>
      <ToggleButtonGroup
        data-test-id="ToggleButtonGroupDoor"               
        value={this.state.data.doors}
        exclusive
        onChange={this.handleDoorsChange}
        style={styles.toggleButtonGroup}
      >
        {['2', '3', '4', '5', '6', '7+'].map(value => (
          <ToggleButton
            key={value}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            value={value}
            style={styles.toggleButton}
            className="noRipple"
          >
            {value}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>

    <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '16px' }}>
      <Typography style={{ color: '#475569', fontSize: '14px', fontWeight: '700' }}>Numbers of seats*</Typography>
      <ToggleButtonGroup
        data-test-id="ToggleButtonGroupSeat"               
        value={this.state.data.seats}
        exclusive
        onChange={this.handleSeatsChange}
        style={styles.toggleButtonGroup}
      >
        {['2', '3', '4', '5', '6', '7', '8', '9+'].map(value => (
          <ToggleButton key={value}
            disableRipple
            disableFocusRipple
            className="noRipple"
            value={value}
            disableTouchRipple
            style={styles.toggleButton}
          >
            {value}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  </Box>
</Box>

      </Box>                  
     
      <Box style={{gap: "16px", 
      width: "100%"}} 
      sx={{
        flexDirection: "row",justifyContent: "flex-end", 
        paddingTop: "10px", 
        paddingBottom: "16px",
        display: "flex", 
      borderTop: "1px solid rgb(226, 232, 240)"}}>       
        <Box 
        style={{
          paddingRight: "40px", 
          display: "flex", 
          gap: "16px"}}>
        <Button data-test-id="back" onClick={this.handleBackClick} style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
            Back
        </Button>
        <Button data-test-id="next" disabled={!this.canProceedToNextStep()} 
        onClick={this.handleNextClick} style={
          this.canProceedToNextStep()
            ? webStyle.buttonStyleModal
            : webStyle.disabledButtonStyle 
        } size="small" >
            Next
        </Button>
        </Box>
      </Box>
      </Box>
    )
  }
  renderStep6 = () => {
    return (
      <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
      
      <Box sx={webStyle.content}>
      
      <Box style={{width: "100%"}}>
      <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Add more details</Typography>
      </Box> 

      <Box style={{display:"flex", 
      paddingLeft: "40px",
      paddingRight: "40px",
      flexDirection: "column", 
      gap: "4px"}}>
        <Typography style={{color: "#475569", fontSize: "14px", fontWeight: "700"}}>Fuel*</Typography>
        <Select
          data-test-id="select-component"
          style={{ borderRadius: "8px", border: "1px solid #cbd5e1" ,paddingLeft: "16px", height: "56px"}}
          className="styled-select"
          placeholder=""
          options={fuel}
          values={fuel.filter(
            (fuel) => fuel.value === this.state.data.fuel
          )} 
          onChange={(values) => this.setValue('fuel', values)}
        />
      </Box> 

      <Box style={{display:"flex", 
      width: "100%",
      flexDirection: "column", 
      gap: "4px"}}>
      <FormControl style={{display: "flex", gap: "4px"}} component="fieldset">
        <Typography style={{color: "#475569", paddingLeft:"40px",fontSize: "14px", fontWeight: "700"}}>Transmission type*</Typography>
        <RadioGroup
          style={{paddingLeft: "40px", paddingRight: "40px"}}
          value={this.state.data.prefTransmission}
          >
          <FormControlLabel
            onClick={(e) => this.onValueChange("prefTransmission", "Automatic")}
            control={<Radio />}
            value="Automatic"
            labelPlacement="start"
            label="Automatic"
            data-test-id="Automatic"
          />
          <FormControlLabel          
            onClick={(e) => this.onValueChange("prefTransmission", "Manual")}
            control={<Radio />}
            value="Manual"
            labelPlacement="start"
            label="Manual"
            data-test-id="Manual"
          />
        </RadioGroup>
      </FormControl>
      </Box>     

      <Box style={{paddingLeft:"40px", 
      paddingRight:"40px", display: "flex", flexDirection:"row", gap:"8px"}}>
        <img src={this.infoSvg}/>
        <Typography style={{color: "#64748B", 
        fontSize: "12px", fontWeight: "400px"}}>
          This information helps drivers find the best fit for them.</Typography>
      </Box> 
      </Box> 
                
      <Box style={{gap: "16px", 
      width: "100%"}} sx={{display: "flex", flexDirection: "row",
      justifyContent: "flex-end", paddingTop: "10px", paddingBottom: "16px",borderTop: "1px solid rgb(226, 232, 240)"}}>       
        <Box style={{paddingRight: "40px", 
        display: "flex", 
        gap: "16px"}}>
        <Button 
        style={webStyle.buttonStyleModal2} 
        size="small" 
        onClick={this.handleBackClick} 
        data-test-id="back"
         variant="outlined" >
            Back
        </Button>

        <Button 
        data-test-id="next"
        style={
          this.canProceedToNextStep()
            ? webStyle.buttonStyleModal
            : webStyle.disabledButtonStyle 
        }
        onClick={this.handleNextClick} 
        disabled={!this.canProceedToNextStep()}
        size="small" >
            Next
        </Button>
        </Box>
      </Box>
      </Box>
    )
  }
  renderStep7 = () => {
    return (
      <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
      
      <Box sx={webStyle.content}>
      
      <Box style={{display: "flex", flexDirection: "row", paddingLeft: "40px",  paddingRight: "40px", justifyContent: "space-between"}}>
      <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A"}}>Any other features?</Typography>
      <Button
        style={{ width: "fit-content",height: "44px"}}
        data-test-id="addNewDestination"
        onClick={this.addNewDestination}
      >
        <img src={this.addIconSvg}/>
      </Button>
      </Box> 

      <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      style={{ padding: '20px 0px', justifyContent: 'space-between' }}
    >
      <ToggleButtonGroup
        data-test-id="ToggleButtonGroupFeatures"
        onChange={this.handleToggleDestination}
        style={styles.toggleButtonGroup2}
      >
        {this.state.data.destinationArray.map((ele, index) => (
          <ToggleButton
            data-test-id={`toggleBtn${index}`}
            key={ele.id}
            style={styles.toggleButton2}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            value={ele.id}
            selected={ele.selected}
          >
            {ele.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

    </Box>
  
    <Dialog open={this.state.showAddDestinationBox}>
    <style>
      {`
    .MuiPaper-rounded{
      border-radius:8px 8px 32px 8px
    }
      `}
    </style>
    <DialogContent
      style={{ padding: "0px", width: "400px", }}
    >
      <Box display={"flex"} flexDirection={"column"} style={{ gap: '10px'  }} >
        
        <Box style={{padding:"24px 16px 24px 40px", borderBottom: "1px solid #E2E8F0" }} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography
            className="LeagueSpartan"
            style={{ color: '#0F172A', fontWeight: 700, fontSize: '24px', lineHeight: '32px' }}>
            Add custom feature
          </Typography>
          <Box
            alignSelf={"end"}
          >
            <Button
              data-test-id="closeAddDestinationBox"
              onClick={this.closeAddDestinationBox}
            >
              <Clear />
            </Button>
          </Box>
        </Box>

        <Box style={{padding: "40px" }}>
          
          <Typography  className="LeagueSpartan" style={{color: "#475569", fontSize: "14px", fontWeight: "700"}}>Feature</Typography>
          <CssTextField 
            className="LeagueSpartan"            
            variant="outlined"                 
            fullWidth
            id="handleNewDestination"             
            name="handleNewDestination"
            value={this.state.newDestination}
            onChange={this.handleNewDestination}
            data-test-id="handleNewDestination"
            InputProps={{
              style: webStyle.inputStyle}}
          />
         </Box>

        <Box style={{borderTop: "1px solid #E2E8F0", padding:"24px 24px 24px 40px", gap: "16px" }}
          display={'flex'} paddingRight={'24px'} justifyContent={'end'} >
          <Button
            onClick={this.closeAddDestinationBox}
            data-test-id="cancelDialogBtn"
            style={{
              borderRadius: '8px',
              color: '#FAA81D',
              border: '#FAA81D 1px solid',
              fontWeight: 700, fontSize: '16px',
              padding: '16px 30px',
              lineHeight: '24px',
              width: "120px",
              height: "56px",
            }}
          >
            CANCEL
          </Button>
          <Button
            data-test-id="addNewDestinationToArray"
            onClick={this.addNewDestinationToArray}
            style={{
              color: '#fff',
              backgroundColor: '#FAA81D',
              fontWeight: 700,
              padding: '16px 30px',
              fontSize: '16px', 
              lineHeight: '24px',
              borderRadius: '8px',
              width: "120px",
              height: "56px",
            }}
          >
            SAVE
          </Button>
        </Box>

      </Box>
    </DialogContent>
    </Dialog>
      </Box> 
                
      <Box style={{gap: "16px", 
      width: "100%"}} sx={{display: "flex", 
      flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", paddingBottom: "16px",borderTop: "1px solid rgb(226, 232, 240)"}}>       
        <Box 
        style={{paddingRight: "40px", 
        display: "flex", 
        gap: "16px"}}>
        <Button 
        style={webStyle.buttonStyleModal2} 
        onClick={this.handleBackClick} 
        size="small" 
        variant="outlined"
        data-test-id="back"
          >
            Back
        </Button>
        <Button data-test-id="next"
        style={
          this.canProceedToNextStep()
            ? webStyle.buttonStyleModal
            : webStyle.disabledButtonStyle 
        }
        onClick={this.handleNextClick} 
        size="small" 
        disabled={!this.canProceedToNextStep()}
        >
            Next
        </Button>
        </Box>
      </Box>
      </Box>
    )
  }
  renderStep8 = () => {
    return (
      <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
      
      <Box sx={webStyle.content}>
      
      <Box style={{width: "100%"}}>
      <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Price Range</Typography>
      </Box> 

      <Box style={{width: "100%", display: "flex", justifyContent: "center", gap: "16px", flexDirection: "column"}}>

      <Box 
        style={{ 
          display: "flex", 
          paddingLeft: "40px",
          paddingRight: "40px",
          flexDirection: "column", 
          gap: "4px" 
        }}
      >
        <Typography 
          style={{ color: "#475569", fontSize: "14px", fontWeight: "700" }}
        >
          From*
        </Typography>

        <CssTextField    
          error={Boolean(this.state.errors.priceFrom)}
          data-test-id="priceFrom"            
          variant="outlined"                 
          fullWidth
          id="priceFrom"             
          name="priceFrom"
          value={this.state.data.priceFrom}
          onChange={(e) => this.onValueChange("priceFrom", e.target.value)}
          InputProps={{
            style: webStyle.inputStyle,
            startAdornment: (
              <InputAdornment position="start">
                <img src={this.euroSvg}/>
              </InputAdornment>
            )
          }}
        />
        {this.renderErrorMessage('priceFrom')}
      </Box>

      <Box 
        style={{ 
          display: "flex", 
          paddingLeft: "40px",
          paddingRight: "40px",
          flexDirection: "column", 
          gap: "4px" 
        }}
      >
        <Typography 
          style={{ color: "#475569", fontSize: "14px", fontWeight: "700" }}
        >
          To*
        </Typography>

        <CssTextField    
          error={Boolean(this.state.errors.priceTo)}
          data-test-id="priceTo"            
          variant="outlined"                 
          id="priceTo"             
          fullWidth
          name="priceTo"
          value={this.state.data.priceTo}
          onChange={(e) => this.onValueChange("priceTo", e.target.value)}
          InputProps={{
            style: webStyle.inputStyle,
            startAdornment: (
              <InputAdornment position="start">
                <img src={this.euroSvg}/>
              </InputAdornment>
            )
          }}
        />
        {this.renderErrorMessage('priceTo')}
      </Box>
      </Box>
      </Box>                  
     
      <Box style={{gap: "16px", width: "100%"}} sx={{
        flexDirection: "row",
        paddingTop: "10px", 
        paddingBottom: "16px",
        display: "flex", 
        justifyContent: "flex-end", 
      borderTop: "1px solid rgb(226, 232, 240)"}}>       
        <Box style={{
          paddingRight: "40px", 
          display: "flex", 
          gap: "16px"}}>
        
        <Button 
        data-test-id="back" onClick={this.handleBackClick} style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
            Back
        </Button>
        <Button
         data-test-id="next" disabled={!this.canProceedToNextStep()}
        onClick={this.handleNextClick}  style={
          this.canProceedToNextStep()
            ? webStyle.buttonStyleModal
            : webStyle.disabledButtonStyle 
        }
         size="small" >
            Next
        </Button>
        </Box>
      </Box>
      </Box>
    )
  }

  renderStep9 = () => {
    const { files, isDragging, anchorEl, selectedFileIndex } = this.state;
  
    const renderDropZone = () => (
      <Box style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box style={{ width: "100%" }}>
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "24px",
              color: "#0F172A",
              paddingLeft: "40px",
            }}
          >
            Add some photos of your car
          </Typography>
        </Box>
        <Box
          style={{
            paddingLeft: "40px",
            paddingRight: "40px",
            display: "flex",
            justifyContent: "center",
            gap: "16px",
            flexDirection: "column",
          }}
        >
          <div
          data-test-id="dropzone"
            style={{
              height: "200px",
              padding: "16px",
              background: "rgba(254, 252, 249, 1)",
              border: "0.5px solid rgba(250, 168, 29, 1)",
              borderRadius: "8px",
              textAlign: "center",
              backgroundColor: isDragging ? "#e0e0e0" : "#f9f9f9",
            }}
            onDrop={this.handleDrop}
            onDragOver={this.handleDragOver}
            onDragLeave={this.handleDragLeave}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "56px",
              }}
            >
              <img
                style={{ width: "48px", height: "48px" }}
                src={this.imageadd}
                alt="Add"
              />
              <p
                className="LeagueSpartan"
                style={{ color: "rgba(71, 85, 105, 1)" }}
              >
                Drag and drop the files here
              </p>
            </Box>
          </div>
          <img src={this.divider} alt="Divider" />
          <label className="custom-file-upload">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={this.handleFileSelect}
            />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={this.add} alt="Add" />
              <Typography
                style={{
                  color: "rgba(250, 168, 29, 1)",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                BROWSE
              </Typography>
            </Box>
          </label>
        </Box>
      </Box>
    );
  
    const renderImagePreview = () => (
      <Box style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          <Typography
            style={{
              fontWeight: "700",
              fontSize: "24px",
              color: "#0F172A",
            }}
          >
            Add some photos of your car
          </Typography>
          <label className="custom-file-upload">
            <input
              accept="image/*"
              type="file"
              multiple
              onChange={this.handleFileSelect}
            />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <img src={this.add} alt="Add" />
              <Typography
                style={{
                  fontSize: "16px",
                  color: "rgba(250, 168, 29, 1)",
                  fontWeight: "700",
                }}
              >
                ADD MORE
              </Typography>
            </Box>
          </label>
        </Box>
        <Box
          style={{
            paddingLeft: "40px",
            position: "relative",
            height: "auto",
            paddingRight: "40px",
          }}
        >
          {files.length > 0 && (
            <Box style={{ position: "relative", width: "100%", height: "300px", overflow: "hidden" }}>
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={this.handleImageBlob(files[0].file)}
                  alt="Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                    transform: `rotate(${files[0].rotation}deg)`,
                    transformOrigin: "center",
                  }}
                />
                <IconButton
                  data-test-id="menuBtn0"
                  style={{ width: "32px", height: "32px", position: "absolute", top: 10, right: 10 , 
                  background: this.state.selectedFileIndex === 0 ? "#FEF3C7" : "#FFFFFFCC"
                }}
                  onClick={(event) => this.handleMenuClick(event, 0)}
                >
                  <MoreHoriz />
                </IconButton>
                <Menu
                  style={{padding: "0", borderRadius: "4px"}}
                  anchorEl={anchorEl}
                  open={selectedFileIndex === 0}
                  onClose={this.handleMenuClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  PaperProps={{
                    style: {
                      marginTop: "25px",
                      transform: 'translateX(-60px)'
                    }
                  }}
                >
                  <MenuItem style={{height:"48px",  display: "flex", padding: "12px 16px 12px 12px", gap: "8px" }} data-test-id="makecover0" onClick={() => this.handleMakeCover(0)}>
                    <img src={this.makeCover}/>
                    <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "14px", fontWeight: "400" }}>Make a cover</Typography>
                  </MenuItem>
                  <MenuItem style={{height:"48px",  display: "flex", padding: "12px 16px 12px 12px", gap: "8px" }} data-test-id="rotate0" onClick={() => this.handleRotate(0)}>
                    <img src={this.rotateImg}/>
                    <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "14px", fontWeight: "400" }}>Rotate photo</Typography>
                  </MenuItem>
                  <MenuItem style={{height:"48px",  display: "flex", padding: "12px 16px 12px 12px", gap: "8px" }} data-test-id="delete0" onClick={() => this.handleDelete(0)}>
                    <img src={this.deleteImg}/>
                    <Typography className="LeagueSpartan" style={{color: "#DC2626", fontSize: "14px", fontWeight: "400" }}>Delete</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "10px",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          {files.slice(1).map((image, index) => (
            <Box key={index + 1} style={{ position: "relative", width: "100%", height: "150px", overflow: "hidden", alignSelf: "center" }}>
              <Box
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={this.handleImageBlob(image.file)}
                  alt={`Preview ${index + 1}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                    transform: `rotate(${image.rotation}deg)`,
                    transformOrigin: "center",
                  }}
                />
                <IconButton
                  data-test-id="menuBtn"
                  style={{ width: "32px", height: "32px", position: "absolute", top: 10, right: 10, 
                  background: this.state.selectedFileIndex === index + 1 ? "#FEF3C7": "#FFFFFFCC"
                }}
                  onClick={(event) => this.handleMenuClick(event, index + 1)}
                >
                  <MoreHoriz />
                </IconButton>
                <Menu
                  style={{padding: "0", borderRadius: "4px"}}
                  anchorEl={anchorEl}
                  open={selectedFileIndex === index + 1}
                  onClose={this.handleMenuClose}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  PaperProps={{
                    style: {
                      marginTop: "25px",
                      transform: 'translateX(-60px)'
                    }
                  }}
                >
                  <MenuItem style={{height:"48px",  display: "flex", padding: "12px 16px 12px 12px", gap: "8px" }} data-test-id="makecover" onClick={() => this.handleMakeCover(index + 1)}>
                    <img src={this.makeCover}/>
                    <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "14px", fontWeight: "400" }}>Make a cover</Typography>
                  </MenuItem>
                  <MenuItem style={{height:"48px",  display: "flex", padding: "12px 16px 12px 12px", gap: "8px" }} data-test-id="rotate" onClick={() => this.handleRotate(index + 1)}>
                    <img src={this.rotateImg}/>
                    <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "14px", fontWeight: "400" }}>Rotate photo</Typography>
                  </MenuItem>
                  <MenuItem style={{height:"48px",  display: "flex", padding: "12px 16px 12px 12px", gap: "8px" }} data-test-id="delete" onClick={() => this.handleDelete(index + 1)}>
                    <img src={this.deleteImg}/>
                    <Typography className="LeagueSpartan" style={{color: "#DC2626", fontSize: "14px", fontWeight: "400" }}>Delete</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );

    
  
    return (
      <Box
        style={{ backgroundColor: "white", gap: "40px", alignItems: "center" }}
        sx={{
          width: "670px",
          height: "fit-content",
          marginTop: "56px",
          boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          padding: "40px, 0px, 24px, 0px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={webStyle.content}>
          {files.length === 0 ? renderDropZone() : renderImagePreview()}
        </Box>
  
        <Box
          style={{ gap: "16px", width: "100%" }}
          sx={{
            flexDirection: "row",
            paddingTop: "10px",
            paddingBottom: "16px",
            display: "flex",
            justifyContent: "flex-end",
            borderTop: "1px solid rgb(226, 232, 240)",
          }}
        >
          <Box style={{ paddingRight: "40px", display: "flex", gap: "16px" }}>
            <Button
              data-test-id="back"
              onClick={this.handleBackClick}
              style={webStyle.buttonStyleModal2}
              size="small"
              variant="outlined"
            >
              Back
            </Button>
            <Button
              data-test-id="next"
              disabled={!this.canProceedToNextStep()}
              onClick={this.handleNextClick}
              style={
                this.canProceedToNextStep()
                  ? webStyle.buttonStyleModal
                  : webStyle.disabledButtonStyle
              }
              size="small"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };
  
  renderStep10Image = () => {
    const { files } = this.state;
      
    return (
      <Box style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Box
          style={{   
            position: "relative",
            height: "auto",
          }}
        >
          {files.length > 0 && (
            <Box style={{ 
              position: "relative", 
              width: "100%", height: "300px", overflow: "hidden" }}>
              <Box
                style={{
                  height: "100%",
                  overflow: "hidden",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <img
                  src={this.handleImageBlob(files[0].file)}
                  style={{
                    transform: `rotate(${files[0].rotation}deg)`,
                    maxWidth: "100%",
                    objectFit: "contain",
                    maxHeight: "100%",
                    transformOrigin: "center",
                  }}
                  alt="Preview"
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gap: "10px",
          }}
        >
          {files.slice(1).map((image, index) => (
            <Box key={index + 1} style={{ position: "relative", width: "100%", overflow: "hidden", alignSelf: "center" }}>
              <Box
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={this.handleImageBlob(image.file)}
                  alt={`Preview ${index + 1}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                    transform: `rotate(${image.rotation}deg)`,
                    transformOrigin: "center",
                  }}
                />
              </Box>
            </Box>
          ))}
          <Button data-test-id="editBtn" style={{width: "108px", height: "108px" ,boxShadow: "0px 2px 8px 0px #0000001A"}} onClick={() => {this.setState((prevState) => ({
          activeStep: 9,
        }))}} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>
      </Box>
    );
  }
  

  renderStep10 = () => {
    return (
      <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
      
      <Box sx={webStyle.content}>
      
      <Box style={{width: "100%"}}>
      <Typography className="LeagueSpartan" style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Car Details</Typography>
      </Box> 

      <Box style={{width: "100%", display: "flex", justifyContent: "center", gap: "16px", flexDirection: "column"}}>

      <Box style={{display:"flex", 
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  flexDirection: "column", 
                  gap: "24px"}}>

        {this.renderStep10Image()}

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Car Registration number</Typography>
          <Button data-test-id="editBtn1" onClick={() => this.handleEditClick(1)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>
        <CssTextField2    
          data-test-id="carRegNo"            
          variant="outlined"                 
          fullWidth
          id="carRegNo"             
          name="carRegNo"
          value={this.state.isEditing[1] ? this.state.data.carRegNo : ''}
          onChange={(e) => this.onValueChange("carRegNo", e.target.value)}
          placeholder={!this.state.isEditing[1] ? this.state.data.carRegNo : ''}
          disabled= {!this.state.isEditing[1]}
          InputProps={{
            style: {
              ...webStyle.inputStyle,
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
              color: '#0F172A',
            },
            inputProps: {
              style: {
                color: '#0F172A', 
            }}
          }}
        />
        {this.renderErrorMessage('carRegNo')}
        </Box>

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Make</Typography>
          <Button data-test-id="editBtn2" onClick={() => this.handleEditClick(2)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>

        {!this.state.isEditing[2] ? 
          <CssTextField2    
            data-test-id="make"            
            variant="outlined"                 
            fullWidth
            id="make"             
            name="make"
            value={this.state.data.make}
            placeholder={this.state.data.make}
            disabled
            InputProps={{
              style: {
                ...webStyle.inputStyle,
                boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
              }
            }}
          />
        : 
          <Select
            data-test-id="select-component"
            searchable = {false}
            style={{ borderRadius: "8px", border: "1px solid #cbd5e1" ,paddingLeft: "16px", height: "56px"}}
            className="styled-select"
            placeholder=""
            options={make}
            values={make.filter(
              (make) => make.value === this.state.data.make
            )} 
            onChange={(values) => this.setValue('make', values)}
          />
        }
        
        </Box>

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Model</Typography>
          <Button data-test-id="editBtn3" onClick={() => this.handleEditClick(3)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>

        {!this.state.isEditing[3] ? 
          <CssTextField2    
            data-test-id="model"            
            variant="outlined"                 
            fullWidth
            id="model"             
            name="model"
            value={this.state.data.model}
            placeholder={this.state.data.model}
            disabled
            InputProps={{
              style: {
                ...webStyle.inputStyle,
                boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
              }
            }}
          />
        : 
        <Select
          style={{ borderRadius: "8px", 
          border: "1px solid #cbd5e1" , paddingLeft: "16px", height: "56px"}}
          data-test-id="select-component2"
          searchable = {false}
          className="styled-select"
          placeholder=""
          options={model}
          values={model.filter(
            (model) => model.value === this.state.data.model
          )} 
          onChange={(values) => this.setValue('model', values)}
          disabled={!this.state.isEditing[3]}
        />
        }
        </Box>

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Year of manufacture</Typography>
          <Button data-test-id="editBtn4" onClick={() => this.handleEditClick(4)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>

        {!this.state.isEditing[4] ? 
          <CssTextField2    
            data-test-id="year"            
            variant="outlined"                 
            fullWidth
            id="year"             
            name="year"
            value={this.state.data.year}
            placeholder={this.state.data.year}
            disabled
            InputProps={{
              style: {
                ...webStyle.inputStyle,
                boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
              }
            }}
          />
        : 
          <Select
            searchable = {false}
            data-test-id="select-component3"
            style={{ borderRadius: "8px", border: "1px solid #cbd5e1", paddingLeft: "16px", height: "56px" }}
            className="styled-select"
            placeholder=""
            options={year}
            values={year.filter(
              (year) => year.value === this.state.data.year
            )} 
            onChange={(values) => this.setValue('year', values)}
            disabled={!this.state.isEditing[4]}
          />
          }
        </Box>

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>What is the mileage?</Typography>
          <Button data-test-id="editBtn5" onClick={() => this.handleEditClick(5)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>
        <CssTextField2   
          data-test-id="mileage"            
          variant="outlined"                 
          fullWidth
          id="mileage"             
          name="mileage"
          value={this.state.data.mileage}
          onChange={(e) => this.onValueChange("mileage", e.target.value)}
          InputProps={{
            style: {
              ...webStyle.inputStyle,
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
            }
          }}
          disabled={!this.state.isEditing[5]}
        />
          {this.renderErrorMessage('mileage')}
        </Box>

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Fuel type</Typography>
          <Button data-test-id="editBtn6" onClick={() => this.handleEditClick(6)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>
        {!this.state.isEditing[6] ? 
          <CssTextField2    
            data-test-id="fuel"            
            variant="outlined"                 
            fullWidth
            id="fuel"             
            name="fuel"
            value={this.state.data.fuel}
            placeholder={this.state.data.fuel}
            disabled
            InputProps={{
              style: {
                ...webStyle.inputStyle,
                boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
              }
            }}
          />
        : 
        <Select
          data-test-id="select-component"
          style={{ borderRadius: "8px", border: "1px solid #cbd5e1" ,paddingLeft: "16px", height: "56px"}}
          className="styled-select"
          placeholder=""
          options={fuel}
          values={fuel.filter(
            (fuel) => fuel.value === this.state.data.fuel
          )} 
          onChange={(values) => this.setValue('fuel', values)}
        />
        }
        </Box>

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Transmission</Typography>
          <Button data-test-id="editBtn7" onClick={() => this.handleEditClick(7)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>

        {!this.state.isEditing[7] ? 
        <CssTextField2    
          data-test-id="transmission"            
          variant="outlined"                 
          fullWidth
          id="transmission"             
          name="transmission"
          value={this.state.data.prefTransmission}
          placeholder={this.state.data.prefTransmission}
          disabled
          InputProps={{
            style: {
              ...webStyle.inputStyle,
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
            }
          }}
        />
        :
        <FormControl style={{display: "flex", gap: "4px"}} component="fieldset">
        <RadioGroup
          value={this.state.data.prefTransmission}
          >
          <FormControlLabel
            value="Automatic"
            control={<Radio />}
            labelPlacement="start"
            onClick={(e) => this.onValueChange("prefTransmission", "Automatic")}
            label="Automatic"
            data-test-id="Automatic"
          />
          <FormControlLabel          
            data-test-id="Manual"
            control={<Radio />}
            value="Manual"
            onClick={(e) => this.onValueChange("prefTransmission", "Manual")}
            label="Manual"
            labelPlacement="start"
          />
        </RadioGroup>
        </FormControl>
        }       
        </Box>

        <Box style={{display: "flex", flexDirection: "column", }}>
          <Box style={{display: "flex", justifyContent: "space-between" }}>
            <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Special Features</Typography>
          </Box>

          <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            style={{ padding: '20px 0px', justifyContent: 'space-between' }}
          >
            <ToggleButtonGroup
              data-test-id="ToggleButtonGroupFeatures"
              onChange={this.handleToggleDestination}
              style={styles.toggleButtonGroup3}
            >
              {this.state.data.destinationArray
              .filter((ele) => ele.selected)
              .map((ele, index) => (
                <ToggleButton
                disabled
                  data-test-id={`toggleBtn${index}`}
                  key={ele.id}
                  style={{
                    ...styles.toggleButton3,
                    color: 'inherit', 
                    opacity: 1, 
                  }}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  value={ele.id}
                >
                  {ele.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

          </Box>

          <Box style={{display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography className="LeagueSpartan" style={{color: "#334155", fontSize: "16px", fontWeight: "400"}}>Any other features?</Typography>
            <Button
              style={{ width: "fit-content", textTransform: "none"}}
              data-test-id="addNewDestination"
              onClick={this.addNewDestination}
            >
              <div style={{display: "flex", gap: "8px"}}>
              <img src={this.add}/>
              <Typography className="LeagueSpartan" style={{color: "#FAA81D", fontSize: "18px", fontWeight: "700"}}>Add feature</Typography>
              </div>
            </Button>
          </Box>

          <Dialog open={this.state.showAddDestinationBox}>
          <style>
            {`
              .MuiPaper-rounded{
                border-radius:8px 8px 32px 8px
              }
            `}
          </style>
          <DialogContent
            style={{ padding: "0px", 
            width: "400px", }}
          >
            <Box 
            display={"flex"} 
            flexDirection={"column"} 
            style={{ gap: '10px'  }} >
              
              <Box style={{
                padding:"24px 16px 24px 40px", 
                borderBottom: "1px solid #E2E8F0" }} 
                justifyContent={"space-between"} 
                display={"flex"} 
                alignItems={"center"}>
                <Typography
                  className="LeagueSpartan"
                  style={{ color: '#0F172A', 
                  fontSize: '24px', 
                  fontWeight: 700, 
                  lineHeight: '32px' }}>
                  Add custom feature
                </Typography>
                <Box
                  alignSelf={"end"}
                >
                  <Button
                    onClick={this.closeAddDestinationBox}
                    data-test-id="closeAddDestinationBox"
                  >
                    <Clear />
                  </Button>
                </Box>
              </Box>

              <Box 
              style={{padding: "40px" }}>
                
                <Typography  
                className="LeagueSpartan" 
                style={{color: "#475569", 
                fontSize: "14px", fontWeight: "700"}}>Feature
                </Typography>
                <CssTextField 
                  className="LeagueSpartan"            
                  variant="outlined"                 
                  id="handleNewDestination"             
                  fullWidth
                  value={this.state.newDestination}
                  name="handleNewDestination"
                  data-test-id="handleNewDestination"
                  onChange={this.handleNewDestination}
                  InputProps={{
                    style: webStyle.inputStyle}}
                />
              </Box>

              <Box style=
              {{borderTop: "1px solid #E2E8F0", 
              padding:"24px 24px 24px 40px", gap: "16px" }}
              paddingRight={'24px'} 
              display={'flex'} 
                justifyContent={'end'} >
                <Button
                  data-test-id="cancelDialogBtn"
                  onClick={this.closeAddDestinationBox}
                  style={{
                    borderRadius: '8px',
                    padding: '16px 30px',
                    color: '#FAA81D',
                    fontWeight: 700, fontSize: '16px',
                    lineHeight: '24px',
                    border: '#FAA81D 1px solid',
                    height: "56px",
                    width: "120px",
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  onClick={this.addNewDestinationToArray}
                  data-test-id="addNewDestinationToArray"
                  style={{
                    color: '#fff',
                    backgroundColor: '#FAA81D',
                    fontWeight: 700,
                    lineHeight: '24px',
                    padding: '16px 30px',
                    height: "56px",
                    borderRadius: '8px',
                    width: "120px",
                    fontSize: '16px', 
                  }}
                >
                  SAVE
                </Button>
              </Box>

            </Box>
          </DialogContent>
          </Dialog>
        </Box> 

        <Box style={{display: "flex", flexDirection: "column", }}>
        <Box style={{display: "flex", justifyContent: "space-between" }}>
          <Typography className="LeagueSpartan" style={{color: "#0F172A", fontSize: "16px", fontWeight: "400"}}>Price category</Typography>
          <Button data-test-id="editBtn8" onClick={() => this.handleEditClick(8)} size="small">
            <img src={this.editBtn}/>
          </Button>
        </Box>

        {!this.state.isEditing[8] ? 
        <CssTextField2    
          data-test-id="price"            
          variant="outlined"                 
          fullWidth
          id="price"             
          name="price"
          placeholder={`€ ${this.state.data.priceFrom} - ${this.state.data.priceTo}`}
          disabled
          InputProps={{
            style: {...webStyle.inputStyle,
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)",
            }
          }}
        />
        :
        <Box>
          <Box style={{width: "100%", 
          justifyContent: "center", 
          display: "flex", 
          gap: "16px", 
          flexDirection: "column"}}>
          <Box 
            style={{ 
              flexDirection: "column", 
              display: "flex", 
              gap: "4px" 
            }}
          >
            <Typography style={{ color: "#475569", 
              fontSize: "14px", 
              fontWeight: "400" }}
            >
              From
            </Typography>

            <CssTextField    
              data-test-id="priceFrom"            
              error={Boolean(this.state.errors.priceFrom)}
              fullWidth
              variant="outlined"                 
              name="priceFrom"
              id="priceFrom"             
              value={this.state.data.priceFrom}
              onChange={(e) => this.onValueChange(
                "priceFrom", e.target.value
              )}
              InputProps={{
                style: webStyle.inputStyle,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={this.euroSvg}/>
                  </InputAdornment>
                )
              }}
            />
            {this.renderErrorMessage('priceFrom')}
          </Box>

          <Box 
            style={{ 
              flexDirection: "column", 
              display: "flex", 
              gap: "4px" 
            }}
          >
            <Typography 
              style={{ 
                fontSize: "14px", 
                color: "#475569", 
                fontWeight: "400" }}
            >
              To
            </Typography>

            <CssTextField    
              data-test-id="priceTo"            
              error={Boolean(this.state.errors.priceTo)}
              value={this.state.data.priceTo}
              variant="outlined"                 
              id="priceTo"             
              name="priceTo"
              fullWidth
              onChange={(e) => this.onValueChange(
                "priceTo", e.target.value)}
              InputProps={{
                style: webStyle.inputStyle,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={this.euroSvg}/>
                  </InputAdornment>
                )}}
            />
            {this.renderErrorMessage('priceTo')}
          </Box>
          </Box>
        </Box>
        }       
        </Box>

      </Box> 
      </Box>
      </Box>                   
      <Box 
      style={{gap: "16px", width: "100%"}} 
      sx={{
        flexDirection: "row",
        paddingTop: "10px", 
        paddingBottom: "16px",
        justifyContent: "flex-end", 
        display: "flex", 
        borderTop: "1px solid rgb(226, 232, 240)"}}>       
      <Box 
        style={{
          display: "flex", 
          paddingRight: "40px", gap: "16px"}}>
        <Button data-test-id="back" onClick={this.handleBackClick} 
        style={webStyle.buttonStyleModal2} 
        size="small" 
        variant="outlined" >
            Back
        </Button>
        <Button 
        data-test-id="confirm" 
        disabled={!this.canProceedToNextStep()}
        onClick={this.handleConfirm} 
        style={
          this.canProceedToNextStep()
            ? webStyle.buttonStyleModal6
            : webStyle.disabledButtonStyle 
        } size="small" >
            Confirm
        </Button>
        </Box>
      </Box>
      </Box>
    )
  }

  renderStep11 = () => {
    return (
      <Box 
      style={{paddingTop: "64px"}}>
        <Box sx={{width: "440px", 
        height: "fit-content", 
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", 
        borderRadius: "8px", padding: "40px", display: "flex",
         flexDirection: "column", justifyContent: "center", 
         alignItems: "center"}}>                  
        <img style={{paddingBottom: "24px"}} 
        src={this.verification}/>
        
        <Typography style={{fontWeight: "700", 
        fontSize: "24px", color: "#0F172A"}}>
          Verification Pending
          </Typography>             
        
        <Box sx={{paddingTop: "16px", 
        paddingBottom: "18px"}}>
          <Typography style={{textAlign:"center" ,
          fontWeight: "400", fontSize: "18px",
           color: "#334155"}}>Your car has been successfully created and will be added to the list of your cars. Thanks!</Typography>           
        </Box>
        
        <Button data-test-id="continue" 
        fullWidth 
        style={webStyle.buttonStyleModal3}>Continue</Button>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <Box sx={webStyle.main}>

        <style>
          {`
            .MuiButton-root{
              min-width: 0px;
            }
            .MuiInputBase-input::placeholder {
              opacity: 1;            
            }
            .MuiList-padding {
              padding-top: 0px;
              padding-bottom: 0px;
            }
            .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
              border-color: transparent;
            }
            .MuiMobileStepper-progress {
              background: #CBD5E1;
              width: 100%;
              border-radius: 6px 0px 0px 6px;
            }
            .MuiMobileStepper-root{
              background: 0;
              padding: 0px;
            }
            .MuiLinearProgress-barColorPrimary {
              background: #CBD5E1;
            }       
            .MuiInput-underline::before .MuiInput-underline::after{
              border: 0;
            }
            .MuiInput-underline{
              border-bottom: 0px;
            }
            .MuiButton-outlined {
              border: 1px solid rgb(250, 168, 29);
            }     
            .MuiTypography-body1 {
              font-family: inherit;
            }
            .MuiOutlinedInput-input {
              padding: 0;
            }
            .MuiRadio-colorSecondary.Mui-checked {
              color: rgba(250, 168, 29, 1);
            }
            .custom-select {
              margin: 0;
              padding: 10px;
              width: 200px; 
            }
            .MuiSelect-select {
              padding: 0px; 
            }
            .MuiMenuItem-root:hover {
              background-color: #FEF3C7; 
            }
            .MuiFormControlLabel-labelPlacementStart {
              flex-direction: row-reverse;
              justify-content: space-between;
              margin-left: 0;
              margin-right: 40px;
            }
            .MuiFormControlLabel-root {
              margin-left: 0;
            }
            .styled-select .react-dropdown-select-dropdown {
              border-radius: 8px;
              border: 1px solid #cbd5e1;
              box-shadow: 0px 2px 4px 0px #00000026;
            }

            .styled-select .react-dropdown-select-item {
              height: 48px;
              display: flex;
              align-items: center;
            }

            .styled-select .react-dropdown-select-item:hover {
              background-color: #fef3c7;
            }

            .styled-select .react-dropdown-select-item.react-dropdown-select-item-selected {
              background-color: #fef3c7;
              color: #000000;
            }

            .styled-select .react-dropdown-select {
              border-radius: 8px;
              border: 1px solid #cbd5e1;
            }


            .MuiTouchRipple-root {
              display: none;
            }

            .MuiToggleButton-root{
              padding: 6px 12px 6px 12px; 
              border: 1px solid #64748B;
              font-family: inherit;
              color: #1E293B;
            }

            .MuiToggleButton-root.Mui-selected {
              background-color: #FEFCF9;
              color: #1E293B !important;
              border: 1px solid #FAA81D !important; 
            }
            .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
              border: 1px solid #64748B; 
              margin-left: 0;
            }
            .MuiFormControlLabel-labelPlacementStart {
              margin-right: -11px !important;
            }

            .ReactDropdownSelect:focus-within {
              outline: 0px;
              box-shadow: none;
            }

            .css-1o5fdv0-ReactDropdownSelect {
              color: #64748B;
            }

            .css-1ncinaz-DropDown{
              top: 55px;
            }

            input[type="file"] {
              display: none;
          }
            .custom-file-upload {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0px 16px 0px 16px;
              height: 44px;
              background: rgba(254, 252, 249, 1);
              border-radius: 8px;
              border: 1px solid rgba(250, 168, 29, 1);
              cursor: pointer;
          }
          `}
        </style>

          <Box sx={{width: "100%",height: "92px",display: "flex", flexDirection: "column"}}>
          <div style={{ display: 'flex', alignItems: 'center', height: '92px', position: 'relative', padding: "15px" }}>
            <img src={this.logoSvg} style={{marginLeft: "10px"}}/>
            <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            <Typography style={{ fontWeight: '700', fontSize: '24px' }}>
              List my car
            </Typography> 
            </span>
          </div>
          <Box sx={{width: "100%", alignSelf: "center"}}>
          {this.renderStepper()}
          </Box>
          </Box>
          
          <Box style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${this.bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            alignSelf: "center",
            display: "flex",
            justifyContent: "center"
            }}>

          {this.state.activeStep == 0 && (
            <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content",margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  

            <span></span>
            <Box>
              <img src={this.addCarSvg}/>
            </Box>
            
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}} style={{ gap: "40px"}}>
              <Typography style={{color: "#64748B", fontSize: "18px", fontWeight: "400px"}}>Begin your business by creating your first car now!</Typography>
              <Button data-test-id="next" style={{width: "142px"}}  onClick={() => this.setState((prevState) => ({ activeStep: prevState.activeStep + 1 }))} size="small">
                <img src={this.addCarBtn}/>
              </Button>
            </Box>
            <span></span>

            </Box>
          )}

          {this.state.activeStep == 1 && (
            <Box style={{ backgroundColor: "white",gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
            
            <Box sx={webStyle.content}>
            
            <Box style={{width: "100%"}}>
            <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Basic Info</Typography>
            </Box> 

            <Box style={{display:"flex", 
            width: "100%",
            flexDirection: "column", 
            gap: "4px"}}>
            <FormControl style={{display: "flex", gap: "4px"}} component="fieldset">
              <Typography style={{color: "#475569", paddingLeft:"40px", fontSize: "14px", fontWeight: "700"}}>What is the vehicle type?*</Typography>
              <RadioGroup
                style={{paddingLeft: "40px", paddingRight: "40px"}}
                value={this.state.data.prefVehicle}
              >
                <Box style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  <Typography>Sedan</Typography>
                  <FormControlLabel
                    style={{width: "fit-content"}}
                    onClick={(e) => this.onValueChange("prefVehicle", "Sedan")}
                    control={<Radio />}
                    value="Sedan"
                    labelPlacement="start"
                    label=""
                    data-test-id="Sedan"
                  />
                </Box>
                <Box style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  <Typography>SUV</Typography>
                  <FormControlLabel
                    style={{width: "fit-content"}}
                    onClick={(e) => this.onValueChange("prefVehicle", "SUV")}
                    control={<Radio />}
                    value="SUV"
                    labelPlacement="start"
                    label=""
                    data-test-id="SUV"
                  />
                </Box>
                <Box style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  <Typography>Camper</Typography>
                  <FormControlLabel
                    style={{width: "fit-content"}}
                    onClick={(e) => this.onValueChange("prefVehicle", "Camper")}
                    control={<Radio />}
                    value="Camper"
                    labelPlacement="start"
                    label=""
                    data-test-id="Camper"
                  />
                </Box>
                <Box style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  <Typography>Motorcycle</Typography>
                  <FormControlLabel
                    style={{width: "fit-content"}}
                    onClick={(e) => this.onValueChange("prefVehicle", "Motorcycle")}
                    control={<Radio />}
                    value="Motorcycle"
                    labelPlacement="start"
                    label=""
                    data-test-id="Motorcycle"
                  />
                </Box>
                <Box style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  <Typography>Convertible</Typography>
                  <FormControlLabel
                    style={{width: "fit-content"}}
                    onClick={(e) => this.onValueChange("prefVehicle", "Convertible")}
                    control={<Radio />}
                    value="Convertible"
                    labelPlacement="start"
                    label=""
                    data-test-id="Convertible"
                  />
                </Box>
              </RadioGroup>

            </FormControl>
            </Box>     
                 
            </Box> 
                      
            <Box style={{gap: "16px", width: "100%"}} sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", paddingBottom: "16px",borderTop: "1px solid rgb(226, 232, 240)"}}>       
              <Box style={{paddingRight: "40px", 
              display: "flex", 
              gap: "16px"}}>
              <Button 
              style={webStyle.buttonStyleModal2} 
              onClick={this.handleBackClick} 
              size="small" 
              data-test-id="back"
               variant="outlined" >
                  Back
              </Button>
              <Button data-test-id="next"
              style={
                this.canProceedToNextStep()
                  ? webStyle.buttonStyleModal
                  : webStyle.disabledButtonStyle 
              }
              disabled={!this.canProceedToNextStep()}
              onClick={this.handleNextClick} 
              size="small" >
                  Next
              </Button>
              </Box>
            </Box>
            </Box>
          )}
          
          {this.state.activeStep == 2 && (
            <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
            
            <Box sx={webStyle.content}>
            
            <Box style={{width: "100%"}}>
            <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Confirm the model of your car</Typography>
            </Box> 

            <Box style={{display:"flex", 
            paddingLeft: "40px",
            paddingRight: "40px",
            flexDirection: "column", 
            gap: "4px"}}>
              <Typography style={{color: "#475569", fontSize: "14px", fontWeight: "700"}}>Make</Typography>
              <Select
                data-test-id="select-component"
                searchable = {false}
                style={{ borderRadius: "8px", border: "1px solid #cbd5e1" ,paddingLeft: "16px", height: "56px"}}
                className="styled-select"
                placeholder=""
                options={make}
                values={make.filter(
                  (make) => make.value === this.state.data.make
                )} 
                onChange={(values) => this.setValue('make', values)}
              />
            </Box>  

            <Box style={{display:"flex", 
            paddingLeft: "40px",
            paddingRight: "40px",
            flexDirection: "column", 
            gap: "4px"}}>
              <Typography style={{color: "#475569", fontSize: "14px", fontWeight: "700"}}>Model</Typography>
              <Select
                searchable = {false}
                data-test-id="select-component2"
                style={{ borderRadius: "8px", border: "1px solid #cbd5e1" , paddingLeft: "16px", height: "56px"}}
                className="styled-select"
                placeholder=""
                options={model}
                values={model.filter(
                  (model) => model.value === this.state.data.model
                )} 
                onChange={(values) => this.setValue('model', values)}

              />
            </Box> 

            <Box style={{display:"flex", 
            paddingLeft: "40px",
            paddingRight: "40px",
            flexDirection: "column", 
            gap: "4px"}}>
              <Typography style={{color: "#475569", fontSize: "14px", fontWeight: "700"}}>Year</Typography>
              <Select
                searchable = {false}
                data-test-id="select-component3"
                style={{ borderRadius: "8px", border: "1px solid #cbd5e1", paddingLeft: "16px", height: "56px" }}
                className="styled-select"
                placeholder=""
                options={year}
                values={year.filter(
                  (year) => year.value === this.state.data.year
                )} 
                onChange={(values) => this.setValue('year', values)}
              />
            </Box>                  
            </Box>                  
            <Box style={{gap: "16px", width: "100%"}} sx={{
              paddingTop: "10px", 
              flexDirection: "row",justifyContent: "flex-end", 
              paddingBottom: "16px",
              display: "flex", 
            borderTop: "1px solid rgb(226, 232, 240)"}}>       
              <Box style={{
                display: "flex", 
                paddingRight: "40px", 
                gap: "16px"}}>
              <Button data-test-id="back" onClick={this.handleBackClick} style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
                  Back
              </Button>
              <Button data-test-id="next" disabled={!this.canProceedToNextStep()} 
                onClick={this.handleNextClick} style={webStyle.buttonStyleModal} size="small" >
                  Next
              </Button>
              </Box>
            </Box>
            </Box>
          )}

          {this.state.activeStep == 3 && (
            <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
            
            <Box sx={webStyle.content}>
            
            <Box style={{width: "100%"}}>
            <Typography style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Basic Info</Typography>
            </Box> 

            <Box style={{width: "100%", display: "flex", justifyContent: "center", gap: "16px", flexDirection: "column"}}>

            <Box 
              style={{ 
                display: "flex", 
                paddingLeft: "40px",
                paddingRight: "40px",
                flexDirection: "column", 
                gap: "4px" 
              }}
            >
              <Typography 
                style={{ color: "#475569", fontSize: "14px", fontWeight: "700" }}
              >
                What is the mileage?*
              </Typography>

              <CssTextField    
                data-test-id="mileage"            
                variant="outlined"                 
                fullWidth
                id="mileage"             
                name="mileage"
                value={this.state.data.mileage}
                onChange={(e) => this.onValueChange("mileage", e.target.value)}
                InputProps={{
                  style: webStyle.inputStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography>mi</Typography>
                    </InputAdornment>
                  )
                }}
              />
              {this.renderErrorMessage('mileage')}
            </Box>

            <Box style={{backgroundColor: "#FEFCF9", paddingLeft:"40px", paddingRight:"40px"}}>
              <Paper style={webStyle.paperStyle}><Typography style={{fontSize: "12px", fontWeight: "400", color: "#64748B"}}>This information allows us to ensure the quality and safety of our fleet.</Typography></Paper>
            </Box>
            </Box>
            </Box>                  
           
            <Box style={{gap: "16px", width: "100%"}} sx={{
              paddingTop: "10px", 
              flexDirection: "row",
              
              paddingBottom: "16px",
              justifyContent: "flex-end", 
              display: "flex", 
            borderTop: "1px solid rgb(226, 232, 240)"}}>       
              <Box 
              style={{
                display: "flex", 
                paddingRight: "40px", 
                gap: "16px"
                }}>
              <Button 
              data-test-id="back" 
              onClick={this.handleBackClick} 
              style={webStyle.buttonStyleModal2} 
              size="small" 
              variant="outlined" >
                  Back
              </Button>
              <Button
               data-test-id="next" 
               onClick={this.handleNextClick}  
               disabled={!this.canProceedToNextStep()}
              style={
                this.canProceedToNextStep()
                  ? webStyle.buttonStyleModal
                  : webStyle.disabledButtonStyle 
              }
               size="small" 
               >
                  Next
              </Button>
              </Box>
            </Box>
            </Box>
          )}

          {this.state.activeStep == 4 && this.renderStep4()}

          {this.state.activeStep == 5 && this.renderStep5()}

          {this.state.activeStep == 6 && this.renderStep6()}

          {this.state.activeStep == 7 && this.renderStep7()}
          
          {this.state.activeStep == 8 && this.renderStep8()}

          {this.state.activeStep == 9 && this.renderStep9()}

          {this.state.activeStep == 10 && this.renderStep10()}

          {this.state.activeStep == 11 && this.renderStep11()}

          </Box>
        </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const webStyle = {
  // custom style definitions
  main: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    fontFamily: "League Spartan, Arial, sans-serif",
    flexDirection: "column"
  },
  paperStyleS: {
    display: 'flex', 
    alignItems: 'center', 
    fontSize: "16px",
    padding: "16px",
    borderRadius: "8px", 
    boxShadow: "0px 2px 8px 0px #0000001A"
  },

  paperStyle: {
    padding: "10px",
    borderRadius: "8px", 
    boxShadow: "0px 2px 8px 0px #0000001A"
  },

  helperText: {
    textAlign: "right",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "24px",
    padding: "40px 40px 0px 40px",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  disabledButtonStyle: {
    color: "#64748B",
    backgroundColor: "#F1F5F9",
    border: 0,
    borderRadius: "8px",
    fontSize: "16px",
    width: "88px",
    height: "56px",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "88px",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal6: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "117px",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal2: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "88px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal3: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "100%",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal4: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "117px",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal5: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "108px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  inputStyle: {
    width: "100%",
    height: "56px",
    padding: "14px",
    gap: "8px",
    borderRadius: "8px",
    color: "#0f172A",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  textStyle : {
    color: "#475569",
    fontSize: "14px",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  textStyle2 : {
    color: "#475569",
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "League Spartan, Arial, sans-serif",
  }
};
// Customizable Area End