export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const ic_notifications = require("../assets/ic_notification.svg");
export const ic_background = require("../assets/ic_background.svg");
export const ic_error = require("../assets/ic_error.svg");
export const ic_logout = require("../assets/ic_logout.svg");
export const ic_privacy = require("../assets/ic_privacy.svg");
export const ic_profile = require("../assets/ic_profile.png");
export const ic_settings = require("../assets/ic_settings.svg");
export const ic_check = require("../assets/ic_check.svg");
export const ic_dollar = require("../assets/ic_dollar.png");
export const ic_pound = require("../assets/ic_pound.png");
export const ic_euro = require("../assets/ic_euro.png");
export const ic_delete = require("../assets/ic_delete.png");
export const ic_business = require("../assets/ic_business.png");
export const ic_userManagement = require("../assets/ic_userManagement.png");

