import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
export interface SavedData {
  bank: string;
  accholdername: string;
  accnum: string;
  sortcode: string;
  iban: string;
  swift: string;
  routingnum: string;
  buildingNumber: string;
  street: string;
  postcode: string;
  city: string;
  country: string;
  businessName: string;
  vatNumber: string;
  descriptionOfService: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
navigation: any;
id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    savedData: SavedData;
    activeStep: number;
    editClicked: boolean;
    bank:string;
    accholdername:string;
    accnum: string;
    sortcode: string;
    iban: string;
    swift: string;
    routingnum: string;
    buildingNumber: string;
    street: string;
    postcode: string;
    city: string;
    country: string;
    businessName: string;
    vatNumber: string;
    descriptionOfService: string;
    errors: {
      street: boolean,
      postcode: boolean,
      city: boolean,
      country: boolean,
    };
    provider_id: string,
    provider_type: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class GeneralBusinessInfoController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    postHotelBusinessInfoAPIid = ""
    postBankInfoAPIid = ""
    postCarBusinessInfoAPIid = ""
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        activeStep: 0,

        savedData: {
          bank:"",
          accholdername:"",
          accnum: "",
          sortcode: "",
          iban: "",
          swift: "",
          routingnum: "",
          buildingNumber: "",
          street: "",
          postcode: "",
          city: "",
          country: "",
          businessName: "",
          vatNumber: "",
          descriptionOfService: "",
        },

        editClicked: false,
        bank:"",
        accholdername:"",
        accnum: "",
        sortcode: "",
        iban: "",
        swift: "",
        routingnum: "",
        buildingNumber: "",
        street: "",
        postcode: "",
        city: "",
        country: "",
        businessName: "",
        vatNumber: "",
        descriptionOfService: "",
        provider_id : "",
        provider_type: "",
        errors: {
          street: false,
          postcode: false,
          city: false,
          country: false,
        },
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
      runEngine.debugLog("Message Received", message);
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        this.handleRestAPIResponseMessage(message);
      } 
      // Customizable Area End
    }

  
    // Customizable Area Start
    private handleRestAPIResponseMessage(message: Message) {
      const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      if (this.postHotelBusinessInfoAPIid === dataMessage) {
        this.handleRequestCallResponse(message);
      }

      if (this.postCarBusinessInfoAPIid === dataMessage) {
        this.handleCarRequestCallResponse(message);
      }
    }

    private async handleRequestCallResponse(message: Message) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (!responseJson.errors) {
          this.setState({ provider_id: responseJson.data.id,
            provider_type: responseJson.data.type,
           });
        } 
      await this.bankAPI()
    }

    private async handleCarRequestCallResponse(message: Message) {
      await this.bankCarAPI()
    }

    businessAPI = async() => {
      const header = {
        token : await getStorageData("token"),
        "Content-Type": "application/json"
      };

      const data = {
        "business_name": this.state.businessName,
        "vat_number": this.state.vatNumber,
        "description": this.state.descriptionOfService,
        "address": this.state.buildingNumber + "" + this.state.street + "" + this.state.city + "" + this.state.postcode + "" + this.state.country,
        "building_name": this.state.buildingNumber,
        "street": this.state.street
      }

      const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postHotelBusinessInfo
      );
      message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
      message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
      message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(data));
      this.postHotelBusinessInfoAPIid = message.messageId;
  
      runEngine.sendMessage(message.messageId, message);

    }

    businessCarAPI = async() => {
    
      const carData = {
        "full_name": "car_provider",
        "business_name": this.state.businessName,
        "business_address": this.state.buildingNumber + "" + this.state.street + "" + this.state.city + "" + this.state.postcode + "" + this.state.country,
        "vat_number": this.state.vatNumber,
        "description": this.state.descriptionOfService,
        "building_name": this.state.buildingNumber,
        "street": this.state.street
      }

      const carHeader = {
        token : await getStorageData("token"),
        "Content-Type": "application/json"
      };

      const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postCarBusinessInfo
      );
      message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
      message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), carHeader);
      message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(carData));
      this.postCarBusinessInfoAPIid = message.messageId;
  
      runEngine.sendMessage(message.messageId, message);

    }

    bankAPI = async() => {
      const header = {
        token : await getStorageData("token"),
        "Content-Type": "application/json"
      };

      const data = {
        "bank_name": this.state.bank,
        "account_holder_name": this.state.accholdername,
        "account_number": this.state.accnum,
        "IBAN": this.state.iban,
        "swift": this.state.swift,
        "sort_code": this.state.sortcode,
        "routing_number": this.state.routingnum,
        "is_verified": true,
        "provider_type": this.state.provider_type,
        "provider_id": this.state.provider_id
      }

      const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postBankInfo
      );
      message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
      message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
      message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(data));
      this.postBankInfoAPIid = message.messageId;
  
      runEngine.sendMessage(message.messageId, message);
    }

    bankCarAPI = async() => {
      const carData = {
        "bank_name": this.state.bank,
        "account_holder_name": this.state.accholdername,
        "account_number": this.state.accnum,
        "IBAN": this.state.iban,
        "swift": this.state.swift,
        "sort_code": this.state.sortcode,
        "routing_number": this.state.routingnum,
        "is_verified": true,
      }
      const carHeader = {
        token : await getStorageData("token"),
        "Content-Type": "application/json"
      };


      const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postCarBankInfo
      );
      message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), carHeader);
      message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(carData));
      message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
      this.postBankInfoAPIid = message.messageId;
  
      runEngine.sendMessage(message.messageId, message);
    }


    handleAPIRole = (role_id: number) => {
      if (role_id == 2) {
        this.businessAPI()
      }
      else if (role_id == 3) {
        this.businessCarAPI()
      }
    }


    handleConfirm = async() => {
      this.handleAPIRole(await getStorageData("role_id"))
      this.setState({
        activeStep: 4
      });
    }
    

    handleNavigate = async() => {
    
      this.navigateToCatalogue(await getStorageData("role_id"))
    }
    
    navigateToCatalogue = async(role_id: any) => {

      const message = new Message(getName(MessageEnum.NavigationMessage));
  
      let navTarget;
  
      if (role_id == "2") {
        navTarget = "HotelCatalogue";
      }
      else if(role_id == "3") {
        navTarget = "CarCatalogue";  
      }
  
      message.addData(getName(MessageEnum.NavigationTargetMessage), navTarget)
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
    
    handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      
      this.setState((prevState) => ({
        ...prevState,
        [name]: value,
        errors: { ...prevState.errors, [name]: false },
      }));
      
    };
  
    handleAccNumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      if (/^\d*$/.test(newValue)) {
        this.setState({ accnum:  e.target.value });
      }
    };

    handleRoutingNumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      if (/^\d*$/.test(newValue)) {
        this.setState({ routingnum:  e.target.value });
      }
    };

    
    handleBackClick = () => {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep - 1,
        errors: {
          street: false,
          postcode: false,
          city: false,
          country: false,
        }
      }));
    }

    handleNextClick = (currentStep : number) => {
      
      const errors = {
        street: currentStep === 2 && this.state.street === "",
        postcode: currentStep === 2 && this.state.postcode === "",
        city: currentStep === 2 && this.state.city === "",
        country: currentStep === 2 && this.state.country === "",
      };
    
      const hasErrors = Object.values(errors).some(error => error === true);
    
      if (!hasErrors) {
        this.setState(prevState => {
          const newSavedData = { ...prevState.savedData };
    
          switch (currentStep) {
            case 1:
              newSavedData.businessName = prevState.businessName;
              newSavedData.vatNumber = prevState.vatNumber;
              newSavedData.descriptionOfService = prevState.descriptionOfService;
              break;
            case 3:
              newSavedData.bank = prevState.bank;
              newSavedData.accholdername = prevState.accholdername;
              newSavedData.accnum = prevState.accnum;
              newSavedData.sortcode = prevState.sortcode;
              newSavedData.iban = prevState.iban;
              newSavedData.swift = prevState.swift;
              newSavedData.routingnum = prevState.routingnum;
              break;
            case 2:
              newSavedData.buildingNumber = prevState.buildingNumber;
              newSavedData.street = prevState.street;
              newSavedData.postcode = prevState.postcode;
              newSavedData.city = prevState.city;
              newSavedData.country = prevState.country;
              break;
            default:
              break;
          }
    
          return {
            errors,
            savedData: newSavedData,
            activeStep: prevState.activeStep + 1,
          };
        });
      } else {
        this.setState({
          errors,
        });
      }
    };
    
    handleEdit = (currentStep: number) => {
      switch (currentStep) {
        case 1:
          this.setState({
            editClicked: true,
            activeStep: 0
          });
          break;
        case 2:
          this.setState({
            editClicked: true,
           activeStep: 1
          });
          break;
        case 3:
          this.setState({
            editClicked: true,
            activeStep: 2
          });
          break;
        default:
          break;
      }
    };

    handleCancel = (currentStep: number) => {    
      let newState = { ...this.state.savedData };
    
      switch (currentStep) {
        case 1:
          this.setState({
            businessName: newState.businessName,
            vatNumber: newState.vatNumber,
            descriptionOfService: newState.descriptionOfService,
          });
          break;
        case 2:
          this.setState({
            buildingNumber: newState.buildingNumber,
            street: newState.street,
            postcode: newState.postcode,
            city: newState.city,
            country: newState.country,
          });
          break;
        case 3:
          this.setState({
            bank: newState.bank,
            accholdername: newState.accholdername,
            accnum: newState.accnum,
            sortcode: newState.sortcode,
            iban: newState.iban,
            swift: newState.swift,
            routingnum: newState.routingnum,
          });
          break;
        default:
          break;
      }

      this.setState({
        editClicked: false, 
        errors: {
          street: false,
          postcode: false,
          city: false,
          country: false,
        },
        activeStep: 3
      });
    };
    
    handleSave = (currentStep: number) => {
      const { street, postcode, city, country } = this.state;
    
      const errors = {
        street: currentStep === 2 && street === "",
        postcode: currentStep === 2 && postcode === "",
        city: currentStep === 2 && city === "",
        country: currentStep === 2 && country === "",
      };
    
      const hasErrors = Object.values(errors).some(error => error === true);
    
      if (!hasErrors) {
        this.setState(prevState => {
          const newSavedData = { ...prevState.savedData };
    
          switch (currentStep) {
            case 1:
              newSavedData.businessName = prevState.businessName;
              newSavedData.vatNumber = prevState.vatNumber;
              newSavedData.descriptionOfService = prevState.descriptionOfService;
              break;
            case 2:
              newSavedData.buildingNumber = prevState.buildingNumber;
              newSavedData.street = prevState.street;
              newSavedData.postcode = prevState.postcode;
              newSavedData.city = prevState.city;
              newSavedData.country = prevState.country;
              break;
            case 3:
              newSavedData.bank = prevState.bank;
              newSavedData.accholdername = prevState.accholdername;
              newSavedData.accnum = prevState.accnum;
              newSavedData.sortcode = prevState.sortcode;
              newSavedData.iban = prevState.iban;
              newSavedData.swift = prevState.swift;
              newSavedData.routingnum = prevState.routingnum;
              break;
            default:
              break;
          }
    
          return {
            editClicked: false, 
            savedData: newSavedData,
            errors,
            activeStep: 3
          };
        });
      } else {
        this.setState({
          
          errors,
        });
      }
    }
    // Customizable Area End
}
