import React from "react";

import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { LeftBox } from "../../../components/src/LeftBox";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";



const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
        },
    },
});
// Customizable Area End

import Emailnotifications2Controller, {
    Props,
} from "./Emailnotifications2Controller";

export default class Emailnotifications2 extends Emailnotifications2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box maxWidth="xl" sx={webStyle.container}>
                    <LeftBox />
                    {/* Right Block */}
                    <Box style={webStyle.rBlock}>
                        <Box sx={webStyle.form}>
                            <Typography variant="h5" style={webStyle.formHeading} className="LeagueSpartan">Email Verification</Typography>
                            <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%', textAlign: 'center' }}>
                                <Typography variant="h6" style={webStyle.subHeading}>Check your email</Typography>
                                <Box style={{ gap: '10px' }}>
                                    <Typography style={webStyle.baseLine}>We sent a Verification code to</Typography>
                                    <Typography style={webStyle.emailText}>{this.state.emailId}</Typography>
                                </Box>
                                <Button onClick={this.handleOtp} data-test-id="CheckBtn" fullWidth
                                    style={{ backgroundColor: '#FAA81D', color: 'white', padding: '16px', fontWeight: 700, fontSize: '16px' }}
                                >Enter Code</Button>
                                <Typography align="center" variant="body2" style={webStyle.loginText}>
                                    Back to <span onClick={this.handleLogin} data-test-id="logInBtn"  style={{ ...webStyle.textLinks,padding:'0', textTransform: 'none' }}>Login</span>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        width: '100%',
        fontFamily: 'League Spartan, Arial, sans-serif'
    },
    rBlock: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40%'
    },
    textLinks: {
        color: '#FAA81D',
        cursor: "pointer",
        fontWeight: 700,
    },
    formHeading: {
        fontWeight: 700,
        fontSize: '30px',
        letterSpacing: '-2',
        marginBottom: '25px'
    },
    loginText: {
        color: '#0F172A',
        fonSize: '18px',
        fontWeight: 400,
        lineHeight: '26px',
        marginTop: '5px'
    },
    form: {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: '400px',
        backgroundColor: '#FEF3C7',
        borderRadius: '24px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        gap: '25px'
    },
    subHeading: {
        fontWeight: 700,
        fontSize: '24px',
        letterSpacing: '-2',
        color: '#0F172A'
    },
    baseLine: {
        fontWeight: 400,
        fontSize: '18px',
        color: '#0F172A'
    },
    emailText: {
        fontWeight: 700,
        fontSize: '18px',
        color: '#0F172A'
    }

    // custom style definitions
};
// Customizable Area End
