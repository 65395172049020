import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
interface ResponseJson {
  messages?: Array<{ otp: string, token: string }>;
  meta?: { token: string };
}
import { setStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  openDialog: boolean;
  verificationSuccess: boolean;
  emailId: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string = "";
  postApiResponseId = "";
  verifyApiResponseId = "";
  submitButtonColor: any = configJSON.submitButtonColor;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.CustomDataMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      openDialog: false, verificationSuccess: false,
      emailId: ''
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    const messageId = message.id;
    const restApiResponseMessage = getName(MessageEnum.RestAPIResponceMessage);
    const customDataMessage = getName(MessageEnum.CustomDataMessage);
    const navigationPayloadMessage = getName(MessageEnum.NavigationPayLoadMessage);
    const restApiResponseSuccessMessage = getName(MessageEnum.RestAPIResponceSuccessMessage);
    const restApiResponseDataMessage = getName(MessageEnum.RestAPIResponceDataMessage);

    switch (messageId) {
      case navigationPayloadMessage: {
        const myData = message.getData(customDataMessage);
        const email = myData;
        this.setState({ emailId: email });
        this.handleOtpVerificationRequest(email);
        break;
      }

      case restApiResponseMessage: {
        let responseJson = message.getData(restApiResponseSuccessMessage);

        const apiResponseId = message.getData(restApiResponseDataMessage);

        if (this.postApiResponseId === apiResponseId) {
          this.handlePostApiResponse(responseJson);
        } else if (this.verifyApiResponseId === apiResponseId) {
          this.handleVerifyApiResponse(responseJson);
        }
        break;
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  handlePostApiResponse(responseJson: ResponseJson) {
    if (responseJson.meta && responseJson.meta.token) {
      this.setState({ otpAuthToken: responseJson.meta.token });
    }
  }

  handleVerifyApiResponse(responseJson: ResponseJson) {
    if (responseJson.messages && responseJson.messages[0].otp === 'Email verified') {
      setStorageData("token",responseJson.messages[0].token)
      setStorageData("role_id",1)
      this.setState({ verificationSuccess: true ,openDialog:true});
    } else {
      this.setState({ verificationSuccess: false,openDialog:true });
    }
  }

  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      //GO TO REQUEST STATE
      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : "",
      };

      const httpBody = {
        data: data,
      };

      //requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.otpAuthToken,
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp(),
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text }),
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric",
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  setOtp = (otp: string) => {
    this.setState({ otp });
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false })
  }

  reVerify = () => {
    this.handleOtpVerificationRequest(this.state.emailId)
    this.setState({ otp: ""})
    this.handleCloseDialog()
  }

  navigateToForm = ()=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'customform')
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleOtpVerificationRequest = async (emailId = this.state.emailId) => {
    const data = {
      data: {
        type: "email_verification",
        attributes: {
          email: emailId
        }
      }
    }
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.postApiResponseId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApi
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(ApiRes.id, ApiRes);
  }

  handleConfirmOTP = async () => {
    const data = {
      data: {
        otp_code: this.state.otp,
        token: this.state.otpAuthToken,
      }
    }
    const header = {
      "Content-Type": configJSON.apiVerifyOtpContentType,
    };
    const ApiRes = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.verifyApiResponseId = ApiRes.messageId;

    ApiRes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.confirmationEndPoint
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    ApiRes.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(ApiRes.id, ApiRes);
  }
  handleVerify = async () => {
    this.handleConfirmOTP();
  }

  // Customizable Area End
}
