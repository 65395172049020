import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  MobileStepper,
  Input,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  Checkbox,
  withStyles,
  CheckboxProps,
  Modal,
  List,
  ListItem,
  ListItemText,
  RadioProps,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ReactDragListView from "react-drag-listview";
import Select from "react-dropdown-select";
import CustomFormController, {
  Props,
} from "./CustomFormController.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const currency = [
  { label: "Euro (€)", value: "€" },
  { label: "USD ($)", value: "$" },
  { label: "Pound Sterling (£)", value: "£" },
];

const vehicle = [
  { label: "SUV", value: "SUV" },
  { label: "Sedan", value: "Sedan" },
  { label: "Hatchback", value: "Hatchback" },
];

const times = [
  { label: "8:00 AM", value: "8:00 AM" },
  { label: "9:00 AM", value: "9:00 AM" },
  { label: "10:00 AM", value: "10:00 AM" },
  { label: "11:00 AM", value: "11:00 AM" },
  { label: "12:00 PM", value: "12:00 PM" },
  { label: "1:00 PM", value: "1:00 PM" },
  { label: "2:00 PM", value: "2:00 PM" },
  { label: "3:00 PM", value: "3:00 PM" },
  { label: "4:00 PM", value: "4:00 PM" },
  { label: "5:00 PM", value: "5:00 PM" },
  { label: "6:00 PM", value: "6:00 PM" },
  { label: "7:00 PM", value: "7:00 PM" },
  { label: "8:00 PM", value: "8:00 PM" }
];

const radiostyles = {
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    border: "1.5px solid rgba(100, 116, 139, 1)",
    width: 20,
    height: 20,
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
  },
  checkedIcon: {
    border: "1.5px solid rgba(250, 168, 29, 1)",
    backgroundColor: 'rgba(250, 168, 29, 1)',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
  },
};


const styles = {
  toggleButtonGroup2: {
    display: 'flex',
    flexWrap: 'wrap', 
    gap: '10px',
    maxWidth: '100%', 
  } as React.CSSProperties,  
  toggleButton2: {
    borderRadius: '58px',
    textTransform: "none", 
  } as React.CSSProperties, 
};


const OrangeRadio = withStyles(radiostyles)(({ classes, ...props }: RadioProps & { classes: any }) => (
  <Radio
    className={classes.root}
    disableRipple
    color="default"
    checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
    icon={<span className={classes.icon} />}
    {...props}
  />
));
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start

  backBtnSvg = require("../assets/backBtn.svg");
  locationSvg = require("../assets/location.svg");
  location2Svg = require("../assets/location2.svg");
  deleteIconSvg = require("../assets/delete.svg");
  incBtnSvg = require("../assets/incBtn.svg");
  decBtnSvg = require("../assets/decBtn.svg");
  addDestination = require("../assets/addDestination.svg");
  addOneMore = require("../assets/addOneMore.svg");
  dragBtn = require("../assets/dragBtn.svg");
  dateSvg = require("../assets/date.svg");
  addAnother = require("../assets/addAnother.svg");
   
  OrangeCheckbox = withStyles({
    root: {
      borderRadius: "16px",
      color: "#64748B",
      '&$checked': {
        color: "#FAA81D",
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
  

  renderStep1 = () => {
    return(
      <Box className="LeagueSpartan" style={{overflowY: "auto"}} sx={{width: "90%", margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "16px", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: "1"}}>
          
          <Box className="LeagueSpartan">
            <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "column"}}>
              <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Button data-test-id="backBtn2" size="small" onClick={this.handleBack}>
                  <img src={this.backBtnSvg}/>
                </Button>
                <Typography style={{fontWeight: "700"}}>Route Preferences</Typography>
              </Box>
            <Box className="LeagueSpartan" sx={{width: "100%", alignSelf: "center"}}>
              <MobileStepper variant="progress"
                steps={8}
                position="static"
                activeStep={this.state.activeStep}
                nextButton={null}
                backButton={null}
                />         
            </Box>
            </Box>
            <Box className="LeagueSpartan" sx={webStyle.content}>

              <Typography style={webStyle.textStyle}>Do you prefer scenic routes or the fastest routes?</Typography>
              <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={this.state.data.routePref}
                  >
                  <FormControlLabel
                    onClick={(e) => this.onValueChange("routePref", "Scenic Routes")}
                    value="Scenic Routes"
                    control={<OrangeRadio />}
                    label="Scenic Routes"
                    labelPlacement="end"
                    data-test-id="scenic"
                  />
                  <FormControlLabel
                    onClick={(e) => this.onValueChange("routePref", "Fastest Routes")}
                    value="Fastest Routes"
                    control={<OrangeRadio />}
                    label="Fastest Routes"
                    labelPlacement="end"
                    data-test-id="fastest"
                  />
                </RadioGroup>
              </FormControl>
              </Box>
              <Typography style={webStyle.textStyle}>
                Are there any specific destinations or landmarks you wish to visit along the way? (optional)
              </Typography>
              {this.state.data.landmarks.map((landmark, index) => (
                <Box
                  className="LeagueSpartan"
                  key={landmark.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "10px"
                  }}
                >
                  <div style={{ position: "relative", display: "inline-block" }}>
                    <Input
                      data-test-id="landmark"
                      value={landmark.value}
                      onChange={(e) => this.onValueChange("landmarks", e.target.value, landmark.id)}
                      startAdornment={
                        <InputAdornment position="start">
                          <img src={this.locationSvg} alt="location" />
                        </InputAdornment>
                      }
                      disableUnderline={true}
                      style={webStyle.inputStyle3}
                      placeholder="Enter location"
                    />
                    <Button
                      data-test-id="delete"
                      onClick={() => this.handleDeleteLandmark(landmark.id)}
                      style={{ top: "50%", right: "10px", position: "absolute", transform: "translateY(-50%)" }}
                    >
                      <img src={this.deleteIconSvg} alt="delete" />
                    </Button>
                  </div>
                  {index === 0 && this.state.data.landmarks.length === 1 && (
                    <>
                      <Typography>or</Typography>
                      <Button
                        size="small"
                        data-test-id="addlandmark"
                        style={webStyle.buttonStyle2}
                        onClick={this.addNewLandmark}
                      >
                        <img src={this.addAnother} alt="add another" />
                      </Button>
                    </>
                  )}
                </Box>
              ))}
              {this.state.data.landmarks.length > 1 && (
                <Box
                  className="LeagueSpartan"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "10px"
                  }}
                >
                  <Button
                    size="small"
                    data-test-id="addlandmark"
                    style={webStyle.buttonStyle2}
                    onClick={this.addNewLandmark}
                  >
                    <img src={this.addAnother} alt="add another" />
                  </Button>
                </Box>
              )}
              <Typography style={webStyle.textStyle}>Are there particular types of scenic stops you’re interested in visiting? (e.g., waterfalls, mountain views) (optional)</Typography>
                <ToggleButtonGroup  
                  data-test-id="ToggleButtonGroup"               
                  value={this.state.data.scenicStops}
                  onChange={this.handleToggleScenicStop}
                  aria-label="scenic stops"
                  style={styles.toggleButtonGroup2}
                >
                  {["Waterfall", "Mountains", "Valley", "Lakes", "Beaches", "Forest", "All"].map(stop => (
                    <ToggleButton data-test-id={stop} key={stop} value={stop} aria-label={stop} style={styles.toggleButton2}>
                      {stop}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              
              <Typography style={webStyle.textStyle}>What is your preferred currency?</Typography>

              <Select
                data-test-id="curr"
                style={{ borderRadius: "8px", border: "1px solid #cbd5e1" ,paddingLeft: "16px", height: "56px", width: "360px"}}
                className="styled-select"
                placeholder="Select Currency"
                options={currency}
                values={currency.filter(
                  (currency) => currency.value === this.state.data.prefCurr
                )} 
                onChange={(values) => this.setValue('prefCurr', values)}
              />
      
              <Typography style={webStyle.textStyle}>What is your overall budget for this road trip? (optional)</Typography>
              <Input placeholder="0.00" data-test-id="ovrBudget" value={this.state.data.ovrBudget} onChange={this.handleOvrBudgetChange}
              startAdornment= {<InputAdornment position="start">{this.state.data.prefCurr || "€"}</InputAdornment>} disableUnderline= {true} style={webStyle.inputStyle} />

              <Typography style={webStyle.textStyle}>Do you have a daily budget for accommodations and activities? (optional)</Typography>
              <Input placeholder="0.00" data-test-id="dailyBudget" value={this.state.data.dailyBudget} onChange={this.handleDailyBudgetChange}
              startAdornment= {<InputAdornment position="start">{this.state.data.prefCurr || "€"}</InputAdornment>} disableUnderline= {true} style={webStyle.inputStyle} />

            </Box>
          </Box>
          <Box className="LeagueSpartan">         
          <Box className="LeagueSpartan" style={{gap: "16px"}} sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", borderTop: "1px solid rgb(226, 232, 240)"}}>
          <Button onClick={this.handleBack} data-test-id="back2" style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
              Back
          </Button>
          <Button data-test-id="next" style={webStyle.buttonStyleModal} size="small" onClick={this.handleNext}>
              Next
          </Button>
          </Box> 

          </Box>
          </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className="LeagueSpartan" width="100vw" height="auto" sx={webStyle.mainWrapper}>
        
        <style>
          {`
            .MuiMobileStepper-progress {
              background: #CBD5E1;
              width: 100%;
              border-radius: 6px 0px 0px 6px;
            }
            .MuiMobileStepper-root{
              background: 0;
              padding: 0px;
            }
            .MuiLinearProgress-barColorPrimary {
              background: #CBD5E1;
            }      
            .MuiInput-underline::before .MuiInput-underline::after{
              border: 0;
            }
            .MuiRadio-colorSecondary.Mui-checked {
              color: rgba(250, 168, 29, 1);
            }
            .MuiFormGroup-root{
              flex-direction: row;
            }
            .MuiInput-underline{
              border-bottom: 0px;
            }
                       
            .MuiButton-outlined {
              border: 1px solid rgb(250, 168, 29);
            }
            .MuiTouchRipple-root {
              display: none;
            }

            .MuiToggleButton-root{
              padding: 6px 12px 6px 12px; 
              border: 1px solid #64748B;
              font-family: inherit;
              color: #1E293B;
            }

            .MuiToggleButton-root.Mui-selected {
              background-color: #FEFCF9;
              color: #1E293B !important;
              border: 1px solid #FAA81D !important; 
            }
            .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
              border: 1px solid #64748B; 
              margin-left: 0;
            }
            .MuiListItem-gutters {
              padding:0;
              display: flex;
              flex-direction: row;
            }          
            .MuiListItemText-root {
              flex: 0;
              min-width: 300px;
              margin-top: 0;
              margin-bottom: 0;
            }
            .MuiList-padding {
              padding-top: 0;
              padding-bottom: 0;
            }
            .MuiFormControlLabel-root {
              min-width: 180px;
            }
            .MuiTypography-body1 {
              font-family: inherit;
            }
            .MuiButton-root{
              font-family: inherit;
            }
            .MuiBox-root-101{
              font-family: "League Spartan, Arial, sans-serif";
            }
            .styled-select .react-dropdown-select-dropdown {
              border-radius: 8px;
              border: 1px solid #cbd5e1;
              box-shadow: 0px 2px 4px 0px #00000026;
            }

            .styled-select .react-dropdown-select-item {
              height: 48px;
              display: flex;
              align-items: center;
            }

            .styled-select .react-dropdown-select-item:hover {
              background-color: #fef3c7;
            }

            .styled-select .react-dropdown-select-item.react-dropdown-select-item-selected {
              background-color: #fef3c7;
              color: #000000;
            }

            .styled-select .react-dropdown-select {
              border-radius: 8px;
              border: 1px solid #cbd5e1;
            }
            .css-1o5fdv0-ReactDropdownSelect {
              color: #64748B;
            }
            .css-xo05iv-DropDown{
              top: 55px;
            }
            .css-wa7x9v-DropDown{
              top: 55px;
            }
            
          `}
        </style>

        {this.state.activeStep == 0 && (
          <Box className="LeagueSpartan" style={{overflowY: "auto"}} sx={{width: "90%", margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "16px", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: "1"}}>
          
          <Box className="LeagueSpartan">
            <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "column"}}>
              <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Button data-test-id="backBtn" size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0}>
                  <img src={this.backBtnSvg}/>
                </Button>
                <Typography style={{fontWeight: "700"}}>Basic Trip Details</Typography>
              </Box>
            <Box className="LeagueSpartan" sx={{width: "100%", alignSelf: "center"}}>
              <MobileStepper variant="progress"
                steps={8}
                position="static"
                activeStep={this.state.activeStep}
                nextButton={null}
                backButton={null}
                />         
            </Box>
            </Box>
            <Box className="LeagueSpartan" sx={webStyle.content}>

              <Typography style={webStyle.textStyle}>What's the starting point of your road trip?</Typography>
              <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
              <Input data-test-id="startloc" value={this.state.data.startLoc} onChange={(e) => this.onValueChange("startLoc", e.target.value)}
              startAdornment= {<InputAdornment position="start"><img src={this.locationSvg}/></InputAdornment>} disableUnderline= {true} style={webStyle.inputStyle} placeholder="Enter location"/>
              <Typography>or</Typography>
              <Button style={{textTransform: "none",  border: 0}} size="small">
                <img src={this.location2Svg}/> <span style={{marginLeft: "4px",color: "#FAA81D",fontWeight: "700", fontSize: "14px"}}>Use my current location</span>
              </Button>
              </Box>

              <Typography style={webStyle.textStyle}>What's your final destination?</Typography>
              <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
              <Input data-test-id="finalloc" value={this.state.data.finalLoc} onChange={(e) => this.onValueChange("finalLoc", e.target.value)}
              startAdornment= {<InputAdornment position="start"><img src={this.locationSvg}/></InputAdornment>} disableUnderline= {true} style={webStyle.inputStyle} placeholder="Enter location"/>
              <Typography>or</Typography>
              <Button style={{textTransform: "none",  border: 0}} size="small">
                <span style={{color: "#FAA81D",fontWeight: "700", fontSize: "14px"}}>Choose random location</span>
              </Button>
              </Box>

              <Typography style={webStyle.textStyle}>What are your preferred travel dates? (If flexible, specify a range.)</Typography>
             
              <DatePicker
                data-test-id="date"
                disabledKeyboardNavigation                    
                selectsRange={true}
                dateFormat="MMMM d"
                startDate={this.state.data.dates[0]}
                endDate={this.state.data.dates[1]}
                onChange={this.handleDateChange}
                isClearable={false}
                placeholderText="Select dates"
                minDate={new Date()}
                customInput={<Input endAdornment= {<InputAdornment position="end"><img src={this.dateSvg}/></InputAdornment>} disableUnderline={true} style={webStyle.inputStyle}/>}
                />            
                              
            </Box>
          </Box>
          <Box className="LeagueSpartan">         
          <Box className="LeagueSpartan" style={{gap: "16px"}} sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", borderTop: "1px solid rgb(226, 232, 240)"}}>
          <Button style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
              Back
          </Button>
          <Button disabled={this.handleNextDisable()} data-test-id="next" style={webStyle.buttonStyleModal} size="small" onClick={this.handleNext}>
              Next
          </Button>
          </Box> 

          </Box>
        </Box>
        )}

        {this.state.activeStep == 1 && this.renderStep1()}

        {this.state.activeStep == 2 && (
        <Box className="LeagueSpartan" style={{overflowY: "auto"}} sx={{width: "90%", margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "16px", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: "1"}}>        
        <Box className="LeagueSpartan">
          <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "column"}}>
            <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <Button size="small" onClick={this.handleBack}>
                <img src={this.backBtnSvg}/>
              </Button>
              <Typography style={{fontWeight: "700"}}>Activity Preferences</Typography>
            </Box>
          <Box className="LeagueSpartan" sx={{width: "100%", alignSelf: "center"}}>
            <MobileStepper variant="progress"
              steps={8}
              position="static"
              activeStep={this.state.activeStep}
              nextButton={null}
              backButton={null}
              />         
          </Box>
          </Box>
          <Box className="LeagueSpartan" sx={webStyle.content}>
            <Typography style={webStyle.textStyle}>What types of activities interest you during your road trip? (optional)</Typography>
            <ToggleButtonGroup
              data-test-id="ToggleButtonGroup4" 
              value={this.state.data.activity}
              onChange={this.handleToggleActivity}     
              style={styles.toggleButtonGroup2}
            >
              {["Hiking", "Visiting beaches", "Bungee jumping", "Ropeway", "Ski", "Safari", "All"].map(stop => (
                <ToggleButton data-test-id={stop} key={stop} value={stop} aria-label={stop} style={styles.toggleButton2}
                >
                  {stop}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            
            <Typography style={webStyle.textStyle}>Would you like to include any cultural experiences in your itinerary? (optional)</Typography>
            <ToggleButtonGroup
              data-test-id="ToggleButtonGroup5" 
              value={this.state.data.cultural}
              onChange={this.handleToggleCulture}      
              style={styles.toggleButtonGroup2}
            >
              {["Museum visits", "Historical sites", "Art & Culture", "All"].map(stop => (
                <ToggleButton data-test-id={stop} key={stop} value={stop} aria-label={stop} style={styles.toggleButton2}>
                  {stop}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            
            <Typography style={webStyle.textStyle}>Are you interested in exploring local cuisines or dining experiences? (e.g., winery tours, food markets) (optional)</Typography>
              <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={this.state.data.cuisine}
                  >
                  <FormControlLabel
                    onClick={(e) => this.onValueChange("cuisine", "Yes")}
                    value="Yes"
                    control={<OrangeRadio />}
                    label="Yes"
                    labelPlacement="end"
                    data-test-id="Yes"
                  />
                  <FormControlLabel
                    onClick={(e) => this.onValueChange("cuisine", "No")}
                    value="No"
                    control={<OrangeRadio />}
                    label="No"
                    labelPlacement="end"
                    data-test-id="No"
                  />
                </RadioGroup>
              </FormControl>
              </Box>

            <Typography style={webStyle.textStyle}>Do you want to explore nightlife or entertainment options in your destinations? (optional)</Typography>
              <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={this.state.data.entertainment}
                  >
                  <FormControlLabel
                    onClick={(e) => this.onValueChange("entertainment", "Yes")}
                    value="Yes"
                    control={<OrangeRadio />}
                    label="Yes"
                    labelPlacement="end"
                    data-test-id="entertainmentYes"
                  />
                  <FormControlLabel
                    onClick={(e) => this.onValueChange("entertainment", "No")}
                    value="No"
                    control={<OrangeRadio />}
                    label="No"
                    labelPlacement="end"
                    data-test-id="entertainmentNo"
                  />
                </RadioGroup>
              </FormControl>
              </Box>
            
            <Typography style={webStyle.textStyle}>Would you like to include shopping opportunities in your itinerary? (optional)</Typography>
              <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={this.state.data.shopping}
                  >
                  <FormControlLabel
                    onClick={(e) => this.onValueChange("shopping", "Yes")}
                    value="Yes"
                    control={<OrangeRadio />}
                    label="Yes"
                    labelPlacement="end"
                    data-test-id="shoppingYes"
                  />
                  <FormControlLabel
                    onClick={(e) => this.onValueChange("shopping", "No")}
                    value="No"
                    control={<OrangeRadio />}
                    label="No"
                    labelPlacement="end"
                    data-test-id="shoppingNo"
                  />
                </RadioGroup>
              </FormControl>
              </Box>

          </Box>
        </Box>
        <Box className="LeagueSpartan">         
        <Box className="LeagueSpartan" 
        style={{gap: "16px"}} 
        sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", borderTop: "1px solid rgb(226, 232, 240)"}}>
          <Button 
          data-test-id="back2" 
          onClick={this.handleBack} 
          style={webStyle.buttonStyleModal2} 
          size="small" variant="outlined" >
              Back
          </Button>
          <Button 
          size="small" 
          style={webStyle.buttonStyleModal} 
          data-test-id="next" 
          onClick={this.handleNext}>
              Next
          </Button>
          </Box> 
        </Box>
        </Box>
        )}
      
        {this.state.activeStep == 3 && (
        <Box className="LeagueSpartan" style={{overflowY: "auto"}} sx={{width: "90%", margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "16px", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: "1"}}>        
        <Box className="LeagueSpartan">
          <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "column"}}>
            <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <Button size="small" onClick={this.handleBack}>
                <img src={this.backBtnSvg}/>
              </Button>
              <Typography style={{fontWeight: "700"}}>Accommodation & Rest</Typography>
            </Box>
          <Box className="LeagueSpartan" sx={{width: "100%", alignSelf: "center"}}>
            <MobileStepper variant="progress"
              steps={8}
              position="static"
              activeStep={this.state.activeStep}
              nextButton={null}
              backButton={null}
              />         
          </Box>
          </Box>
          <Box className="LeagueSpartan" sx={webStyle.content}>
            <Typography style={webStyle.textStyle}>What type of accommodations do you prefer? (optional)</Typography>
              <ToggleButtonGroup
                data-test-id="ToggleButtonGroup2" 
                value={this.state.data.accom}
                onChange={this.handleToggleAccomodation}
                aria-label="scenic stops"
                style={styles.toggleButtonGroup2}
              >
                {["Hotels", "Camping", "Motels", "Glamping", "All"].map(stop => (
                  <ToggleButton data-test-id={stop} key={stop} value={stop} aria-label={stop} style={styles.toggleButton2}>
                    {stop}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>

            <Typography style={webStyle.textStyle}>How often do you prefer to take rest stops  during your trip? (optional)</Typography>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
            <FormControl component="fieldset">
              <RadioGroup
                value={this.state.data.restStop}
                
              >
                <FormControlLabel
                  onClick={(e) => this.onValueChange("restStop", "Once in a day")}
                  data-test-id="once"
                  label="Once in a day"
                  labelPlacement="end"
                  control={<OrangeRadio />}
                  value="Once in a day"
                />
                <FormControlLabel
                  value="Twice in a day"
                  labelPlacement="end"
                  onClick={(e) => this.onValueChange("restStop", "Twice in a day")}
                  control={<OrangeRadio />}
                  data-test-id="twice"
                  label="Twice in a day"
                />
                <FormControlLabel
                  onClick={(e) => this.onValueChange("restStop", "Thrice in a day")}
                  control={<OrangeRadio />}
                  data-test-id="thrice"
                  label="Thrice in a day"
                  labelPlacement="end"
                  value="Thrice in a day"
                />
              </RadioGroup>
            </FormControl>
            </Box> 

          </Box>
        </Box>
        <Box className="LeagueSpartan">         
        <Box className="LeagueSpartan" 
        style={{gap: "16px"}} 
        sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", 
        paddingTop: "10px", borderTop: "1px solid rgb(226, 232, 240)"}}>
          <Button 
          data-test-id="back2" 
          onClick={this.handleBack} 
          style={webStyle.buttonStyleModal2} 
          size="small" variant="outlined" >
              Back
          </Button>
          <Button 
          size="small" 
          style={webStyle.buttonStyleModal} 
          data-test-id="next" 
          onClick={this.handleNext}>
              Next
          </Button>
          </Box> 

        </Box>
        </Box>
        )}

        {this.state.activeStep == 4 && (
        <Box className="LeagueSpartan" style={{overflowY: "auto"}} sx={{width: "90%", margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "16px", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: "1"}}>        
        <Box className="LeagueSpartan">
          <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "column"}}>
            <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <Button size="small" onClick={this.handleBack}>
                <img src={this.backBtnSvg}/>
              </Button>
              <Typography style={{fontWeight: "700"}}>Travel Preferences & Needs</Typography>
            </Box>
          <Box className="LeagueSpartan" sx={{width: "100%", alignSelf: "center"}}>
            <MobileStepper variant="progress"
              steps={8}
              position="static"
              activeStep={this.state.activeStep}
              nextButton={null}
              backButton={null}
              />         
          </Box>
          </Box>
          <Box className="LeagueSpartan" sx={webStyle.content}>
            <Typography style={webStyle.textStyle}>How many people are travelling with you? (optional)</Typography>
            <Box className="LeagueSpartan" sx={webStyle.box}>
            <Box className="LeagueSpartan" sx={{ width: "295px", height: "48px", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <Box className="LeagueSpartan" sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography style={{fontSize: "16px", fontWeight: "500"}}>Adults</Typography>
                <Typography style={{fontSize: "14px", fontWeight: "400", color: "rgba(71, 85, 105, 1)"}}>Age 13+</Typography>
              </Box>
              <Box className="LeagueSpartan">
              <Button data-test-id="decAdult" size="small" onClick={this.handleDecAdult} disabled={this.state.data.adults == 0}>
                <img src={this.decBtnSvg}/>
              </Button>
                {this.state.data.adults}
              <Button data-test-id="incAdult" size="small" onClick={this.handleIncAdult}>
                <img src={this.incBtnSvg}/>
              </Button>
              </Box>
            </Box>  
            <Box className="LeagueSpartan" sx={{ width: "295px", height: "48px", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <Box className="LeagueSpartan" sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography style={{fontSize: "16px", fontWeight: "500"}}>Children</Typography>
                <Typography style={{fontSize: "14px", fontWeight: "400", color: "rgba(71, 85, 105, 1)"}}>Age 2-12</Typography>
              </Box>
              <Box className="LeagueSpartan">
              <Button data-test-id="decChild" size="small" onClick={this.handleDecChild} disabled={this.state.data.child == 0}>
                <img src={this.decBtnSvg}/>
              </Button>
                {this.state.data.child}
              <Button data-test-id="incChild" size="small" onClick={this.handleIncChild}>
                <img src={this.incBtnSvg}/>
              </Button>
              </Box>
            </Box> 
            </Box>

            <Typography style={webStyle.textStyle}>Do you have any specific needs or preferences to consider for your travel companions? (optional)</Typography>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", gap: "20px"}}>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "column"}}>
            <FormControlLabel
              data-test-id="access"
              control={
                <this.OrangeCheckbox
                name="Accessibility requirements"
                onChange={this.handleCheckboxChange}
                checked={this.state.data.travelCompanion.includes("Accessibility requirements")}
              /> }
            label="Accessibility requirements"
            />
            <FormControlLabel
            data-test-id="petfriendly"
              control={
                <this.OrangeCheckbox
                  name="Pet friendly options"
                  onChange={this.handleCheckboxChange}
                  checked={this.state.data.travelCompanion.includes("Pet friendly options")}
                /> }
              label="Pet friendly options"
            />
            </Box>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "column"}}>
            <FormControlLabel
            data-test-id="familyfriendly"
            control={
              <this.OrangeCheckbox
                name="Family friendly options"
                onChange={this.handleCheckboxChange}
                checked={this.state.data.travelCompanion.includes("Family friendly options")}
              />  }
            label="Family friendly options"
            />
            <FormControlLabel 
            data-test-id="couplefriendly"
            control={
                <this.OrangeCheckbox
                  name="Couple friendly options"
                  onChange={this.handleCheckboxChange}
                  checked={this.state.data.travelCompanion.includes("Couple friendly options")}
                /> }
              label="Couple friendly options" />
            </Box>
            </Box> 

            <Typography style={webStyle.textStyle}>Do you have any dietary preferences or restrictions? (optional)</Typography>
              <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={this.state.data.dietPref}
                  >
                  <FormControlLabel
                    data-test-id="dietYes"
                    onClick={(e) => this.onValueChange("dietPref", "Yes")}
                    value="Yes"
                    control={<OrangeRadio />}
                    label="Yes"
                    labelPlacement="end"
      
                  />
                  <FormControlLabel
                    data-test-id="dietNo"
                    onClick={(e) => this.onValueChange("dietPref", "No")}
                    value="No"
                    control={<OrangeRadio />}
                    label="No"
                    labelPlacement="end"               
                  />
                </RadioGroup>
              </FormControl>
              </Box>
              {this.state.data.dietPref == "Yes" ? <Box className="LeagueSpartan">
                <Input data-test-id="dietInput" value={this.state.data.dietPrefInfo} onChange={(e) => this.onValueChange("dietPrefInfo", e.target.value)} style={webStyle.inputStyle} disableUnderline={true} placeholder="Enter your preferences here..."></Input>
              </Box> : <Box className="LeagueSpartan"></Box>}
          </Box>
        </Box>
        <Box className="LeagueSpartan">   
        <Box className="LeagueSpartan" style={{gap: "16px"}} sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", borderTop: "1px solid rgb(226, 232, 240)"}}>
          <Button  onClick={() => this.handleSkip(1)} data-test-id="skip1" style={{textTransform: "none", color: "rgba(250, 168, 29, 1)"}} size="small">
              Skip
          </Button>
          <Button onClick={this.handleBack} data-test-id="back2" style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
              Back
          </Button>
          <Button data-test-id="next" style={webStyle.buttonStyleModal} size="small" onClick={this.handleNext}>
              Next
          </Button>
        </Box>      

        </Box>
        </Box>
        )}

        {this.state.activeStep == 5 && (
        <Box className="LeagueSpartan" style={{overflowY: "auto"}} sx={{width: "90%", margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "16px", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: "1"}}>        
        <Box className="LeagueSpartan">
          <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "column"}}>
            <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <Button size="small" onClick={this.handleBack}>
                <img src={this.backBtnSvg}/>
              </Button>
              <Typography style={{fontWeight: "700"}}>Driving & Vehicle Needs</Typography>
            </Box>
          <Box className="LeagueSpartan" sx={{width: "100%", alignSelf: "center"}}>
            <MobileStepper variant="progress"
              steps={8}
              position="static"
              activeStep={this.state.activeStep}
              nextButton={null}
              backButton={null}
              />         
          </Box>
          </Box>
          <Box className="LeagueSpartan" sx={webStyle.content}>
            <Typography style={webStyle.textStyle}>What's your preferred daily driving duration? (optional)</Typography>
            <Box className="LeagueSpartan" sx={webStyle.box2}>
            <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "column"}}>
              <Typography style={webStyle.textStyle}>Start</Typography>
              <Select
                data-test-id="timeStart"
                style={{borderRadius: "8px",width: "161px", height: "56px", border: "1px solid rgba(203, 213, 225, 1)", padding: "10px 8px 10px 8px"}}
                className="styled-select"
                placeholder=""
                options={times}
                values={times.filter(
                  (times) => times.value === this.state.data.timeStart
                )} 
                onChange={(values) => this.setValue('timeStart', values)}
              />
            </Box>  
            <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "column"}}>
            <Typography style={webStyle.textStyle}>End</Typography>

            <Select
                data-test-id="timeEnd"
                style={{borderRadius: "8px",width: "161px", height: "56px", border: "1px solid rgba(203, 213, 225, 1)", padding: "10px 8px 10px 8px"}}
                className="styled-select"
                placeholder=""
                options={times}
                values={times.filter(
                  (times) => times.value === this.state.data.timeEnd
                )} 
                onChange={(values) => this.setValue('timeEnd', values)}
              />
            </Box> 
            </Box>
            
            <Typography style={webStyle.textStyle}>Do you have any preferences for the type of rental vehicle? (optional)</Typography>
              <Select
                data-test-id="prefVehicle"
                style={{ borderRadius: "8px", border: "1px solid #cbd5e1" ,paddingLeft: "16px", height: "56px", width: "360px"}}
                className="styled-select"
                placeholder=""
                options={vehicle}
                values={vehicle.filter(
                  (vehicle) => vehicle.value === this.state.data.prefVehicle
                )} 
                onChange={(values) => this.setValue('prefVehicle', values)}
              />

            <Typography style={webStyle.textStyle}>Are any additional car features required? (optional)</Typography>
            <ToggleButtonGroup
                data-test-id="ToggleButtonGroup3" 
                value={this.state.data.carFeatures}
                onChange={this.handleToggleCarFeatures}
                aria-label="car features"
                style={styles.toggleButtonGroup2}
              >
                {["Air conditioning", "GPS", "Stability control", "Child seat", "Roof box", "Bike rack", "Heated seats", "Sunroof"].map(stop => (
                  <ToggleButton data-test-id={stop} key={stop} value={stop} aria-label={stop} style={styles.toggleButton2}>
                    {stop}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
          </Box>
        </Box>
        <Box className="LeagueSpartan">         
        <Box className="LeagueSpartan" style={{gap: "16px"}} sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", borderTop: "1px solid rgb(226, 232, 240)"}}>
          <Button onClick={() => this.handleSkip(2)} data-test-id="skip1" style={{textTransform: "none", color: "rgba(250, 168, 29, 1)"}} size="small">
              Skip
          </Button>
          <Button onClick={this.handleBack} data-test-id="back2" style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
              Back
          </Button>
          <Button data-test-id="next" style={webStyle.buttonStyleModal} size="small" onClick={this.handleNext}>
              Next
          </Button>
        </Box>

        </Box>
        </Box>
        )}

        {this.state.activeStep == 6 && (
        <Box className="LeagueSpartan" style={{overflowY: "auto"}} sx={{width: "90%", margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "16px", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: "1"}}>        
        <Box className="LeagueSpartan">
          <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "column"}}>
            <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <Button size="small" onClick={this.handleBack}>
                <img src={this.backBtnSvg}/>
              </Button>
              <Typography style={{fontWeight: "700"}}>Personalisation & Flexibility</Typography>
            </Box>
          <Box className="LeagueSpartan" sx={{width: "100%", alignSelf: "center"}}>
            <MobileStepper variant="progress"
              steps={8}
              position="static"
              activeStep={this.state.activeStep}
              nextButton={null}
              backButton={null}
              />         
          </Box>
          </Box>
          <Box className="LeagueSpartan" sx={webStyle.content}>
            <Typography style={webStyle.textStyle}>How flexible are you with detours or spontaneous activities? (optional)</Typography>
            <Input data-test-id="flexibility" value={this.state.data.flexibility} onChange={(e) => this.onValueChange("flexibility", e.target.value)} style={webStyle.inputStyle} disableUnderline={true} placeholder="Enter here"></Input>
            
            
            <Typography style={webStyle.textStyle}>Are there any specific locations where you’d like to have leisure days or relax? (optional)</Typography>
            {this.state.data.landmarks[0].value ? 
            (<ToggleButtonGroup
              data-test-id="ToggleButtonGroup7" 
              value={this.state.data.leisureDaysorStops}
              onChange={this.handleToggleLeisure}     
              style={styles.toggleButtonGroup2}
            >
              {this.state.data.landmarks.map(landmark => (
                <ToggleButton data-test-id={landmark.value} key={landmark.id} value={landmark.value} style={styles.toggleButton2}>
                  {landmark.value}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            )
            
            : 
            
            (<Box className="LeagueSpartan" >
              <Button data-test-id="addDestination" size="small" onClick={this.handleOpen}>
              <img src = {this.addDestination}/>
              </Button>
              <Modal
                open={this.state.open}
                onClose={this.handleClose}
              >
                <Box className="LeagueSpartan" sx={webStyle.paper}>

                  <Box className="LeagueSpartan" sx={{borderBottom: "1px solid #E2E8F0", display: "flex", justifyContent: "space-between"}} style={webStyle.modalHead}>
                  <Typography style={{fontWeight: "700", fontSize: "24px", marginLeft: "30px", alignSelf: "center"}}>Add new destination</Typography>
                  <Button data-test-id="closeModal" size="small" onClick={this.handleClose}><img src={this.deleteIconSvg}/></Button>
                  </Box>

                  <Box className="LeagueSpartan" sx={{ display: "flex", marginLeft: "30px", flexDirection: "column"}} >
                  <Typography style={webStyle.textStyle2}>Are there any specific destinations or landmarks you wish to visit along the way? (optional)</Typography>
                  {this.state.landmarks.map(landmark => (
                    <Box className="LeagueSpartan" key={landmark.id} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }}>
                      <div style={{ position: "relative", display: "inline-block" }}>
                      <Input
                        data-test-id="landmark"
                        value={landmark.value}
                        onChange={(e) => this.onModalValueChange(e.target.value, landmark.id)}
                        startAdornment={<InputAdornment position="start"><img src={this.locationSvg} alt="location" /></InputAdornment>}
                        disableUnderline={true}
                        style={webStyle.inputStyle}
                        placeholder="Enter location"
                      />
                    <Button data-test-id="delete" onClick={() => this.handleModalDeleteLandmark(landmark.id)} style={{ top: "50%", right: "10px", position: "absolute", transform: "translateY(-50%)" }}>
                      <img src={this.deleteIconSvg} alt="delete" />
                    </Button>
                    </div>
                   
                    </Box>
                  ))}
                  <Button data-test-id="addlandmark" size="small" style={webStyle.buttonStyle3} onClick={this.addModalLandmark}>
                    <img src={this.addOneMore}/>
                  </Button>            
                  </Box>

                  <Box className="LeagueSpartan" style={{gap: "16px"}} sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", padding: "20px", borderTop: "1px solid rgb(226, 232, 240)"}}>
                    <Button onClick={this.handleClose} data-test-id="cancel" style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
                        Cancel
                    </Button>
                    <Button data-test-id="save" style={webStyle.buttonStyleModal} size="small" onClick={this.updateLandmarks}>
                        Save
                    </Button>
                  </Box>

                </Box>
              </Modal>
            </Box>)}

            <Typography style={webStyle.textStyle}>Is this road trip celebrating a special occasion? (optional)</Typography>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
            <FormControl component="fieldset">
              <RadioGroup
                value={this.state.data.occassion}
                >
                <FormControlLabel
                  onClick={(e) => this.onValueChange("occassion", "Yes")}
                  value="Yes"
                  control={<OrangeRadio />}
                  label="Yes"
                  labelPlacement="end"
                  data-test-id="occYes"
                />
                <FormControlLabel
                  onClick={(e) => this.onValueChange("occassion", "No")}
                  value="No"
                  control={<OrangeRadio />}
                  label="No"
                  labelPlacement="end"
                  data-test-id="occNo"
                />
              </RadioGroup>
            </FormControl>
            </Box>
          </Box>
        </Box>
        <Box className="LeagueSpartan">         
        <Box className="LeagueSpartan" style={{gap: "16px"}} sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", borderTop: "1px solid rgb(226, 232, 240)"}}>
          <Button onClick={() => this.handleSkip(3)} data-test-id="skip1" style={{textTransform: "none", color: "rgba(250, 168, 29, 1)"}} size="small">
              Skip
          </Button>
          <Button onClick={this.handleBack} data-test-id="back2" style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
              Back
          </Button>
          <Button data-test-id="next" style={webStyle.buttonStyleModal} size="small" onClick={this.handleNext}>
              Next
          </Button>
        </Box>

        </Box>
        </Box>
        )}

        {this.state.activeStep == 7 && (
        <Box className="LeagueSpartan" style={{overflowY: "auto"}} sx={{width: "90%", margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "16px", padding: "2%", display: "flex", flexDirection: "column", justifyContent: "space-between", flexGrow: "1"}}>        
        <Box className="LeagueSpartan">
          <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "column"}}>
            <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <Button data-test-id="back2" size="small" onClick={this.handleBack}>
                <img src={this.backBtnSvg}/>
              </Button>
              <Typography style={{fontWeight: "700"}}>Review Information</Typography>
            </Box>
          <Box className="LeagueSpartan" sx={{width: "100%", alignSelf: "center"}}>
            <MobileStepper variant="progress"
              steps={8}
              position="static"
              activeStep={this.state.activeStep}
              nextButton={null}
              backButton={null}
              />         
          </Box>
          </Box>
          <Box className="LeagueSpartan" sx={webStyle.content2}>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Places</Typography>
            <ReactDragListView onDragEnd={this.onDragEnd} nodeSelector="li" handleSelector="button">
              <List>
                {this.state.data.landmarks.map((item, index) => (
                  <ListItem key={item.id}>
                    <ListItemText>
                      <Input
                        disableUnderline={true}
                        style={webStyle.inputStyle2}
                        value={item.value}
                        onChange={(e) => {
                          const updatedLandmarks = [...this.state.data.landmarks];
                          updatedLandmarks[index].value = e.target.value;
                          this.setState({
                            data: {
                              ...this.state.data,
                              landmarks: updatedLandmarks,
                            },
                          });
                        }}
                      />
                    </ListItemText>
                    <Button size="small">
                      <img src={this.dragBtn}/>
                    </Button>
                  </ListItem>
                ))}
              </List>
            </ReactDragListView>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection:"row", gap: "10px" }}>
            <Button size="small" style={{textTransform: "none", textDecoration: "underline", color: "#FAA81D", fontSize: "16px"}} onClick={this.addNewLandmark}>Add a new location</Button>
            <Button data-test-id="reset" size="small" style={{textTransform: "none", textDecoration: "underline", color: "#64748B", fontSize: "16px"}} onClick={this.handleReset}>Reset</Button>
            </Box>
            </Box>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Dates</Typography>            
            <DatePicker  disabledKeyboardNavigation                    
                startDate={this.state.data.dates[0]} selectsRange={true}
                data-test-id="date"
                placeholderText="Select dates" minDate={new Date()}
                dateFormat="MMMM d"
                onChange={this.handleDateChange}
                isClearable={false}
                endDate={this.state.data.dates[1]}
                customInput={<Input endAdornment= {<InputAdornment position="end"><img src={this.dateSvg}/></InputAdornment>} disableUnderline={true} style={webStyle.inputStyle}/>}
                /> 
            </Box>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
              <Typography style={webStyle.textStyle}>Overall budget</Typography>
              <Input placeholder="0.00" 
              data-test-id="ovrBudgetR" 
              onChange={this.handleOvrBudgetChange}
              value={this.state.data.ovrBudget} 
              startAdornment= {<InputAdornment position="start">{this.state.data.prefCurr || "€"}</InputAdornment>} disableUnderline= {true} style={webStyle.inputStyle} />
            </Box>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
              <Typography style={webStyle.textStyle}>Daily budget</Typography>
              <Input placeholder="0.00" 
              value={this.state.data.dailyBudget} 
              data-test-id="dailyBudgetR" 
              onChange={this.handleDailyBudgetChange}
              startAdornment= {<InputAdornment position="start">{this.state.data.prefCurr || "€"}</InputAdornment>} disableUnderline= {true} style={webStyle.inputStyle} />
            </Box>
            
            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Route Preference</Typography>
            <Box className="LeagueSpartan" style={{display:"flex", 
            alignItems:"center", 
            flexDirection: "row", 
            gap: "10px"}}>
            <FormControl component="fieldset">
              <RadioGroup
                value={this.state.data.routePref}
                >
                <FormControlLabel
                  onClick={(e) => this.onValueChange("routePref", "Scenic Routes")}
                  control={<OrangeRadio />}
                  value="Scenic Routes"
                  labelPlacement="end"
                  label="Scenic Routes"
                  data-test-id="scenicR"
                />
                <FormControlLabel
                  onClick={(e) => this.onValueChange("routePref", "Fastest Routes")}
                  control={<OrangeRadio />}
                  value="Fastest Routes"
                  labelPlacement="end"
                  label="Fastest Routes"
                  data-test-id="fastestR"
                />
              </RadioGroup>
            </FormControl>
            </Box>
            </Box>
            
            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Scenic stops preference</Typography>
            <ToggleButtonGroup  
              data-test-id="ToggleButtonGroupR"               
              value={this.state.data.scenicStops}
              aria-label="scenic stops"
              onChange={this.handleToggleScenicStop}
              style={styles.toggleButtonGroup2}
            >
              {["Waterfall", "Mountains", "Valley", "Lakes", "Beaches", "Forest", "All"].map(stop => (
                <ToggleButton 
                value={stop} aria-label={stop} 
                key={stop} 
                data-test-id={stop} 
                style={styles.toggleButton2}>
                  {stop}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            </Box>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Types of activities</Typography>
            <ToggleButtonGroup
              value={this.state.data.activity}
              data-test-id="ToggleButtonGroup4R" 
              style={styles.toggleButtonGroup2}
              onChange={this.handleToggleActivity}     
            >
              {["Hiking", "Visiting beaches", "Bungee jumping", "Ropeway", "Ski", "Safari", "All"].map(stop => (
                <ToggleButton 
                aria-label={stop} 
                value={stop} 
                data-test-id={stop} 
                key={stop} 
                style={styles.toggleButton2}>
                  {stop}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            </Box>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Cultural activities</Typography>
            <ToggleButtonGroup
              onChange={this.handleToggleCulture}      
              data-test-id="ToggleButtonGroup5R" 
              value={this.state.data.cultural}
              style={styles.toggleButtonGroup2}
            >
              {["Museum visits", "Historical sites", "Art & Culture", "All"].map(stop => (
                <ToggleButton 
                aria-label={stop} 
                value={stop} 
                key={stop} 
                data-test-id={stop} 
                style={styles.toggleButton2}>
                  {stop}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            </Box>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Local cuisines</Typography>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
            <FormControl component="fieldset">
              <RadioGroup
                value={this.state.data.cuisine}
                >
                <FormControlLabel
                  onClick={(e) => this.onValueChange("cuisine", "Yes")}
                  control={<OrangeRadio />}
                  value="Yes"
                  labelPlacement="end"
                  label="Yes"
                  data-test-id="YesR"
                />
                <FormControlLabel
                  onClick={(e) => this.onValueChange("cuisine", "No")}
                  control={<OrangeRadio />}
                  data-test-id="NoR"
                  value="No"
                  labelPlacement="end"
                  label="No"
                />
              </RadioGroup>
            </FormControl>
            </Box>
            </Box>
            
            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Explore nightlife</Typography>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
            <FormControl component="fieldset">
              <RadioGroup
                value={this.state.data.entertainment}
                >
                <FormControlLabel
                  onClick={(e) => this.onValueChange("entertainment", "Yes")}
                  labelPlacement="end"
                  value="Yes"
                  label="Yes"
                  control={<OrangeRadio />}
                  data-test-id="entertainmentYesR"
                />
                <FormControlLabel
                  onClick={(e) => this.onValueChange("entertainment", "No")}
                  labelPlacement="end"
                  value="No"
                  label="No"
                  data-test-id="entertainmentNoR"
                  control={<OrangeRadio />}
                />
              </RadioGroup>
            </FormControl>
            </Box>
            </Box>
            
            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Shopping opportunities</Typography>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
            <FormControl component="fieldset">
              <RadioGroup
                value={this.state.data.shopping}
                >
                <FormControlLabel
                  onClick={(e) => this.onValueChange("shopping", "Yes")}
                  value="Yes"
                  label="Yes"
                  control={<OrangeRadio />}
                  data-test-id="shoppingYesR"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="No"
                  onClick={(e) => this.onValueChange("shopping", "No")}
                  label="No"
                  control={<OrangeRadio />}
                  data-test-id="shoppingNoR"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            </Box>
            </Box>
            
            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Types of accommodation</Typography>
            <ToggleButtonGroup
              aria-label="scenic stops"
              data-test-id="ToggleButtonGroup2R" 
              value={this.state.data.accom}
              onChange={this.handleToggleAccomodation}
              style={styles.toggleButtonGroup2}
            >
              {["Hotels", "Camping", "Motels", "Glamping", "All"].map(stop => (
                <ToggleButton 
                value={stop} 
                key={stop} 
                aria-label={stop} 
                data-test-id={stop} 
                style={styles.toggleButton2}>
                  {stop}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            </Box>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Rest stops</Typography>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
            <FormControl component="fieldset">
              <RadioGroup
                value={this.state.data.restStop}          
              >
                <FormControlLabel
                  data-test-id="onceR"
                  onClick={(e) => this.onValueChange("restStop", "Once in a day")}
                  control={<OrangeRadio />}
                  value="Once in a day"
                  labelPlacement="end"
                  label="Once in a day"
                />
                <FormControlLabel
                  onClick={(e) => this.onValueChange("restStop", "Twice in a day")}
                  control={<OrangeRadio />}
                  data-test-id="twiceR"
                  value="Twice in a day"
                  labelPlacement="end"
                  label="Twice in a day"
                />
                <FormControlLabel
                  onClick={(e) => this.onValueChange("restStop", "Thrice in a day")}
                  label="Thrice in a day"
                  value="Thrice in a day"
                  control={<OrangeRadio />}
                  data-test-id="thriceR"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            </Box> 
            </Box>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>People travelling (excluding driver)</Typography>
            <Box className="LeagueSpartan" sx={webStyle.box}>
            <Box className="LeagueSpartan" sx={{ width: "295px", 
            flexDirection: "row", 
            display: "flex", 
              height: "48px", 
            justifyContent: "space-between"}}>
              <Box className="LeagueSpartan" sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography style={{fontSize: "16px", fontWeight: "500"}}>Adults</Typography>
                <Typography style={{fontSize: "14px", fontWeight: "400", color: "rgba(71, 85, 105, 1)"}}>Age 13+</Typography>
              </Box>
              <Box className="LeagueSpartan">
              <Button 
              onClick={this.handleDecAdult} 
              data-test-id="decAdultR" 
              size="small" 
              disabled={this.state.data.adults == 0}>
                <img src={this.decBtnSvg}/>
              </Button>
                {this.state.data.adults}
              <Button 
              size="small" 
              data-test-id="incAdultR" 
              onClick={this.handleIncAdult}>
                <img src={this.incBtnSvg}/>
              </Button>
              </Box>
            </Box>  
            <Box className="LeagueSpartan" sx={{ width: "295px", 
            flexDirection: "row", 
            display: "flex", 
            height: "48px", 
            justifyContent: "space-between"}}>
              <Box className="LeagueSpartan" sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography style={{fontSize: "16px", fontWeight: "500"}}>Children</Typography>
                <Typography style={{fontSize: "14px", fontWeight: "400", color: "rgba(71, 85, 105, 1)"}}>Age 2-12</Typography>
              </Box>
              <Box className="LeagueSpartan">
              <Button 
              onClick={this.handleDecChild} 
              size="small" 
              data-test-id="decChildR" 
              disabled={this.state.data.child == 0}>
                <img src={this.decBtnSvg}/>
              </Button>
                {this.state.data.child}
              <Button 
              size="small" 
              data-test-id="incChildR" 
              onClick={this.handleIncChild}>
                <img src={this.incBtnSvg}/>
              </Button>
              </Box>
            </Box> 
            </Box>
            </Box>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Do you have any specific needs or preferences to consider for your travel companions? (optional)</Typography>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row"}}>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "column"}}>
            <FormControlLabel
              data-test-id="accessR"
              label="Accessibility requirements"
              control={
                <this.OrangeCheckbox
                onChange={this.handleCheckboxChange}
                checked={this.state.data.travelCompanion.includes("Accessibility requirements")}
                name="Accessibility requirements"
              /> }
            />
            <FormControlLabel
            label="Pet friendly options"
            data-test-id="petfriendlyR"
            control={
              <this.OrangeCheckbox
                checked={this.state.data.travelCompanion.includes("Pet friendly options")}
                name="Pet friendly options"
                onChange={this.handleCheckboxChange}
              /> }
            />
            </Box>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "column"}}>
            <FormControlLabel
            label="Family friendly options"
            data-test-id="familyfriendlyR"
            control={
              <this.OrangeCheckbox
                name="Family friendly options"
                checked={this.state.data.travelCompanion.includes("Family friendly options")}
                onChange={this.handleCheckboxChange}
              />  }
            />
            <FormControlLabel 
            control={
                <this.OrangeCheckbox
                  name="Couple friendly options"
                  checked={this.state.data.travelCompanion.includes("Couple friendly options")}
                  onChange={this.handleCheckboxChange}
                /> }
            data-test-id="couplefriendlyR"
              label="Couple friendly options" />
            </Box>
            </Box> 
            </Box>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Time of travelling during day</Typography>
            <Box className="LeagueSpartan" sx={webStyle.box2}>
            <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "column"}}>
              <Typography style={webStyle.textStyle}>Start</Typography>
              <Select
                className="styled-select"
                style={{borderRadius: "8px",width: "161px", height: "56px", border: "1px solid rgba(203, 213, 225, 1)", padding: "10px 8px 10px 8px"}}
                options={times}
                data-test-id="timeStart"
                placeholder=""
                values={times.filter(
                  (times) => times.value === this.state.data.timeStart
                )} 
                onChange={(values) => this.setValue('timeStart', values)}
              />
            </Box>  
            <Box className="LeagueSpartan" sx={{display: "flex", flexDirection: "column"}}>
            <Typography style={webStyle.textStyle}>End</Typography>
            <Select
                options={times}
                style={{borderRadius: "8px",width: "161px", height: "56px", 
                border: "1px solid rgba(203, 213, 225, 1)", padding: "10px 8px 10px 8px"}}
                placeholder=""
                className="styled-select"
                data-test-id="timeEnd"
                values={times.filter(
                  (times) => times.value === this.state.data.timeEnd
                )} 
                onChange={(values) => this.setValue('timeEnd', values)}
              />
            </Box> 
            </Box>
            </Box>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Type of Vehicle</Typography>
            <Select
                data-test-id="prefVehicle"
                style={{ borderRadius: "8px", border: "1px solid #cbd5e1" ,paddingLeft: "16px", height: "56px", width: "360px"}}
                className="styled-select"
                placeholder=""
                options={vehicle}
                values={vehicle.filter(
                  (vehicle) => vehicle.value === this.state.data.prefVehicle
                )} 
                onChange={(values) => this.setValue('prefVehicle', values)}
              />
            </Box>

            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Vehicles features</Typography>
            <ToggleButtonGroup
                aria-label="car features"
                data-test-id="ToggleButtonGroup3R" 
                onChange={this.handleToggleCarFeatures}
                value={this.state.data.carFeatures}
                style={styles.toggleButtonGroup2}
              >
                {["Air conditioning", "GPS", "Stability control", "Child seat", "Roof box", "Bike rack", "Heated seats", "Sunroof"].map(stop => (
                  <ToggleButton 
                  key={stop} value={stop} 
                  aria-label={stop} 
                  data-test-id={stop} 
                  style={styles.toggleButton2}>
                    {stop}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
            </Box>  
            
            <Box style={{display: "flex", flexDirection: "column", gap:"12px"}}>
            <Typography style={webStyle.textStyle}>Any special occasion?</Typography>
            <Box className="LeagueSpartan" style={{display:"flex", flexDirection: "row", alignItems:"center", gap: "10px"}}>
            <FormControl component="fieldset">
              <RadioGroup
                value={this.state.data.occassion}
                >
                <FormControlLabel
                  onClick={(e) => this.onValueChange("occassion", "Yes")}
                  control={<OrangeRadio />}
                  value="Yes"
                  labelPlacement="end"
                  data-test-id="occYesR"
                  label="Yes"
                />
                <FormControlLabel
                  control={<OrangeRadio />}
                  onClick={(e) => this.onValueChange("occassion", "No")}
                  label="No"
                  data-test-id="occNoR"
                  labelPlacement="end"
                  value="No"
                />
              </RadioGroup>
            </FormControl>
            </Box>
            </Box>
          </Box>
        </Box>
        <Box className="LeagueSpartan">         
        <Box className="LeagueSpartan" style={{gap: "16px"}} sx={{display: "flex", flexDirection: "row",justifyContent: "flex-end", paddingTop: "10px", borderTop: "1px solid rgb(226, 232, 240)"}}>
          <Button onClick={this.handleBack} style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
              Back
          </Button>
          <Button data-test-id="confirm" style={webStyle.buttonStyleModal} size="small">
              Confirm
          </Button>
          </Box> 
        </Box>
        </Box>
        )}
        
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  content: {
    minHeight: "500px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "1.5%"
  },
  content2: {
    minHeight: "500px",
    display: "flex",
    flexDirection: "column",
    gap: "22px",
    padding: "1.5%"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: 'absolute',
    width: "800px",
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    gap: "40px",
    borderRadius:"8px 8px 32px 8px",
    opacity: "0px",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  modalHead: {
    width: "100%",
    height: "80px",
    gap: "10px",
  },

  box: {
    width: "50%",
    display: "flex", 
    flexDirection: "row", 
    justifyContent: "space-between"
  },

  box2: {
    width: "50%",
    display: "flex", 
    flexDirection: "row", 
    gap: "5%",
  },

  mainWrapper: {
    fontFamily: "League Spartan, Arial, sans-serif",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  buttonStyle: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "360px",
    height: "56px",
    color: "white",
    fontWeight: "700",
  },

  buttonStyle2: {
    fontSize: "16px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
  },
  
  buttonStyle3: {
    width: "148px",
    fontSize: "16px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
  },

  buttonStyleModal: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "120px",
    height: "56px",
    color: "white",
    fontWeight: "700",
  },

  buttonStyleModal2: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "120px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
  },


  inputStyle: {
    width: "360px",
    height: "56px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid rgba(203, 213, 225, 1)",
  },

  inputStyle2: {
    width: "288px",
    height: "56px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid rgba(203, 213, 225, 1)",
  },

  inputStyle3: {
    width: "360px",
    height: "56px",
    padding: "10px 16px 10px 16px",
    paddingRight: "40px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid rgba(203, 213, 225, 1)",
  },

  textStyle: {
    fontSize: "16px",
    fontWeight: "500",
    color: "rgba(51,65,85,1)",
  },
  textStyle2: {
    fontSize: "16px",
    fontWeight: "500",
    color: "rgba(51,65,85,1)",
    fontFamily: "League Spartan, Arial, sans-serif",
  }
};
// Customizable Area End
