import React, { CSSProperties } from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  TextField,
  Paper,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  RadioGroup,
  Radio,
  Switch,
  Checkbox,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ic_background, ic_check, ic_delete, ic_dollar, ic_error, ic_euro, ic_logout, ic_notifications, ic_pound, ic_privacy, ic_profile, ic_settings } from "./assets";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
import { ProfileField } from "./Settings2Controller";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup"
import OTPInput from "react-otp-input";
import { styled, } from "@material-ui/styles";
import { MdCheck } from "react-icons/md";

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  confirm_email: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Emails must match')
    .required('Confirm Email is required'),
});

const passwordValidationSchema = Yup.object().shape({
  old_password: Yup.string()
    .required('Old password is required'),
  new_password: Yup.string()
    .min(8, 'New password must be at least 8 characters long')
    .required('New password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Please confirm your new password'),
})

const styles: {
  toggleButtonGroup: CSSProperties;
  toggleButton: CSSProperties;
} = {
  toggleButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    flexWrap: 'wrap',
  },
  toggleButton: {
    borderRadius: '58px',
    padding: '6px 18px 6px 18px',
    color: '#64748B',
    border: '1px solid #64748B'
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#FAA81D",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
  },
});

const fields: ProfileField[] = [
  'Change name',
  'Change email',
  'Change password',
  'Preferred destination',
  'Preferred activities',
  'Preferred accommodation'
]


const OrangeCheckbox = styled(Checkbox)({
  color: "#FAA81D",
  '&.Mui-checked': {
    color: "#FAA81D",
  },
});

export const SwitchBtn = styled(Switch)({
  width: 42,
  height: 28,
  padding: 0,
  margin: theme.spacing(1),
  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: '#FAA81D',
        opacity: 1,
        border: 'none',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#FAA81D',
      border: '6px solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    height: 'auto',
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
})

// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import { AddCircleOutline, ArrowBack, Clear, Place, Visibility, VisibilityOff } from "@material-ui/icons";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  ChangeName = () => {
    return (
      <Paper elevation={2} >
        <Box display={"flex"} flexDirection={"column"} padding={"20px 20px 0px 20px"}>
          <Typography
            className="LeagueSpartan"
            style={{ fontWeight: 700, fontSize: '20px', color: '#334155', lineHeight: '28px', }}
          >
            Change name
          </Typography>

          <Box
            display={'flex'}
            flexDirection={'row'}
            width={'100%'}
            style={{ padding: '20px 0px', justifyContent: 'space-between' }}
          >
            <Box
              style={{ width: '44%' }}
            >
              <Typography className="LeagueSpartan" style={webStyle.nameHeadings}>First name</Typography>
              <TextField
                data-test-id="firstNameTestId"
                variant="outlined"
                name="firstName"
                type="text"
                fullWidth
                value={this.state.firstName}
                onChange={this.handleFirstNameChange}
              />
            </Box>
            <Box
              style={{ width: '44%' }}
            >
              <Typography className="LeagueSpartan" style={webStyle.nameHeadings}>Last name</Typography>
              <TextField
                variant="outlined"
                data-test-id="lastNameTestId"
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleLastNameChange}
                fullWidth
              />
            </Box>

          </Box>
        </Box>

        <Box
          borderTop={'1px solid #E2E8F0'}
          display={'flex'} padding={'20px'} justifyContent={'end'}>
          <Button
            onClick={this.handleNameChange}
            data-test-id="changeNameBtn"
            style={{ backgroundColor: '#FAA81D', color: '#fff', borderRadius: '8px', fontWeight: 700, fontSize: '16px', lineHeight: '24px', padding: '16px 24px' }}
          >
            SAVE
          </Button>
        </Box>
      </Paper>
    )
  }

  ChangeEmail = () => {
    return (<Paper>
      {!this.state.showOtpVerification ? (
        <Box display={"flex"} flexDirection={"column"} padding={"20px 0px 0px 0px"}>
          <Box paddingLeft={"20px"}>
            <Typography
              className="LeagueSpartan"
              style={{ fontWeight: 700, fontSize: '20px', color: '#334155', lineHeight: '28px', }}
            >
              Change email
            </Typography>
          </Box>
          <Formik
            initialValues={{ email: '', confirm_email: '' }}
            validationSchema={emailValidationSchema}
            validateOnMount={true}
            onSubmit={this.handleEmailChange}
            style={{ margin: '0px' }}
          >
            {({ values, handleChange, isValid }) => (
              <Form>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  width={'100%'}
                  style={{ padding: '20px 0px', justifyContent: 'space-between', gap: '15px' }}
                >
                  <Box padding={"10px 20px"} style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}>
                    <Box
                      style={{ width: '44%' }}
                    >
                      <Typography className="LeagueSpartan" style={webStyle.nameHeadings}>Enter email address</Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        name="email"
                        type="text"
                        fullWidth
                        value={values.email}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="email" component="div" style={{ color: 'red', fontSize: '10px' }} />
                    </Box>
                    <Box
                      style={{ width: '44%' }}>
                      <Typography className="LeagueSpartan" style={webStyle.nameHeadings}>Confirm email address</Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        style={{ width: '100%' }}
                        name="confirm_email"
                        value={values.confirm_email}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="confirm_email" component="div" style={{ color: 'red', fontSize: '10px' }} />
                    </Box>
                  </Box>
                </Box>


                <Divider />
                <Box display={'flex'} padding={'15px 20px'} justifyContent={'end'}>
                  <Button
                    style={{
                      color: isValid ? '#fff' : '#64748B',
                      backgroundColor: isValid ? '#FAA81D' : '#F1F5F9',
                      borderRadius: '8px',
                      fontWeight: 700,
                      fontSize: '16px',
                      lineHeight: '24px',
                      padding: '16px 24px'
                    }}
                    type="submit"
                    disabled={!isValid}
                  >
                    CHANGE EMAIL
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      ) : (
        <Box sx={webStyle.form}>
          <Box display={"flex"}
            style={{ gap: '5px', padding: '10px 0px 10px 10px', alignItems: 'baseline' }}>
            <Button onClick={this.goBackToEmail} data-test-id='goBackToEmailBtn'>
              <ArrowBack />
            </Button>
            <Typography variant="h5" style={webStyle.formHeading}>Verification Code</Typography>
          </Box>
          <Box
            borderBottom={'1px solid #E2E8F0'}
            style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', padding: '20px' }}>
            <Typography style={webStyle.instructions}>Enter Verification Code</Typography>
            <Box width={"30%"}>
              <OTPInput
                containerStyle={{ justifyContent: 'space-between' }}
                value={this.state.otp}
                onChange={this.setOtp}
                data-test-id='input1'
                numInputs={4}
                inputType="tel"
                renderInput={(props) => <input {...props} />}
                inputStyle={{ ...webStyle.otpInput }}
              />
            </Box>
            <Typography align="center" variant="body2" style={{
              ...webStyle.resendText, alignSelf: 'start'
            }}>
              Don’t receive the email? <span style={webStyle.resendLink} data-test-id="reverifyBtn" onClick={this.reVerify}>Click to resend</span>
            </Typography>
          </Box>
          <Box display={"flex"} padding={"20px"} flexDirection={"column"}>
            <Button onClick={this.handleEmailOtpVerification} data-test-id='verifyBtn' style={{ ...webStyle.verifyButton, alignSelf: 'end' }}>VERIFY</Button>
          </Box>
        </Box>
      )}
    </Paper>
    )
  }

  ChangePassword = () => {
    return (
      <Paper>
        <Box display={"flex"} flexDirection={"column"} padding={"20px 0px 0px 0px"}>
          <Box paddingLeft={"20px"}>
            <Typography
              className="LeagueSpartan"
              style={{ fontWeight: 700, fontSize: '20px', color: '#334155', lineHeight: '28px' }}
            >
              Change password
            </Typography>
          </Box>
          <Formik
            initialValues={{ old_password: '', new_password: '', confirm_password: '' }}
            validationSchema={passwordValidationSchema}
            onSubmit={this.handlePasswordChange}
            validateOnMount={true}
            style={{ margin: '0px' }}
          >
            {({ values, handleChange, isValid, }) => (
              <Form
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  width={'100%'}
                  style={{ padding: '20px 0px', justifyContent: 'space-between', gap: '15px' }}
                >
                  <Box padding={"10px 20px"} style={{
                    gap: "16px",
                    display: 'flex',
                    flexDirection: "column"
                  }}>
                    <Box>
                      <Typography className="LeagueSpartan" style={webStyle.nameHeadings}>Old password</Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        name="old_password"
                        value={values.old_password}
                        onChange={handleChange}
                        type={this.state.showOldPassword ? 'text' : 'password'}
                        inputProps={{ style: { backgroundColor: "white" } }}
                        className="login_test_password"
                        InputProps={{
                          style: { backgroundColor: 'white', padding: '2' },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => this.handleToggle(1)}
                                className="password_button_type"
                              >
                                {!this.state.showOldPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        style={{ width: '390px' }}
                        placeholder="********"
                      />
                      <ErrorMessage name="old_password" component="div" style={{ color: 'red', fontSize: '10px' }} />
                    </Box>
                    <Box>
                      <Typography className="LeagueSpartan" style={webStyle.nameHeadings}>New password</Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        name="new_password"
                        type={this.state.showNewPassword ? 'text' : 'password'}
                        value={values.new_password}
                        onChange={handleChange}
                        className="login_test_password2"
                        inputProps={{ style: { backgroundColor: "white" } }}
                        InputProps={{
                          style: { backgroundColor: 'white', padding: '2' },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => this.handleToggle(2)}
                                className="password_button_type2"
                              >
                                {!this.state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        style={{ width: '390px' }}
                        placeholder="********"
                      />
                      <ErrorMessage name="new_password" component="div" style={{ color: 'red', fontSize: '10px' }} />
                    </Box>
                    <Box>
                      <Typography className="LeagueSpartan" style={webStyle.nameHeadings}>Confirm password</Typography>
                      <Field
                        as={TextField}
                        className="login_test_password3"
                        type={this.state.showConfirmPassword ? 'text' : 'password'}
                        variant="outlined"
                        name="confirm_password"
                        value={values.confirm_password}
                        onChange={handleChange}
                        inputProps={{ style: { backgroundColor: "white" } }}
                        InputProps={{
                          style: { backgroundColor: 'white', padding: '2' },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => this.handleToggle(3)}
                                className="password_button_type3"
                              >
                                {!this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        style={{ width: '390px' }}
                        placeholder="********"
                      />
                      <ErrorMessage name="confirm_password" component="div" style={{ color: 'red', fontSize: '10px' }} />
                    </Box>
                  </Box>
                </Box>

                <Divider />
                <Box display={'flex'} padding={'15px 20px'} justifyContent={'end'}>
                  <Button
                    style={{
                      color: isValid ? '#fff' : '#64748B',
                      backgroundColor: isValid ? '#FAA81D' : '#F1F5F9',
                      borderRadius: '8px', fontWeight: 700,
                      fontSize: '16px', lineHeight: '24px',
                      padding: '16px 24px'
                    }}
                    type="submit"
                    disabled={!isValid}
                  >
                    CHANGE PASSWORD
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
    )
  }

  PreferredDestination = () => {
    return (
      <Paper elevation={2}>
        <Box display={"flex"} flexDirection={"column"} padding={"20px 20px 10px 30px"} style={{ gap: '10px' }}>
          <Typography
            className="LeagueSpartan"
            style={{ fontWeight: 700, fontSize: '20px', color: '#334155', lineHeight: '28px', }}
          >
            Preferred destination
          </Typography>
          <Typography
            className="LeagueSpartan"
            style={{ fontWeight: 400, paddingTop: "10px", fontSize: '18px', color: '#334155', lineHeight: '26px', fontStyle: "normal" }}
          >
            Search or select your preferred destinations
          </Typography>

          <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            style={{ padding: '20px 0px', justifyContent: 'space-between' }}
          >
            <ToggleButtonGroup
              data-test-id="handleToggleDestinationBtn"
              onChange={this.handleToggleDestination}
              style={styles.toggleButtonGroup}
            >
              {this.state.destinationArray.map((ele, index) => (
                <ToggleButton
                  data-test-id={`toggleBtn${index}`}
                  key={ele.id}
                  style={styles.toggleButton}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  value={ele.id}
                  selected={ele.selected}
                >
                  {ele.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

          </Box>
          <Button
            style={{ fontWeight: 700, fontStyle: 'bold', alignSelf: 'start', padding: '10px 0px', fontSize: '18px', lineHeight: '26px', color: "#FAA81D", textTransform: 'none' }}
            startIcon={<AddCircleOutline style={{ color: "#FAA81D", }} />}
            data-test-id="addNewDestination"
            onClick={this.addNewDestination}
          >
            Add new
          </Button>
        </Box>
        <Divider />

        <Box
          display={'flex'} padding={'20px'} justifyContent={'end'}>
          <Button
            onClick={this.handlePreferredDestination}
            data-test-id="handlePreferredDestinationBtn"
            style={{ backgroundColor: '#FAA81D', color: '#fff', borderRadius: '8px', fontWeight: 700, fontSize: '16px', lineHeight: '24px', padding: '16px 24px' }}
          >
            SAVE
          </Button>
        </Box>

      </Paper>
    )
  }

  PreferredActivities = () => {
    return (
      <Paper elevation={2}>
        <Box display={"flex"} flexDirection={"column"} padding={"20px 20px 10px 30px"} style={{ gap: '10px' }}>
          <Typography
            className="LeagueSpartan"
            style={{ fontWeight: 700, fontSize: '20px', color: '#334155', lineHeight: '28px', }}
          >
            Preferred activities
          </Typography>
          <Typography
            className="LeagueSpartan"
            style={{
              fontWeight: 400, fontSize: '18px', color: '#334155',
              paddingTop: "10px", lineHeight: '26px', fontStyle: "normal"
            }}
          >
            Search or select your preferred activities
          </Typography>

          <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            style={{ padding: '20px 0px', justifyContent: 'space-between' }}
          >
            <ToggleButtonGroup
              data-test-id="handleToggleActivityBtn"
              onChange={this.handleToggleActivity}
              style={styles.toggleButtonGroup}
            >
              {this.state.activityArray.map((ele, index) => (
                <ToggleButton
                  data-test-id={`toggleBtn${index}`}
                  key={ele.id}
                  style={styles.toggleButton}
                  value={ele.id}
                  selected={ele.selected}
                >
                  {ele.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

          </Box>
        </Box>
        <Divider />

        <Box
          display={'flex'} padding={'20px'} justifyContent={'end'}>
          <Button
            onClick={this.handlePreferredActivity}
            data-test-id="handleToggleActivityBtn2"
            style={{ backgroundColor: '#FAA81D', color: '#fff', borderRadius: '8px', fontWeight: 700, fontSize: '16px', lineHeight: '24px', padding: '16px 24px' }}
          >
            SAVE
          </Button>
        </Box>

      </Paper>
    )
  }

  PreferredAccomodation = () => {
    return (
      <Paper elevation={2}>
        <Box display={"flex"} flexDirection={"column"} padding={"20px 20px 10px 30px"} style={{ gap: '10px' }}>
          <Typography
            className="LeagueSpartan"
            style={{ fontWeight: 700, fontSize: '20px', color: '#334155', lineHeight: '28px', }}
          >
            Preferred accomodation
          </Typography>
          <Typography
            className="LeagueSpartan"
            style={{ fontWeight: 400, paddingTop: "10px", fontSize: '18px', color: '#334155', lineHeight: '26px', fontStyle: "normal" }}
          >
            Search or select your preferred accommodation
          </Typography>

          <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            style={{ padding: '20px 0px', justifyContent: 'space-between' }}
          >
            <ToggleButtonGroup
              data-test-id="handleToggleAccommodationBtn"
              onChange={this.handleToggleAccommodation}
              style={styles.toggleButtonGroup}
            >
              {this.state.accommodationArray.map((ele, index) => (
                <ToggleButton
                  data-test-id={`toggleBtn${index}`}
                  key={ele.id}
                  style={styles.toggleButton}
                  value={ele.id}
                  selected={ele.selected}
                >
                  {ele.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

          </Box>
        </Box>
        <Divider />

        <Box
          display={'flex'} padding={'20px'} justifyContent={'end'}>
          <Button
            onClick={this.handlePreferredAccommodation}
            data-test-id="handlePreferredAccommodationBtn"
            style={{ backgroundColor: '#FAA81D', color: '#fff', borderRadius: '8px', fontWeight: 700, fontSize: '16px', lineHeight: '24px', padding: '16px 24px' }}
          >
            SAVE
          </Button>
        </Box>

      </Paper>
    )
  }

  renderProfileSettings = () => {
    const { activeField } = this.state;

    switch (activeField) {
      case 'Change name': 
        return (
          <>
            {this.ChangeName()}
          </>
        );
      case 'Change email':
        return (
          <>
            {this.ChangeEmail()}
          </>
        );
      case 'Change password':
        return (
          <>
            {this.ChangePassword()}
          </>
        );
      case 'Preferred accommodation':
        return (
          <>
            {this.PreferredAccomodation()}
          </>
        );
      case 'Preferred activities':
        return (
          <>
            {this.PreferredActivities()}
          </>
        );
      case 'Preferred destination':
        return (
          <>
            {this.PreferredDestination()}
          </>
        )
    }
  }

  renderTermsAndConditions = () => {
    return (
      <Paper style={{ padding: '40px 0px' }}>
        <Box display={"flex"} flexDirection={"column"} style={{ gap: '16px' }} padding={"0px 40px"}>
          <Typography
            className="LeagueSpartan"
            style={{ fontWeight: 700, fontSize: '20px', color: '#334155', lineHeight: '28px', }}
          >
            Terms and Conditions
          </Typography>
          {this.state.termsAndConditionsContent.map((content, index) => {
            return (
              <Typography
                key={index}
                className="LeagueSpartan"
                style={{
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '26px',
                  color: '#0F172A',
                  padding: '5px 0px'
                }}
              >{content}</Typography>
            )
          })}
        </Box>
      </Paper>
    )
  }

  renderPrivacyPolicy = () => {
    return (
      <Paper style={{ padding: '40px 0px' }}>
        <Box display={"flex"} flexDirection={"column"} style={{ gap: '16px' }} padding={"0px 40px"}>
          <Typography
            className="LeagueSpartan"
            style={{ fontWeight: 700, fontSize: '20px', color: '#334155', lineHeight: '28px', }}
          >
            Privacy policy
          </Typography>
          {this.state.privacyPolicyContent.map((content, index) => {
            return (
              <Typography
                key={index}
                className="LeagueSpartan"
                style={{
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '26px',
                  color: '#0F172A',
                  padding: '5px 0px'
                }}
              >{content}</Typography>
            )
          })}
        </Box>
      </Paper>
    )
  }

  renderGeneralSettings = () => {
    return (
      <Paper>
        <Container style={{ height: '100%', padding: '20px', borderRadius: '8px' }}>
          <Box display={"flex"} style={{ gap: '16px' }} flexDirection={"column"}>
            <Typography style={webStyle.generalFormHeading} >Notification</Typography>
            <Paper elevation={2} style={webStyle.generalFormBoxes}>
              <Typography style={webStyle.generalFormSubHeading} >Email Notification</Typography>
              <SwitchBtn
                checked={this.state.emailNotification}
                data-test-id="handleToggleGeneral1"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleToggleGeneral('emailNotification', event)} />
            </Paper>

            <Paper elevation={2} style={webStyle.generalFormBoxes}>
              <Typography style={webStyle.generalFormSubHeading}>SMS Notification</Typography>
              <SwitchBtn
                checked={this.state.smsNotification}
                data-test-id="handleToggleGeneral2"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleToggleGeneral('smsNotification', event)} />
            </Paper>

            <Paper elevation={2} style={webStyle.generalFormBoxes}>
              <Typography style={webStyle.generalFormSubHeading}>Push Notification</Typography>
              <SwitchBtn
                checked={this.state.pushNotification}
                data-test-id="handleToggleGeneral3"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleToggleGeneral('pushNotification', event)} />
            </Paper>
            <>
              <Typography style={{ ...webStyle.generalFormHeading, fontSize: '16px' }}>
                What kind of notification you would like to receive?
              </Typography>
              <Box display={'flex'} alignItems={"center"} style={{ gap: '8px' }}>
                <OrangeCheckbox checked={this.state.tripUpdates}
                  data-test-id="handleToggleGeneral4"
                  style={{ height: '20px', width: '20px', borderRadius: '6px', padding: 0 }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleToggleGeneral('tripUpdates', event)}
                />
                <Typography style={webStyle.generalFormSubHeading}>Trip Updates</Typography>
                <OrangeCheckbox checked={this.state.promotions} style={{ padding: 0 }}
                  data-test-id="handleToggleGeneral5"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleToggleGeneral('promotions', event)}
                />
                <Typography style={webStyle.generalFormSubHeading}>Promotions</Typography>
              </Box>
            </>
          </Box>

          <Box paddingY={"20px"}>
            <Divider />
          </Box>

          <Box display={"flex"} style={{ gap: '16px' }} flexDirection={"column"}>
            <Typography style={webStyle.generalFormHeading} >Location & Bluetooth</Typography>
            <Paper elevation={2} style={webStyle.generalFormBoxes}>
              <Typography style={webStyle.generalFormSubHeading}>GPS Location</Typography>
              <SwitchBtn
                checked={this.state.gpsLocation}
                data-test-id="handleToggleGeneral6"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleToggleGeneral('gpsLocation', event)}
              />
            </Paper>
            <Paper elevation={2} style={webStyle.generalFormBoxes}>
              <Typography style={webStyle.generalFormSubHeading}>Bluetooth</Typography>
              <SwitchBtn
                checked={this.state.bluetooth}
                data-test-id="handleToggleGeneral7"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleToggleGeneral('bluetooth', event)}
              />
            </Paper>
          </Box>

          <Box paddingY='20px' >
            <Divider />
          </Box>

          <Typography style={{ ...webStyle.generalFormHeading, padding: '10px 0px' }}>Change Language</Typography>
          <Paper elevation={2}>
            <RadioGroup
              style={{
                display: 'flex',
                width: "100%",
                flexDirection: "column",
                borderRadius: '10px'
              }}
              value={this.state.language} data-test-id="handleChangeGeneral" onChange={this.handleChangeGeneral('language')}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #E2E8F0' }}>
                <FormControlLabel
                  style={webStyle.FormControlCheck}
                  labelPlacement="start"
                  label={
                    <Typography style={webStyle.formControlLanguageHeading}>English</Typography>
                  }

                  value="English"
                  control=
                  {
                    <Radio
                      style={webStyle.radioBtn}
                      icon={<span style={{ display: 'none' }} />}
                      checkedIcon={<MdCheck color="#FAA81D" />}
                    />
                  }
                />
              </Box >
              <Box style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #E2E8F0' }}>
                <FormControlLabel
                  style={webStyle.FormControlCheck}
                  labelPlacement="start"
                  label={
                    <Typography style={webStyle.formControlLanguageHeading}>Spanish</Typography>
                  }
                  value="Spanish"
                  control=
                  {
                    <Radio
                      style={webStyle.radioBtn}
                      icon={<span style={{ display: 'none' }} />}
                      checkedIcon={<MdCheck color="#FAA81D" />}
                    />
                  }
                />
              </Box >
              <Box style={{ display: 'flex', justifyContent: 'space-between', }}>
                <FormControlLabel
                  style={webStyle.FormControlCheck}
                  labelPlacement="start"
                  label={
                    <Typography style={webStyle.formControlLanguageHeading}>Icelandic</Typography>
                  }
                  value="Icelandic"
                  control=
                  {
                    <Radio
                      style={webStyle.radioBtn}
                      icon={<span style={{ display: 'none' }} />}
                      checkedIcon={<MdCheck color="#FAA81D" />}
                    />
                  }
                />
              </Box >
            </RadioGroup>
          </Paper>

          <Typography style={{ ...webStyle.generalFormHeading, padding: '10px 0px', marginTop: '20px' }}>Change Currency</Typography>
          <Paper elevation={2}>
            <RadioGroup
              style={{
                display: 'flex',
                width: "100%",
                flexDirection: "column",
                borderRadius: '10px'
              }}
              value={this.state.currency} onChange={this.handleChangeGeneral('currency')}
            >
              <Box style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #E2E8F0' }}>
                <FormControlLabel
                  style={webStyle.FormControlCheck}
                  labelPlacement="start"
                  label={
                    <Typography style={webStyle.generalFormCurrency}>
                      <img src={ic_euro} alt="" />
                      Euro</Typography>
                  }
                  value="Euro"
                  control=
                  {
                    <Radio
                      style={webStyle.radioBtn}
                      icon={<span style={{ display: 'none' }} />}
                      checkedIcon={<MdCheck color="#FAA81D" />}
                    />
                  }
                />
              </Box >
              <Box style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #E2E8F0' }}>
                <FormControlLabel
                  style={webStyle.FormControlCheck}
                  labelPlacement="start"
                  label={
                    <Typography style={webStyle.generalFormCurrency}>
                      <img src={ic_dollar} alt="" />
                      USA Dollar</Typography>
                  }
                  value="USD"
                  control=
                  {
                    <Radio
                      style={webStyle.radioBtn}
                      icon={<span style={{ display: 'none' }} />}
                      checkedIcon={<MdCheck color="#FAA81D" />}
                    />
                  }
                />
              </Box >
              <Box style={{ display: 'flex', justifyContent: 'space-between', }}>
                <FormControlLabel
                  style={webStyle.FormControlCheck}
                  labelPlacement="start"
                  label={
                    <Typography style={webStyle.generalFormCurrency}>
                      <img src={ic_pound} alt="" />
                      British Pound</Typography>
                  }
                  value="GBP"
                  control=
                  {
                    <Radio
                      style={webStyle.radioBtn}
                      icon={<span style={{ display: 'none' }} />}
                      checkedIcon={<MdCheck color="#FAA81D" />}
                    />
                  }
                />
              </Box >
            </RadioGroup>
          </Paper>

          <Paper elevation={2}>
            <Button variant="text" style={{
              marginTop: '20px',
              color: "#DC2626",
              display: 'flex',
              padding: '12px 8px',
              gap: '8px',
            }}
              data-test-id="ShowDeleteAccount"
              onClick={this.ShowDeleteAccount}
            >
              <img src={ic_delete} color="#DC2626" />
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px'
                }}
              >
                Delete account
              </Typography>
            </Button>
          </Paper>

          <Box paddingY='20px' >
            <Divider />
          </Box>

          <Button variant="contained"
            style={{
              color: '#fff',
              backgroundColor: '#FAA81D',
              padding: '16px 24px',
              fontWeight: 700,
              borderRadius: '8px', float: 'right',
              fontSize: '16px',
              marginBottom: '20px'
            }}
            data-test-id="saveChanges"
            onClick={this.saveChanges}
          >
            Save
          </Button>
        </Container>
      </Paper>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <style>
          {`
          .MuiToggleButton-root{
            border: 1px solid #64748B;
            color: #1E293B;
          }
          .MuiToggleButton-root.Mui-selected {
            background-color: #FEFCF9;
            border: 1px solid #FAA81D !important; 
            color: #FAA81D !important;
          }
          .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
            border: 1px solid #64748B; 
            margin-left: 0;
          }
          `}
        </style>

        {/* main */}
        <Box display={'flex'} flexDirection={'row'} height={'100%'}>
          {/* left */}
          <Box width={'120px'}>
            <NavigationMenu navigation={undefined} id={""} />
          </Box>

          {/* right */}
          <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'} style={{
            backgroundImage: `url(${ic_background})`,
            backgroundRepeat: "no-repeat", backgroundSize: 'cover',
          }}>

            {/* header for setting */}
            <Box display={'flex'} justifyContent={"space-between"} padding={'20px 40px'} style={{ borderLeft: "1px #CBD5E1 solid" }}>
              <Typography variant="h5" className="LeagueSpartan" style={{ fontWeight: 700, fontSize: '24px', color: "#0F172A" }}>Settings</Typography>
              <Button><img src={ic_notifications} alt="" /></Button>
            </Box>

            {/* settings */}
            <Box display={'flex'} flexDirection={'row'} >

              {/* left */}
              <Box width={"360px"} style={{ backgroundColor: '#fff', borderLeft: "1px #CBD5E1 solid", minHeight: '100vh' }} padding={"10px 15px 0px 10px"}  >
                {/* innerBox */}
                <Box padding={'10px'} style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}>
                  {/* Profile box */}
                  <Box display={"flex"} width={'310px'} borderRadius={'8px'} padding={"16px 20px"} style={{ backgroundColor: this.state.profileSettings ? '#FEF3C7' : '#fff', gap: "10px" }}>
                    <img height={"24px"} width={"24px"} src={ic_profile} alt="" />
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography variant="h6" style={webStyle.leftHeadings} className="LeagueSpartan">Profile Settings</Typography>
                      <Box style={{ padding: "10px 0px 0px 4px" }}>
                        {fields.map((field, index) => (
                          <Typography
                            onClick={() => this.handleFieldClick(field)}
                            key={index}
                            data-test-id={`case${index}`}
                            className="LeagueSpartan"
                            style={this.state.activeField === field ? webStyle.profileFieldActive : webStyle.profileFields}>
                            {field}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  {/* general */}
                  <Box style={{
                    ...webStyle.list,
                    backgroundColor: this.state.generalSettings ? '#FEF3C7' : '#fff'
                  }}
                    data-test-id="handleGeneralSettings" onClick={this.handleGeneralSettings}>
                    <img height={"24px"} width={"24px"} src={ic_settings} alt="" />
                    <Typography variant="h6" style={webStyle.leftHeadings} className="LeagueSpartan">General Settings</Typography>
                  </Box>
                  {/* Terms & conditions */}
                  <Box
                    style={{ ...webStyle.list, backgroundColor: this.state.termsAndConditions ? '#FEF3C7' : '#fff' }}
                    data-test-id="termsBlock"
                    onClick={this.handleTermsAndConditions}
                  >
                    <img height={"24px"} width={"24px"} src={ic_error} alt="" />
                    <Typography variant="h6" style={webStyle.leftHeadings} className="LeagueSpartan">Terms & Conditions</Typography>
                  </Box>
                  {/* Privacy Policy */}
                  <Box
                    style={{ ...webStyle.list, backgroundColor: this.state.privacyPolicy ? '#FEF3C7' : '#fff' }}
                    data-test-id="privacyBlock"
                    onClick={this.handlePrivacyPolicy}
                  >
                    <img height={"24px"} width={"24px"} src={ic_privacy} alt="" />
                    <Typography variant="h6" style={webStyle.leftHeadings} className="LeagueSpartan">Privacy Policy</Typography>
                  </Box>
                  {/*Log out  */}
                  <Box style={webStyle.list} onClick={this.handleLogoutBox} data-test-id="logoutBox" >
                    <img height={"24px"} width={"24px"} src={ic_logout} alt="" />
                    <Typography variant="h6" style={{ color: '#DC2626', fontWeight: 700, fontSize: '20px', lineHeight: '28px' }} className="LeagueSpartan">Log out</Typography>
                  </Box>

                </Box>
              </Box>

              {/* Right */}
              <Box width={"100"} style={{
                padding: "20px 40px", width: "100%"
              }}>
                {this.state.profileSettings && this.renderProfileSettings()}
                {this.state.generalSettings && this.renderGeneralSettings()}
                {this.state.termsAndConditions && this.renderTermsAndConditions()}
                {this.state.privacyPolicy && this.renderPrivacyPolicy()}
              </Box>
            </Box>

          </Box>
        </Box>

        <Dialog open={this.state.showSuccessDialog}>
          <Box style={{ display: "flex", flexDirection: 'column', alignItems: 'center', padding: '5px', width: '440px', height: '350px' }}>
            <DialogContent >
              <Box sx={webStyle.dialog}>
                <img height='100px' width='100px' src={ic_check} />
                <Typography style={{ fontWeight: 700, letterSpacing: '-2px', fontSize: '24px', marginTop: '5px', color: '#0F172A', lineHeight: '32px' }}>
                  {this.state.showEmailDialogContent ? "Email updated" : "Password saved"}
                </Typography>
                <Box textAlign={"center"}>
                  <Typography style={{ color: '##334155', fontWeight: 400, fontSize: '18px' }}>
                    {this.state.showEmailDialogContent ? "Your email successfully updated." : "Your password successfully saved"}
                  </Typography>
                  <Typography style={{ color: '##334155', fontWeight: 400, fontSize: '18px' }}>
                    Click below to continue.
                  </Typography>
                </Box>
                <Button onClick={this.closeSuccessDialogBox} data-test-id="closeSuccessDialogBox" variant="outlined" fullWidth style={{ backgroundColor: '#FAA81D', borderRadius: '8px', padding: '16px' }}>
                  <Typography style={{
                    color: "#fff",
                    lineHeight: "24px",
                    fontWeight: 700,
                    fontSize: "16px"
                  }}>
                    CONTINUE
                  </Typography>
                </Button>
              </Box>

            </DialogContent>
          </Box>
        </Dialog>

        <Dialog open={this.state.showEmailDialogBox}>
          <style>
            {`
          .MuiPaper-rounded{
            border-radius:8px 8px 32px 8px
          }
            `}
          </style>
          <DialogContent style={{ height: "280px", padding: "10px 0px", width: "600px", }}>
            <Box display={"flex"} flexDirection={"column"} >
              <Box alignSelf={"end"}>
                <Button onClick={this.closeEmailDialogBox} data-test-id="closeDialogBtn">
                  <Clear />
                </Button>
              </Box>
              <Box padding={'25'} style={{
                gap: "10px",
                display: "flex",
                flexDirection: "column"
              }}>
                <Typography className="LeagueSpartan" style={{ color: '#0F172A', fontWeight: 700, fontSize: '20px', lineHeight: '32px' }}>Your email address has been updated!</Typography>
                <Typography className="LeagueSpartan" style={{ color: '#0F172A', fontWeight: 400, fontSize: '15px', lineHeight: '26px' }}>We sent you a verification code to confirm your new email. Please <br /> open this link to verify the change.</Typography>
              </Box>
              <Box style={{ gap: '10px' }} display={'flex'} padding={'24px 24px 0px 0px'} justifyContent={'end'} borderTop={"1px #CBD5E1 solid"} >
                <Button
                  onClick={this.closeEmailDialogBox}
                  data-test-id="cancelEmailDialogBtn"
                  style={{
                    color: '#FAA81D',
                    border: '#FAA81D 1px solid',
                    borderRadius: '8px',
                    padding: '16px 26px',
                    fontWeight: 700, fontSize: '16px',
                    lineHeight: '24px'
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  data-test-id="checkEmailDialogBtn"
                  onClick={this.handleEmailDialogContinue}
                  style={{
                    color: '#fff',
                    backgroundColor: '#FAA81D',
                    borderRadius: '8px',
                    padding: '16px',
                    fontWeight: 700,
                    fontSize: '16px', lineHeight: '24px'
                  }}
                >
                  OPEN EMAIL
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.showAddDestinationBox}>
          <style>
            {`
          .MuiPaper-rounded{
            border-radius:8px 8px 32px 8px
          }
            `}
          </style>
          <DialogContent
            style={{ height: "280px", padding: "10px 0px", width: "600px", }}
          >
            <Box display={"flex"} flexDirection={"column"} style={{ gap: '10px' }} >
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}
              >
                <Typography
                  className="LeagueSpartan"
                  style={{ paddingLeft: '25px', color: '#0F172A', fontWeight: 700, fontSize: '15px', lineHeight: '26px' }}>
                  Search and add new destinations
                </Typography>
                <Box
                  alignSelf={"end"}
                >
                  <Button
                    data-test-id="closeAddDestinationBox"
                    onClick={this.closeAddDestinationBox}
                  >
                    <Clear />
                  </Button>
                </Box>
              </Box>
              <Divider />
              <Box padding="25px">

                <TextField
                  variant="outlined"
                  fullWidth
                  value={this.state.newDestination}
                  onChange={this.handleNewDestination}
                  data-test-id="handleNewDestination"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Place style={{ color: "#FAA81D" }} />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search"
                />
              </Box>
              <Divider />

              <Box style={{ gap: '10px' }}
                display={'flex'} padding={'24px 24px 0px 0px'} justifyContent={'end'} >
                <Button
                  onClick={this.closeAddDestinationBox}
                  data-test-id="cancelEmailDialogBtn"
                  style={{
                    borderRadius: '8px',
                    color: '#FAA81D',
                    border: '#FAA81D 1px solid',
                    fontWeight: 700, fontSize: '16px',
                    padding: '16px 26px',
                    lineHeight: '24px'
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  data-test-id="addNewDestinationToArray"
                  onClick={this.addNewDestinationToArray}
                  style={{
                    color: '#fff',
                    backgroundColor: '#FAA81D',
                    fontWeight: 700,
                    padding: '16px',
                    fontSize: '16px', lineHeight: '24px',
                    borderRadius: '8px',
                  }}
                >
                  Add Destination
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.showLogOutBox}>
          <style>
            {`
          .MuiPaper-rounded{
            border-radius:8px 8px 32px 8px
          }
            `}
          </style>
          <DialogContent
            style={{ padding: "10px 0px 0px 0px", width: "600px", }}
          >
            <Box display={"flex"} flexDirection={"column"} style={{ gap: '10px' }} >
              <Box alignSelf={"end"}>
                <Button
                  data-test-id="closeLogoutBox"
                  onClick={this.closeLogoutBox}
                >
                  <Clear />
                </Button>
              </Box>


              <Typography
                className="LeagueSpartan"
                style={{
                  paddingLeft: '25px',
                  color: '#0F172A',
                  fontWeight: 700,
                  fontSize: '24px',
                  lineHeight: '32px',
                  letterSpacing: '-0.5'
                }}>
                Are you sure you want to log out of your account?
              </Typography>
              <Divider />

              <Box style={{ gap: '10px' }}
                display={'flex'} padding={'24px 24px 24px 0px'} justifyContent={'end'} >
                <Button
                  onClick={this.closeLogoutBox}
                  data-test-id="closeLogoutBox2"
                  style={{
                    borderRadius: '8px',
                    color: '#FAA81D',
                    border: '#FAA81D 1px solid',
                    fontWeight: 700, fontSize: '16px',
                    padding: '16px 26px',
                    lineHeight: '24px'
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  data-test-id="handleLogout"
                  onClick={this.handleLogout}
                  style={{
                    color: '#fff',
                    backgroundColor: '#FAA81D',
                    fontWeight: 700,
                    padding: '16px 24px',
                    fontSize: '16px', lineHeight: '24px',
                    borderRadius: '8px',
                  }}
                >
                  Logout
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog open={this.state.showDeleteAccount}>
          <style>
            {`
            .MuiPaper-rounded{
              border-radius:8px 8px 32px 8px
            }`
            }
          </style>
          <DialogContent style={{ height: "280px", padding: "10px 0px", width: "600px", }}>
            <Box display={"flex"} flexDirection={"column"} >
              <Box alignSelf={"end"}>
                <Button onClick={this.closeShowDeleteAccount} data-test-id="closeShowDeleteAccountCross">
                  <Clear />
                </Button>
              </Box>
              <Box padding={'15 20'} style={{
                gap: "10px",
                display: "flex",
                flexDirection: "column"
              }}>
                <Typography className="LeagueSpartan" style={{ color: '#0F172A', fontWeight: 700, fontSize: '24px', lineHeight: '32px' }}
                >
                  Are you sure you want to delete "user” account?
                </Typography>
                <Typography className="LeagueSpartan" style={{ color: '#0F172A', fontWeight: 400, fontSize: '15px', lineHeight: '26px' }}
                >
                  It will be impossible to cancel this action. Please click “delete account” <br /> to confirm the change
                </Typography>
              </Box>
              <Box style={{ gap: '10px' }} display={'flex'} padding={'24px 24px 0px 0px'} justifyContent={'end'} borderTop={"1px #CBD5E1 solid"} >
                <Button
                  onClick={this.closeShowDeleteAccount}
                  data-test-id="closeShowDeleteAccount"
                  style={{
                    color: '#DC2626',
                    borderRadius: '8px',
                    padding: '16px 26px',
                    fontWeight: 700, fontSize: '16px',
                    lineHeight: '24px'
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  data-test-id="DeleteAccount"
                  onClick={this.DeleteAccount}
                  style={{
                    color: '#fff',
                    backgroundColor: '#DC2626',
                    borderRadius: '8px',
                    padding: '16px',
                    fontWeight: 700,
                    fontSize: '16px', lineHeight: '24px'
                  }}
                >
                  DELETE
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>


      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  dialog: {
    width: "360px",
    height: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center"
  },
  list: {
    display: 'flex',
    padding: '16px 20px',
    gap: '10px',
    borderRadius: '8px',
    alignItems: 'center',
    cursor: 'pointer'
  },
  profileFieldActive: {
    borderLeft: '#FBBF24 1px solid',
    padding: '4px 16px',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    cursor: 'pointer',
    color: '#0F172A'
  },
  nameHeadings: {
    color: '#475569',
    fontSize: '14px',
    fontWeight: 700,
    padding: "5px 0px"
  },
  profileFields: {
    borderLeft: '#CBD5E1 1px solid',
    padding: '4px 16px',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#0F172A',
    cursor: 'pointer',
  },
  leftHeadings: {
    color: '#0F172A',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '24px',
  },
  formHeading: {
    fontWeight: 700,
    fontSize: '30px',
    color: '#0F172A',
    letterSpacing: '-2',
    marginBottom: '10px'
  },
  instructions: {
    fontSize: '16px',
    color: '#334155',
    width: '100%'
  },
  otpInput: {
    height: '56px',
    width: '56px',
    fontSize: '24px',
    margin: '6px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    color: '#4B4C4B',
    gap: '20px'
  },
  verifyButton: {
    backgroundColor: '#FAA81D',
    color: 'white',
    padding: '16 24 16 24',
    fontSize: '16px',
    fontWeight: 700,
  },
  resendText: {
    color: '#0F172A',
    fontSize: '14px',
    fontWeight: 400,
    marginTop: '20px',
  },
  resendLink: {
    color: '#FAA81D',
    cursor: "pointer",
    fontWeight: 700,
    marginLeft: '5px'
  },
  generalFormHeading: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    color: '#0F172A',
    fontFamily: 'League Spartan , sans-serif'
  },
  generalFormSubHeading: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#0F172A',
    fontFamily: 'League Spartan , sans-serif'
  },
  generalFormBoxes: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: ' 12px 16px',
    borderRadius: "8px",
    alignItems: "center",
  },
  generalFormCurrency: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: '0',
    padding: '0',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#0F172A',
    fontFamily: 'League Spartan , sans-serif'
  },
  FormControlCheck: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '16px',
    margin: '0',
  },
  radioBtn: { margin: 0, padding: 0 },
  formControlLanguageHeading: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#0F172A',
    fontFamily: 'League Spartan , sans-serif',
    margin: 0, padding: 0
  }
};
// Customizable Area End
