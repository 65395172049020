import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start


export interface Data{
  prefVehicle: string;
  make: string;
  model: string;
  year: string;
  mileage: string;
  seats: string;
  doors: string;
  carRegNo: string;
  vin: string;
  countryOfReg: string;
  fuel: string;
  prefTransmission: string;
  destinationArray: Array<UserDataArrayType>,
  priceFrom: string,
  priceTo: string,
}

export interface UserDataArrayType {
  id: number,
  name: string,
  selected: boolean
}
export interface Errors {
  mileage: string;
  carRegNo: string;
  vin: string;
  priceFrom: string,
  priceTo: string,
}
export interface Option {
  label: string;
  value: string;
}

const initialDestinationArray: Array<UserDataArrayType> = [
  { id: 1, name: "Air conditioning", selected: false },
  { id: 2, name: "Stability control", selected: false },
  { id: 3, name: "GPS", selected: false },
  { id: 4, name: "Child seat", selected: false },
  { id: 5, name: "Roof box", selected: false },
  { id: 6, name: "Bike rack", selected: false },
  { id: 7, name: "Winter tyres", selected: false },
  { id: 8, name: "Chains", selected: false },
  { id: 9, name: "Ski rack", selected: false },
  { id: 10, name: "Heated seats", selected: false },
  { id: 11, name: "Bluetooth audio", selected: false },
  { id: 12, name: "ABS", selected: false },
  { id: 13, name: "Apple CarPlay", selected: false },
  { id: 14, name: "Android Auto", selected: false },
  { id: 15, name: "Sunroof", selected: false },
];

interface ImageWithRotation {
  file: File;
  rotation: number;
}

interface IsEditing {
  [id: number]: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
navigation: any;
id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeStep: number;
    data : Data;
    errors: Errors;
    showAddDestinationBox: boolean;
    newDestination: string;
    files: ImageWithRotation[];
    isDragging: boolean;
    anchorEl: HTMLElement | null;
    selectedFileIndex: number | null;
    isEditing: IsEditing;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CarCatalogueController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    postCarCatalogueApiId = ""
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        activeStep: 0,
        isEditing: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
        },
        anchorEl: null,
        selectedFileIndex: null,
        files: [],
        isDragging: false,
        data: {
          prefVehicle: "",
          make: "",
          model: "",
          year: "",
          mileage: "",
          carRegNo: "",
          vin: "",
          countryOfReg: "",
          seats: "",
          doors: "",
          fuel: "",
          prefTransmission: "",
          destinationArray: initialDestinationArray,
          priceFrom: "",
          priceTo: "",
        },
        showAddDestinationBox: false,
        newDestination: "",
        errors: {
          mileage: "",
          carRegNo: "",
          vin: "",
          priceFrom: "",
          priceTo: "",
        }
        // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
      runEngine.debugLog("Message Recived", message);
      // Customizable Area End
    }

  
    // Customizable Area Start

  
    handleConfirm = async() => {
      const header = {
        token : await getStorageData("token"),
      };

      let formData = new FormData();
      formData.append("category_id", "1");
      formData.append("sub_category_id", "1");


      formData.append("vehicle_type", this.state.data.prefVehicle);
      formData.append("make", this.state.data.make);
      formData.append("model", this.state.data.model);
      formData.append("year", this.state.data.year);
      formData.append("mileage", this.state.data.mileage);
      formData.append("registration_number", this.state.data.carRegNo);
      formData.append("vehicle_identification_number", this.state.data.vin);
      formData.append("country_of_registration", this.state.data.countryOfReg);
      formData.append("number_of_doors", this.state.data.doors);
      formData.append("number_of_seats", this.state.data.seats);
      formData.append("fuel", this.state.data.fuel);
      formData.append("transmission", this.state.data.prefTransmission);

      const selectedFeatures = this.state.data.destinationArray
      .filter(destination => destination.selected)
      .map(destination => destination.name);

      selectedFeatures.forEach((feature, index) => {
        formData.append(`features[${index}]`, feature);
      });

      formData.append("capacity_of_luggage", "1"); 
      formData.append("loc", this.state.data.countryOfReg); 
      formData.append("min_price", this.state.data.priceFrom);
      formData.append("max_price", this.state.data.priceTo);
      formData.append("discount", "0"); 
      

      this.state.files.forEach((image) => {
        let selectedImageData = image as unknown as {file : Blob};
        formData.append("images[]", selectedImageData.file);
      });

      formData.append("purpose_of_usage", "Business Car"); 
      formData.append("daily_price", 100 as unknown as string); 
      formData.append("weekly_price", 30 as unknown as string); 
      formData.append("monthly_price", 60 as unknown as string); 

      const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
      message.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postCarCatalogue
      );
      message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);
      message.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
      message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
      this.postCarCatalogueApiId = message.messageId;
  
      runEngine.sendMessage(message.messageId, message);

      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }

    handleEditClick = (id: number) => {
      this.setState(prevState => ({
        isEditing: {
          ...prevState.isEditing,
          [id]: !prevState.isEditing[id]
        }
      }));
    };

    handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      this.setState({ isDragging: false });
    
      if (event.dataTransfer.files.length) {
        const filesWithRotation = Array.from(event.dataTransfer.files).map(file => ({
          file,
          rotation: 0
        }));
        this.setState(prevState => ({
          files: [...prevState.files, ...filesWithRotation]
        }));
      }
    };
  
    handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      this.setState({ isDragging: true });
    };
  
    handleDragLeave = () => {
      this.setState({ isDragging: false });
    };
  
    handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const newFiles = Array.from(event.target.files).map(file => ({
          file,
          rotation: 0
        }));
    
        this.setState((prevState) => ({
          files: [...prevState.files, ...newFiles]
        }));
      }
    };
    
    handleImageBlob=(image:string | Blob)=>{
      let imageSrc;
      if (typeof(image) != "string") {
      imageSrc = URL.createObjectURL(image);
      }
      return imageSrc;
    }
    
    handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
      this.setState({
        anchorEl: event.currentTarget,
        selectedFileIndex: index,
      });
    };
  
    handleMenuClose = () => {
      this.setState({ anchorEl: null, selectedFileIndex: null });
    };
    
    handleMakeCover = (index: number) => {
      this.setState((prevState) => {
        const updatedFiles = [...prevState.files];
        const [selectedCover] = updatedFiles.splice(index, 1); 
        updatedFiles.unshift(selectedCover); 
    
        return {
          files: updatedFiles,
          anchorEl: null, 
          selectedFileIndex: null, 
        };
      });
    };
    
    // Function to handle deleting an image
    handleDelete = (index: number) => {
      this.setState((prevState) => {
        const updatedFiles = [...prevState.files];
        updatedFiles.splice(index, 1); 
    
        return {
          files: updatedFiles,
          anchorEl: null, 
          selectedFileIndex: null, 
        };
      });
    };
    
    handleRotate = (index: number) => {
      this.setState(prevState => {
        const updatedFiles = [...prevState.files];
        updatedFiles[index].rotation = (updatedFiles[index].rotation + 90) % 360;
        return { files: updatedFiles,anchorEl: null, 
          selectedFileIndex: null };
      });
    };

    closeAddDestinationBox = () => {
      this.setState({ showAddDestinationBox: false, newDestination: "" });
    };
  
    addNewDestination = () => {
      this.setState({ showAddDestinationBox: true });
    };
  
    handleNewDestination = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ newDestination: event.target.value });
    };
  
    addNewDestinationToArray = () => {
      const newDestinationObject: UserDataArrayType = {
        id: this.state.data.destinationArray.length + 1,
        name: this.state.newDestination,
        selected: true,
      };
  
      this.setState((prevState) => ({
        newDestination: "",
        showAddDestinationBox: false,
        data: {
          ...prevState.data,
          destinationArray: [...prevState.data.destinationArray, newDestinationObject],
        },
      }));
    };

    handleToggleDestination = (event: React.MouseEvent<HTMLElement>, id: Array<number>) => {
      if (id !== null) {
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            destinationArray: prevState.data.destinationArray.map(item =>
              item.id === id[0] ? { ...item, selected: !item.selected } : item
            )
          }
        }));
      }
    };
    

    onValueChange = (name: keyof S['data'], value: any) => {
      const errors = { ...this.state.errors };
    
      const validationRules: { [key in keyof S['data']]?: (value: any) => string } = {
        mileage: (value) => (!/^\d*$/.test(value) ? 'The current mileage should only contain numbers' : ''),
        carRegNo: (value) => (!/^[A-Za-z0-9]*$/.test(value) ? 'The registration number should only contain numbers and letters' : ''),
        vin: (value) => {
          if (value.length === 0) {
            return '';
          }
          if (!/^\d{12}$/.test(value)) {
            return 'Enter a valid 12-digit VIN number';
          }
          return '';
        },
        priceFrom: (value) => (!/^\d*$/.test(value) ? 'The price range should contain only numbers.' : ''),
        priceTo: (value) => (!/^\d*$/.test(value) ? 'The price range should contain only numbers.' : ''),
      };
    
      const validateField = (name: keyof S['data'], value: any) => {
        const validate = validationRules[name];
        return validate ? validate(value) : '';
      };
    
      if (name in errors) {
        errors[name as keyof Errors] = validateField(name, value);
      }
    
      this.setState({
        data: { ...this.state.data, [name]: value },
        errors,
      });
    };
    
    
  
    setValue = (field: string, values: Option[]) => {
      const selectedValue = values.length > 0 ? values[0].value : "";
      this.setState(prevState => ({
        data: {
          ...prevState.data,
          [field]: selectedValue,
        },
      }));
    };
  
    handleBackClick = () => {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep - 1,
      }));
    }
  
    handleNextClick = () => {
      if (this.canProceedToNextStep()) {
        this.setState((prevState) => ({
          activeStep: prevState.activeStep + 1,
        }));
      }
    };
  
    handleDoorsChange = (event: React.MouseEvent<HTMLElement>, newDoors: string | null) => {
      if (newDoors !== null) {
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            doors: newDoors,
          },
        }));
      }
    };
  
    handleSeatsChange = (event: React.MouseEvent<HTMLElement>, newSeats: string | null) => {
      if (newSeats !== null) {
        this.setState(prevState => ({
          data: {
            ...prevState.data,
            seats: newSeats,
          },
        }));
      }
    };
  
    canProceedToNextStep = () => {
      const { activeStep, data, errors } = this.state;
      const { prefVehicle, mileage, carRegNo, vin , priceFrom, priceTo, doors, seats, fuel, prefTransmission} = data;
    
      switch (activeStep) {
        case 1:
          
          return prefVehicle.trim() !== "";
        case 3:
          
          return mileage.trim() !== "" && !errors.mileage;
        case 4:
          
          return carRegNo.trim() !== "" && vin.trim() !== "" && !errors.carRegNo && !errors.vin;
        case 5:
        
          return doors !== '' && seats !== '';
        case 6:
        
          return fuel.trim() !== "" && prefTransmission.trim() !== "";
        case 8:
          
          return priceFrom.trim() !== "" && priceTo.trim() !== "" && !errors.priceFrom && !errors.priceTo;
        default:
          return true;
      }
    };

  
    // Customizable Area End
}
